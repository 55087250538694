@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');


$main-font: "DM Sans", serif;
$main-color: #fcfcfc;
$orangecolor:#F05125;
$bluecolor:#077DCC;
$secgrycolor:#F5F5F5;
$blackcolor:#000000;
$lightorangecolor:#FF6A46;
$textblackcolor:#2a2a2a;
$orangelightcolor:#F8ECEB;
$whitecolor:#ffffff;
$lightbluebg:#DEE9F0;
$colorborder:#c9c9c9;
$bgteamcolor:#E9E9E9;
$bglightmoregrey:#F2F2F2;
$carouslround:#ACACAC;
$keybgshadow:#9b9b9b59;
$greycolor:#f9f9f9;
$borderslash:#bcbcbc;
$borderbelow:#d6d6d6;
$geryfotcolor:#efefef;
$bordercareer:#b4b4b4;
$borderform:#cccccc;
$red:#cf0000;
$green:#188351;
$greyicon:#585858;
$successroundgreen:#4bb71b;
$likegrey:#3e3e3e;




* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }
  
  body {
    font-family: $main-font;
    background-color: $main-color;
   
  }

  @mixin pos-block{
    width: 100%;
    position: relative;
    display: block;
  }
  @mixin pos-inlineblock{
    width: 100%;
    position: relative;
    display: inline-block;
  }
  .main-sec-header {
     margin-bottom: -6px;
     position: fixed;
     width: 100%;
     z-index: 999;
  }

  .navbar-brand {
    margin-right: 0px;
    img {
      height: 55px;
     
    }
  }

  .navbar-expand-lg{
    .navbar-collapse {
        justify-content: flex-end;
    }
    .navbar-nav {
        margin-top: 5px;
    }
  } 

  .nav-menu {
    line-height: 35px;
   
}

.navbar-nav{
    overflow: hidden;
    line-height: 50px;
}
.nav-link-menu{
    position: relative;
    display: inline-block;
    padding: 0px 17px;
    color: $blackcolor;
    font-size: 17px;
    text-decoration: none;
    margin: 0px 5px;
    line-height: 40px;
    &:hover{
        color: $lightorangecolor;
        &::before{
            opacity: 1;
            top:0;
        }
    }
    &::before{
        content: "";
        position: absolute;
        left: 0;
        top: 110%;
        width: 100%;
        height: 100%;
        background-color: #f051251f;
        // z-index: -1;
        opacity: 1;
        transition: all 0.2s ease-out;
        border-top: 3px solid $lightorangecolor !important;
        border:1px solid #f0512547;
    }
}
.navbar-nav li:last-child .nav-link-menu{
    margin-right: 0px;
}
.navbar-nav li:first-child .nav-link-menu{
    margin-left: 0px;
}
.active{
    color: $lightorangecolor;
    &::before{
        opacity: 1;
        top:0;
    }
}
.main-bg-sec{
  // background: linear-gradient(to right, #f9d9d5, #e0eaf1);
  background: linear-gradient(90deg, rgba(255,230,227,1) 0%, rgba(244,240,239,1) 47%, rgba(217,228,236,1) 100%);
}
.header-sec{
  padding:4px 0px;
}


.main-sec{
@include pos-block;
padding: 160px 0px;
.banner-sec-content{
  @include pos-block;
  p{
    text-align: left;
    font-size: 22px;
    line-height: 38px;
    width: 100%;
    max-width: 570px;
    padding-left: 40px;
    margin-bottom: 50px;

    &::before{
      position: absolute;
      content: "";
      width: 40px;
      height: 2px;
      background: $orangecolor;
      left: -13px;
      top: 15px;
    }
  }
  h1{
    text-align: left;
    font-size: 44px;
    line-height: 70px;
    font-weight: 700;
    // text-shadow: 2px 2px #50505030;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.32);
    margin-bottom:0px;
    .head-highlight{
      color:$orangecolor;
    }
  }
  h2{
    text-align: left;
    font-size: 44px;
    line-height: 70px;
    font-weight: 700;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.32);
    .sechead-highlight{
      color:$bluecolor;
    }
  }
}
}

// liner gradient color code 
// background: linear-gradient(to right, #f5d0cb, #e0eaf1);

.home-service-sec{
  @include pos-block;
  padding: 100px 0px;
  background-color: $main-color;
  .service-short-head{
    @include pos-block;
    &::before{
     
    }
    .short-head-line{
      color:$orangecolor;
      font-size: 21px;
      &::before{
        content: "";
        display: inline-block;
        width: 200px;
        height: 2px;
        vertical-align: middle;
        background-color:$orangecolor;
        margin-right: 15px;
      }
      &::after{
        content: "";
        display: inline-block;
        width: 200px;
        height: 2px;
        vertical-align: middle;
        background-color: $orangecolor;
        margin-left: 15px;
      }
    }

    h2{
      color:$textblackcolor;
      font-size: 32px;
      font-weight:700;
      margin: 39px 0px 19px 0px;
      // text-shadow: 2px 2px rgba(80, 80, 80, 0.1882352941);
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.32);
    }
    p{
      font-size: 31px;
      font-weight: 500;
      color:$textblackcolor;
    }
  }
}

.service-category{
  @include pos-block;
  padding: 65px 0px 0px 0px;
  .service-box{
    @include pos-block;
    background-color: $orangelightcolor;
    border-left: 2px solid $orangecolor;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    padding: 35px 20px 40px 20px;

    .service-inner{
      @include pos-block;
          .service-icon{
            @include pos-inlineblock;
                .icon-sec{
                  width: 20%;
                  float: left;
                    .icon-circle{
                        float: left;
                        width: 65px;
                        height: 65px;
                        border: 1px solid $orangecolor;
                        border-radius: 50%;
                        img{
                          height: 42px;
                          position: relative;
                          top: 11px;
                      }
              } 
            }
            .service-para-main{
              width: 80%;
              float: left;
              text-align: left;
              h3{
                font-size: 20px;
                font-weight: 600;
                position: relative;
                top: 25px;
               
              }
            }
      }

      .service-content{
        text-align: left;
        font-size: 16px;
        color: $textblackcolor;
        line-height: 34px;
        margin-top: 10px;
        height:220px;
       
      }
      
    }

  }
}

.btn-inner-sec {
  display: inline-block;
 
  background: $bluecolor;
  color: $whitecolor;
  padding: 5px 16px;
  transition: all .4s;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  border: 1px solid $bluecolor;
  .btn-icon{
    height: 33px;
    width: 33px;
    display: inline-block;
    border-radius: 50%;
    transition: all .4s;

    text-align: center;
    background: $whitecolor;
    color:$textblackcolor;
    transform: rotate(-45deg);
    position: relative;
    left: 8px;
    svg{
      font-size: 19px;
      position: relative;
      top: 4px;
    }
  }
  .btn-name{
    position: relative;
    top: 5px;
    font-size: 16px;
  }
}
.btn-align{
text-align: left;
}
.btn-inner-sec:hover {
  color: var(--vl-text-text-1);
  transition: all .4s;
  transform: translateY(-5px);
}

.btn-inner-sec:hover {
  background-color: $lightorangecolor;
  border: 1px solid $lightorangecolor;
  color: $whitecolor;
}

.btn-inner-sec:hover span {
  transition: all .4s;
  transform: rotate(0deg);
}
.btn-inner-sec:hover span {
  transition: all .4s;
  transform: rotate(0deg);
}

.button-sec{
  a{
    text-decoration: none;
  }
}

.blue-service-box{
  background-color: $lightbluebg !important;
  border-left: 2px solid $bluecolor  !important;
  .service-inner{
    .service-icon{
      .icon-sec{
        .icon-circle{
          border: 1px solid $bluecolor !important;
        }
      }
    }
  }
}

// .bg-icon-service::before{
//   content: "";
//   background-image: url(../img/bg-image_blue_main.png);
// }

.bg-icon-service{
  position: absolute;
  right: 0;
  top: 0;

}
.bg-icon-belowservice{
  position: absolute;
  left: 0;
  bottom: 0;
}

.bg-icon-belowprojt{
  position: absolute;
  right: 0;
  bottom: 0;
}


.home-projt-sec{
  background-color: $secgrycolor;
}
.orange-bold{
  color:$orangecolor;
}
.blue-bold{
  color:$bluecolor;
}

.projet-category-sec{
  @include pos-block;
  
  max-width: 1000px;
  margin: 0 auto;
  padding: 45px 0px 10px 0px;

}
.project-box{
  // background: linear-gradient(90deg, rgb(243, 115, 79) 30%, rgb(245 138 108) 50%, rgb(242, 148, 121) 100%);
  background: linear-gradient(90deg, rgba(243,115,79,1) 11%, rgba(242,148,121,1) 100%, rgba(83,166,222,1) 100%);
  border-radius: 8px;
  width: 100%;
    position: relative;
    display: block;
    padding: 40px 30px;
  @include pos-block;
  p{
    color:$whitecolor;
    border-radius: 8px;
    font-size: 23px;
    text-align: left;
    line-height: 46px;
    margin-bottom: 26px;
  }
}

.btn-white{
  .btn-inner-sec{
      background-color: transparent;
      color: $whitecolor;
      border:1px solid $whitecolor;
      cursor: pointer;
  }
}

// .btn-white .btn-inner-sec:hover{
//   background-color: $whitecolor;
//   color: $textblackcolor;
// }

.project-box-blue{
  background: linear-gradient(90deg, rgba(7,125,204,1) 14%, rgba(83,166,222,1) 100%, rgba(83,166,222,1) 100%);
}
.projet-category-sec::before{
  content:"";
  background-image: url(../img/bg-image_blue_main.png);
  position: absolute;
  top: -172px;
  left: -51%;
  width: 100%;
  height: 100%;
  /* background-size: cover; */
  background-position: center;
  background-repeat: no-repeat;
  /* opacity: 0.3; */
  border-radius: 8px;

}

.vision-sec{
  background-color: $whitecolor;
  .vision-content{
    width:100%;
    position: relative;
    display: block;
    margin-top: 60px;
    h2{
      text-align: left;
      font-size: 31px;
      font-weight: 700;
      color: $orangecolor;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.32);
      &::after {
        content: "";
    display: inline-block;
    vertical-align: middle;
    width: 13%;
    margin-right: -100%;
    margin-left: 10px;
    border-top: 2px solid $orangecolor;
    }
    }
    p{
      text-align: left;
      font-size: 22px;
      font-weight: 500;
      margin-top: 25px;
      line-height: 37px;
    }
  }
}

.vision-image{
@include pos-block;
&::before{
  content: "";
  background-color: $bluecolor;
  position: absolute;
  top: 30px;
  left: 169px;
  width: 250px;
  height: 232px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: #89898938 0px 8px 24px;

}


img{
  z-index: 999;
  position: relative;
  display: inline-block;
  border-radius: 5px;
}
}

.vision-image-bg{
  @include pos-block;
  .bg-icon-belowservicess{
    img{
      right: 140px;
      position: absolute;
      bottom: -70px;
    }
  }
}

.footer-sec{
  @include pos-block();
  //background-color: $secgrycolor;
  background-color: $geryfotcolor;
  &::before{
    content: "";
    background-color: $bluecolor;
    position: absolute;
    top: 0;
    left: 0;
    width:30%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    
  }
  // .row{
  //   margin-right: 0px;
  // }
  .row{
    margin-right: 0px;
  }
  .footer-left-sec{
    background-color: $bluecolor;
    display: block;
    width: 100%;
    position: relative;
    padding: 60px 40px 0px 0px;
    height: 100%;
       img{
          height: 70px;
          display: block;
       }
       .social-icon{
        list-style-type: none;
        display: flex;
        position: relative;
        padding-left: 0px;
        margin: 23px 0px;
        width: 100%;
        li{
            border: 1px solid $whitecolor;
            width: 33px;
            height: 33px;
            float: left;
            margin-right: 11px;
            cursor: pointer;
          img{
            height: 18px;
            position: relative;
            top: 6px;
            margin: 0 auto;
          }
          .twit-sec{
            height: 16px;
            top: 8px;
          }
        }
       }
    // }

    .map-sec{
      width: 100%;
      position: relative;
      display: flex;
      text-align: left;
      padding: 12px 0px;
      img{
        height: 25px;
        margin-right: 8px;
        float: left;
        

      }
      p{
        color:$whitecolor;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
      }
    }

    .map-contect{
      @include pos-inlineblock();

      ul{
        list-style-type: none;
        padding-left: 0px;
        @include pos-inlineblock;
      li{
          float: left;
          color:$whitecolor;
          margin-right: 30px;
        svg{
          float: left;
          font-size: 20px;
          position: relative;
          top: 3px;
      
        }
        p{
          margin-bottom: 0px;
          float: left;
          font-size: 16px;
          font-weight: 400;
          margin-left: 7px;
        }
      }
      }
    }
  }

  .footer-mid-sec{
    padding: 60px 0px 0px 40px;
    position: relative;
    display: block;
    color:$textblackcolor;
    h3{
      font-size: 19px;
      font-weight: 600;
      text-align: left;
  
    }
    ul{
      list-style-type: none;
      text-align: left;
      padding-left: 0px;
      li{
        font-size: 17px;
        font-weight: 400;
        line-height: 40px;
        cursor: pointer;
        a{
          transition: all ease .3s;
          color: $textblackcolor;
          text-decoration: none;
          &:hover{
            color:$bluecolor;
            font-weight: 600;
          }
        }
      }
    }
  }

  .footer-right-sec{
    @include pos-block;
    padding: 60px 0px 0px 40px;
    text-align: left;
    color:$textblackcolor;
    h3{
      font-size: 19px;
      font-weight: 600;
      text-align: left;
    }
    p{
      font-size: 16px;
      font-weight: 500;
     
      padding: 15px 0px 10px 0px;
    }
    .email-sec{
      button{
        border:none;
        float: right;
        position: absolute;
        width: 28%;
        background-color: transparent;
        left: 61%;
        
      }
      .inputemail{
        padding: 4px 90px 4px 16px;
        border-radius: 30px;
        border: 1px solid $colorborder;
        background-color: transparent;
        height: 45px;
        width: 81%;
        outline: none;
        font-size: 15px;
        display: inline-block;
      }
      .email-btn{
        .btn-inner-sec{
            padding: 7px 17px;
          .btn-name{
            top:3px;
          }
        }
        .btn-icon{
            width:28px;
            height: 28px;
          svg{
            font-size: 17px;
            position: relative;
            top: 2px;
            left: -1px;
          }
        }
      }
    }

ul{
  @include pos-inlineblock;
  margin-top:40px;
  li{
    float: left;
    font-size: 16px;
    cursor: pointer;
    &:last-child{
      margin-left: 40px;
    }
    &::marker{
      color:$bluecolor;
      font-size: 22px;
      position:relative;

    }
    a{
      transition: all ease 0.3s;
      text-decoration: none;
      color: $textblackcolor;
      top: -2px;
      position: relative;
      left: -5px;
      &:hover{
        color:$bluecolor;
        font-weight: 500;
      }
    }
  }
}

.copy-sec{
p{
  color:$textblackcolor;
  font-size: 16px;
  font-weight: 400;
  padding: 80px 0px 20px 13px;
}
}


  }
}

.btn-inner-secss:hover {

  transition: all 0.4s;
  transform: translateY(0px);
}
.navbar {
  padding: 0px 0px;
}

.about-main{
  width: 100%;
    position: relative;
    height: 58vh;
    overflow: hidden;
    .about-sec-banner{
      height: 100%;
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0px;
      width: 100%;
      background-position: 90% 30%;
      background-image:  linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../img/about-bg-image.png);
    }
    .abut-banner-cont{
      position: absolute;
      bottom: 10vh;
      text-align: center;
      color: $whitecolor;
      left: 50%;
      transform: translate(-50%, -85%);
      z-index: 99;
      h1{
        font-size: 37px;
        font-weight: 600;
        text-shadow: 2px 2px rgb(105 103 103 / 67%);
        margin-bottom: 0px;
      }
      p{
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
        width: 100%;
        max-width: 750px;
        line-height: 30px;
        margin-top: 20px;
        text-shadow: 2px 2px rgb(105 103 103 / 67%);
      }
    }
}

.about-innov-main{
  background-color: $whitecolor;
  .about-innov-sec{
    h2{
      font-size: 31px;
      margin-bottom: 40px;
    }
    p{
      font-size: 18px;
      line-height: 30px;
      font-weight: 500;
    }
    .about-innov-para{
      font-size: 18px;
      line-height: 33px;
      font-weight: 400;
      margin-bottom: 0px;
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
    }
   
  }
}


.founder-sec{
  @include pos-block;
  padding: 100px 0px;
  background-color: $secgrycolor;
}

.social-icon{
  list-style-type: none;
  display: flex;
  position: relative;
  padding-left: 0px;
  margin: 23px 0px;
  width: 100%;
  li{
      border: 1px solid $whitecolor;
      width: 33px;
      height: 33px;
      float: left;
      margin-right: 11px;
      cursor: pointer;
    img{
      height: 18px;
      position: relative;
      top: 6px;
      margin: 0 auto;
    }
    .twit-sec{
      height: 16px;
      top: 8px;
    }
  }
}

.social-icon-found{
  justify-content: center;
  margin: 15px 0px;
  li{
    border:1px solid $orangecolor;
    img{
      top:3px;
      height:16px;
    }
    .twit-sec-foun{

    }
  }
}

.founder-sec-cont{
text-align: left;
h2{
  overflow: hidden;
  font-size: 31px;
  font-weight: 700;
  color: $orangecolor;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.32);
  &:after{
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 12%;
    margin-right: -100%;
    margin-left: 10px;
    border-top: 2px solid $orangecolor;
  }
}
p{
  font-size: 19px;
  font-weight: 500;
  margin: 25px 0px;
  line-height: 38px;
}
h3{
  text-shadow: 0px 1px 3px rgb(0 0 0 / 32%);
    font-size: 19px;
    font-weight: 600;
}
h4{
  text-shadow: 0px 1px 3px rgb(0 0 0 / 32%);
  font-size: 19px;
  font-weight: 600;
  margin: 20px 0px 13px 0px;
  span{
    position: relative;
    top:5px;
    margin-right: 8px;
  }

  .found-link{
    
      width: 33px;
      height: 33px;
     display: inline-block;
      margin-right: 11px;
      cursor: pointer;
      border:1px solid $bluecolor; 
      img{
        top: 3px;
        height: 18px;
        position: relative;
        margin: 0 auto;
        left:6px;
      }
    }
  }
  .book-sec{
    margin: 9px 0px;
    font-size: 19px;
    font-weight: 600;
    text-shadow: 0px 1px 3px rgb(0 0 0 / 32%);
    span{
      color:$bluecolor;
      text-shadow: none;
      img{
        height: 23px;
        margin-left: 18px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
}

.founsd-bg{
  top:0;
}
.team-sec{
  background-color: $whitecolor;
  @include pos-block;
  .team-sec-content{
    h2{
      font-size: 31px;
      margin: 29px 0px 19px 0px;
    }
    p{
      font-size: 18px;
      font-weight: 500;
      width: 100%;
      max-width: 790px;
      text-align: center;
      margin: 0 auto;
      padding: 15px 0px 10px 0px;
      line-height: 34px;
    }
  }
  .team-category-sec{
    @include pos-block;
    padding: 70px 60px 40px 60px;
    .team-box{
      @include pos-block;
      cursor: pointer;
      padding: 30px 0px;
      transition: all 1s;
      // &:hover{
      //   background-color: #077dcc59;
        
      // }
      &::before{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color:rgb(7 125 204 / 91%);
        transition: all 1s;
        opacity: 0;
      }
      
      .teamimg-sec{
       
          border-radius: 50%;
          width: 137px;
          height: 137px;
          position: relative;
          display: inline-block;
          background-color: $bgteamcolor;
          margin-bottom: 13px;
          img{
            height: 157px;
            position: relative;
            display: block;
            top: -5px;
            left: -5px;
          }
          &::before{
            position: absolute;
            content: "";
            width: 100%;
            top: 0;
            left: 0;
            bottom: 2px;
            box-shadow: 9px 10px 7px 0px rgb(0 0 0 / 41%);
            border-radius: 50%;
   
}
          }
          h3{
            font-size: 18px;
            font-weight: 600;
            color: $bluecolor;
          }
          h4{
            font-size: 17px;
            font-weight: 600;
          }
      p{
        font-size: 17px;
        font-weight: 500;
      }
    }
    .teamming-sec-hover{
    opacity: 0;
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -30%);
    color:$whitecolor;
    h5{
      font-size: 50px;
      font-weight: 600;
    }
    h6{
      font-size: 17px;
      font-weight:400;
    }
    }
    
  }
}


.team-box:hover .team-sec-back{
  opacity: 0.3;
}
.team-box:hover .teamming-sec-hover{
  opacity: 1;
}

.team-sec .team-category-sec .team-box:hover::before  {
  opacity: 1;
}







.video-container {
  position: relative;
  width: 100%;
  height: 94vh;
  overflow: hidden;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 48%);
    z-index: 9;
  }
}

.background-video {

  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.content-overlay {
  position: relative;
  z-index: 1;

  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  border: none; 
  outline: none;
}

video {
  border: none;
  outline: none;
}


// code for timeline start

.ps-timeline-sec {
  position: relative;

}
.ps-timeline-sec .container {
  position: relative;
}
@media screen and (max-width: 767px) {
  .ps-timeline-sec .container ol:before {
   
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: 130px !important;
    left: 36px !important;
  }
  .ps-timeline-sec .container ol:after {
    
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: inherit !important;
    left: 36px;
  }
  .ps-timeline-sec .container ol.ps-timeline {
    margin: 130px 0 !important;
   
    padding-left: 0 !important;
    padding-top: 120px !important;
    border-top: 0 !important;
    margin-left: 25px !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li {
    height: 220px;
    float: none !important;
    width: inherit !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li:nth-child(2) .img-handler-bot img {
    width: 70px;
  }
  .ps-timeline-sec .container ol.ps-timeline li:last-child {
    margin: 0;
    bottom: 0 !important;
    height: 120px;
  }
  .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot {
    bottom: 40px !important;
    width: 40% !important;
    margin-left: 25px !important;
    margin-top: 0 !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot img {
    width: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li:last-child .ps-top {
    margin-bottom: 0 !important;
    top: 20px;
    width: 50% !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span {
    left: 0 !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
    position: absolute !important;
    bottom: 150px !important;
    width: 30% !important;
    float: left !important;
    margin-left: 35px !important;
    margin-bottom: 0 !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
    margin: 0 auto !important;
    width: 80% !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
    position: absolute !important;
    bottom: 115px !important;
    width: 30% !important;
    float: left !important;
    margin-left: 35px !important;
    margin-bottom: 0 !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li p {
    text-align: left !important;
    width: 100% !important;
    margin: 0 auto !important;
    margin-top: 0px !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    width: 60% !important;
    float: right !important;
    right: 0;
    top: -40px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
    width: 60% !important;
    float: right !important;
    right: 0;
    top: -40px;
  }
}


.ps-timeline-sec .container ol.ps-timeline {
  margin: 200px 0px;
  padding: 0;
}
.ps-timeline-sec .container ol.ps-timeline li {
  float: left;
  width: 12.4%;
  padding-top: 30px;
  position: relative;
  list-style-type: none;
 
}
.ps-timeline-sec .container ol.ps-timeline li span {
  width: 50px;
  height: 50px;
  margin-left: -25px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px -10;
  font-size: 2em;
  font-style: normal;
  position: absolute;
  top: -26px;
  left: 50%;
}
.ps-timeline-sec .container ol.ps-timeline li .ps-sp-top:before {
  content: "";
  color: $textblackcolor;
  width: 2px;
  height: 50px;
 background-color: $textblackcolor;
  position: absolute;
  top: -50px;
  left: 71%;
}

.ps-timeline-sec .container ol.ps-timeline li .ps-sp-bot:before {
  content: "";
  color: $textblackcolor;
  width: 2px;
  height: 50px;
  background-color:  $textblackcolor;
  position: absolute;
  bottom: -50px;
  left: 71%;
}

.ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
  position: absolute;
  bottom: 0;
  margin-bottom: 130px;
  width: 100%;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
  display: table;
  margin: 0 auto;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
  position: absolute;
  margin-top: 60px;
  width: 100%;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot img {
  display: table;
  margin: 0 auto;
}
.ps-timeline-sec .container ol.ps-timeline li p {
  text-align: center;
  width:90%;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 500;

}
.ps-timeline-sec .container ol.ps-timeline li .ps-top {
  position: absolute;
  bottom: 0;
  margin-bottom: 104px;
  width: 130%;
}
.ps-timeline-sec .container ol.ps-timeline li .ps-bot {
  position: absolute;
  margin-top: 73px;
  width: 130%;
}
.img-time{position: absolute;
  top: -26px;
  left: 0%;
}    
.timedata{
  position: absolute;
    bottom: 19px;
    left: -7px;
    font-size: 17px;
    font-size: 19px;
    font-weight: 500;
}
.img-time-first{
  top:-31px;
}
.img-last{
  top:-33px;
}

.ps-timeline{
  display: flex;
  justify-content: center;
}

// code for timeline end

.main-banner-sec{
  p{
    &::before{
      background:none !important;
    }
  }
 
}
.main-innerbanner{
  padding: 155px 0px;
  .main-banner-sec{
      
    h1{
      font-size: 37px;
        font-weight: 700;
        color: $bluecolor;
        text-align: center;
    }
    p{
      text-align: center;
      font-size: 26px;
      font-weight: 600;
      width: 100%;
      max-width: 100%;
      padding-left: 0px;
      margin-bottom: 10px;
      margin-top: 15px; 
    }
  }
}

.meet-pata{
  font-weight: 400 !important;
}
.secure-main{
.secure-sec{
  .secure-para{
    font-size: 29px;
    font-weight: 600;
    width: 100%;
    max-width: 967px;
    line-height: 50px;
    margin-top: 46px;
  }
}
}

.left-side-head-before{
  h2{
  text-align: left;
    font-size: 31px;
    font-weight: 700;
    color: $orangecolor;
    text-shadow: 0px 1px 3px rgb(0 0 0 / 29%);
    line-height: 46px;
    &:after{
      content: "";
    display: inline-block;
    vertical-align: middle;
    width: 13%;
    margin-right: -100%;
    margin-left: 10px;
    border-top: 2px solid $orangecolor;
    }
  }
}

.differ-service-sec{
  @include pos-block;
  padding: 120px 0px;
  .differ-service-inner{
    @include pos-block;
    margin-top:30px;
    p{
      text-align: left;
      color: $textblackcolor;
      font-size: 27px;
      font-weight: 600;
      margin-top: 25px;
      line-height: 48px;
      width: 100%;
      
  
    }
  }
}

.service-img-right{
  @include pos-block;
  &::after{
    content: "";
    background-color: $bluecolor;
    position: absolute;
    top: 10px;
    left: 189px;
    width: 330px;
    height: 292px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    box-shadow: #89898938 0px 8px 24px;
  }
  img{
    z-index: 9999;
    position: relative;
    display: inline-block;
  }
  }

  .serv-img-inner{
    // @include pos-inlineblock;
    img{
      position: absolute;
      top: 30px;
      left: 169px;
      border-radius: 5px;
    }
    &::before{
      content: "";
      background-color: $whitecolor;
      position: absolute;
      top: 30px;
      left: 169px;
      width: 330px;
      height: 292px;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 5px;
      z-index: 99;
    }
  }


  .sev-main-bg{
  .serv-bg-iconrigt{  
    img{
      right:70px;
      z-index: auto;
    }
  }
}

.service-inner-category{
  @include pos-block;
  margin-top: 140px;
  padding: 0px 0px;
  .serv-categ-box-grey{
    background-color: $main-color !important;
  }
  .serv-categ-box{
      background-color: $whitecolor;
      box-shadow: rgb(137 137 137 / 25%) 0px 3px 8px;
      border-left: 4px solid $bluecolor;
      padding: 25px 15px 25px 14px;
    .serv-categ-main{
          width: 100%;
          position: relative;
          display: flex;
        .serv-categ-icon{
            float: left;
            width: 15%;
            img{
              height: 60px;
            }
          .service-icon{
            height: 67px;
          }
        }
        .serv-categ-head{
            width: 87%;
            h3{
              text-align: left;
              font-size: 22px;
              color: $textblackcolor;
              font-weight: 600;
              margin-bottom: 0px;
              padding-top: 14px;
              text-shadow: 2px 1px 3px rgb(90 87 87 / 23%);
            }
        }
    }
    .serv-categ-para{
      p{
        margin-bottom: 0px;
        text-align: left;
        font-size: 19px;
        font-weight: 400;
        padding: 18px 10px 0px 10px;
        line-height: 33px;
        height: 110px;
      }
    }
  }
}

.margin-right-ser{
  margin-right:5px;
}
.margin-left-ser{
  margin-left:5px;
}
.margin-catg-top{
  margin-top: 25px;
}
.serv-dot-bg{
  img{
    left:70px;
  }
}

.servce-img-left{
  &:after{
    top: 0px;
    left: 105px;
  }
}

.servleft-img-inner{
  img{
    position: absolute;
    top: 12px;
    left: 119px;
    border-radius: 5px;
  }
  &:before{
    top: 14px;
    left: 119px;
  }
}

.proof-sec{
  background-image:linear-gradient(90deg, rgb(255, 230, 227) 0%, rgb(244, 240, 239) 47%, rgb(217, 228, 236) 100%);
  padding: 100px 0px;
  @include pos-block;
 &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: $secgrycolor;
}
 .prrof-img{
  @include pos-block;
  .proof-content-sec{
    @include pos-block;
    img{
      width: 100%;
      }
    .team-content{
      position: absolute;
      bottom: 21%;
      left: 40%;
      transform: translate(-30%, -30%);
      color: $whitecolor;
      h3{
        font-size: 31px;
        margin-bottom: 15px;
      }
      h4{
        font-size: 31px;
        margin-bottom: 45px;
      }
    }
  }


 }
}

.tem-img-overlay{
  position: absolute;
  content: "";
  width: 100%;
  height: 554px;
  top: 0;
  background-color: #f0512530;
  transition: all 1s;
  border-radius: 13px;
}

.red-button-sec{
.btn-red-sec{
  background-color:$orangecolor;
  border-color: $orangecolor;
  padding: 6px 16px;
  cursor: pointer;
  &:hover{
    background-color: $whitecolor;
    border-color: $orangecolor;
    color:$orangecolor;
    .btn-icon{
      background-color: $orangecolor;
      svg{
        color:$whitecolor;
      }
    }
  }
}
}
.product-sec{
background-color: $bglightmoregrey;
  @include pos-block;
  .short-head-line{
    font-size: 21px !important;
  }
  p{
    font-size: 20px !important;
   
  }
  .prod-para{
    margin-top: 25px;
  }
}

.produt-category{
  @include pos-block;
  margin-top: 85px;
  .product-box{
    @include pos-inlineblock;
    background-color: $whitecolor;
    box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px;
    border-radius: 12px;
    padding: 40px 10px;
    &::before{
      content: "";
      display: block;
      width: 4px;
      height: 70%;
      background-color: $bluecolor;
      position: absolute;
      left: 0px;
    }
      .prodct-image{
        // width:40%;
        float: left;
        img{
          width: 100%;
        }
      }
      .prodct-descp{
        // width: 57%;
        float: left;
        text-align: left;
        // margin-left: 17px;
        h2{
          font-size: 21px;
          line-height: 25px;
          font-weight: 600;
          margin-bottom: 13px;
        }
        p{
          font-size: 16px !important;
          font-weight:500;
          line-height: 26px;
          height: 70px;

        }
      }
  }
}

.btn-learn{
  float: left;
  margin-right: 10px;
  .btn-inner-lern{
    .btn-name{
      font-size: 15px;
    }
  }
}

.btn-video{
  .btn-inner-vido{
    background-color: $whitecolor;
    color: $bluecolor;
    
    &:hover{
      background-color: $bluecolor;
      color: $whitecolor;
      border:1px solid $bluecolor;
      .btn-icon{
        background-color: $whitecolor;
        svg{
          color: $textblackcolor;
        }
      }
    }

    .btn-name{
      font-size: 15px;
      transition: all 0.2s;
      font-family: $main-font;
    }
    .btn-icon{
      transform: rotate(0deg);
      background:$bluecolor;
      svg{
        color: $whitecolor;
      }
    }
  }
}

.btn-prodct{
  margin-top: 25px;
}

.prodct-margin{
  margin-top: 30px;
}

.product-main{
    width: 100%;
    position: relative;
    display: inline-block;
    .prodct-left{
      width: 48%;
      float: left;
      margin-right: 45px;
    }
    .prodct-right{
      width: 48%;
      float: left;
    }
}
.main-innerbanner{
.prodt-details{
  @include pos-block;
  margin-top: 70px;
  h1{
    font-size: 38px;
    text-shadow: 0px 1px 3px rgb(0 0 0 / 25%);
    text-align: left;
    margin-bottom: 70px;
    color: $textblackcolor;
    span{
      color:$orangecolor;
    }
    
  }
  p{
    text-align: left;
    font-size: 27px;
  }
}
}

// video icon animation section start
.video-play-button {
  position: relative;
  z-index: 10;
  border-radius: 50%;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 240%;
  top: 50%;
  transform: translateX(-250%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: $bluecolor;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 30%;
  top: 50%;
  transform: translateX(6%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: $bluecolor;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:hover:after {
  background-color: darken($bluecolor, 10%);
}

.video-play-button img {
  position: relative;
  z-index: 3;
 
  height: 23px;
  left:42px;

}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 26px solid $whitecolor;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}



.video-overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.80);
  opacity: 0;
  transition: all ease 500ms;
}

.video-overlay.open {
  position: fixed;
  z-index: 1000;
  opacity: 1;
}

.video-overlay-close {
  position: absolute;
  z-index: 1000;
  top: 15px;
  right: 20px;
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
  color: $whitecolor;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms;
}

.video-overlay-close:hover {
  color: $bluecolor;
}

.video-overlay iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  /* width: 90%; */
  /* height: auto; */
  box-shadow: 0 0 15px rgba(0,0,0,0.75);
}

// video icon animation section end

.prodt-detail-main{
  width: 100%;
  position: relative;
  display: block;
  text-align: left;
  .watch-sec{
    color: $textblackcolor;
    text-align: left;
    text-decoration: none;
    font-size: 27px;
    font-weight: 600;
    float: left;  
    width:60%;
    float: left;
  }
  .play-sec-prdt{
    width: 40%;
    float: left;
  }
}


.prodtbannerimg{
  img{
    width:100%;
  }
}
.keyprocs-sec{
  @include pos-block;
  background-color: $whitecolor;
  .keyprocs-content{
    @include pos-block;
    padding: 90px 0px;
    h2{
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 30px;
      color: $orangecolor;
      text-shadow: 0px 1px 3px rgb(0 0 0 / 26%);
    }
    p{
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
      width: 100%;
      max-width: 940px;
      text-align: center;
      margin: 0 auto;
      line-height: 37px;
    }
  }
}

.detail-carousl-sec{
  padding: 110px 0px 130px 0px;
  .carousl-details-content{
    text-align: left;
    z-index: 9;
    position: relative;
    h2{
      font-size: 28px;
      font-weight: 600;
      color:$textblackcolor;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.26);
      margin-bottom: 25px;
      line-height: 40px;
    }
    p{
      font-size: 21px !important;
      font-weight: 500;
      line-height: 38px;
      color:$textblackcolor;
      margin-bottom: 0px;
    }
  }
  .carousal-img{
    @include pos-block;
    .carousel {
      .carousel-control-prev{
        display: none;
      }
      .carousel-control-next{
        display: none;
      } 
    }
    .carousel{
      .carousel-indicators{
        bottom: -56px;
        button{
          background-color: $carouslround;
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
      }
  }
}
}
.carousel-indicators .active{
  background-color:$bluecolor !important;
  width:13px !important;
  height: 13px !important;
}


.keyprocs-feturesec{
  @include pos-block;
  background-color: $whitecolor;
  padding: 90px 0px;
  .keyfeatre-container{
    margin: 0 55px;
  }
.keyprocs-heading{
  h2{
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 40px;
    color: $orangecolor;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.26);
  }
}

//   .keyfeature-box{
//     @include pos-block;
//     margin: 40px 0px;
//   .keyfetur-inner{
//     &:before{
//       content: "";
//       background-color: #ffffff;
//       position: absolute;
//       top: 30px;
//       left: 0px;
//       width: 330px;
//       height: 292px;
//       background-position: center;
//       background-repeat: no-repeat;
//       border-radius: 5px;
//       z-index: 99;
//       box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px;
  
//     }
    
  
//   &:after{
//     content: "";
//     background-color: #077DCC;
//     position: absolute;
//     top: 40px;
//     left: 10px;
//     width: 330px;
//     height: 292px;
//     background-position: center;
//     background-repeat: no-repeat;
//     border-radius: 5px;
//     box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px;

//   }
//   }
// }
.keyfeature-box{
  @include pos-block;
  margin: 40px 0px;
  z-index: 9;
.keyfetur-inner{
  .keyfeatre-inn-sec{
    position: relative;
    z-index: 999;
    padding: 30px 21px;
    top: 30px;
    text-align: left;
    img{
      height: 55px;
    }
    h3{
      font-size: 19px;
      font-weight: 600;
      color:$textblackcolor;
      margin:20px 0px 15px 0px;
      height: 35px;
    }
    p{
      font-size: 17px;
      font-weight: 400;
      color:$textblackcolor;
      margin-bottom: 0px;
      line-height: 32px;
      height: 130px;
    }
    &::before{
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 40%;
      border-top: 3px solid $bluecolor;
      position: absolute;
      top: 0;
      left: 5px;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
    }
  }
    &:before{
      content: "";
      background-color: $whitecolor;
      position: absolute;
      top: 30px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 12px;
      z-index: 99;
      box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px;

    }
    

  &:after{
    content: "";
    background-color: $keybgshadow;
    position: absolute;
    top: 45px;
    left: 8px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 12px;
    box-shadow: rgb(137 137 137 / 12%) 0px 3px 2px;

  }
}
}
}

.impact-sec{
  background-color: $greycolor;
  padding:90px 0px;
  position: relative;
  display: block;
  h2{
    text-shadow:2px 1px 3px rgba(90, 87, 87, 0.23);
    font-size:31px;
    line-height: 40px;
    font-weight:600;
  }
  .imapct-inner-sec{
  
    width: 100%;
    max-width: 1030px;
    margin:60px auto 0px auto;
    h3{
      font-size:38px;
      font-weight:600;
      line-height:40px;
      color:$bluecolor;
      text-shadow:2px 1px 3px rgba(90, 87, 87, 0.23);
      margin-bottom: 17px;
    }
    p{
      font-size:25px;
      font-weight:400;
      line-height:35px;
      color:$textblackcolor;
      margin-bottom:0px;
      text-shadow: 2px 1px 3px rgba(90, 87, 87, 0.23);
      max-width: 301px;
      margin: 0 auto;
      width: 100%;
    }
    .impact-box-center{
      @include pos-block;
      &:before{
        content: "";
        display: block;
        vertical-align: middle;
        width: 34%;
        border-top: 2px solid $borderslash;
        transform: rotate(107deg);
        top: 40px;
        position: absolute;
        left: -50px;
      }
      &:after{
        content: "";
        display: block;
        vertical-align: middle;
        width: 34%;
        border-top: 2px solid $borderslash;
        transform: rotate(107deg);
        top: 40px;
        position: absolute;
        right: -65px;
      }
    }
  }
}
.callactn-sec{
  @include pos-block;
  padding: 80px 0px;
  background-color: $whitecolor;
  .callactn-content{
    box-shadow: rgb(137 137 137 / 24%) 0px 1px 11px;
    background-color: $whitecolor;
    text-align: left;
    width: 100%;
    max-width: 870px;
    margin: 0 auto;
    border-radius: 12px;
    padding: 45px 30px;
    display: inline-block;
    position: relative;
    &::before{
    content: "";
    display: block;
    width: 3px;
    height: 65%;
    background-color: $orangecolor;
    position: absolute;
    left: 0px;
    top: 31px;
  }
    p{
      width:65%;
      float: left;
      font-size: 22px;
      line-height: 36px;
      font-weight: 600;
      color:$textblackcolor;
      margin-right: 20px;
      margin-bottom: 0px;
    }
    .cal-btn-sec{
      width: 30%;
      float: right;
      margin-top:13px;
    }
  }
}

.blog-list{
  @include pos-block;
  padding: 90px 0px;
  .container-width-blog{
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;

  }
  .blog-inner-sec{
    @include pos-block;
    .blog-category{
      float: left;
      p{
        color:$textblackcolor;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0px;
      }
      img{
        height: 36px;
        cursor: pointer;
      }
    }
    .blog-search{
      float: right;
      border-bottom: 1px solid #2a2a2a63;
      width: 100%;
      max-width: 280px;
      position: relative;
      top: 19px;
      .input-srch{
        border: none;
        outline: none;
        float: left;
        width: 100%;
        max-width: 250px;
      }
      span{
        float: left;
        svg{
          color:$bluecolor;
        }
      }
    }
  }

  .blog-list-inner{
    @include pos-block;
    margin:30px 0px 0px 0px;
    .blog-new-list{
      padding: 25px 20px 35px 20px;
    }
    .blog-list-sec{
      background-color: $whitecolor;
      box-shadow: rgb(137 137 137 / 32%) 0px 3px 8px;
      
      img{
        width: 100%;;
      }
      .blog-impor-sec{
        @include pos-block;
        
        .blog-date{
          float: left;
          width:45%;
          text-align: left;
          a{
            img{
              width: auto;
              height: 19px;
              margin-right: 7px;
             
            }
            span{
              color:$textblackcolor;
              font-size: 17px;
              position: relative;
              top:2px;
              font-weight: 500;
            }
          }
        }
        .blog-category{
          position: relative;
          display: inline-block;
          width:55%;
          text-align: left;
          p{
            margin-bottom: 0px;
            color:$bluecolor;
            font-size: 17px;
            font-weight: 500;
            &::before{
              content: "";
              display: block;
              width: 2px;
              height: 100%;
              background-color: $bluecolor;
              position: absolute;
              left: -20px;
          }
          }
        }
      }

      .blog-list-content{
        @include pos-block;
        text-align: left;
        padding: 29px 0px 21px 0px;
        h2{
          font-size: 19px;
          font-weight: 500;
          line-height: 33px;
          color:$textblackcolor;
          // height: 60px;
          height: 67px;
        }
        p{
          font-size: 16px;
          font-weight: 400;
          line-height: 31px;
          color: $textblackcolor;
          margin-bottom: 0px;
          margin-top: 12px;
          // height: 90px;
      
        }
      }
    }
  }
}

.btn-blog-blue{
  .btn-inner-sec{
      background-color: transparent;
      color: $bluecolor;
      border:1px solid $bluecolor;
      &:hover{
        background-color: $bluecolor;
        color: $whitecolor;
        .btn-icon{
          background-color: $whitecolor;
          svg{
            color:$bluecolor;
          }
        }
      }
      .btn-icon{
        background-color: $bluecolor;
        svg{
          color:$whitecolor;
        }
      }
  }
}
.margin-blog-top{
  margin-top: 40px;
}
.load-btn{
  margin:50px 0px 0px 0px;
  text-align: center;
}

.main-bannerinner{
  h1{
    font-size: 35px !important;
  }
}

.blog-details-sec{
  background-color: $whitecolor;
  @include pos-block;
  .blog-details-inner{
    @include pos-inlineblock;
    box-shadow: rgba(137, 137, 137, 0.32) 0px 3px 8px;
    background-color: $whitecolor;
    margin-bottom: 10px;
    img{
      width: 100%;
    }
    .blog-tag-details{
      @include pos-inlineblock();
      .blog-tag-para-new{
        width: 40%;
        float: left;
      }
      .blog-tag-para{
       
       
        float: left;
        p{
          margin-bottom: 0px;
          font-size: 17px;
          line-height: 34px;
          font-weight: 500;
          color:$whitecolor;
          padding: 12px 17px;
          background-color: $bluecolor;
        }
      }
    .blog-detal-date{
      width: 35%;
      float: right;
      text-align: right;
      margin-right: 25px;
      padding: 20px 0px 11px 0px;
      img{height: 20px;
      width:auto;
      }
      a{
        font-size: 16px;
        font-weight: 500;
        color: $textblackcolor;
        position: relative;
        top: 2px;
        margin-left: 7px;
      }
    }
  }
  .blog-detal-content{
    padding: 30px 25px;
    width: 100%;
    display: block;
    position: relative;
    text-align: left;
    p{
      font-size: 16px;
      font-weight: 400;
      color:$textblackcolor;
      margin-bottom: 30px;
      line-height: 32px;
    }
    h2{
      font-size: 20px;
      font-weight: 600;
      color: $bluecolor;
      margin-bottom: 21px;
    }
    h3{
      font-size: 18px;
      font-weight: 600;
      color: $textblackcolor;
      margin-bottom: 21px;
      line-height: 35px;
    }
    h4{
      font-size: 18px;
      font-weight: 600;
      color: $textblackcolor;
    }
    .blog-bullet-points{
     li{
      &::marker{
        color: $bluecolor;
        font-size: 24px;
       
      }
      p{
        margin-bottom: 17px;
      }
     }
    }
    .admin-sec{
      float: right;
      margin:0px 20px 30px 0px;
      p{
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 0px;
        svg{
          color:$bluecolor;
        }
        span{
          font-size: 17px;
          position: relative;
          top: 2px;
          margin-left: 4px;
        }
      }
    }
  }
  }

  .blog-detail-rights{
    @include pos-block;
    .blog-search{
      background-color: $whitecolor;
      box-shadow: rgba(137, 137, 137, 0.32) 0px 3px 8px;
      padding: 30px 30px;
      p{
        font-size: 19px;
        font-weight: 600;
        padding-bottom: 16px;
        &:after{
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: $bluecolor;
          position: relative;
          top: 9px;

        }
      
      }
      .blog-search-box{
        @include pos-block;
        margin-top: 15px;
        background-color: $whitecolor;
        box-shadow: rgba(137, 137, 137, 0.32) 0px 3px 8px;
        padding: 20px 0px;
        .input-srch{
         width:80%;
         float: left; 
         border:none;
         outline: none;
         padding: 0px 15px;
        }
        .search-icon{
          background-color: $bluecolor;
          padding: 12px 12px;
          cursor: pointer;
          svg{
            color:$whitecolor;
            cursor: pointer;
          }
        }

      }
    }

    .detail-categ{
      background-color: $whitecolor;
      box-shadow: rgba(137, 137, 137, 0.32) 0px 3px 8px;
      padding: 30px 30px;
      margin:30px 0px;
      p{
        font-size: 19px;
        font-weight:600;
        padding-bottom: 16px;
        &:after{
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: $bluecolor;
          position: relative;
          top: 9px;

        }
      
      }
      .categ-list{
        @include pos-block;
        ul{
          list-style-type: none;
          padding-left: 0px;
          display: inline-block;
          .active{
            background-color: $bluecolor;
            color: $whitecolor;
          }
          li{
            float: left;
            font-size: 17px;
            line-height: 34px;
            font-weight: 500;
            color: $bluecolor;
            padding: 10px 17px;
            background-color: $whitecolor;
            border:1px solid $bluecolor;
            box-shadow: rgba(137, 137, 137, 0.32) 0px 1px 6px;
            margin:8px 11px 8px 0px;
            cursor: pointer;
            width: 100%;
          }
      }
       

      }
    }

    .recent-post{
      background-color: $whitecolor;
      box-shadow: rgba(137, 137, 137, 0.32) 0px 3px 8px;
      padding: 30px 30px;
      margin:30px 0px;
      p{
        font-size: 19px;
        font-weight:600;
        padding-bottom: 16px;
        &:after{
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: $bluecolor;
          position: relative;
          top: 9px;

        }
      
      }
      ul{
        list-style-type: none;
        padding-left: 0px;
        li{
          width:100%;
          display: inline-block;
          padding:20px 0px 0px 0px;
          position: relative;
          img{
            float: left;
            margin-right: 16px;
            width: 30%;
            float: left;
          }
          p{
            margin-bottom: 0px;
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            padding-bottom: 0px;
            color: $textblackcolor;
            width: 60%;
            float: left;
            cursor: pointer;
            &:after{
              content: none;
            }
          }
          &:after{
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: $borderbelow;
            position: absolute;
            bottom: -13px;
          }
          &:last-child{
            &:after{
              display: none;
            }
          }
        }
      }
    }
  

  }
}
.blog-bullet-points{
  margin:10px 16px 30px 0px;
  padding-left: 25px;
  .blog-detail-right{
    @include pos-inlineblock;
    .blog-search{
      @include pos-inlineblock;
      p{

      }
    }
  }
}

.blog-detl-call{
  padding: 20px 0px 0px 0px;
  .blog-detl-sec{
    padding: 32px 30px;
    border-radius: 0px;
    &:before{
      top: 0;
      height: 100%;
    }
    p{
      position: relative;
      top: 14px;
    }
    .blg-detl-btn{
      text-align: right;
     
      .btn-red-sec{
        padding: 6px 25px;
        .btn-icon{
          left:12px;
        }
        .btn-name{
          left: -5px;
        }
      }
      
    }
  }
}
.case-stu-para{
  font-size: 29px !important;
  font-weight: 600 !important;
  margin-top:25px;
}

.case-studies-list{
  width: 100%;
  position: relative;
  display: inline-block;
    margin-top: 60px;
    z-index: 9;
  .case-studies-list-row{
    width:100%;
    position: relative;
    display: inline-block;
    max-width: 1070px;
    margin: 0 auto;
    .case-studies-list-left{
      width:48%;
      float: left;
      margin-right: 25px;
    }
    .case-studies-list-right{
      width:48%;
      float: left;
    }
  }
  .case-study-box{
    @include pos-inlineblock;
    background-color: $whitecolor;
    border-radius: 12px;
    padding: 32px 41px 40px 41px;
    box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px;
  img{
    width: 100%;
   
  }
  p{
    font-size: 18px !important;
    font-weight: 600;
    line-height: 35px;
    text-align: left;
    line-height: 31px;
    margin-top: 15px;
    margin-bottom: 23px;
  }
}
}
.margin-casstud-top{
  margin-top: 40px;
}

.margin-case{
  margin-top: 30px !important;
}

.case-video-sec{
  @include pos-block;
  padding: 90px 0px;
  background-color: $greycolor;
  .case-video-main{
    @include pos-block;
    max-width: 1080px;
    margin: 0 auto;

      .case-video-left{
        width:48%;
        float: left;
        position: relative;
      .case-video-box{
          box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px;
          background-color: $whitecolor;
          border-radius: 10px;
          padding: 70px 35px;
          position: absolute;
          width: 100%;
          left: 10%;
          z-index: 999;
          transform: translate(10%, 77%);
        &::before{
          content: "";
          display: block;
          width: 4px;
          height: 75%;
          background-color:$bluecolor;
          position: absolute;
          left: 0px;
          top: 22px;
        }
        p{
          margin-bottom: 0px;
          font-size: 26px;
          line-height: 35px;
          font-weight: 600;
          color: $textblackcolor;
          text-align: left;
        }
      }
      }
      .case-video-right{
        width:52%;
        float: right;
        display: inline-block;
        position: relative;
        img{
          z-index: 99;
          border-radius: 12px;
          width: 100%;
        
        }
        // .proj-vido-overlay{
        //   position: absolute;
        // content: "";
        // width: 100%;
        // height: 554px;
        // top: 0;
        // background-color: rgba(240, 81, 37, 0.1882352941);
        // transition: all 1s;
        // border-radius: 13px;
        // }
        &:after{
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgb(0 0 0 / 27%);
        
          left: 0px;
          z-index: 99;
          border-radius: 12px;
          top: 0;
        }
        .play-sec-prdt{
          .video-play-button{
            z-index: 999;
            top: -180px;
            left: -5%;
          img{
            border-radius: 0px;
            width: auto;
          }
        }
      }
      }
   
}
}

.busn-sec{
  @include pos-block;
  .business-sec{
    @include pos-block;
    h2{
      color:$orangecolor;
      text-shadow: 0px 1px 3px rgb(0 0 0 / 31%);
      font-size: 31px;
      line-height: 45px;
      margin-bottom: 30px;
      margin-top: 0px;
      }
    h3{
      color:$textblackcolor;
      font-size: 23px;
      line-height: 40px;
      font-weight: 600;
      width: 100%;
      max-width: 730px;
      text-align: center;
      margin: 0 auto;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.31);
    }
    p{
      color:$textblackcolor;
      font-size: 20px;
      line-height: 37px;
      font-weight: 400;
      width: 100%;
      max-width: 770px;
      text-align: center;
      margin: 0 auto;
      margin:20px auto;
    }
  }
}

.busn-main-sec{
  @include pos-block;
  .busn-key-head{
    @include pos-block;
    h2{
      color:$textblackcolor;
      font-size: 31px;
      line-height: 45px;
      font-weight: 600;
      margin-bottom: 45px;
      text-align: center;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.31);
    }
  }
  .key-benefits-sec{
    margin-top: 30px;
    img{
     height: 79px;
     margin-bottom: 10px;
    }
    h3{
      font-size: 24px;
      line-height: 37px;
      font-weight: 400;
      margin-top: 18px;
      color:$textblackcolor;
      text-shadow: 0px 1px 3px rgb(0 0 0 / 18%);
      width: 100%;
      max-width: 400px;
      margin: 0 auto;

    }
  }

}

.consult-sec{
  .consult-content{
    background-color: $bluecolor;
    color: $whitecolor;
    padding: 60px 40px 60px 40px;
    max-width: 1000px;
    p{
      color:$whitecolor;
      font-size: 29px;
      line-height:43px;
      margin-bottom: 0px;
      margin-top: 2px;
    }
    &::before{
      display: none;
    }
  }
}
.consult-btn{
  margin-top: 0px !important;
  .btn-white{
    background: $whitecolor;
    box-shadow: rgb(108 108 108 / 42%) 0px 1px 8px;
    color: $bluecolor;
    padding: 9px 16px;
    &:hover{
      border:1px solid $whitecolor;
    }
    .btn-icon{
      background: $bluecolor;
      svg{
        color: $whitecolor;
      }
    }
    .btn-name{
      font-size: 17px;
      font-weight: 500;
    }

  }

}

.case-bg-icons{
  @include pos-block;
  img{
    position: absolute;
    left: 160px;
    transform: translate(-50%, -50%);
  }
}

.resol-sec{
  h2{
    color:$orangecolor;
    font-size: 31px;
    line-height: 35px;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.31);
  }
  p{
    font-size: 23px;
    line-height: 39px;
    font-weight: 600;
    text-shadow: 0px 1px 3px rgb(0 0 0 / 23%);
    width: 100%;
    max-width: 940px;
    margin: 33px auto 60px auto;
  }
}

.resol-inner-sec{
  @include pos-block;
  .resol-inner-content{
    @include pos-inlineblock;
    background-color: $whitecolor;
    box-shadow: rgba(137, 137, 137, 0.24) 0px 1px 11px;
    border-radius: 12px;
    padding: 35px 35px;
    text-align: left;
    width: 100%;
    max-width: 1090px;
    margin-bottom: 20px;
    &:before{
      content: "";
      display: block;
      width: 4px;
      height: 65%;
      background-color: $bluecolor;
      position: absolute;
      left: 0px;
      top: 31px;
    }
    .resol-head{
      width: 100%;
      position: relative;
      display: block;
      float: left;
      margin-right: 5px;
      h3{
        font-size: 22px;
        font-weight:700;
        line-height: 32px;
        color: $bluecolor;
        margin-bottom: 15px;
        text-align: center;
      }
    }
    .resol-dot{
      
      width:2%;
      float: left;
      p{
        font-weight: 500;
        color: $bluecolor;
        font-size: 32px;
        margin:0px;
        position: relative;
        top: -3px;

      }
     
    }
    .resol-para{
      width: 100%;
      float: left;
      p{
        color:$textblackcolor;
        font-size: 19px;
        font-weight: 500;
        line-height: 35px;
        text-shadow: none;
        margin:0px 0px;
        text-align: center;
        max-width: 920px;
        margin: 0 auto;
      }
    }
  }
}

.outcome-sec{
  @include pos-block;
  padding: 90px 0px;
  background-color: $whitecolor;
  h2{
   color:$orangecolor;
  font-weight: 600;
  font-size: 31px;
  line-height: 35px;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.31);
}
p{
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  color:$textblackcolor;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.23);
  margin:30px 0px 30px 0px;
}
.outcome-order{
  @include pos-block;
  margin:15px 0px 0px 0px;
  width: 100%;
  max-width: 690px;
  margin: 0 auto;
  ul{
    padding-left: 0px;
    li{
      font-size: 19px;
      line-height: 35px;
      color:$textblackcolor;
      text-align: left;
      margin: 12px 0px;
      text-shadow: 0px 1px 3px rgb(0 0 0 / 5%);
      &::marker{
        color: $bluecolor;
        font-size:25px;
      }
    }
  }
}
}

.schol-name{
  color:$orangecolor;
  font-weight: 500;
  font-size: 19px;
}

.job-sec{
  @include pos-block;
  padding: 110px 0px;
  background-color: $whitecolor;
  h2{
    font-size: 31px;
    font-weight: 700;
    text-shadow: 0px 1px 3px rgb(0 0 0 / 20%);
    color:$orangecolor;
    line-height: 40px;
    margin-bottom: 30px;
  }
  .job-inner-sec{
    @include pos-block;
    margin: 50px auto;
    max-width: 1100px;
   
    .job-inner-left{
      width:48%;
      float: left;
    }
    .job-inner-right{
      width:48%;
      float: right;
    }
    .job-desc-new{
      background-color: $whitecolor;
      box-shadow: rgb(137 137 137 / 33%) 0px 3px 8px;
      border-radius: 12px;
      padding: 40px 35px;
      position: relative;
      text-align: left;
      &::before{
        content: "";
        display: block;
        width: 4px;
        height: 75%;
        background-color: $bluecolor;
        position: absolute;
        left: 0px;
        top: 33px;
    
      }
      h3{
        color:$textblackcolor;
        font-size: 22px;
        line-height: 35px;
        font-weight: 600;
        margin-bottom: 14px;
      }
      .jon-loc{
        @include pos-block;
        svg{
          color:$bluecolor;
        }
        span{
          color:$textblackcolor;
          font-size: 16px;
          margin-left: 7px;
        }
      }
      .job-desc{
        width: 100%;
        position: relative;
        display: inline-block;
        margin: 11px 0px;
        .job-desc-left{
          float: left;
          margin:8px 20px 0px 0px;
          svg{
            color: $bluecolor;
            font-size: 22px;
          }
          span{
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
            margin-left: 9px;
            position: relative;
            top: 2px;
          }
        }
        .job-desc-right{
          float: left;
          position: relative;
          top: 7px;
          svg{
            color:$bluecolor;
            font-size: 22px;
          }
          span{
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
            margin-left: 9px;
            position: relative;
            top: 2px;
          }
        }
      }
    }
  }
}

.apply-btn{
  display: inline-block;
  border-radius: 50%;
  transition: all 0.4s;
  text-align: center;
  color:$bluecolor;
  transform: rotate(-45deg);
  position: relative;
  svg{
    font-size: 22px;
    position: relative;
  }
}

.apply-btn-cont{
  color: $bluecolor;
  font-size: 17px;
  font-weight: 700;
  text-decoration: none;
}


.apply-sec:hover .apply-btn{transition: all 0.4s;
transform: rotate(0deg);}
.apply-sec{
  @include pos-block;
  margin-top: 4px;

}

.job-details{
  @include pos-block;
  padding: 110px 0px;
  .job-desc-inner{
    @include pos-block;
      .job-desc-left{
        background-color: $whitecolor;
        box-shadow: rgb(137 137 137 / 33%) 0px 3px 8px;
        border-radius: 12px;
        text-align: left;
        padding: 20px 25px;
        @include pos-block;
        h2{
          font-size: 22px;
          line-height: 35px;
          font-weight: 600;
          padding-bottom: 0px;
          margin-bottom: 0px;
          color:$textblackcolor;
          position: relative;
          display: block;
          &:after{
            content: "";
            display: block;
            width: 114%;
            height: 0.9px;
            background-color: $bordercareer;
            margin-right: 15px;
            margin: 0 auto;
            text-align: center;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -28%);
            bottom: -28%;
          }
        }
        .job-inform-below{
          @include pos-block;
          ul{
            padding-left: 0px;
            margin-top: 40px;
            margin-bottom: 0px;
            li{
              list-style-type: none;
              margin-top:23px;
              .info-icon{
                @include pos-inlineblock;
                .info-img{
                  width: 15%;
                  float: left;
                  img{
                    height: 27px;
                  }
                  svg{ font-size: 30px;}
                }
                .info-text{
                  float: left;
                  width: 85%;
                  h3{
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 5px;
                  }
                  p{
                    font-size: 17px;
                    font-weight: 500;
                    color:$bluecolor;
                    margin-bottom: 0px;
                  }
                }    
              }
            }
          }
        }
      }

      .job-desc-right{
        @include pos-block;
        text-align: left;
        margin-left: 30px;
        h2{
          font-size: 22px;
          line-height: 35px;
          font-weight: 700;
          color:$bluecolor;
        }
        p{
          font-size: 16px;
          line-height: 30px;
          font-weight: 400;
          margin-bottom: 12px;
          text-align: justify;
        }
        h3{
          font-size: 19px;
          line-height: 25px;
          color:$textblackcolor;
          font-weight: 700;
          margin:20px 0px;
        }
        h4{
          font-size: 19px;
          line-height: 25px;
          color:$textblackcolor;
          font-weight: 700;
          margin:20px 0px;
        }
        ul{
          text-align: left;
          padding-left: 0px;
          li{

            font-size: 16px;
            line-height:29px;
            font-weight: 400;
            color:$textblackcolor;
            margin-bottom: 9px;
            margin-left: 35px;
            text-align: justify;
            &::marker{
              color: $bluecolor;
              font-size: 25px;
            }
          }
        }
      }
  }
  }


.detail-apply-sec{
  @include pos-inlineblock;
  margin-top: 10px;
  .detail-inn{
    @include pos-inlineblock;
    p{
      float: left;
      font-weight: 600 !important;
      font-size: 16px !important;
      margin-bottom: 0px !important;
      position: relative;
      top:6px;
      span{
        color: $bluecolor;
      }
    }
    .or-sec{
      float: left;
      margin: 0px 25px;
    }
    
  }
}

.info-job{
  img{
    height: 29px;
  }
}
.info-date{
  svg{
    height: 27px;
  }
}
.info-experience{
img{
  height: 25px;
  position: relative;
  left: 4px;

}
}
.info-quali{
  img{
    height: 24px;
    position: relative;
    left: 5px;
  }
}
.info-emply{
  svg{
    height: 30px;
    position: relative;
    left: 5px;
  }
}
.info-vac{
  svg{
    height: 28px;
    position: relative;
    left: 3px;

  }
}

.job-detl-dots{
  img{
    right: auto !important;
    left: -45px;
  }
}

.contact-sec{
  @include pos-block;
  padding: 110px 0px;
  .contact-inner{
    @include pos-inlineblock;
    padding: 60px 50px 80px 50px;
    max-width: 1180px;
    margin: 0 auto;
    &:before{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 76%;
      background-color: $whitecolor;
      transform: translate(-70%, -10px);
      /* height: 500px; */
      box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px;
      width: 95%;
      border-radius: 12px;
    }
   
   
    .contact-info{
      
        position: relative;
        width: 100%;
       color:$whitecolor;
        background-color: $bluecolor;
        box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px;
        text-align: left;
        padding: 30px 36px;
        max-width:390px;
        h2{
          font-size: 22px;
          line-height: 35px;
          margin-bottom: 35px;
          font-weight: 600;
          margin-top: 16px;
        }
      .contact-info-inner{
        @include pos-inlineblock;
        p{
          display: flex;
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          margin-bottom: 30px;
          svg{
            position: relative;
            top:3px;
          }
          span{
            margin-left: 20px;
            font-weight: 400;
          }
        }
      }
    }
    .contact-form{
      @include pos-inlineblock;
      margin-top: 23px;
      h2{
        font-size: 24px;
        line-height: 35px;
        font-weight: 700;
        text-align: left;
        color: $bluecolor;
        margin-bottom: 20px;
      }
    }
  }
}

.social-icons-contact{
  .social-icon{justify-content: center;padding-top: 120px;margin:23px 0px 0px 0px;}
  ul{
    li{
      img{
        top:3px;
        left:7px;
      }
      .twit-sec{
        top:3px;
        left:7px;
      }
      .fb-sec{
        left:10px;
      }
    }
  }
}

.form-sec{
  margin-bottom: 20px;
  font-family: $main-font !important;
  .MuiBox-root{
    font-family: $main-font !important;
    .MuiTextField-root{
      box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px !important;
      margin: 8px 0px !important;
      label{font-family: $main-font !important;;}
      .MuiOutlinedInput-root{
        border-radius: 0px !important;
        .MuiOutlinedInput-notchedOutline{
          border-color: $borderform !important;
        }
      }
    }
  }
}

.img-sec{
  @include pos-block;
  float: left;
  margin-bottom: 30px;
  img{
    height: 60px;
    float: left;
  }
}

.MuiBox-root{
  font-family: $main-font !important;
}

.MuiInputBase-input{
  font-family: $main-font !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: $bluecolor !important;
}

.form-sec .MuiBox-root .MuiTextField-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: $bluecolor !important;
  border-width: 1px !important;
}

.location-sec{
 width: 100%;
 position: relative;
  padding: 20px 0px 0px 0px;
  background-color: $whitecolor;
  display: grid;
  h2{
    font-size: 31px;
    line-height: 42px;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.31);
    font-weight: 600;
    margin-bottom:40px;
  }
}

.btn-sub{
  margin-right: 8px;
}

.privacy-policy-sec{
  background-color: $whitecolor;
  padding: 90px 0px;
  @include pos-block;
 
}
// .privacy-policy-content{
.infinite-scroll-component {
  
  @include pos-inlineblock;
  text-align: left;
  overflow-x: hidden !important;
  h2{
    text-shadow: 0px 1px 3px rgb(0 0 0 / 18%);
    color: $textblackcolor;
    font-size: 31px;
    font-weight: 600;
    line-height: 45px;
    text-align: center;
  }
  p{
    font-size: 17px;
    line-height: 34px;
    margin-bottom: 20px;
    color: $textblackcolor;
  }
  h3{
    font-size: 26px;
    line-height: 35px;
    font-weight: 600;
    margin-bottom: 21px;
    margin-top: 28px;
    color: $orangecolor;
    &:after{
      content: "";
    display: inline-block;
    vertical-align: middle;
    width: 10%;
    margin-right: -100%;
    margin-left: 10px;
    border-top: 2px solid $orangecolor;
    }
}
h4{
  font-size: 23px;
  line-height: 29px;
  font-weight: 600;
  margin: 15px 0px;
  color: $textblackcolor;
}
h5{
  font-size: 21px;
  line-height: 35px;
  font-weight: 600;
  margin: 22px 0px 9px 0px;
  color: $textblackcolor;
}
ul{
  @include pos-block;
  li{
    font-size: 17px;
    line-height: 34px;
    margin:12px 0px;
    color: $textblackcolor;
  }
}
span{
  font-size: 18px;
  line-height: 33px;
  font-weight:600;
  color: $textblackcolor;
}
    }
  

    .urllink{
      color: $bluecolor;
      font-weight: 600;
      text-decoration: none;
    }
    .mail-sec-priv{
      color:$bluecolor;
      text-decoration: none;
      font-weight: 600;
    }

    .terms-sec{
      @include pos-block;
   
    }

    .new-font-bold{
      font-size: 19px !important;
    }

    .dialogfilter{
      span{
        background: none !important;
        padding: 10px !important;
      }
    }

    .dialogmain{
      font-weight: 400 !important;
      &:hover{filter: none !important;}
    }

    .dialogfilternew{
      max-width: 650px !important;
      height: 29vh !important;
      // overflow-y: scroll;
      padding: 10px 20px;
      // position: absolute;
      // left: 50%;
      // transform: translate(-50%, -50%) !important;
      // top: 600px !important;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // overflow-x: hidden;
      overflow: hidden;
      border-radius: 0px;
      background-color: $whitecolor;
      h2{
        font-size: 23px;
        font-weight: 700;
        color: $bluecolor;
        text-align: center;
        margin-bottom: 28px;
        margin-top: 9px;
      }
    }

    .categfilt-sec{
      width: 100%;
      position: relative;
      display: flex;
      fieldset{
        margin: 0px !important;
        .MuiFormGroup-root {
          label{
            margin-left: 0px !important;
            margin-right: 0px !important;
            span{
              font-family: $main-font !important;
              color:$textblackcolor !important;
              text-align:left;
            }
          }
        }
      }
      .category-box{
        width:100%;
        position: relative;
        display: block;
        .categy-left{
          width: 48%;
          float: left;
          position: relative;
          display: inline-block;
        }
        .categy-right{
          width: 48%;
          float: left;
          position: relative;
          display: inline-block;
        }
      }
    }

    .close-modal-sec{
      width: 43px;
      height:43px;
      background-color: rgb(7 125 204 / 22%);
      border-radius: 50%;
    svg{
      position: relative;
      top: 2px;
      color: #767575;
      font-size: 22px;
      left: 0px;
    }
    }

    .main-innerbanner .new-img-baner h1{
      text-align: left;
    }
    .main-innerbanner .new-img-baner p{
      text-align: left;
    }

    .new-img-baner{
      margin-top:100px;
    }
    .innerbannerimg{
    padding: 80px 0px;
    }
    .new-img-baner p{
      line-height: 50px !important;
      font-size: 30px !important;
      font-weight: 700 !important;
      margin-top: 32px !important;
    }
    .new-banr-img{
      img{height:400px;
      }
    }

    .main-innerbanner .main-banner-sec h1 {
      font-size: 37px;
      font-weight: 700;
      color: $bluecolor;
      text-align: center;
      line-height: 52px;
  }
  .new-img-baner h1{
    text-align: left !important;
  }
  .home-right-banner img{
    height: 400px;
  }
  .homebanner{
    padding: 100px 0px 150px 0px;
  }
  .homebanner .banner-sec-content{
    margin-top: 60px;
  }
  .cont-img img{
    height: 350px;
  }

  .modal-close-new{
    right: -9px;
    /* overflow: hidden; */
    top: -7px;
    position: fixed;
  }
  .modal-close-new .close-modal-sec svg {
    position: relative;
    top: 12px;
    color: #565555;
    font-size: 20px;
    left: -3px;
}

.mdl-btn{margin-left: 10px;margin-top: 18px;text-align: center;}

.modl-sec{
  overflow-y: scroll;
  height: 25vh;;
}

.modl-sec::-webkit-scrollbar-track {
  background: transparent;
}

/** Scrollbar section code start **/
::-webkit-scrollbar {width:4px;height: 7px;}
::-webkit-scrollbar-track {background-color: #ebebeb;-webkit-border-radius: 5px;border-radius: 10px;}
::-webkit-scrollbar-thumb {-webkit-border-radius: 5px;border-radius: 10px;background: #c0c0c0; }
/** Scroll section code end **/
.categfilt-sec .MuiCheckbox-root.Mui-checked{
  color:$bluecolor !important;
}
.book-sec .book-label{
  font-size: 19px !important;
  padding: 0px !important;
  position: relative;
  top: 1px;
}
.book-app-modal{
  height: 84vh !important;
  padding: 10px 37px;
  max-width: 516px !important;
  font-family: $main-font !important;
}
// .book-app-modal .form-sec-main{
//   overflow-y: scroll;
// }

.modl-sec-form::-webkit-scrollbar-track {
  background: transparent;
}

.modl-sec-form{
  overflow-y: scroll;
  height: 80vh;
  overflow-x: hidden;
}
// modal tooltip start

.tooltipss {
  position: relative;
  text-decoration: underline dotted;
  font-style: italic;
  transition: color linear 150ms;
  cursor: help;
  box-sizing: content-box;
  border-top: 5px solid transparent;
}

.tooltipss:hover,
.tooltipss:focus-visible {
  color: $blackcolor;
}

.tooltipss:focus-visible {
  outline: 2px solid currentColor;
  outline-offset: 1px;
}

.tooltipss:hover::before,
.tooltipss:hover::after,
.tooltipss:focus-visible::before,
.tooltipss:focus-visible::after {
  opacity: 1;
  visibility: visible;
}

.tooltipss::before,
.tooltipss::after {
  visibility: hidden;
  opacity: 0;
  transition: visibility 150ms, opacity 150ms linear;
}

.tooltipss::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #313131;
  left: 50%;
  top: 0;
  transform: translateY(-100%) translateX(-50%);
}

.tooltipss::after {
  content: attr(data-content);
  font-size: 14px;
  border-radius: 6px;
  color: $whitecolor;
  font-style: normal;
  background: #313131;
  padding: 6px;
  text-align: center;
  position: absolute;
  white-space: nowrap;
  top: 0;
  left: -160%;
  transform: translateY(-110%) translateX(-77%);
}

.tooltipss i{
  color:$red;
  cursor: pointer;
}
.content-tol{
  float: right;
  width: 100%;
  position: relative;
  display: block;
  .tooltipss{
    position: absolute;
    bottom: 20px;
    right: 9px;
  }
}

// modal tooltip end

.form-radio-sec{
  .MuiFormControl-root {
    .MuiRadioGroup-root{
        .MuiFormControlLabel-root{
          .MuiFormControlLabel-label{
            text-align: left;
            font-family: $main-font !important;
          }
        }
    }
  }
}


.form-radio-sec{
  .MuiFormControl-root {
      .MuiFormLabel-root{
        color: $textblackcolor !important;
        font-weight: 600;
        font-family: "DM Sans", serif !important;
        font-size: 19px;
        text-align: left;
        margin-bottom: 13px;
        margin-top: 8px;
    
      }

  }

}

.case-privacy{
  text-align: left;
  font-size: 16px;
  line-height: 23px;
  margin-top: 12px;
}
.case-link-prv{
  color: $bluecolor;
  text-decoration: none;
}

.book-app-modal .form-sec .MuiBox-root .MuiTextField-root{padding-right: 0px !important;}

.invalid-feedback{
  margin: 0px !important;
  position: relative;
  width: 100%;
  .error-msg{
    position: absolute;
    color:$red;
    font-size: 14px;
    top: -7px;
  }
}

.invalid-feedbackcontact{
  margin: 0px !important;
  position: relative;
  width: 100%;
  .error-msg{
    position: absolute;
    color:$red;
    font-size: 14px;
    top:-10px;
  }
}

.email-verify-sec{
  p{
    font-size: 14px;
    position: relative;
    text-align: right;
    color: $textblackcolor;
    margin-bottom: 0px;
  }
}
.dialogfilternew  .form-sec{
margin-bottom: 8px;
}

.dialogfilternew .form-sec .MuiBox-root .MuiTextField-root label{
  color:$textblackcolor !important;
}
.book-app-modal .mdl-btn{
  margin-top: 25px;
}
// .email-verify-sec{
//   position: relative;
//   display: block;
//   width: 100%;
//   p{
//     position: absolute;
//   }
// }

.captch-error{
  top:-2px !important;
}

.otp-modle{
 .otp-head{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
 } 
}

.otp-modle .otp-main-secss{
  margin: 24px 0px 40px 0px;
}

.otp-main-secss {
  .otp-form{
    margin: 0 auto !important;
    max-width: 300px !important;
    width: 100% !important;
    .otp-sec-input{
      box-shadow: 2px 1px 5px rgb(7 125 204 / 15%) !important;
      margin-right: 20px !important;
      padding: 10px 0 !important;
      background-color: $whitecolor !important;
      border:1px solid $bluecolor;
    }
  }
}

.otp-modle{
  height: 34vh !important;
}

.otp-main-secss .otp-sec-input:focus{
  background-color: $bluecolor !important;
  color:$whitecolor;
}
.mdl-btn .btn-inner-sec .btn-name{
  left: 5px;
}
.btn-otp{
  margin:34px 0px 10px 0px !important;
}
// input:valid {
//   background-color: $bluecolor ;
//   color:$whitecolor;
// }
.error-msg-sec{
  width:100%;
  position: relative;
  display: block;
  .error-msg{
    font-size: 15px;
    font-weight: 500;
    color:$red;
  }
}
.success-msg-sec{
  width:100%;
  position: relative;
  display: block;
  .succ-msg{
    font-size: 15px;
    font-weight: 500;
    color:$green;
  }
}

.form-sec .MuiBox-root .MuiTextField-root.is-invalid{
  width: 100% !important;
  background-image: none !important;
  padding-right: 0px !important;
}

.contact-form .form-sec{
  margin-bottom: 10px;
}

.success-left{
  position: relative;
    display: block;
    text-align: left;
    p{
      position: absolute;
    }
}
.error-left{
  position: relative;
  display: block;
  text-align: left;
  p{
    position: absolute;
  }
}
.servicebtn-prof .btn-name{
  padding: 0px !important;
  top:5px !important;
  cursor: pointer;
}
.servicebtn-prof .btn-icon{
  padding: 0px !important;
  background-color: $whitecolor !important;
}

.servicebtn-prof .red-button-sec .btn-inner-sec:hover .btn-icon{
  background-color: $orangecolor !important;
}
.servicebtn-prof .btn-inner-sec .btn-icon svg{
  top:5px;
}
.servicebtn-prof .btn-red-sec:hover .btn-icon{
  background-color: $orangecolor !important;
}
.main-innerbanner .prodt-details h1{
  text-align: left;
}
// .prodct-modl{
//   z-index: 999;
// }

.prodct-modl .date-pick-sec-modl{
  .MuiStack-root{
    width:100% !important;
  }
}
.produt-modal-new{
  z-index: 99;
}

// body{
//   pointer-events:auto !important;
//   z-index: 99;
// }

.prodt-modl-news {
  .MuiDialog-container{
    
    h2{
      padding: 25px 37px 20px 37px !important;
      font-size: 23px;
      font-weight: 700;
      color: $bluecolor;
      text-align: center;
      font-family: $main-font !important;
    }
    .MuiDialogContent-root{
      border-bottom: 0px !important;
      border-top: 0px !important;
      padding: 10px 37px !important;
    }
    .MuiPaper-root{
      max-width: 516px !important;
      border-radius: 0px;
      overflow-x:hidden;
      width: 100%;
    }
  }

  .new-modl-calen{
    .MuiStack-root {
      width: 100%;
      position: relative;
      display: block;
      .MuiTextField-root{
        min-width: 100% !important;
      
        .MuiOutlinedInput-root{
          padding-right: 0px !important;
          box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px !important;
          margin: 8px 0px !important;
          border-radius: 0px !important;
          font-family: $main-font !important;
        }
        label{
          font-family: $main-font !important;
          top:8px;
        }
      }
    }
  }
}

.MuiPickersDay-root.Mui-selected:hover{background-color: $bluecolor !important;}
.MuiButton-root{
  color:$bluecolor !important;
}

.new-modl-calen .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline{border-width: 1px !important;
color:$bluecolor !important;}
.MuiPickersLayout-root{
  font-family: $main-font !important;
  .MuiPickersToolbar-root{
    font-family: $main-font !important;
    .MuiTypography-root{
      font-family: $main-font !important;
    }
    .MuiPickersToolbar-content{
      font-family: $main-font !important;
    }
  }
}
.MuiPickersLayout-contentWrapper{
  font-family: $main-font !important;
  .MuiDateCalendar-root{
    font-family: $main-font !important;
  }
}
.MuiPickersCalendarHeader-label{
  font-family: $main-font !important;
}
.MuiDayCalendar-header{
  font-family: $main-font !important;
  .MuiDayCalendar-weekDayLabel{
    font-family: $main-font !important;
  }
}
.MuiDayCalendar-weekDayLabel {
  font-family: $main-font !important;
}
.MuiPickersDay-root {
  font-family: $main-font !important;
}
.MuiButton-root {
  font-family: $main-font !important; 
}

.prodt-modl-news{
  .MuiDialog-scrollPaper{
    .MuiPaper-elevation{
      .closebew-icon{
        width: 43px;
        height: 43px;
        background-color: rgba(7, 125, 204, 0.22);
        border-radius: 50%;
        position: absolute;
        right: -8px;
        top: -10px;
        svg{
          position: relative;
          top: 3px;
          color: #565555;
          font-size: 19px;
          left: -1px;

        }
      }
    }
  }
}

.new-mdl-btn{
  margin-bottom: 27px;
  margin-top: 23px;
}

.new-calen-icons {
  cursor: pointer;
  display: block;
  margin-top: 0 !important;
  position: relative !important;
  width: 100%;
}
.new-calen-icons svg {
  bottom: 25px;
  color: $greyicon;
  font-size: 20px;
  position: absolute;
  right: 8px;
}

.project-box-blue{
  .btn-white{
    .btn-inner-sec{
      .btn-name{
        margin-right: 22px;
      }
    }
  }
}

.content-tol .home-tool{
  bottom: 24px;
}
.job-detal-sec .content-tol .home-tool{
  bottom: 24px;
}

.contact-veri{
  margin: 0px !important;
}
.new-modl-calen{
  .MuiStack-root {
    padding-top: 0px !important;
  }
}

.prodt-modl-news{
  .form-sec{
    margin-bottom: 10px;
  }
}
.MuiClock-pin{
  background-color:$bluecolor !important;
}
.MuiClockPointer-root{
  background-color: $bluecolor !important;
}
.MuiClockPointer-thumb{
  background-color: $bluecolor !important;
}
.about-calen-sec .MuiDialog-container .MuiPaper-root{
  max-width: 426px !important;
}
.about-calen-sec .MuiDialog-container h2{
  font-size: 22px;
}
.about-time-sec .MuiDialog-container .MuiPaper-root{
  max-width: 426px !important;
}
.about-time-sec .time-pick-sec-modl .MuiStack-root .MuiStack-root .MuiTextField-root .MuiInputBase-root{
  box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px !important;
  border-radius: 0px !important;
  width: 100% !important;
  padding-right: 0px !important;
}
.about-time-sec .time-pick-sec-modl .MuiStack-root .MuiStack-root .MuiTextField-root{
  width:100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
}
.about-time-sec .time-pick-sec-modl .MuiStack-root{
  width: 100% !important;

}
.about-time-sec .MuiDialog-container h2{
  font-size: 22px;
}
.abut-inform .tooltipss{bottom: 43px;}
.about-time-sec .abut-clock-icon{}
.about-time-sec .abut-clock-icon svg{
  bottom: 16px;
color: $greyicon;
font-size: 22px;
position: absolute;
right: 8px;
}
.about-time-sec .abut-clock-icon{cursor: pointer;
display: block;
margin-top: 0 !important;
position: relative !important;
width: 100%;
}
.about-time-sec{
  .new-modl-time{
    .MuiStack-root {
      .MuiTextField-root{
        label{
          font-family: $main-font !important;
        }
        .MuiInputBase-root{
          .MuiInputBase-input{
            font-family: $main-font !important;
          }
        }
      }
    }
  }
}
.service-modl-new {
  .form-serv-sec{
    h2{
      padding-bottom: 3px !important;
      color:$bluecolor;
    }
    .serv-head-modl{
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      color:$textblackcolor;
    }
    .form-radio-sec{
      .MuiFormControl-root {
        width:100%;
        flex-direction: row;
        .MuiFormLabel-root {
          float: left;
          font-size: 17px;
          color: $textblackcolor;
          margin-right: 14px;
        }
        .MuiFormGroup-root {
          .MuiFormControlLabel-root{
            .MuiRadio-root{
              &.Mui-checked{
                color: $bluecolor !important;
              }
            }
          }
          .MuiTypography-root{
            color:$textblackcolor !important;
          }
        }
      }
    }
  }
}

.service-modl-new{
  .form-sec{
    margin-bottom: 8px !important;
  }
}
.service-modl-new .form-sec .MuiBox-root .MuiTextField-root{
  margin:7px 0px !important;
}

.prodt-modl-news{
  .form-radio-sec{
  .MuiFormControl-root {
    width:100%;
    flex-direction: row;
    .MuiFormLabel-root {
      float: left;
      font-size: 17px;
      color: $textblackcolor;
      margin-right: 14px;
      position: relative;
    top: 2px;
    }
    .MuiFormGroup-root {
      .MuiFormControlLabel-root{
        .MuiRadio-root{
          &.Mui-checked{
            color: $bluecolor !important;
          }
        }
      }
      .MuiTypography-root{
        color:$textblackcolor !important;
      }
    }
  }
}
}


.prodt-modl-news{
  .system-radio{
    .MuiFormControl-root {
      width:100%;
      flex-direction: column;
      label{
        margin-bottom: 3px;
      }
    }
    }
}


.prodt-modl-news .time-pick-sec-modl .MuiStack-root .MuiStack-root .MuiTextField-root .MuiInputBase-root{
  box-shadow: rgb(46 39 39 / 17%) 0px 3px 8px !important;
  border-radius: 0px !important;
  width: 100% !important;
  padding-right: 0px !important;
  margin: 8px 0px !important;
}
.prodt-modl-news .time-pick-sec-modl .MuiStack-root .MuiStack-root .MuiTextField-root{
  width:100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
}
.prodt-modl-news .time-pick-sec-modl .MuiStack-root{

  width: 100% !important;
  // box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px !important;
  // padding-top: 0px;

}

.prodt-modl-news .abut-clock-icon svg{
  bottom: 25px;
color: $greyicon;
font-size: 22px;
position: absolute;
right: 8px;
}
.prodt-modl-news .abut-clock-icon{cursor: pointer;
display: block;
margin-top: 0 !important;
position: relative !important;
width: 100%;
}

.prodt-modl-news .form-radio-sec{
  margin-bottom: 10px !important;
}

.new-modl-time{
  font-family: $main-font !important;
  .MuiStack-root{
    .MuiTextField-root{
      label{
        font-family: $main-font !important;
        top: 8px;
      }
    }
  }
}
.MuiClockNumber-root{
  font-family: $main-font !important;
}

.time-pick-sec-modl .MuiStack-root {
  padding-top: 0px !important;
}

.system-radio{
  .MuiFormControl-root{
    .MuiFormLabel-root {
      margin-top: 0px !important;
    }
  }
}

.prodt-modl-news .case-privacy{
  margin-top:5px !important;
}
.charcter-sec{
  margin: 0px !important;
  text-align: right;
  color: #2a2a2a;
  font-size: 14px;
}

.newotp-sec .MuiDialogContent-root {
border-top:none !important;
border-bottom: none !important;
padding: 22px 26px;
}
.newotp-sec{
  .MuiPaper-elevation{
    border-radius: 0px !important;
    max-width: 516px !important;
    width: 100% !important;
    overflow-x: hidden;
    h2{
      padding: 30px 37px 16px 37px !important;
      font-size: 23px;
      font-weight: 700;
      color: $bluecolor;
      text-align: center;
      font-family:$main-font !important;

    }
  }
  .otp-content-sec{
    .otp-head{
      color: #2a2a2a;
      text-align: center;
      font-size: 18px;
      font-weight: 500;

    }
    .otp-bottom{
      margin-bottom: 10px;
    }
  }
  .otp-main-secss{
    margin: 33px 10px !important;
  }
}

.newotp-sec .MuiDialog-scrollPaper .MuiPaper-elevation .closebew-icon {
  width: 43px;
  height: 43px;
  background-color: rgba(7, 125, 204, 0.22);
  border-radius: 50%;
  position: absolute;
  right: -8px;
  top: -10px;
}
.newotp-sec .MuiDialog-scrollPaper .MuiPaper-elevation .closebew-icon svg {
  position: relative;
  top: 3px;
  color: #565555;
  font-size: 19px;
  left: -1px;
}

// thank you code start from here


.success-animation { margin:0px auto;}

.checkmark {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #049504;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #049504;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
   margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #049504;
    fill: $whitecolor;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
 
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px $successroundgreen;
    }
}
.thanks-para {
  
    color: $textblackcolor;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
  
}

.thanksuou{
  .MuiDialog-container{
    .MuiPaper-root{
      .MuiDialogContent-root{
        padding: 46px 36px !important;
        border-bottom: 6px solid $bluecolor !important;
      }
    }
  }
}

.prodt-modl-news .MuiDialog-container .MuiDialogContent-root{
  border-bottom:4px solid $bluecolor!important;
}

.newotp-sec .MuiDialog-container .MuiDialogContent-root{
  border-bottom:4px solid $bluecolor!important;
}
.width-otp{
  .error-msg-sec{
    .error-msg{
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -21%);
      margin-bottom: 0px;
    }
  }
}

// file upload section start
.formbold-form-wrapper {
  margin: 0 auto;
  max-width: 550px;
  width: 100%;
  background: $whitecolor;
}
.formbold-form-label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  margin-bottom: 12px;
}
.formbold-form-label-2 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

.formbold-form-input {
  width: 100%;
  padding: 12px 24px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: $whitecolor;
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  outline: none;
  resize: none;
}
.formbold-form-input:focus {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-btn {
  text-align: center;
  font-size: 16px;
  border-radius: 6px;
  padding: 14px 32px;
  border: none;
  font-weight: 600;
  color: $whitecolor;
  cursor: pointer;
}
.formbold-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold--mx-3 {
  margin-left: -12px;
  margin-right: -12px;
}
.formbold-px-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.formbold-file-input input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0px;
}

.formbold-file-input label {
  position: relative;
  border: 1px dashed $carouslround;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 7px;
  text-align: center;
  cursor: pointer;
}
.formbold-drop-file {
  display: block;
  font-weight: 500;
  color: $textblackcolor;
  font-size: 16px;
  margin-bottom: 4px;
  margin-top: 4px;
}

.formbold-or {
  font-weight: 500;
  font-size: 16px;

  display: block;
  margin-bottom: 8px;
}
.formbold-browse {
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 4px;
}

.formbold-file-list {
  border-radius: 6px;
  padding: 16px 32px;
}

.formbold-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formbold-file-item button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.formbold-file-name {
  font-weight: 500;
  font-size: 16px;
  color: $successroundgreen;
  padding-right: 12px;
}
.formbold-progress-bar {
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 8px;
 
}

.formbold-progress {
  position: absolute;
  width: 75%;
  height: 100%;
  left: 0;
  top: 0;
 
  border-radius: 8px;
}

.job-detal-sec .uplad-sec-new{
  h2{
    padding: 5px 0px !important;
    text-align: left;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 12px;
  }
}
.suprt-sec{
  font-size: 12px;
  color: $textblackcolor;
}
.uplod-inner{
  svg{
    color:$green;
    font-size: 25px;
  }
}
.formbold-file-input{
  width:100%;position: relative;
}
.file-del-new{
  margin:15px 0px;
  p{
    margin-bottom: 0px;
    float: left;
    span{
      svg{
        color: $red;
        position: relative;
        font-size: 18px;
        margin-left: 3px;
        top: -1px;
      }
    }
  }
}

.job-detal-sec .MuiDialog-container .MuiDialogContent-root{
  padding:10px 41px !important;
}
.job-detal-sec .case-privacy{
  margin-top:15px !important;
}
.error-newss{
  top:-2px !important;
}
// file upload section end

// loader code start from here

.loader {
  position: absolute;
  top: 50%;
  left: 40%;
  margin-left: 10%;
  transform: translate3d(-50%, -50%, 0);
}
.dot {
  width: 24px;
  height: 24px;
  background: $bluecolor;
  border-radius: 100%;
  display: inline-block;
  animation: slide 1s infinite;
}
.dot:nth-child(1) {
  animation-delay: 0.1s;
  background: $bluecolor;
}
.dot:nth-child(2) {
  animation-delay: 0.2s;
  background: #038eebcc;
}
.dot:nth-child(3) {
  animation-delay: 0.3s;
  background: #077dcc73;
}
.dot:nth-child(4) {
  animation-delay: 0.4s;
  background: #f051259e;
}
.dot:nth-child(5) {
  animation-delay: 0.5s;
  background: #f051256b;
}
@-moz-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
// loader code end from here

// animation for arrow start here
.arrows-container {
  display: flex;
  position: relative;
  top:9px !important;
  float: left;
}

.arrownew {
  padding: 7px;
  box-shadow: 2px -2px $bluecolor inset;
  transform: rotate(225deg);
  opacity: 0;
  position: absolute;
}
.arrownewss{
  padding: 7px;
  box-shadow: 2px -2px $bluecolor inset;
  transform: rotate(225deg);
  opacity: 0;
  position: absolute;
}

.arrow-one {
  animation: arrowMovement 2s ease-in-out infinite;
}

.arrow-two {
  animation: arrowMovement 2s 1s ease-in-out infinite;
}

@keyframes arrowMovement {
  0% {
    left: 0;
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    left: 30px;
    opacity: 0;
  }
}

.arrownewwprev{
  transform: rotate(47deg);
}
.arrownewwprevsec{
  transform: rotate(47deg);
}
.arrownewwprev {
  animation: arrowMovementss 2s ease-in-out infinite;
}

.arrownewwprevsec {
  animation: arrowMovementss 2s 1s ease-in-out infinite;
}

@keyframes arrowMovementss {
  0% {
    right: 0;
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    right: 30px;
    opacity: 0;
  }
}

// animation for arrow end here
.pre-btnss{
  width: 100%;
  position: relative;
  display: block;
  text-align: center;
  
.prev-main-sec{
  color: $textblackcolor;
  text-decoration: none;
  margin-right: 50px;
  position: relative;
  display: inline-block;
  .btn-name{
    font-size: 20px;
    font-weight: 600;
    color: $bluecolor;
    border-bottom: 1px solid $whitecolor;
    transition: all ease 0.3s;
    &:hover{
      border-bottom: 1px solid $bluecolor;
    }
  }
}
}


.pre-btnss{
  width: 100%;
  position: relative;
  display: block;
  text-align: center;
  margin-top: 40px;
.next-main-sec{
  color: $textblackcolor;
  text-decoration: none;
  margin-right: 17px;
  position: relative;
  display: inline-block;
  position: relative;
  top:9px;
  cursor: pointer;
  .btn-name{
    font-size:20px;
    font-weight: 600;
    float: left;
    color: $bluecolor;
    border-bottom: 1px solid $whitecolor;
    transition: all ease 0.3s;
    &:hover{
      border-bottom: 1px solid $bluecolor;
    }
  }
}
}
.absentcase{
  background-color: #f9f9f9;
}

.blog-para-span{
    font-weight: 600;
}

.blog-detl-call .blog-detl-sec p{
  top:3px;
}

.new-case-add{
  padding: 50px 0px 90px 0px;
  margin-top: 0px !important;
  h2{
    color:$textblackcolor;
    &:after{
      border-top: none !important;
    }
  }
  p{
    font-size: 24px !important;
    font-weight: 400;
    margin-top: 25px !important;
    line-height: 43px !important;
  }
}
.absentcase{
  padding: 50px 0px 0px 0px;
}
 /* Media Query code start from here */
 @media only screen and (max-width: 1700px) {
  .main-sec .banner-sec-content h1{
    font-size: 43px;
  }
  .main-sec .banner-sec-content h2{
    font-size: 43px;
  }
  .home-service-sec .service-short-head h2{
    font-size: 31px;;
  }
  .home-service-sec .service-short-head p{
    font-size: 28px;;
  }
  .project-box p{
    line-height: 45px;
  }
  .team-sec-content .meet-pata{
    font-size: 18px !important;
  }
  .main-innerbanner .main-banner-sec h1{
    font-size: 39px;
    line-height: 56px;
  }
  .new-img-baner p {
    line-height: 45px !important;
    font-size: 29px !important;}
    .secure-main .secure-sec .secure-para{
      font-size: 28px;
      line-height: 47px;
    }
    .differ-service-sec .differ-service-inner p{
      font-size: 26px;
      line-height: 46px;
    }
    .left-side-head-before h2{
      font-size: 31px;
      line-height: 45px;
    }
    .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-icon img {
      height: 53px;
  }
  .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3{
    font-size: 23px;
    line-height: 30px;
    padding-top: 7px;
  }
  .service-inner-category .serv-categ-box .serv-categ-para p{
    font-size: 17px;
    line-height: 30px;
  }
  .differ-service-sec .differ-service-inner{
    margin-top: 22px;
  }
  .keyprocs-feturesec .keyprocs-heading h2{
    margin-bottom: 30px;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec h3{
    line-height: 25px;
  }
  .impact-sec .imapct-inner-sec p{
    font-size: 24px;
  }
  .impact-sec .imapct-inner-sec h3{
    font-size: 36px;
  }
  .callactn-sec .callactn-content p{
    font-size: 22px;
  }
  .busn-sec{
    .business-sec{
      p{
        font-size: 20px !important;
        line-height: 34px;
      }
    }
  }
  .busn-main-sec .busn-key-head h2{
    font-size: 30px;
    margin-bottom: 17px;
  }
  .consult-content p{
    font-size: 28px !important;
  }
  .resol-sec h2{
    font-size: 30px;;
  }
  .resol-sec p{
    font-size: 22px;
    line-height: 38px;
  }
  .outcome-sec h2{
    font-size: 30px;
  }
  .busn-main-sec .key-benefits-sec img {
    height: 73px;}
    .case-video-sec .case-video-main .case-video-left .case-video-box p{
      font-size: 25px;
    }
    .new-banr-img img {
      height: 380px;
  }
  .blog-list .blog-list-inner .blog-list-sec .blog-list-content h2{
    line-height: 32px;
  }
  .innerbannerimg {
    padding: 75px 0px;
}
.job-sec .job-inner-sec .job-desc-new h3{
  font-size: 21px;
}
.infinite-scroll-component h3 {
  font-size: 25px;}
  .infinite-scroll-component h4{
    font-size: 22px;
  }
  .infinite-scroll-component p{
    margin-bottom: 18px;
  }
  .case-stu-para{
    font-size: 29px !important;}
 }

 @media (min-width: 1500px) and (max-width: 1650px) {
  .main-sec .banner-sec-content p{
    font-size: 21px;
    line-height: 37px;
  }
  .main-sec .banner-sec-content h1{
    font-size: 39px;
  }
  .main-sec .banner-sec-content h2{
    font-size: 41px;
  }
  .mainbaner-img img{
    height: 380px;
  }
  .homebanner .banner-sec-content {
    margin-top: 50px;
}
.home-service-sec .service-short-head .short-head-line {
  font-size: 20px;
}
.home-service-sec .service-short-head h2 {
  font-size: 30px;
}
.home-service-sec .service-short-head p {
  font-size: 27px;
}
.service-category .service-box .service-inner .service-icon .icon-sec .icon-circle img {
  height: 40px;}
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle{
    height: 62px;
    width: 62px;
  }
  .project-box p {
    line-height: 44px;
    font-size: 22px;
}
.vision-sec .vision-content h2{
  font-size: 30px;
}
.vision-sec .vision-content p{
  font-size: 21px;
  line-height: 38px;
}
.about-main .abut-banner-cont h1 {
  font-size: 35px;}
  .about-innov-main .about-innov-sec h2{
    margin-bottom: 30px;
  }
  .founder-sec-cont h2{
    font-size: 30px;
  }
  .founder-sec-cont p{
    font-size: 18px;
    line-height: 36px;
  }
  .ps-timeline-sec .container ol.ps-timeline li p{
    font-size: 17px;
  }
  .main-sec .new-img-baner h1 {
    font-size: 38px;
}
.new-img-baner p {
  line-height: 45px !important;
  font-size: 28px !important;
}
.innerbannerimg {
  padding: 70px 0px;
}
.secure-main .secure-sec .secure-para {
  font-size: 26px;
  line-height: 47px;
}
.left-side-head-before h2 {
  font-size: 30px;
}
.differ-service-sec .differ-service-inner p {
    font-size: 25px;
    line-height: 44px;
}
.callactn-sec .callactn-content .cal-btn-sec{
  margin-top: 5px;
}
.service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3 {
  font-size: 22px;
  line-height: 31px; height: 68px;}
  .serv-img-inner::before {
    content: "";
    position: absolute;
    top: 33px;
    left: 199px;
    width: 296px;
    height: 260px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    z-index: 99;
}
.serv-img-inner img {
  position: absolute;
  top: 33px;
  left: 199px;
  border-radius: 5px;
  height: 262px;
}
.service-img-right::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 224px;
  width: 290px;
  height: 272px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  
}

.servleft-img-inner::before{
  top: 33px;
  left: 145px;
  width: 296px;
  height: 260px;
}
.servleft-img-inner img {
  position: absolute;
  top: 33px;
  left: 139px;
  border-radius: 5px;
  height: 262px;
}
.servce-img-left::after{
  top: 10px;
  left: 123px;
  width: 290px;
  height: 272px;
}
.proof-sec .prrof-img .proof-content-sec .team-content h4{
  font-size: 30px;
}
.prodct-margin {
  margin-top: 25px;
}
.product-sec p {
  font-size: 26px !important;
}
.prodtbannerimg img {
  width: 90%;}
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec img {
    height: 52px;
}
.impact-sec .imapct-inner-sec p {
  font-size: 23px;
}
.callactn-sec .callactn-content p {
  font-size: 21px;
}
.impact-sec .imapct-inner-sec h3 {
  font-size: 34px;
}
.main-bannerinner h1 {
  font-size: 34px !important;
}
.busn-main-sec .key-benefits-sec h3 {
  font-size: 23px;
  line-height: 35px;}
  .busn-main-sec .key-benefits-sec img {
    height: 70px;
}
.consult-content p {
  font-size: 27px !important;
}
.outcome-sec .outcome-order ul li{
  line-height: 35px;
}
.case-video-sec{
  padding: 85px 0px;
}
.outcome-sec p {
  font-size: 23px;}
  .footer-sec .footer-left-sec img {
    height: 65px;}
  .blog-detl-sec p{
    font-size: 22px;
  }
  .new-banr-img img {
    height: 360px;
}
.job-sec{
  padding: 90px 0px;
}
.job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img img {
  height: 25px;
}
.job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img svg {
  font-size: 29px;
}
.infinite-scroll-component h3 {
  font-size: 24px;
}
.infinite-scroll-component h4 {
  font-size: 21px;
}
 }


 @media (min-width: 1400px) and (max-width: 1500px) {
  .thanksuou {
    margin-top: 50px !important;
}
  .thanksuou p{
    font-size: 19px !important;
  }
  .thanksuou h2{
    font-size: 31px !important;
    margin-bottom: 20px !important;
  }
  .secure-main .container .about-innov-sec .serice-nee-fix{
    font-size: 24px !important;
  }
  .main-sec .banner-sec-content p{
    font-size: 21px;
    line-height: 37px;
  }
  .main-sec .banner-sec-content h1{
    font-size: 39px;
  }
  .main-sec .banner-sec-content h2{
    font-size: 41px;
  }
  .mainbaner-img img{
    height: 380px;
  }
  .homebanner .banner-sec-content {
    margin-top: 50px;
}
.home-service-sec .service-short-head .short-head-line {
  font-size: 20px !important;
}
.home-service-sec .service-short-head h2 {
  font-size: 28px;
}
.home-service-sec .service-short-head p {
  font-size: 25px !important;
}
.service-category .service-box .service-inner .service-icon .icon-sec .icon-circle img {
  height: 40px;}
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle{
    height: 62px;
    width: 62px;
  }
  .project-box p {
    line-height: 43px;
    font-size: 22px;
}
.vision-sec .vision-content h2{
  font-size: 28px;
}
.vision-sec .vision-content p{
  font-size: 20px;
  line-height: 36px;
}
.about-main .abut-banner-cont h1 {
  font-size: 34px;}
  .about-innov-main .about-innov-sec h2{
    margin-bottom: 30px;
  }
  .founder-sec-cont h2{
    font-size: 29px;
  }
  .founder-sec-cont p{
    font-size: 18px;
    line-height: 36px;
  }
  .ps-timeline-sec .container ol.ps-timeline li p{
    font-size: 17px;
  }
  .main-sec .new-img-baner h1 {
    font-size: 34px;
    line-height: 50px;
}
.new-img-baner p {
  line-height: 43px !important;
  font-size: 26px !important;
}
.innerbannerimg {
  padding: 55px 0px;
}
.secure-main .secure-sec .secure-para {
  font-size: 24px;
  line-height: 43px;
}
.left-side-head-before h2 {
  font-size: 29px;
}
.differ-service-sec .differ-service-inner p {
  font-size: 22px;
  line-height: 43px;
}
.service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3 {
  font-size: 21px;
  line-height: 31px;
}
  .serv-img-inner::before {
    content: "";
    position: absolute;
    top: 33px;
    left: 199px;
    width: 296px;
    height: 260px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    z-index: 99;
}
.serv-img-inner img {
  position: absolute;
  top: 33px;
  left: 199px;
  border-radius: 5px;
  height: 262px;
}
.service-img-right::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 224px;
  width: 290px;
  height: 272px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  
}

.servleft-img-inner::before{
  top: 33px;
  left: 145px;
  width: 296px;
  height: 260px;
}
.servleft-img-inner img {
  position: absolute;
  top: 33px;
  left: 139px;
  border-radius: 5px;
  height: 262px;
}
.servce-img-left::after{
  top: 10px;
  left: 123px;
  width: 290px;
  height: 272px;
}
.proof-sec .prrof-img .proof-content-sec .team-content h4{
  font-size: 28px;
}
.prodct-margin {
  margin-top: 25px;
}
.product-sec p {
  font-size: 26px !important;
}
.prodtbannerimg img {
  width: 90%;}
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec img {
    height: 52px;
}
.impact-sec .imapct-inner-sec p {
          font-size: 22px;
        line-height: 30px;
}
.callactn-sec .callactn-content p {
  font-size: 21px;
}
.impact-sec .imapct-inner-sec h3 {
  font-size: 34px;
}
.main-bannerinner h1 {
  font-size: 34px !important;
}
.busn-main-sec .key-benefits-sec h3 {
  font-size: 23px;
  line-height: 35px;}
  .busn-main-sec .key-benefits-sec img {
    height: 70px;
}
.consult-content p {
  font-size: 27px !important;
}
.outcome-sec .outcome-order ul li{
  line-height: 35px;
}
.case-video-sec{
  padding: 85px 0px;
}
.outcome-sec p {
  font-size: 23px;}
  .footer-sec .footer-left-sec img {
    height: 62px;}
  .blog-detl-sec p{
    font-size: 22px;
  }
  .new-banr-img img {
    height: 360px;
}
.job-sec{
  padding: 90px 0px;
}
.job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img img {
  height: 25px;
}
.job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img svg {
  font-size: 29px;
}
.infinite-scroll-component h3 {
  font-size: 23px;
}
.infinite-scroll-component h4 {
  font-size: 21px;
}
// container changes code start
.container {
  max-width: 1260px;
}

// container changes code end

.navbar-brand img {
  height: 53px;}
  .nav-link-menu{
    margin:0px 4px;
    padding: 0px 16px;
  }
  // .main-sec .banner-sec-content p {
  //   font-size: 19px;
  //   max-width: 540px;
  // }
    .homebanner .banner-sec-content h2{
      font-size: 38px;
    }
    .homebanner .banner-sec-content h1{
      font-size: 38px;
    }
    .bg-icon-service img{
      height:125px;
    }
    .bg-icon-belowservice img{
      height:125px;
    }
    .vision-image-bg .bg-icon-belowservicess img{
      height: 125px;
    }
    .bg-icon-belowprojt img{
      height: 125px;
    }
    .service-category .service-box .service-inner .service-icon .icon-sec {
      width: 17%;
      float: left;
      margin-right: 3px;
  }
  .service-category .service-box .service-inner .service-icon .service-para-main {
    width: 82%;}

  .service-category .service-box .service-inner .service-icon .service-para-main h3 {
    top: 20px;
    left: 8px;
  }
    .homebanner {padding: 90px 0px 130px 0px;}
    .service-category .service-box .service-inner .service-content{
      height: 251px;
    }
    .home-service-sec{
      padding: 90px 0px;
    }
    .about-innov-main{
      padding: 85px 0px;
    }
    .vision-sec .vision-content{
      margin-top: 42px;
    }
    .nav-link-menu{
      font-size: 16px;
    }
    .about-main .abut-banner-cont{
      bottom: 5vh;
    }
    .video-container{
      height: 55vh;
    }
    .about-main{
      height: 55vh;
    }
    .book-sec .book-label {
      font-size: 18px !important;}
      .founder-sec-cont .book-sec span img {
        height: 22px;}
        .founder-sec{
          padding: 90px 0px;
        }
        .team-sec .team-category-sec{
          padding:60px 60px 40px 60px;
        }
        .ps-timeline-sec .container ol.ps-timeline li{
          width:12.9%;
        }
        .new-img-baner {
          margin-top: 84px;
      }
      .new-banr-img{
        position: relative;
        top: 15px;
      }
      .differ-service-sec{
        padding: 100px 0px;
      }
      .service-inner-category .serv-categ-box .serv-categ-para p{
        height: 100px;
      }
      .tem-img-overlay{
        height: 100%;
      }
      .btn-inner-vido{
        padding: 5px 15px;
      }
      .produt-category .product-box .prodct-descp h2{
        font-size: 20px;
      }
      .main-innerbanner .prodt-details{
        margin-top: 40px;
      }
      .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec h3{
        font-size: 18px;
        height: 5vh;
      }
      .keyprocs-sec .keyprocs-content p{
        font-size: 21px;
        line-height: 35px;
      }
      .impact-sec h2{
        font-size: 30px;
      }
      .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec p{
        line-height: 31px;
      }
      // .main-innerbanner .new-img-baner p{
      //   margin-top: 20px !important;
      //   line-height: 39px !important;
      // }
      .busn-sec .business-sec h3{
        line-height: 38px;
      }
      .busn-sec .business-sec p{
        font-size: 20px !important;
      }
      .job-sec .job-inner-sec .job-desc-new h3 {
        font-size: 20px;
    }
    .about-innov-main .about-innov-sec .about-innov-para{
      font-size: 18px !important;
    }
    .team-sec .team-sec-content .meet-pata{
      font-size: 18px !important;
    }

   body .CookieConsent{
      width:86% !important;
    }
    .error-image {
      height: 260px !important;
  }
 }

 @media (min-width: 1300px) and (max-width: 1400px){
  .thanksuou {
    margin-top: 50px !important;
}
  .thanksuou p{
    font-size: 18px !important;
  }
  .thanksuou h2{
    font-size: 30px !important;
    margin-bottom: 20px !important;
  }
 
  .error-image {
    height: 260px !important;
}
  body .CookieConsent{
    width:86% !important;
  }
  .about-innov-main .about-innov-sec .about-innov-para {
    font-size: 18px !important;
}
.team-sec .team-sec-content .meet-pata {
  font-size: 18px !important;
}
  .keyprocs-feturesec .keyprocs-heading h2 {
    margin-bottom: 10px;
}
.secure-main .container .about-innov-sec .serice-nee-fix {
  font-size: 24px !important;
}
  .main-sec .banner-sec-content p{
    font-size: 20px;
    line-height: 35px;
  }
  .main-sec .banner-sec-content h1{
    font-size: 35px;
  }
  .main-sec .banner-sec-content h2{
    font-size: 41px;
  }
  .mainbaner-img img{
    height: 370px;
  }
  .job-sec h2 {
    font-size: 28px;}
  .homebanner .banner-sec-content {
    margin-top: 60px;
}
.home-service-sec .service-short-head .short-head-line {
  font-size: 19px !important;
}
.home-service-sec .service-short-head h2 {
  font-size: 28px;
}
.home-service-sec .service-short-head p {
  font-size: 24px !important;
  line-height: 32px;
}
.service-category .service-box .service-inner .service-icon .icon-sec .icon-circle img {
  height: 42px;
  top: 5px;}
  .service-category{
    padding: 45px 0px 0px 0px;
  }
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle{
    height: 55px;
    width: 55px;
  }
  .project-box p {
    line-height: 43px;
    font-size: 22px;
}
.vision-sec .vision-content h2{
  font-size: 28px;
}
.vision-sec .vision-content p{
  font-size: 20px;
  line-height: 36px;
}
.about-main .abut-banner-cont h1 {
  font-size: 33px;}
  .about-innov-main .about-innov-sec h2{
    margin-bottom: 30px;
  }
  .founder-sec-cont h2{
    font-size: 28px;
  }
  .founder-sec-cont p{
    font-size: 17px;
    line-height: 34px;
  }
  .ps-timeline-sec .container ol.ps-timeline li p{
    font-size: 17px;
  }
  .main-sec .new-img-baner h1 {
    font-size: 33px;
    line-height: 50px;
}
.founder-sec-cont h3{
  font-size: 18px;
}
.new-img-baner p {
  line-height: 42px !important;
  font-size: 25px !important;
}
.innerbannerimg {
  padding: 55px 0px;
}
.home-service-sec .secure-sec p{font-size: 23px !important;
  line-height: 43px;}
.secure-main .secure-sec .secure-para {
  font-size: 24px;
  line-height: 43px;
}
.left-side-head-before h2 {
  font-size: 28px;
}
.differ-service-sec .differ-service-inner p {
  font-size: 22px;
  line-height: 43px;
}
.service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3 {
  font-size: 20px;
  line-height: 31px;
}
  .serv-img-inner::before {
    content: "";
    position: absolute;
    top: 33px;
    left: 199px;
    width: 296px;
    height: 260px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    z-index: 99;
}
.serv-img-inner img {
  position: absolute;
  top: 33px;
  left: 199px;
  border-radius: 5px;
  height: 262px;
}
.service-img-right::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 224px;
  width: 290px;
  height: 272px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  
}

.servleft-img-inner::before{
  top: 33px;
  left: 145px;
  width: 296px;
  height: 260px;
}
.servleft-img-inner img {
  position: absolute;
  top: 33px;
  left: 139px;
  border-radius: 5px;
  height: 262px;
}
.servce-img-left::after{
  top: 10px;
  left: 123px;
  width: 290px;
  height: 272px;
}
.proof-sec .prrof-img .proof-content-sec .team-content h4{
  font-size: 27px;
}
.prodct-margin {
  margin-top: 25px;
}
.product-sec p {
  font-size: 26px !important;
}
.prodtbannerimg img {
  width: 90%;}
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec img {
    height: 52px;
}
.impact-sec .imapct-inner-sec p {
          font-size: 21px;
        line-height: 30px;
}
.detail-carousl-sec .carousl-details-content h2 {
  font-size: 27px;}
  .detail-carousl-sec .carousl-details-content p {
    font-size: 20px !important;
    font-weight: 500;
    line-height: 36px;}
.callactn-sec .callactn-content p {
  font-size: 20px;
  line-height: 31px;
}
.impact-sec .imapct-inner-sec h3 {
  font-size: 34px;
}
.main-bannerinner h1 {
  font-size: 32px !important;
}
.busn-main-sec .key-benefits-sec h3 {
  font-size: 22px;
  line-height: 33px;}
  .busn-main-sec .key-benefits-sec img {
    height: 70px;
}
.busn-main-sec .busn-key-head h2 {
  font-size: 28px;}
.consult-content p {
  font-size: 26px !important;
}
.outcome-sec h2 {
  font-size: 28px;
}
.outcome-sec .outcome-order ul li{
  line-height: 35px;
}
.case-video-sec{
  padding: 85px 0px;
}
.outcome-sec p {
  font-size: 23px;}
  .footer-sec .footer-left-sec img {
    height: 55px;}
  .blog-detl-sec p{
    font-size: 22px;
  }
  .new-banr-img img {
    height: 340px;
}
.job-sec{
  padding: 90px 0px;
}
.job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img img {
  height: 25px;
}
.job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img svg {
  font-size: 29px;
}
.infinite-scroll-component h3 {
  font-size: 23px;
}
.infinite-scroll-component h4 {
  font-size: 21px;
}
// container changes code start
// .container {
//   max-width: 1260px;
// }

// container changes code end

.navbar-brand img {
  height: 53px;}
  .nav-link-menu{
    margin:0px 4px;
    padding:0px 12px;
  }
  // .main-sec .banner-sec-content p {
  //   font-size: 19px;
  //   max-width: 540px;
  // }
    .homebanner .banner-sec-content h2{
      font-size: 37px;
    }
    .homebanner .banner-sec-content h1{
      font-size: 37px;
      line-height: 52px;
    }
    .bg-icon-service img{
      height:125px;
    }
    .bg-icon-belowservice img{
      height:125px;
    }
    .vision-image-bg .bg-icon-belowservicess img{
      height: 125px;
    }
    .bg-icon-belowprojt img{
      height: 125px;
    }
    .service-category .service-box .service-inner .service-icon .icon-sec {
      width: 15%;
      float: left;
      margin-right: 3px;
  }
  .service-category .service-box .service-inner .service-icon .service-para-main {
    width: 84%;}

  .service-category .service-box .service-inner .service-icon .service-para-main h3 {
    top: 20px;
    left: 8px;
    font-size: 18px;
  }
    .homebanner {padding: 90px 0px 130px 0px;}
    .service-category .service-box .service-inner .service-content{
      height: 251px;
    }
    .home-service-sec{
      padding: 90px 0px;
    }
    .about-innov-main{
      padding: 85px 0px;
    }
    .vision-sec .vision-content{
      margin-top: 42px;
    }
    .nav-link-menu{
      font-size: 16px;
    }
    .about-main .abut-banner-cont{
      bottom: 5vh;
    }
    .video-container{
      height: 50vh;
    }
    .about-main{
      height: 50vh;
    }
    .book-sec .book-label {
      font-size: 18px !important;}
      .founder-sec-cont .book-sec span img {
        height: 22px;}
        .founder-sec{
          padding: 90px 0px;
        }
        .team-sec .team-category-sec{
          padding:40px 60px 20px 60px;
        }
        .ps-timeline-sec .container ol.ps-timeline li{
          width:13.9%;
        }
        .new-img-baner {
          margin-top: 84px;
      }
      .new-banr-img{
        position: relative;
        top: 15px;
      }
      .differ-service-sec{
        padding: 30px 0px 10px 0px;
      }
      .service-inner-category .serv-categ-box .serv-categ-para p{
        height: 100px;
        padding: 12px 10px 0px 10px;
      }
      .proof-sec .prrof-img .proof-content-sec .team-content h3 {
        font-size: 29px;}
      .tem-img-overlay{
        height: 100%;
      }
      .btn-inner-vido{
        padding: 5px 15px;
      }
      .produt-category .product-box .prodct-descp h2{
        font-size: 19px;
      }
      .product-main .prodct-left {
        width: 49%;}
        .product-main .prodct-right {
          width: 49%;}
      .main-innerbanner .prodt-details{
        margin-top: 40px;
      }
      .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec h3{
        font-size: 18px;
        height: 5vh;
      }
      .keyprocs-sec .keyprocs-content p{
        font-size: 20px;
        line-height: 35px;
      }
      .impact-sec h2{
        font-size: 28px;
      }
      .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec p{
        line-height: 31px;
      }
      .home-service-sec .product-head p{
        font-size: 25px !important;
      }
      // .main-innerbanner .new-img-baner p{
      //   margin-top: 20px !important;
      //   line-height: 39px !important;
      // }
      .busn-sec .business-sec h3{
        line-height: 38px;
      }
      .busn-sec .business-sec p{
        font-size: 20px !important;
      }
      .job-sec .job-inner-sec .job-desc-new h3 {
        font-size: 20px;
    }
    .service-category .service-box{
     padding: 25px 15px 42px 15px; 
    }
    .btn-inner-sec .btn-name{
      font-size: 15px;
    }
    .about-main .abut-banner-cont p{
      font-size: 17px;
    }
    .ps-timeline-sec .container ol.ps-timeline {
      margin: 200px -20px;}
      .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-icon img {
        height: 50px;
    }
    .product-sec .product-head p{
      font-size: 24px !important;
    }
    .product-main .prodct-left{
      margin-right: 22px;
    }
    .btn-inner-sec{
      padding: 5px 11px;
    }
    .btn-inner-sec .btn-icon {
      height: 29px;
      width: 29px;
      top: 0px;
      left: 5px;
    }
    .btn-inner-sec .btn-name{
      top:3px;
    }  
    .btn-inner-sec .btn-icon svg {
      font-size: 18px;
      position: relative;
      top:2px;}
      .prodt-detail-main .watch-sec{
        font-size: 24px;
      }
      .keyprocs-sec .keyprocs-content h2 {
        font-size: 28px;}
        .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec p{
          font-size: 16px;
        }
        .keyprocs-feturesec .keyprocs-heading h2 {
          font-size: 28px;}
          .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec{
            padding: 30px 15px;
          }
          .produt-category{
            margin-top: 65px;
          }
          .blog-details-sec .blog-detail-rights .detail-categ .categ-list ul li{
            font-size: 16px;
          }
          .blog-details-sec .blog-detail-rights .recent-post ul li p{
            font-size: 15px;
          }
          .btn-inner-sec .btn-name{
            left:2px;
          }
          .location-sec h2 {
            font-size: 28px;}
            .footer-sec .footer-right-sec .email-sec button{
              left:59%;
            }
            .footer-sec .footer-right-sec .email-sec .email-btn .btn-inner-sec {
              padding: 7px 14px;
          }
          .btn-inner-secss .btn-name {
            left: 0px;
        }
        .main-sec .prodt-details h1 {
          font-size: 33px;
          line-height: 54px;
      }
      .prodt-modl-news .MuiDialog-container h2{
        font-size: 21px;
      }
      .prodt-modl-news .form-radio-sec .MuiFormControl-root .MuiFormLabel-root{
        font-size: 16px;
      }
      .about-innov-para{
        font-size: 18px;
      }
      .footer-sec .footer-mid-sec ul li {
        font-size: 16px;}
        .footer-sec .footer-left-sec .map-contect ul li{
          margin-bottom: 19px;
        }
        .footer-sec .footer-left-sec{
          padding: 50px 40px 0px 0px;
        }
 }

 @media (min-width: 1200px) and (max-width: 1299px){
  .key-main-secss .differ-service-sec{
    padding: 0px 0px !important;
  }
  .job-details .job-desc-inner .job-desc-right p{
    line-height: 30px;
  }
  .job-details .job-desc-inner .job-desc-right p{
    margin-bottom: 8px;
  }
  .job-details .job-desc-inner .job-desc-right ul li{
    margin-bottom: 7px;
  }
  .job-details .job-desc-inner .job-desc-right h4{
    margin:19px 0px 13px 0px;
  }
  .back-btnn{
    .btn-inner-sec{
      padding:5px 12px !important;
      .btn-name{
        font-size: 15px !important;
      }
    }
  }
  .thanksuou {
    margin-top: 50px !important;
}
  .thanksuou p{
    font-size: 18px !important;
  }
  .thanksuou h2{
    font-size: 29px !important;
    margin-bottom: 20px !important;
  }
  .error-page .error-page-para{
    font-size: 19px !important;
  }
  .error-page-banner {
    height: 84vh;
  }
  .error-image {
    height: 260px !important;
}
  .contact-sec .contact-inner .contact-info{
    padding:30px 39px !important;
  }
  body .CookieConsent{
    width:86% !important;
    padding-left: 10px !important;
  }
  body .head-cookie{
    font-family: 23px !important;
    padding: 10px 10px !important;
  }
      .container{
        max-width: 1110px ;
      }
      .navbar-brand img {
        height: 50px;}
        .nav-link-menu{
          font-size: 16px;
          padding: 0px 14px;
        }
        .mainbaner-img img{
          height: 340px;
        }
        .main-sec .banner-sec-content p{
          font-size: 19px;
          line-height: 35px;
          max-width: 540px;
        }
        .main-sec .banner-sec-content h1 {
          font-size: 34px;
          line-height: 47px;
      }
      .main-sec .banner-sec-content h2 {
        font-size: 35px;
      }
      .homebanner .banner-sec-content {
        margin-top: 42px;
      }
      .homebanner {
        padding: 80px 0px 110px 0px;
      }
      .home-service-sec .service-short-head .short-head-line{
        font-size: 17px;
      }
      .home-service-sec .service-short-head h2 {
        font-size: 28px;
        margin: 39px 0px 26px 0px;
      }
      .home-service-sec .service-short-head p {
        font-size: 24px;
    }
    .bg-icon-service img{
      height: 119px;
    }
    .service-category .service-box{
      padding: 35px 15px 40px 15px;
    }
    .service-category .service-box .service-inner .service-icon .icon-sec {
      width: 17%;
      float: left;
    }
    .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle{
      width: 55px;
      height: 55px;
    }
    .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle img {
      height: 37px;
      position: relative;
      top: 9px;
    }
    .service-category .service-box .service-inner .service-icon .service-para-main {
      width: 83%;
      float: left;
      text-align: left;
    }
    .service-category .service-box .service-inner .service-icon .service-para-main h3 {
      font-size: 17px;
      top: 18px;
      left: 8px;
    }
    .service-content p{
      font-size: 16px;
    }
    .service-category .service-box .service-inner .service-content{
      margin-top: 7px;
      height: 250px;
    }
    .btn-inner-sec .btn-name{
      font-size: 14px !important;
      top: 2px;
    }
    .btn-inner-sec{
      padding: 5px 10px;
    }
    .bg-icon-belowservice img{
      height: 119px;
    }
    .home-service-sec {
      padding: 85px 0px;
  }
    .btn-inner-sec .btn-icon {
      height: 28px;
      width: 28px;    left: 5px;}
      .project-box p{
        font-size: 21px;
        line-height: 45px;
        height: 173px;
      }
      .vision-sec .vision-content h2 {
        text-align: left;
        font-size: 28px;
      }
      .vision-sec .vision-content p{
        font-size: 20px;
        font-weight: 500;
        margin-top: 25px;
        line-height: 35px;
      }  
      .bg-icon-belowprojt img{
        height: 119px;
      }  
      .projet-category-sec::before{
        background-size: 67px;
      }
      .vision-sec .vision-content{
        margin-top: 40px;
      }
      .vision-image-bg .bg-icon-belowservicess img {
        right: 31px;
        position: absolute;
        bottom: -63px;
        height: 119px;
    }
    .footer-sec .footer-mid-sec ul li {
      font-size: 16px;}
      .footer-sec .footer-left-sec .map-contect ul li {
        margin-bottom: 19px;
    }
    .footer-sec .footer-left-sec img {
      height: 56px;
    }
      .btn-inner-sec .btn-icon svg{
        font-size: 16px;
        top: 2px;
      }
      .footer-sec .footer-left-sec .social-icon li{
        height: 32px;
        width: 32px;
      }
      .footer-sec .footer-left-sec .social-icon li img{
        height: 17px;
        top: 7px;
      }
      .footer-sec .footer-left-sec .social-icon li .twit-sec {
        height: 14px;
        top: 10px;
    }
    .footer-sec .footer-left-sec .map-sec p{
      line-height: 34px;
    }
    .footer-sec .footer-left-sec{
      padding: 50px 53px 0px 0px;
    }
    .footer-sec .footer-right-sec .email-sec .email-btn .btn-inner-sec {
      padding: 7px 14px !important;
  }
  .about-main .abut-banner-cont h1 {
    font-size: 33px;}
    .about-main .abut-banner-cont p{
      width: 100%;
      max-width: 750px;
      margin: 0 auto;
      margin-top: 20px;
    }
    .product-main .prodct-left{
      margin-right: 43px;
    }
    .new-banr-img img {
      height: 340px;
  }
  .btn-learn{
    margin-right: 5px;
  }
  .produt-category .product-box .prodct-descp h2 {
    font-size: 17px;}
    .produt-category .product-box .prodct-descp p {
      font-size: 15px !important;}
      .footer-sec .footer-right-sec ul{
        padding-left: 23px;
      }
      .ps-timeline-sec .container ol.ps-timeline li {
        float: left;
        width: 13.7%;}
        .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec h3{
          font-size: 17px;
          height: 40px;
        }
        .footer-sec .footer-right-sec .copy-sec p{
          padding:80px 0px 20px 0px;
        }
        .about-main{
          height: 53vh;
        }
        .video-container{
          height: 53vh;
        }
        .about-main .abut-banner-cont{
          bottom:7vh;
          width: 100%;
          transform: translate(-50%, -76%);
        }
        .founder-sec-cont h2 {
          overflow: hidden;
          font-size: 28px;
        }
        .founder-sec-cont p {
          font-size: 18px;
        }
        .team-sec .team-category-sec{
          padding: 35px 60px 10px 60px;
        }
        .ps-timeline-sec .container ol.ps-timeline {
          margin: 195px -41px;
        }
        .ps-timeline-sec .container ol.ps-timeline li p{
          font-size: 17px;
        }
        .founder-sec-cont h3{
          font-size: 18px;
        }
        .founder-sec-cont h4 span{
          font-size: 18px;
        }
        .founder-sec-cont .book-sec{
          font-size: 18px;
        }
        .founder-sec-cont .book-sec span{
          font-size: 18px !important;
        }
        .innerbannerimg {
          padding: 51px 0px;
        }
        .new-img-baner {
          margin-top: 70px;
        }
        .innerbannerimg .new-img-baner p{
          font-size: 26px !important;
        }
        .secure-main .secure-sec .secure-para {
          font-size: 24px;
          line-height: 46px;
        }
        .left-side-head-before h2 {
          font-size: 28px;
          line-height: 43px;
      }
      .differ-service-sec .differ-service-inner p {
        font-size: 21px !important;
        line-height: 41px !important;
    }
    .serv-img-inner img{
      height: 258px;
      left: 167px;
    }
    .serv-img-inner::before{
      left: 169px;
      width: 290px;
      height: 258px;
    }
    .service-img-right::after{
      left: 176px;
      width: 300px;
      height: 258px;
    }
    .service-inner-category .serv-categ-box .serv-categ-para p{
      line-height: 31px;
    }
    .service-inner-category{
      margin-top: 110px;
    }
    .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3 {
      font-size: 20px;
      height: 55px;
    }
      .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-icon img {
        height: 48px;
    }
    .servleft-img-inner img{
      height: 258px;
        left: 117px;
    }
    .servleft-img-inner::after {
      left: 99px;
      width: 300px;
      height: 258px;
  }
  .servleft-img-inner::before {
    left: 119px;
  }
  .servce-img-left::after {
    left: 101px;
  }
  .tem-img-overlay{
    height: 464px;
  }
  .proof-sec .prrof-img .proof-content-sec .team-content h3 {
    font-size: 28px;
    margin-bottom: 13px;
  }
  .proof-sec .prrof-img .proof-content-sec .team-content h4 {
    font-size: 28px;
  }
  .servicebtn-prof{
    .btn-red-sec{
      .btn-name{
        font-size: 15px !important;
        top: 3px !important;
      }
      .btn-icon{
        svg{
          top: 2px !important;
        }
      }
    }
  }

  .product-head .secure-para{
    font-size: 23px !important;
  }
  .produt-category{
    margin-top: 70px;
  }
  .prodt-detail-main .watch-sec{
    font-size: 23px;
  }
  .prodtbannerimg img {
    width: 85%;}
    .main-proddetai-inner {
      padding: 100px 0px 105px 0px;
  }
  .main-proddetai-inner .prodt-details{
    margin-top: 40px;
  }
  .keyprocs-sec .keyprocs-content h2 {
    font-size: 27px;}
  .keyprocs-sec .keyprocs-content p {
    font-size: 20px;
  }
  .detail-carousl-sec .carousl-details-content h2 {
    font-size: 25px;
    line-height: 39px;
  }
  .detail-carousl-sec .carousl-details-content p {
    font-size: 19px !important;
  }
    .keyprocs-feturesec .keyprocs-heading h2 {
      margin-bottom: 30px;
      font-size: 28px;
  }
  .keyprocs-feturesec .keyfeature-box{
    margin:15px 0px;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec p {
    font-size: 16px;
    height: 150px;
    line-height: 31px;
  }
  .impact-sec h2{
    font-size: 28px;
  }
  .impact-sec{
    padding: 85px 0px;
  }
  .impact-sec .imapct-inner-sec h3 {
    font-size: 34px;
}
.impact-sec .imapct-inner-sec p {
  font-size: 22px;
}
.callactn-sec .callactn-content p {
  font-size: 19px;
}
.callactn-sec .btn-inner-sec .btn-name {
  font-size: 15px !important;
}
.pre-btnss .prev-main-sec .btn-name {
  font-size: 19px;
  cursor: pointer;
}
.pre-btnss .next-main-sec .btn-name{
  font-size: 19px;
  cursor: pointer;
}
.case-stu-para {
  font-size: 24px !important;
}
.btn-new-case{
  .button-sec {
    .btn-inner-sec{
      
        padding: 5px 15px;
   
      .btn-name{
        font-size: 15px !important;
      }
      .btn-icon{
        left: 10px;
      }
    }
  }
}
.main-bannerinner h1 {
  font-size: 33px !important;
  line-height: 42px !important;
}

.case-video-sec .case-video-main .case-video-right img{
  width:85%;
}
.case-video-sec .case-video-main .case-video-right:after {
 
  width: 85%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.case-video-sec .case-video-main .case-video-left .case-video-box p {
  font-size: 23px;
}
.case-video-sec .case-video-main .case-video-left .case-video-box{
  width: 90%;
  left: 30%;
  z-index: 999;
  transform: translate(10%, 55%);
}
.case-video-sec .case-video-main .case-video-right .play-sec-prdt .video-play-button{
  top:-164px;
}
.busn-sec .business-sec h3{
  font-size: 22px;
    line-height: 39px;
}
.busn-sec .business-sec p {
  font-size: 19px !important;
  line-height: 34px;
}
.busn-main-sec .busn-key-head h2 {
  font-size: 28px;
  margin-bottom: 28px;
}
.busn-main-sec .key-benefits-sec img {
    height: 63px;
}
.busn-main-sec .key-benefits-sec h3 {
  font-size: 21px;
  line-height: 34px;}
.busn-sec{
  padding: 60px 0px 70px 0px;
}
.busn-main-sec .key-benefits-sec {
  margin-top: 18px;
}
.case-video-sec{
  padding: 85px 0px;
}
.consult-content p {
  font-size: 26px !important;
}
.case-bg-icons img{
  left: 50px;
}
.key-main-secss{
  padding: 75px 0px 85px 0px;
}
.resol-sec p {
  font-size: 21px;
  line-height: 38px;
}
.resol-inner-sec .resol-inner-content .resol-head h3 {
  font-size: 21px;}
  .resol-inner-sec .resol-inner-content .resol-para p{
    font-size: 18px;
    font-weight: 500;
    line-height: 34px;
  }
  .outcome-sec h2 {
    font-size: 28px;
}
.outcome-sec .outcome-order ul li {
  font-size: 17px;}
  .outcome-sec p {
    font-size: 23px;}

  .btn-blog-blue{
    .btn-inner-sec{
      padding: 5px 17px;
      .btn-name{
        font-size: 15px !important;
      }
      .btn-icon{
        left: 11px;
      }
    }
  }
  .blog-list .blog-inner-sec .blog-category p{
    font-size: 19px;
  }
  .blog-list .blog-inner-sec .blog-category img {
    height: 31px;
  }
  .blog-details-sec .blog-detail-rights .detail-categ .categ-list ul li{
    font-size: 16px;
  }
  .blog-details-sec .blog-detail-rights .recent-post ul li p{
    font-size: 15px;
    line-height: 25px;
  }
  .blog-details-sec .blog-detail-rights .recent-post p{
    padding-bottom: 5px;
  }
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date img {
    height: 18px;}
    .blog-details-sec .blog-details-inner .blog-detal-content h4 {
      font-size: 17px;}

      .job-sec h2 {
        font-size: 28px;}

        .job-sec{
          padding: 85px 0px;
        }
        .job-sec .job-inner-sec{
          margin:40px auto;
        }
        .job-sec .job-inner-sec .job-desc-new h3 {
          font-size: 19px;
      }
      .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text h3 {
        font-size: 17px;
      }
      .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text p {
        font-size: 16px;
      }
      .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img img {
        height: 25px;
      }
      .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-quali img{
        height: 23px;
      }
      .job-details .job-desc-inner .job-desc-left h2 {
        font-size: 20px;
      }
      .job-details{
        padding: 85px 0px;
      
      }
      .btn-job-aply{
        .btn-inner-lern{
          padding: 5px 15px;
          .btn-name{
            font-size: 15px !important;
            left: -2px;
          }
          .btn-icon{
            position: relative;
            left: 6px;
          }
        }
      }
      .footer-sec .footer-mid-sec h3 {
        font-size: 18px;
      }
      .email-btn{
        .btn-inner-secss{
          padding: 7px 17px !important;
          .btn-icon{
            left:9px;
          }
        }
      }
      .footer-sec .footer-right-sec .email-sec button{
        width:29%;
      }
      .charcter-sec{
        font-size: 13px;
      }
      .main-sec .banner-sec-content p {
        font-size: 20px;
        line-height: 35px;
        width: 100%;
        max-width: 100%;
    }
    .infinite-scroll-component h3 {
      font-size: 21px;
    }
    .infinite-scroll-component h4 {
      font-size: 20px;
    }
    .infinite-scroll-component p {
      font-size: 16px;
    }
    .privacy-policy-sec{
      padding: 85px 0px;
    }
    .infinite-scroll-component span {
      font-size: 17px;
    }
    .infinite-scroll-component h5 {
      font-size: 19px;
    }

    .policy-banner {
      padding: 105px 0px;
  }
  .location-sec h2 {
    font-size: 28px;}
    .rating p{
      font-size: 16px;
    }

  }

.rateing-width{
  width: 30%;
  float: left;
}

.rateing-width .ratils-detal {
  float: left;
  width: 100%;
  margin-left: 16px;
  margin-bottom: 15px;
  margin-top: 18px;
}

 @media only screen and (max-width: 1400px) {

 }
 @media (min-width: 992px) and (max-width: 1199px){
  .toolcontent::after{
    width: 474px !important;
  }
  .new-cont-secss .tooltipss::after {
    padding: 6px 10px !important;
}
  .error-image {
    height: 240px;
}
  .key-main-secss .differ-service-sec{
    padding: 0px 0px !important;
  }
  .error-page .error-page-head{
    font-size: 26px;
  }
  .error-page .error-page-para{
    font-size: 19px;
  }
  .key-main-secss .new-case-add {
    padding: 40px 0px 50px 0px !important;
  }
  .error-page-banner {
    height: 88vh;}
  .key-main-secss .new-case-add p {
    font-size: 22px !important;}
  .navbar-brand img {
    height: 44px;
  }
  .nav-link-menu{
    font-size: 15px;
    padding: 0px 13px;
  }
  .main-sec .banner-sec-content p{
    font-size: 17px;
    line-height: 35px;
  }
  .main-sec .banner-sec-content h1 {
    font-size: 30px;
    line-height: 44px;
  }
  .main-sec .banner-sec-content h2 {
    font-size: 30px;
  }
  .mainbaner-img img{
    height: 300px;
  }
  .homebanner {
    padding: 60px 0px 100px 0px;
  }
  .bg-icon-service img{
    height: 110px;
  }
  .home-service-sec .service-short-head .short-head-line{
    font-size: 17px;
  }

  .home-service-sec .service-short-head h2 {
    font-size: 25px;
  }
  .home-service-sec .service-short-head p {
    font-size: 21px !important;
  }
  .home-service-sec .service-short-head h2{
    margin:34px 0px 19px 0px;
  }
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle{
    width: 40px;
    height: 40px;

  }
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle img {
    height: 29px;
    position: relative;
    top: 5px;
  }
  .bg-icon-belowservice img{
    height: 110px;
  }
  .service-category .service-box{
    padding:28px 10px 40px 10px;
  }
  .service-category .service-box .service-inner .service-icon .icon-sec{
    width:13%;
  }
  .service-category .service-box .service-inner .service-icon .service-para-main {
    width: 87%;}
    .home-service-sec{
      padding: 70px 0px;
    }
  .service-category .service-box .service-inner .service-icon .service-para-main h3 {
    font-size: 16px;
    font-weight: 600;
    position: relative;
    top: 11px;
    left: 7px;
  }
  .service-category{
    padding: 35px 0px 0px 0px;
  }
  .service-box  .service-inner .service-content {
    p{
      font-size: 15px;
      line-height: 33px;
    }
  }
  .service-category .service-box{
    z-index: 999;
  }
  .service-category .service-box .service-inner .service-content{
    margin-top: 5px;
    height: 270px;
  }
  .btn-inner-sec .btn-name{
    font-size: 15px;
    top: 4px;
  }
  .btn-inner-sec{
    padding: 5px 12px;
  }
  .btn-inner-sec .btn-icon {
    height: 30px;
    width: 30px;left:7px}
    .btn-inner-sec .btn-icon svg{
      top:3px !important;
    }
    .project-box p {
      line-height: 41px;
        font-size: 20px;
    
    }
    .project-box-blue{
      z-index: 999;
    }
    .bg-icon-belowprojt img{
      height: 110px;
    }
    .projet-category-sec::before{
      top: -172px;
      left: -49%;
      width: 100%;
      height: 100%;
      background-size: 56px;
    }
    .vision-image-bg .bg-icon-belowservicess img {
      right: 10px;
      height: 110px;
    }
    .vision-sec .vision-content h2{
      font-size: 25px;
    }
    .vision-sec .vision-content p{
      font-size: 19px;
      line-height:35px;
    }
    .vision-image::before{
      top: 30px;
      left: 82px;
      width: 250px;
      height: 232px;
    }
    .vision-sec .vision-image-bg .bg-icon-belowservicess img {
      right: 56px;
      height: 110px;
  }
  .vision-sec .vision-content{
    margin-top: 50px;
  }
  .footer-sec .footer-left-sec img{
    height: 46px;
  }
  .footer-sec .footer-left-sec .social-icon li img {
    height: 15px;}
    .footer-sec .footer-left-sec .social-icon li .twit-sec {
      height: 14px;}
      .footer-sec .footer-left-sec .social-icon li{
        width:30px;
        height: 30px;
      }
      .footer-sec .footer-left-sec .map-sec p{
        font-size: 15px;
      }
      .footer-sec .footer-left-sec .map-sec img{
        height: 22px;
        margin-right: 8px;
        float: left;
        top: 4px;
        position: relative;
      }
      .footer-sec .footer-left-sec .map-contect ul li p{
        font-size: 15px;
      }
      .footer-sec .footer-left-sec .map-contect ul li svg{
        font-size: 18px;
      }
      .map-contect ul li:first-child{
        margin-bottom: 10px;
      }
      .footer-sec .footer-mid-sec ul li{
        font-size: 15px;
        line-height: 38px;
      }
      .footer-sec .footer-mid-sec h3 {
        font-size: 17px;}
        .footer-sec .footer-right-sec h3 {
          font-size: 17px;}
          .footer-sec .footer-right-sec p {
            font-size: 15px;}
            .footer-sec .footer-right-sec{
              padding: 60px 0px 0px 11px;
            }
            .footer-sec .footer-right-sec .email-sec button{
              left:57%;
            }
            .footer-sec .footer-right-sec .email-sec .email-btn .btn-inner-sec {
              padding: 7px 12px;
          }
          .footer-sec .footer-right-sec ul li{
            font-size: 15px;
          }
          .footer-sec .footer-right-sec ul li:last-child {
            margin-left: 25px;
        }
        .footer-sec .footer-right-sec ul li a{
          left:-6px;
        }
        .background-video{
          width: 100%;
          height: auto;
        }
        .video-container{
          height: 50vh;
        }
        .about-main{
          height: 50vh;
        }
        .about-main .abut-banner-cont h1 {
          font-size: 29px;}
          .about-main .abut-banner-cont p{
            font-size: 17px;
            line-height: 28px;
          }
          .about-main .abut-banner-cont{
            bottom: 4vh;
          }
          .about-innov-main .about-innov-sec .about-innov-para {
            font-size: 17px;
            line-height: 32px;}
            .founder-sec-cont h2{
              font-size: 25px;
            }
            .founder-sec-cont p{
              font-size: 17px;
              line-height: 36px;
            }
            .founder-sec-cont h3{
              font-size: 18px;
            }
            .founder-sec-cont h4{
              font-size: 18px;
            }
            .founder-sec-cont .book-sec{
              font-size: 18px !important;
            }
            .about-main .abut-banner-cont{
              width: 100%;
              max-width: 630px;
            }
            .book-sec span{
              display: block;
              img{
                margin-left: 0px !important;
                height: 20px !important;
                .book-label{
                  font-size: 18px !important;
                  display: inline-block !important;
                }
              }
            }
            .book-sec .book-label{
              display: inline-block !important;
              font-size: 18px !important;
            }
            .founder-sec{
              padding: 80px 0px;
              font-size: 18px !important;
            }

            .team-sec-content{
              .meet-pata{
                font-size: 17px !important;
              }
            }
            .team-sec .team-category-sec{
              padding: 40px 60px 40px 60px;
            }
            .team-sec .team-category-sec .teamming-sec-hover h5{
              font-size: 43px;
            }
            .team-sec .team-category-sec .teamming-sec-hover h6 {
              font-size: 16px;}
            .team-sec .team-category-sec .team-box h3 {
              font-size: 17px;
            }
            .team-sec .team-category-sec .team-box h4 {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box p {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box{
              padding: 20px 0px;
            }
            .ps-timeline-sec .container ol.ps-timeline li{
              width:13.4%;
            }
            .ps-timeline{
                li{
                  .img-time {
                    img{
                      height: 59px;
                    }
                  }
                }
            }
            .ps-timeline-sec .container ol.ps-timeline li p{
              font-size: 16px;
              position: relative;
              top: -10px;
              left: -13px;
            }
            .img-time-first {
              top: -28px;
              img{
                height: 63px !important;
              }
          }
          .img-last {
            top: -28px;
            img{
              height: 64px !important;
              top: -3px;
              position: relative;
            }
        }
        .img-time  .timedata{
          top:-41px !important;
        }
        .ps-timeline-sec .container ol.ps-timeline li .ps-sp-bot:before{
          bottom:-29px !important;
        }
        .main-sec .new-img-baner p {
          font-size: 22px !important;
          line-height: 39px !important;
      }
      .new-banr-img img {
        height: 300px;
    }
    .new-img-baner {
      margin-top: 50px;
  }
  .innerbannerimg {
    padding: 60px 0px 50px 0px;
}
.secure-main .secure-sec .serice-nee-fix{
  font-size: 20px !important;
  line-height: 35px;
  width: 100%;
  max-width: 810px;
  margin: 0 auto;
  text-align: center;
  margin-top: 28px;
}
.left-side-head-before h2 {
  font-size: 25px;
  line-height: 39px;
}
.differ-service-sec .differ-service-inner p {
  font-size: 19px;
  line-height: 39px;
}
.serv-img-inner::before{
  top: 34px;
  left: 178px;
  width: 250px;
  height: 239px;
}
.serv-img-inner img {
  position: absolute;
  top: 34px;
  left: 159px;
  border-radius: 5px;
  height: 240px;
}
.service-img-right::after{
  top: 17px;
    left: 191px;
    width: 250px;
    height: 251px;
}
.vision-image-bg .serv-bg-iconrigt img {
  right: 0px;}
  .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-icon img {
    height: 44px;
}
.service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3 {
  font-size: 19px;
  height: 58px; line-height: 28px !important;}
  .service-inner-category .serv-categ-box .serv-categ-para p {
    font-size: 16px;
    line-height: 29px;
}
.servleft-img-inner::before {
  top: 34px;
  left: 105px;}
  .servleft-img-inner img {
    position: absolute;
    top: 34px;
    left: 96px;}
    .servce-img-left::after {
      top: 17px;
      left: 84px;
      width: 250px;
      height: 251px;
  }
  .left-side-head-before h2:after{
    width: 9%;
    margin-right: -100%;
    margin-left: 3px;
  }  
  .service-inner-category{
    margin-top: 120px;
  }   
  .tem-img-overlay{
    height: 400px;
  }
  .proof-sec .prrof-img .proof-content-sec .team-content h3 {
    font-size: 27px;}
    .proof-sec .prrof-img .proof-content-sec .team-content h4 {
      font-size: 24px;}
      .proof-sec .prrof-img .proof-content-sec .team-content {
        position: absolute;
        bottom: 13%;
        left: 35%;
        transform: translate(-29%, -30%);}
        .servicebtn-prof .btn-name{
          top:4px !important;
        }
        .proof-sec{
          padding: 80px 0px !important;
        }
        .product-sec .short-head-line{
          font-size: 17px !important;
        }
        .product-head{
          .secure-para{
            font-size: 21px !important;
          }
        }
        .product-main .prodct-left{
          margin-right: 36px;
        }
        .produt-category .product-box{
          padding: 40px 8px;
        }
        .produt-category .product-box .prodct-descp p {
          font-size: 15px !important;}
          .produt-category .product-box .prodct-descp h2 {
            font-size: 16px !important;}
            .produt-category .product-box .prodct-descp{
              padding: 0px 4px;
            }
            .btn-prodct{}
            .produt-category{
              margin-top: 65px;
            }
            .produt-category .product-box .prodct-descp p{
              height: 85px;
            }
            .btn-prodct .btn-inner-sec{
              padding: 5px 6px !important;
            }
            .btn-prodct .btn-inner-sec .btn-name{
              font-size: 14px;
              top: 1px;
            }
            .btn-prodct .btn-inner-sec .btn-icon {
              height: 26px;
              width: 26px;
              left: 4px;
          }
          .btn-prodct .btn-inner-sec .btn-icon svg {
            font-size: 16px;top: 1px !important;}
            .btn-prodct .btn-learn {
              float: left;
              margin-right: 6px;
          }
              .produt-category .product-box {
        padding: 30px 8px;
    }
    .footer-sec .footer-left-sec{
      padding: 45px 40px 0px 0px;
    }
    .footer-sec .footer-mid-sec {
      padding: 45px 0px 0px 40px;}
      .footer-sec .footer-right-sec {
        padding: 45px 0px 0px 11px;
    }
    .main-sec .prodt-details h1 {
      font-size: 29px;}
      .main-innerbanner {
        padding: 50px 0px;
    }
    .prodt-detail-main .watch-sec{
      font-size: 20px;
      width: 65%;
    }
    .prodt-detail-main .play-sec-prdt {
      width: 35%;
      float: left;
  }
    .video-play-button:before{
      width: 70px;
      height: 70px;
    }
    .video-play-button:after{
      width: 70px;
      height: 70px;
    }
    .video-play-button img{
      left:37px;
    }
    .video-play-button:before{
      left:213%;
    }
    .prodtbannerimg img {
      width: 81%;
  }
  .main-innerbanner .prodt-details{
    margin-top: 30px;
  }
  .prodct-margin {
    margin-top: 20px;
}
.keyprocs-sec .keyprocs-content h2 {
  font-size: 25px;
  line-height: 33px;}

  .keyprocs-sec .keyprocs-content p {
    font-size: 18px;
    max-width: 640px;
    line-height: 31px;
}
    .keyprocs-sec .keyprocs-content{
      padding: 70px 0px;
    }
    .detail-carousl-sec .carousl-details-content h2 {
      font-size: 23px;        z-index: 99999;
      position: relative;}
    .carousel-item img{
      width: 90% !important;
    }
    .detail-carousl-sec .carousl-details-content p{
      font-size: 17px !important;
      font-weight: 500;
      line-height: 34px;
    }
    .detail-carousl-sec {
      padding: 75px 0px 90px 0px;
  }
  .keyprocs-feturesec .keyprocs-heading h2 {
    font-size: 25px;}
    .keyprocs-feturesec{
      padding: 70px 0px;
    }
    .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec{
      padding:20px 11px;
    }
    .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec img {
      height: 40px;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec h3{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec p{
    font-size: 15px;
    line-height: 28px;
  }
  .keyprocs-feturesec .keyfeature-box{
    margin:0px 0px 40px 0px;
  }
  .impact-sec h2{
    font-size: 25px;
  }
  .impact-sec{
    padding: 70px 0px;
  }
  .impact-sec .imapct-inner-sec h3 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .impact-sec .imapct-inner-sec p {
    font-size: 19px;
    line-height: 27px;
  }
  .impact-sec .imapct-inner-sec{
    margin: 40px auto 20px auto;
  }
  .callactn-sec .callactn-content p {
    font-size: 20px;
    line-height: 32px;
  }
  .pre-btnss .prev-main-sec .btn-name{
    font-size: 18px;
  }
  .pre-btnss .next-main-sec .btn-name{
    font-size: 18px;
    cursor: pointer;
  }
  .arrows-container{
    top:7px !important;
  }
  .callactn-sec{
    padding: 75px 0px;
  }
  .blog-detl-call {
    padding: 35px 0px !important;
}
  .detail-carousl-sec .carousal-img .carousel .carousel-indicators button{
    width: 10px;
    height: 10px;
  }
  .carousel-indicators .active{
    height: 10px;
    width: 10px;
  }
  .case-studies-list .case-study-box p{font-size: 16px !important;line-height: 28px;}
  .footer-sec .footer-right-sec .copy-sec p{
    font-size: 15px;
  }
  .footer-sec .footer-right-sec .email-sec button{
    width:32%;
  }
  .case-studies-list .case-study-box{
    padding: 32px 34px 40px 34px;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-left{
    z-index: 999;
    position: relative;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-left{
    margin-right: 37px;
  }
  .main-innerbanner .main-banner-sec h1{
    font-size: 29px !important;
  }
  .main-detsila-two {
    padding: 90px 0px 120px 0px;
}
.case-video-sec .case-video-main .case-video-left .case-video-box p {
  font-size: 21px;
}
.case-video-sec .case-video-main .case-video-left .case-video-box{
  padding: 45px 35px;
  width: 90%;
  left: 10%;
  z-index: 999;
  transform: translate(10%, 47%);
}
.case-video-sec .case-video-main .case-video-right:after{
  width: 85%;
  height: 93%;
  background-color: rgba(0, 0, 0, 0.27);
  left: -5px;
  z-index: 99;
  border-radius: 12px;
  top: 0;
}
.case-video-sec .case-video-main .case-video-right img {
  z-index: 99;
  border-radius: 12px;
  width: 85%;
}
.case-video-sec .case-video-main .case-video-right .play-sec-prdt .video-play-button{
  top:-144px;
}
.case-vieo-inner{
  position: relative;
  left: -40px;
}
.case-video-sec{
  padding: 60px 0px;
}
.busn-sec .business-sec h3{
  font-size: 22px;
        line-height: 37px;
}
.home-service-sec .business-sec p{
  font-size: 18px !important;
  line-height: 32px;
}
.busn-main-sec .key-benefits-sec img {
  height: 55px;
}
.busn-main-sec .key-benefits-sec h3 {
  font-size: 19px;
  line-height: 32px;
}
.busn-main-sec .key-benefits-sec {
  margin-top: 20px;
}
.consult-sec .consult-content p{
  font-size: 23px !important;
  line-height: 36px;
  position: relative;
  top: 6px;

}
.consult-btn .btn-white .btn-name {
  font-size: 15px;
}
.resol-sec p {
  font-size: 20px;
  line-height: 35px;
  margin: 33px auto 45px auto;
}
.resol-inner-sec .resol-inner-content{
  max-width: 790px !important;
  padding: 31px 35px;
}
.resol-inner-sec .resol-inner-content .resol-head h3 {
  font-size: 20px;}
  .resol-inner-sec .resol-inner-content .resol-para p{
    font-size: 17px;
    font-weight: 500;
    line-height: 31px;
  }
  .outcome-sec{
    padding: 70px 0px;
  }
  .busn-sec .details-new h2{
    margin: 0px 0px 19px 0px !important;
  }
  .case-bg-icons img {
    position: absolute;
    left: 100px;
    transform: translate(-180%, -50%);
    height: 120px;
}
.consult-sec .consult-content{
  max-width: 860px !important;
 padding: 50px 40px 50px 40px;
}
.outcome-sec h2 {
  font-size: 25px;
}
.outcome-sec p {
  font-size: 21px;margin:24px 0px 23px 0px;}
  .outcome-sec .outcome-order ul li {
    font-size: 17px;
    line-height: 33px;    
    margin: 9px 0px;
  }
  .outcome-sec .outcome-order{
    max-width: 600px;
  }
  .blog-list .blog-inner-sec .blog-category p{
    font-size: 18px;
  }
  .blog-list .blog-inner-sec .blog-category img {
    height: 33px;
    cursor: pointer;
    margin-left: 12px;
    font-weight: 500;
}
.blog-list .container-width-blog{
  max-width: 960px !important;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date{
  width:44%;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date a img{
  height: 16px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date a span{
  font-size: 15px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p{
  font-size: 16px;
  position: relative;
  top: 3px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category{
  width:55%;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p::before{
  left:-16px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content h2 {
  line-height: 32px;
  font-size: 17px;
  line-height: 32px;
  font-weight: 600;
  height: 58px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 31px;
}
  .like-count {
    margin-right: 6px !important;
  }
  .blog-list .blog-list-inner .blog-new-list {
    padding: 25px 17px 35px 17px;
}
.blog-list .blog-list-inner .blog-list-sec{
  z-index: 9999;
    position: relative;
}
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-tag-para-new {
  width: 50%;}
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-tag-para p{
    font-size: 16px;
  }
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date img {
    height: 17px;
  }
    .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date a {
      font-size: 15px;
    }
      .blog-details-sec .blog-details-inner .blog-detal-content h2 {
        font-size: 18px;
        margin-top: 20px;
      }
        .blog-details-sec .blog-details-inner .blog-detal-content p{
          margin-bottom: 15px;
          line-height: 33px;
        }
        .blog-details-sec .blog-details-inner .blog-detal-content h3 {
          font-size: 17px;
          margin-bottom: 17px;
          line-height: 32px;
      }
      .blog-details-sec .blog-details-inner .blog-detal-content h4 {
        font-size: 17px;}
        .blog-detal-content .rate-admin-details-sec .rating-blgdetals p{
          font-size: 16px !important;
        }
        .blog-details-sec .blog-details-inner .blog-detal-content .admin-sec p span {
          font-size: 16px;}
          .blog-details-sec .blog-details-inner .blog-detal-content .admin-sec p svg{
            font-size: 19px;
          }
          .blog-detl-call{
            .blog-detl-sec{
              p{
                font-size: 18px;
                line-height: 32px;
              }
            }
          }
          .blog-detl-call .blog-detl-sec .blg-detl-btn .btn-red-sec {
            padding: 6px 23px;
        }
        .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .input-srch {
          width: 70%;}
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .search-icon{
            padding: 8px 9px;
          }
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .search-icon svg{
            font-size: 21px;
          }
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box{
            padding: 12px 0px;
          }
          .input-srch{
            font-size: 15px;
          }
          .blog-details-sec .blog-detail-rights .blog-search p {
            font-size: 17px;}
            .blog-details-sec .blog-detail-rights .blog-search{
              padding: 20px 20px;
            }
            .blog-details-sec .blog-detail-rights .detail-categ p {
              font-size: 17px;}
              .blog-details-sec .blog-detail-rights .detail-categ .categ-list ul li{
                font-size: 15px;
                padding: 6px 7px;
              }
              .blog-details-sec .blog-detail-rights .detail-categ{
                padding: 20px 20px;
              }
              .blog-details-sec .blog-detail-rights .recent-post p {
                font-size: 17px;padding-bottom: 0px;}
                .blog-details-sec .blog-detail-rights .recent-post{
                  padding: 20px 20px;
                }
                .blog-details-sec .blog-detail-rights .recent-post ul li img{
                  width:25%;
                }
                .blog-details-sec .blog-detail-rights .recent-post ul li p{
                  width: 68%;
                  font-size: 15px;
                }
                .job-sec h2 {
                    font-size: 25px;margin-bottom: 15px;}
                    .job-sec{
                      padding: 70px 0px;
                    }
                    .job-sec .job-inner-sec{
                      margin: 30px auto;
                      max-width: 890px;
                    }
                    .job-sec .job-inner-sec .job-desc-new h3 {
                      font-size: 18px;
                  }
                  .job-sec .job-inner-sec .job-desc-new{
                    padding: 25px 30px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .jon-loc svg{
                    font-size: 18px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left svg{
                    font-size: 18px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right svg{
                    font-size: 18px;
                  }
                  .apply-btn-cont{
                    font-size: 16px;
                  }
                  .job-sec .job-inner-sec .job-inner-left{
                    z-index: 999;
                    position: relative;
                  }
                  .job-details{
                    padding: 75px 0px;
                  }
                  .job-details .job-desc-inner .job-desc-left h2 {
                    font-size: 18px;
                  }
                  .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text h3 {
                    font-size: 17px;
                    margin-bottom: 8px;
                }
                .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text p{
                  font-size: 16px;
                  line-height: 23px;
                }
                .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img img {
                  height: 25px;
              }
              .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img svg {
                font-size: 25px;
            }
            .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-experience img{
              height: 22px;
            }
            .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-quali img{
              height: 22px;
            }
            .job-details .job-desc-inner .job-desc-left{
              padding: 20px 19px;
            }
            .job-details .job-desc-inner .job-desc-right h2 {
              font-size: 20px;}
              .job-details .job-desc-inner .job-desc-right p{
                line-height: 32px;
              }
              .job-details .job-desc-inner .job-desc-right ul li{
                margin-bottom: 7px;
              }
              .job-details .job-desc-inner .job-desc-right h4{
                font-size: 17px;
                margin: 16px 0px;
              }
             .or-sec{
              margin: 0px 13px;
             }
             .contact-sec{
              padding: 86px 0px;
             }
             .contact-sec .contact-inner:before{
              width: 85%;
             }
             .contact-sec .contact-inner .contact-form h2 {
              font-size: 21px;}
              .new-cont-secss .tooltipss {
                left: 130px !important;}
                .new-cont-secss .tooltipss .fa-solid {
                  font-size: 14px !important;
              }
              .cont-newss p{
                font-size: 11px !important;
              }
              .invalid-feedback .error-msg{
                font-size: 13px !important;
              }
              .contact-sec .contact-inner .contact-info{
                padding: 30px 25px;
                max-width: 320px;
              }
              .form-msg-sec{
                margin-top: 8px !important;
              }
              .eror-contact-new{
                top:5px !important;
              }
              .location-sec h2 {
              font-size: 25px;
              margin-bottom: 25px;
            }
            .location-sec{
              padding: 0px !important;
            }
            .location-sec{
              iframe{
                height: 450px !important;
                width: 100%;
              }
            }
            .contact-sec .contact-inner .contact-info h2 {
              font-size: 19px;
            margin-top: 6px;
            }
            .new-cont-secss .tooltipss::after{
              font-size: 13px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p{
              font-size: 15px;
              line-height: 30px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p svg{
              font-size: 22px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p span {
              margin-left: 14px;}
              .social-icons-contact .social-icon li img {
                height: 16px;top: 2px !important;}
                .social-icons-contact .social-icon li .twit-sec {
                  height: 14px;top: 2px !important;}
                  .social-icons-contact .social-icon li{
                    height: 31px;
                    width:31px;
                  }
                  .infinite-scroll-component p{
                    font-size: 16px;
                    line-height: 33px;
                  }
                  .privacy-policy-sec{
                    padding: 70px 0px;
                  }
                  .infinite-scroll-component h3 {
                    font-size: 21px;
                    margin-bottom: 19px;
                    margin-top: 22px;
                }
                .infinite-scroll-component h4 {
                  font-size: 20px;
              }
              .infinite-scroll-component span{
                font-size: 17px;
              }
              .infinite-scroll-component ul li{
                margin:7px 0px;
                font-size: 16px;
              }
              .infinite-scroll-component h5 {
                font-size: 19px;}
                .new-font-bold {
                  font-size: 18px !important;
              }
              .busn-main-sec .busn-key-head h2 {
                font-size: 25px;
                margin-bottom: 17px;
            }
            .about-innov-main .main-difeer-about{
              .about-innov-para{
                font-size: 17px !important;
              }
            }
            .team-sec .team-newssss{
              .meta-patahhh{
                font-size: 17px !important;
              }
            }
            .team-sec .team-category-sec .teamming-sec-hover{
              width:100%;
            }
            body .CookieConsent{
              width: 90% !important;
            }
            .head-cookie{
              font-size: 22px !important;
            }
            .footer-sec .footer-right-sec ul li:last-child {
              margin-left: 25px !important;
            }
 }
 

 



.MuiDialog-root{
  z-index: 99999 !important;
}



 @media (min-width: 768px) and (max-width: 991px){

  .toolcontent::after{
    width: 474px !important;
  }
  .new-cont-secss .tooltipss::after {
    padding: 6px 10px !important;
}
  
  .rating-time p{
    font-size: 14px !important;
  }
  .footer-sec .footer-right-sec ul li:last-child {
    margin-left: 0px !important;
  }
  .nav-link-menu.active::before{
    opacity: 1;
  }
  .error-image {
    height: 220px !important;
}
.error-page{
  padding-top: 55px !important;
}
.error-page .error-page-head{
  font-size: 25px !important;
}
.error-page .error-page-para{
  font-size: 18px !important;
}
  .key-main-secss .differ-service-sec{
    padding: 0px 0px !important;
  }
  .error-page .error-page-head{
    font-size: 26px;
  }
  .error-page .error-page-para{
    font-size: 19px;
  }
  .key-main-secss .new-case-add {
    padding: 40px 0px 50px 0px !important;
  }
  .error-page-banner {
    height: 88vh;}
  .key-main-secss .new-case-add p {
    font-size: 18px !important;
    line-height: 33px !important;       
    margin-top: 15px !important;
  }
  .navbar-brand img {
    height: 48px;
        position: relative;
        top: 7px;
        width:auto;
  }
  .nav-link-menu{
    font-size: 15px;
    padding: 0px 22px;
    width: 100%;
    margin: 0px 0px !important;
    text-align: left;
  }
  .main-sec .banner-sec-content p{
    font-size: 16px;
    line-height: 35px;
    margin-bottom: 21px;
  }
  .main-sec .banner-sec-content h1 {
    font-size: 25px;
    line-height: 44px;
  }
  .mainbaner-img{
    margin:30px 0px 2px 0px;
  }
  .main-sec .banner-sec-content h2 {
    font-size: 25px;
    line-height: 37px;
    margin-top: 9px;
  }
  .mainbaner-img img {
    height: 235px;
}
  .homebanner {
    padding:20px 0px 70px 0px;
  }
  .bg-icon-service img{
    height: 100px;
  }
  .home-service-sec .service-short-head .short-head-line{
    font-size: 16px !important;
  }

  .home-service-sec .service-short-head h2 {
    font-size: 22px;
    line-height: 32px;
  }
  .home-service-sec .service-short-head p {
    font-size: 18px !important;
  }
  .home-service-sec .service-short-head h2{
    margin: 20px 0px 17px 0px;
  }
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle{
    width: 60px;
    height: 60px;

  }
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle img {
    height: 36px;
    position: relative;
    top: 12px;
  }
  .bg-icon-belowservice img{
    height: 100px;
  }
  .service-category .service-box{
    padding: 24px 20px 34px 20px;
  }
  .service-category .service-box .service-inner .service-icon .icon-sec{
    width: 11%;
    margin-right: 19px;
  }
  .service-category .service-box .service-inner .service-icon .service-para-main {
    width: 82%;}
    .home-service-sec{
      padding: 65px 0px;
    }
  .service-category .service-box .service-inner .service-icon .service-para-main h3 {
    font-size: 18px;
    font-weight: 600;
    position: relative;
    top: 21px;
    left: -16px;
  }
  .service-category{
    padding: 35px 0px 0px 0px;
    width: 100%;
        max-width: 630px;
        margin: 0 auto;
  }
  .service-box  .service-inner .service-content {
    p{
      font-size: 15px;
      line-height: 33px;
    }
  }
  .service-category .service-box{
    z-index: 999;
  }
  .service-category .service-box .service-inner .service-content{
    margin-top: 5px;
    height: auto;
  }
  .btn-inner-sec .btn-name{
    font-size: 15px;
    top: 4px;
  }
  .btn-inner-sec{
    padding: 5px 12px;
  }
  .btn-inner-sec .btn-icon {
    height: 30px;
    width: 30px;left:7px}
    .btn-inner-sec .btn-icon svg{
      top:3px !important;
    }
    .project-box p {
      line-height: 38px;
      font-size: 18px;
    
    }
    .project-box-blue{
      z-index: 999;
    }
    .bg-icon-belowprojt img{
      height: 100px;
    }
    .projet-category-sec::before{
      top: -132px;
      left: -49%;
      width: 100%;
      height: 100%;
      background-size: 53px;
    }
    .vision-image-bg .bg-icon-belowservicess img {
      right: 10px;
      height: 100px;
    }
    .vision-sec .vision-content h2{
      font-size: 23px;
    }
    .vision-sec .vision-content p{
      font-size: 17px;
      line-height: 33px;
    }
    .vision-image::before{
      top: 14px;
      left: 70px;
      width: 240px;
      height: 225px;
    }
    .vision-image img{
      height: 234px;
    }
    .vision-sec .vision-image-bg .bg-icon-belowservicess img {
      right: 6px;
      height: 100px;
      top: -40px;
  }
  .vision-sec .vision-content{
    margin-top: 20px;
  }
  .footer-sec .footer-left-sec img{
    height: 46px;
  }
  .footer-sec .footer-left-sec .social-icon li img {
    height: 15px;}
    .footer-sec .footer-left-sec .social-icon li .twit-sec {
      height: 14px;}
      .footer-sec .footer-left-sec .social-icon li{
        width:30px;
        height: 30px;
      }
      .footer-sec .footer-left-sec .map-sec p{
        font-size: 15px;
      }
      .footer-sec .footer-left-sec .map-sec img{
        height: 22px;
        margin-right: 8px;
        float: left;
        top: 4px;
        position: relative;
      }
      .footer-sec .footer-left-sec .map-contect ul li p{
        font-size: 15px;
      }
      .footer-sec .footer-left-sec .map-contect ul li svg{
        font-size: 18px;
      }
      .map-contect ul li:first-child{
        margin-bottom: 10px;
      }
      .footer-sec .footer-mid-sec ul li{
        font-size: 15px;
        line-height: 38px;
      }
      .footer-sec .footer-mid-sec h3 {
        font-size: 16px;}
        .footer-sec .footer-right-sec h3 {
          font-size: 16px;}
          .footer-sec .footer-right-sec p {
            font-size: 15px;}
            .footer-sec .footer-right-sec{
              padding: 60px 0px 0px 11px;
            }
            .footer-sec .footer-right-sec .email-sec button{
              left:57%;
            }
            .footer-sec .footer-right-sec .email-sec .email-btn .btn-inner-sec {
              padding: 7px 10px;
          }
          .footer-sec .footer-right-sec ul li{
            font-size: 15px;
            width: 100%;
            margin-bottom: 7px;
          }
          .email-btn{
            width: 100%;
            position: absolute;
            right: 0px;
            text-align: right;
            top: -44px;
          }
         
          .footer-sec .footer-right-sec ul li:last-child {
            margin-left: 0px;
        }
        .footer-sec .footer-right-sec ul li a{
          left:-6px;
        }
        .background-video{
          width: 100%;
          height: auto;
        }
        .video-container{
          height: 35vh;
        }
        .about-main{
          height: 35vh;
        }
        .about-main .abut-banner-cont h1 {
          font-size: 27px;}
          .about-main .abut-banner-cont p{
            font-size: 16px;
            line-height: 28px;
          }
          .about-main .abut-banner-cont{
            bottom: 4vh;
          }
          .about-innov-main .about-innov-sec .about-innov-para {
            font-size: 17px;
            line-height: 32px;}
            .founder-sec-cont h2{
              font-size: 23px;
              line-height: 33px;
            }
            .founder-sec-cont p{
              font-size: 16px;
              line-height: 33px;
              margin: 17px 0px;
              text-align: justify;
            }
            .founder-sec-cont h3{
              font-size: 17px;
            }
            .founder-sec-cont h4{
              font-size: 17px;
            }
            .founder-sec-cont .book-sec{
              font-size: 17px !important;
            }
            .about-main .abut-banner-cont{
              width: 100%;
              max-width: 630px;
              transform: translate(-50%, -68%);
            }
            .book-sec span{
              display: block;
              margin: 4px 0px 0px 0px;
              img{
                margin-left: 0px !important;
                height: 20px !important;
                .book-label{
                  font-size: 18px !important;
                  display: inline-block !important;
                }
              }
            }
            .book-sec .book-label{
              display: inline-block !important;
              font-size: 17px !important;
            }
            .founder-sec{
              padding: 70px 0px;
              font-size: 18px !important;
            }

            .team-sec-content{
              .meet-pata{
                font-size: 17px !important;
              }
            }
            .team-sec .team-category-sec{
              padding: 30px 60px 10px 60px;
            }
            .team-sec .team-category-sec .teamming-sec-hover h5{
              font-size: 43px;
            }
            .team-sec .team-category-sec .teamming-sec-hover h6 {
              font-size: 16px;}
            .team-sec .team-category-sec .team-box h3 {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box h4 {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box p {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box{
              padding: 20px 0px;
            }
            .ps-timeline-sec .container ol.ps-timeline li{
              width:13.4%;
            }
            .ps-timeline{
                li{
                  .img-time {
                    img{
                      height: 45px !important;
                    }
                  }
                }
            }
            .ps-timeline-sec .container ol.ps-timeline {
              margin:170px -13px 140px -13px;}
            .ps-timeline-sec .container ol.ps-timeline li p{
              font-size: 15px;
              position: relative;
              top: -31px !important;
              left: -13px;
            }
            .team-sec .team-category-sec .team-box .teamimg-sec img {
              height: 147px;}
            .img-time-first {
              top: -28px;
              img{
                height: 63px !important;
              }
          }
          .img-last {
            top: -28px;
            img{
              height: 64px !important;
              top: -3px;
              position: relative;
            }
        }
        .img-time  .timedata{
          top:-41px !important;
        }
        .ps-timeline-sec .container ol.ps-timeline li .ps-sp-bot:before{
          bottom:-29px !important;
        }
        .main-sec .new-img-baner p {
          font-size: 19px !important;
          line-height: 34px !important;
          margin-top: 15px !important;
          margin-bottom: 25px !important;
      }
      .new-banr-img img {
        height: 270px;
    }
    .new-img-baner {
      margin-top: 50px;
  }
  .innerbannerimg {
    padding: 60px 0px 50px 0px;
}
.secure-main .secure-sec .serice-nee-fix{
  font-size: 18px !important;
  line-height: 35px;
  width: 100%;
  max-width: 810px;
  margin: 0 auto;
  text-align: center;
  margin-top: 28px;
}
.left-side-head-before h2 {
  font-size: 23px;
  line-height: 35px;
}
.differ-service-sec .differ-service-inner p {
  font-size: 18px;
  line-height: 34px;
  margin-top: 15px;
}
.serv-img-inner::before{
  top: 40px;
  left: 110px;
  width: 210px;
  height: 209px;
}
.serv-img-inner img {
  position: absolute;
  top: 40px;
  left: 89px;
  border-radius: 5px;
  height: 210px;
}
.service-img-right::after{
  top: 30px;
  left: 118px;
  width: 220px;
  height: 208px;
}
.vision-image-bg .serv-bg-iconrigt img {
  right: 0px;    }
  .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-icon img {
    height: 38px;
}
.service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3 {
  font-size: 18px;
  height: 58px;
  line-height: 29px !important;}
  .service-inner-category .serv-categ-box .serv-categ-para p {
    font-size: 16px;
    line-height: 29px;
    height: 130px;
}
.servce-img-left .vision-image-bg .serv-bg-iconrigt img {
  right: 0px; left:30px;   }
.servleft-img-inner::before {
  top: 34px;
  left: 75px;}
  .servleft-img-inner img {
    position: absolute;
    top: 34px;
    left: 56px;}
    .servce-img-left::after {
      top: 24px;
      left: 45px;
      width: 230px;
      height: 212px;
  }
  .left-side-head-before h2:after{
    width: 9%;
    margin-right: -100%;
    margin-left: 3px;
  }  
  .service-inner-category {
    margin-top: 60px;
}  
.tem-img-overlay {
  height: 299px;
}
  .proof-sec .prrof-img .proof-content-sec .team-content h3 {
    font-size: 23px;}
    .proof-sec .prrof-img .proof-content-sec .team-content h4 {
      font-size: 23px;}
      .proof-sec .prrof-img .proof-content-sec .team-content {
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translate(-50%, -30%);
        width: 100%;}
        .servicebtn-prof .btn-name{
          top:4px !important;
        }
        .proof-sec {
          padding: 70px 0px !important;
      }
        .product-sec .short-head-line{
          font-size: 17px !important;
        }
        .product-head{
          .secure-para{
            font-size: 21px !important;
          }
        }
        .product-main .prodct-left{
          margin-right: 36px;
          width: 100%;
        }
        .product-main .prodct-right{
          width: 100%;
          margin-top: 20px;
        }
        .produt-category .product-box{
          padding: 40px 8px;
        }
        .produt-category .product-box .prodct-descp p {
          font-size: 15px !important;}
          .produt-category .product-box .prodct-descp h2 {
            font-size: 16px !important;}
            .produt-category .product-box .prodct-descp {
              padding: 0px 8px 0px 4px;
          }
            .btn-prodct{}
            .produt-category {
              margin-top: 25px;
          }
            .produt-category .product-box .prodct-descp p{
              height: auto;
            }
            .btn-prodct .btn-inner-sec{
              padding: 6px 13px !important;
            }
            .btn-prodct .btn-inner-sec .btn-name{
              font-size: 14px;
              top: 1px;
              left: -3px;
            }
            .btn-prodct .btn-inner-sec .btn-icon {
              height: 26px;
              width: 26px;
              left: 7px;
          }
          .btn-prodct .btn-inner-sec .btn-icon svg {
            font-size: 16px;top: 1px !important;}
            .btn-prodct .btn-learn {
              float: left;
              margin-right: 6px;
          }
              .produt-category .product-box {
        padding: 30px 8px;
    }
    .footer-sec .footer-left-sec{
      padding: 40px 30px 0px 0px;
    }
    .footer-sec .footer-mid-sec {
      padding: 45px 0px 0px 40px;}
    .footer-sec .footer-right-sec {
        padding:45px 0px 0px 7px;
    }
    .main-sec .prodt-details h1 {
      font-size: 25px !important;
      line-height: 36px !important;}
      .main-innerbanner {
        padding: 35px 0px;
    }
    .prodt-detail-main .watch-sec{
      font-size: 18px;
      width: 73%;
  
    }
    .prodt-detail-main .play-sec-prdt {
      width: 27%;
      float: left;
  }
    .video-play-button:before{
      width: 70px;
      height: 70px;
    }
    .video-play-button:after{
      width: 65px;
      height: 65px;
    }
    .video-play-button img{
      left: 34px;
    }
    .video-play-button:before{
      left: 223%;
    }
    .prodtbannerimg img {
      width: 81%;
  }
  .main-innerbanner .prodt-details{
    margin-top: 10px;
  }
  .prodct-margin {
    margin-top: 20px;
}
.keyprocs-sec .keyprocs-content h2 {
  font-size: 23px;
  line-height: 34px;}

  .keyprocs-sec .keyprocs-content p {
    font-size: 17px;
    max-width: 670px;
    line-height: 30px;
}
    .keyprocs-sec .keyprocs-content{
      padding: 70px 0px;
    }
    .detail-carousl-sec .carousl-details-content h2 {
      font-size: 20px;
      z-index: 99999;
      position: relative;
      line-height: 32px;margin-bottom: 15px;}
    .carousel-item img{
      width: 90% !important;
    }
    .detail-carousl-sec .carousl-details-content p{
      font-size: 17px !important;
      font-weight: 500;
      line-height: 34px;
    }
    .detail-carousl-sec {
      padding: 75px 0px 90px 0px;
  }
  .keyprocs-feturesec .keyprocs-heading h2 {
    font-size: 23px;margin-bottom: 20px;}
    .keyprocs-feturesec{
      padding: 60px 0px;
    }
    .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec{
      padding:20px 11px;
    }
    .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec img {
      height: 40px;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec h3{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    height: auto;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec p{
    font-size: 15px;
    line-height: 28px;
    height: 110px;
  }
  .keyprocs-feturesec .keyfeature-box{
    margin:0px 0px 40px 0px;
  }
  .impact-sec h2{
    font-size: 23px;
  }
  .impact-sec{
    padding: 65px 0px;
  }
  .impact-sec .imapct-inner-sec h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .impact-sec .imapct-inner-sec p {
    font-size: 18px;
    line-height: 27px;
  }
  .impact-sec .imapct-inner-sec .impact-box-center:before{
    left:-49px;
  }
  .impact-sec .imapct-inner-sec .impact-box-center:after{
    right:-50px;
  }
  .impact-sec .imapct-inner-sec{
    margin: 40px auto 20px auto;
  }
  .callactn-sec .callactn-content p {
    font-size: 18px;
        line-height: 31px;
        width: 68%;
        margin-right: 0px;
        max-width: 370px;
  }
  .pre-btnss .prev-main-sec .btn-name{
    font-size: 17px;
  }
  .pre-btnss .next-main-sec .btn-name{
    font-size: 17px;
    cursor: pointer;
  }
  .arrows-container{
    top:7px !important;
  }
  .callactn-sec{
    padding: 65px 0px;
  }
  .detail-carousl-sec .carousal-img .carousel .carousel-indicators button{
    width: 10px;
    height: 10px;
  }
  .carousel-indicators .active{
    height: 10px;
    width: 10px;
  }
  .case-studies-list .case-study-box p{        font-size: 16px !important;
    line-height: 27px;margin-bottom: 17px;}
  .footer-sec .footer-right-sec .copy-sec p{
    font-size: 15px;
    padding: 20px 0px 20px 13px;
  }
  .footer-sec .footer-right-sec .email-sec button{
    width: 100%;
    /* left: 8px; */
    position: relative;
    left: 0px;
  }
  .case-studies-list .case-study-box{
    padding: 32px 27px 32px 27px;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-left{
    z-index: 999;
    position: relative;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-left{
    margin-right: 27px;
  }
  .main-innerbanner .main-banner-sec h1 {
    font-size: 23px !important;
    line-height: 40px;
}
  .main-detsila-two {
    padding: 85px 0px 100px 0px;
}
.case-video-sec .case-video-main .case-video-left .case-video-box p {
  font-size: 18px;
  line-height: 29px;
}
.case-video-sec .case-video-main .case-video-left .case-video-box{
  padding: 42px 15px;
  width: 94%;
  left: 10%;
  z-index: 999;
  transform: translate(10%, 47%)
}
.case-video-sec .case-video-main .case-video-left .case-video-box::before{
  height: 65%;
}
.case-video-sec .case-video-main .case-video-right:after{
  width: 85%;
  height: 93%;
  background-color: rgba(0, 0, 0, 0.27);
  left: -12px;
  z-index: 99;
  border-radius: 12px;
  top: 0;
}
.case-video-sec .case-video-main .case-video-right img {
  z-index: 99;
  border-radius: 12px;
  width: 85%;
}
.case-video-sec .case-video-main .case-video-right .play-sec-prdt .video-play-button{
  top: -114px;
}
.video-play-button img{
  height: 20px;
}
.case-vieo-inner{
  position: relative;
  left: -40px;
}
.case-video-sec{
  padding: 60px 0px;
}
.busn-sec .business-sec h3{
  font-size: 18px;
  line-height: 32px;
  max-width: 600px;
}
.home-service-sec .business-sec p{
  font-size: 17px !important;
  line-height: 32px;
  max-width: 650px;
}
.busn-main-sec .key-benefits-sec img {
  height: 53px;
}
.busn-main-sec .key-benefits-sec h3 {
  font-size: 17px;
  line-height: 29px;
}
.busn-main-sec .key-benefits-sec {
  margin-top: 20px;
}
.consult-sec .consult-content p{
  font-size: 20px !important;
  line-height: 36px;
  position: relative;
  top: -4px;
  width: 61%;

}
.like{
  font-size: 17px !important;
  margin-right: 4px !important;
}
.dislike{
  font-size: 17px !important;
}
.callactn-sec .callactn-content .cal-btn-sec {
  width: 30%;
  float: right;}
.consult-btn .btn-white .btn-name {
  font-size: 15px;
}
.callactn-sec .callactn-content .consult-btn{
  width: 37%;
  float: right;

}
.resol-sec p {
  font-size: 19px;
  line-height: 35px;
  margin: 23px auto 38px auto;
}
.resol-inner-sec .resol-inner-content{
  max-width: 790px !important;
  padding: 31px 35px;
}
.resol-inner-sec .resol-inner-content .resol-head h3 {
  font-size: 19px; 
  margin-bottom: 9px;
}
  .resol-inner-sec .resol-inner-content .resol-para p{
    font-size: 16px;
    font-weight: 500;
    line-height: 29px;
    
  }
  .outcome-sec{
    padding: 65px 0px;
  }
  .busn-sec .details-new h2{
    margin: 0px 0px 19px 0px !important;
  }
  .case-bg-icons img {
    position: absolute;
    left: 80px;
    transform: translate(-180%, -50%);
    height: 100px;
}
.consult-sec .consult-content{
  max-width: 860px !important;
 padding: 40px 30px 40px 30px;
}
.outcome-sec h2 {
  font-size: 23px;
}
.outcome-sec p {
  font-size: 19px;margin:14px 0px 21px 0px;}
  .outcome-sec .outcome-order ul li {
    font-size: 16px;
    line-height: 32px;   
    margin: 9px 0px;
  }
  .outcome-sec .outcome-order{
    max-width: 520px;
  }
  .blog-list .blog-inner-sec .blog-category p{
    font-size: 16px;
  }
  .blog-list .blog-inner-sec .blog-category img {
    height: 29px;
    cursor: pointer;
    margin-left: 12px;
    font-weight: 500;
}
.blog-list .container-width-blog{
  max-width: 960px !important;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date{
  width: 100%;
  margin-bottom: 8px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date a img{
  height: 16px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date a span{
  font-size: 15px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p{
  font-size: 16px;
  position: relative;
  top: 3px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category{
  width: 100%;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p::before{
  left:-16px;
  width:0px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content h2 {
 
  font-size: 15px;
  line-height: 27px;
  font-weight: 600;
  height: 67px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content p {
  font-size: 15px;
  font-weight: 400;
  line-height: 29px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content{
  padding: 20px 0px 21px 0px; 
}
  .rating .like-count {
    margin-right: 6px !important;
    font-size: 15px !important;
  }
  .blog-list .blog-list-inner .blog-new-list {
    padding: 25px 15px 35px 15px;
}
.blog-list .blog-list-inner .blog-list-sec{
  z-index: 9999;
    position: relative;
}
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-tag-para-new {
    width: 56%;}
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-tag-para p{
    font-size: 16px;
    padding: 12px 10px;
    line-height: 25px;
  }
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date img {
    height: 17px;
  }
  .blog-details-sec .blog-details-inner .blog-detal-content {
    padding: 20px 20px;}
    .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date a {
      font-size: 15px;
    }
      .blog-details-sec .blog-details-inner .blog-detal-content h2 {
        font-size: 17px;
        margin-top: 13px;
        line-height: 27px;
        margin-bottom: 18px;
      }
        .blog-details-sec .blog-details-inner .blog-detal-content p{
          margin-bottom: 15px;
          line-height: 31px;
          font-size: 15px;
        }
        .blog-details-sec .blog-details-inner .blog-detal-content h3 {
          font-size: 16px;
          margin-bottom: 17px;
          line-height: 29px;
      }
      .blog-details-sec .blog-details-inner .blog-detal-content h4 {
        font-size: 16px;}
        .blog-detal-content .rate-admin-details-sec .rating-blgdetals p{
          font-size: 16px !important;
        }
        .blog-details-sec .blog-details-inner .blog-detal-content .admin-sec p span {
          font-size: 16px;}
          .blog-details-sec .blog-details-inner .blog-detal-content .admin-sec p svg{
            font-size: 19px;
          }
          .blog-detl-call{
            .blog-detl-sec{
              p{
                font-size: 17px;
                line-height: 28px;
                margin-right: 2px;
                width: 57%;
              }
            }
          }
          .blog-detl-call .blog-detl-sec .blg-detl-btn .btn-red-sec {
            padding: 6px 23px;
        }
        .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .input-srch {
          width: 75%;}
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .search-icon{
            padding: 8px 9px;
          }
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .search-icon svg{
            font-size: 21px;
          }
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box{
            padding: 12px 0px;
          }
          .input-srch{
            font-size: 15px;
          }
          .blog-details-sec .blog-detail-rights .blog-search p {
            font-size: 16px;}
            .blog-details-sec .blog-detail-rights .blog-search{
              padding: 15px 15px;
            }
            .blog-details-sec .blog-detail-rights .detail-categ p {
              font-size: 16px;        margin-bottom: 10px;}
              .blog-details-sec .blog-detail-rights .detail-categ .categ-list ul li{
                font-size: 14px;
                padding: 6px 10px;
                line-height: 23px;
                width: 100%;
              }
              .blog-details-sec .blog-detail-rights .detail-categ{
                padding: 15px 15px;
              }
              .blog-details-sec .blog-detail-rights .recent-post p {
                font-size: 16px;padding-bottom: 0px;}
                .blog-details-sec .blog-detail-rights .recent-post{
                  padding: 15px 15px;
                }
                .blog-details-sec .blog-detail-rights .recent-post ul li img{
                  width:25%;
                  margin-right: 12px;
                }
                .blog-details-sec .blog-detail-rights .recent-post ul li p{
                  width: 68%;
                  font-size: 14px;
                  line-height: 23px;
                  position: relative;
                  top: -5px;
                }
                .job-sec h2 {
                  font-size: 22px;
                  margin-bottom: 13px;}
                    .job-sec{
                      padding: 65px 0px;
                    }
                    .job-sec .job-inner-sec{
                      margin: 30px auto;
                      max-width: 890px;
                    }
                    .job-sec .job-inner-sec .job-desc-new h3 {
                      font-size: 17px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc{
                    margin: 7px 0px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left {
                    float: left;
                    margin: 8px 20px 4px 0px;
                }
                .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right {
                  margin-bottom: 3px;}
                  .job-sec .job-inner-sec .job-desc-new{
                    padding: 25px 30px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .jon-loc svg{
                    font-size: 18px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left svg{
                    font-size: 18px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right svg{
                    font-size: 18px;
                  }
                  .apply-btn-cont{
                    font-size: 16px;
                    margin-top: 7px !important;
                    display: block;
                  }
                  .job-sec .job-inner-sec .job-inner-left{
                    z-index: 999;
                    position: relative;
                  }
                  .job-details{
                    padding: 75px 0px;
                  }
                  .job-details .job-desc-inner .job-desc-left h2 {
                    font-size: 17px;
                  }
                  .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text h3 {
                    font-size: 16px;
                    margin-bottom: 8px;
                }
                .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text p{
                  font-size: 15px;
                  line-height: 23px;
                }
                .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img img {
                  height: 22px;
              }
              .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img svg {
                font-size: 20px;
                position: relative;
                top: -2px;
            }
            .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-experience img{
              height: 19px;
            }
            .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-quali img{
              height: 19px;
            }
            .job-details .job-desc-inner .job-desc-left{
              padding: 20px 10px;
            }
            .job-details .job-desc-inner .job-desc-right h2 {
              font-size: 20px;}
              .job-details .job-desc-inner .job-desc-right p{
                line-height: 30px;
                font-size: 15px;
                margin-bottom: 11px;
              }
              .job-details .job-desc-inner .job-desc-right ul li{
                margin-bottom: 7px;
                font-size: 15px;
                line-height: 25px;
              }
              .listcarer li span {
                position: relative;
                top: -2px;
            }
              .job-details .job-desc-inner .job-desc-right h4{
                font-size: 17px;
                margin: 16px 0px;
              }
              .btn-job-aply{
                margin-top: 25px;
              }
             .or-sec{
              margin: 0px 13px;
             }
             .contact-sec{
              padding: 86px 0px;
             }
             .contact-sec .contact-inner:before{
              width: 85%;
             }
             .contact-sec .contact-inner .contact-form h2 {
              font-size: 21px;}
              .new-cont-secss .tooltipss {
                left: 130px !important;}
                .new-cont-secss .tooltipss .fa-solid {
                  font-size: 14px !important;
              }
              .cont-newss p{
                font-size: 11px !important;
              }
              .invalid-feedback .error-msg{
                font-size: 13px !important;
              }
              .contact-sec .contact-inner .contact-info{
                padding: 22px 21px;
                max-width: 100%;
              }
              .form-msg-sec{
                margin-top: 8px !important;
              }
              .eror-contact-new{
                top:5px !important;
              }
              .location-sec h2 {
              font-size: 23px;
              margin-bottom: 25px;
            }
            .location-sec{
              padding: 0px !important;
            }
            .location-sec{
              iframe{
                height: 300px !important;
                width: 100%;
              }
            }
            .contact-sec .contact-inner .contact-info h2 {
              font-size: 19px;
            margin-top: 6px;
            }
            .new-cont-secss .tooltipss::after{
              font-size: 13px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p{
              font-size: 15px;
              line-height: 30px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p svg{
              font-size: 22px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p span {
              margin-left: 14px;}
              .social-icons-contact .social-icon li img {
                height: 16px;top: 2px !important;}
                .social-icons-contact .social-icon li .twit-sec {
                  height: 14px;top: 2px !important;}
                  .social-icons-contact .social-icon li{
                    height: 31px;
                    width:31px;
                  }
                  .infinite-scroll-component p{
                    font-size: 16px;
                    line-height: 33px;
                  }
                  .privacy-policy-sec{
                    padding: 70px 0px;
                  }
                  .infinite-scroll-component h3 {
                    font-size: 21px;
                    margin-bottom: 19px;
                    margin-top: 22px;
                }
                .infinite-scroll-component h4 {
                  font-size: 20px;
              }
              .infinite-scroll-component span{
                font-size: 17px;
              }
              .infinite-scroll-component ul li{
                margin:7px 0px;
                font-size: 16px;
              }
              .infinite-scroll-component h5 {
                font-size: 19px;}
                .new-font-bold {
                  font-size: 18px !important;
              }
              .busn-main-sec .busn-key-head h2 {
                font-size: 23px;
                margin-bottom: 17px;
            }
            .about-innov-main .main-difeer-about{
              .about-innov-para{
                font-size: 16px !important;
                line-height: 30px;
              }
            }
            .team-sec .team-newssss{
              .meta-patahhh{
                font-size: 16px !important;
                padding: 10px 0px 8px 0px;
              }
            }
            .team-sec .team-category-sec .teamming-sec-hover{
              width:100%;
            }
            .homebanner .banner-sec-content {
              margin-top: 40px;
          }
          .service-home-widg{
            width:100%;
            margin-bottom: 20px;
          }
          .main-sec .banner-sec-content p::before{
            left:-4px;
          }
          .project-box{
            padding: 30px 14px;
          }
          .vision-sec{
            padding: 65px 0px;
          }
          .footer-sec .footer-right-sec .email-sec .inputemail{
            width:100%;
            font-size: 14px;
          }
          .footer-sec .footer-right-sec ul li::marker{
            font-size: 20px;
          }
          .header-sec {
            padding: 1px 0px;
        }
        .imglogbet{
          top:-6px !important;
        }
        .founder-img{
          img{
            height: 330px;
          }
        }
        .team-img-widh{
          width: 50%;
        }
        .team-sec .team-category-sec .team-box .teamimg-sec{
          margin-bottom: 17px;
        }
        .team-sec .team-category-sec .team-box .teamimg-sec::before{
          box-shadow: 3px 4px 6px 0px rgb(0 0 0 / 24%);
        }
        .ps-timeline li .img-time-first img {
          height:50px !important;
      }
      .ps-timeline li .img-last img {
        height:50px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-bot{
      width: 150% !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-top {
      position: absolute;
      bottom: -20px;
      margin-bottom: 104px;
      width: 150%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-time-first .timedata{
    top: -33px !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-last .timedata{
    top: -35px !important;
  }
  .home-service-sec .service-short-head .short-head-line::before{
    width: 130px;
  }
  .home-service-sec .service-short-head .short-head-line::after{
    width: 130px;
  }
  .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3{
    padding-top: 3px;
  }
  .service-inner-category .serv-categ-box .serv-categ-para p {
    font-size: 15px !important;
    line-height: 28px !important;
    padding: 14px 10px 0px 10px !important;
}
.key-prodt-width{
  width:50%;
}
.keyfeature-inner{
  margin-top: 20px;
}
.case-stu-para {
  font-size: 20px !important;
}
.case-studies-list{
  margin-top: 40px;
}
.blog-list .blog-inner-sec .blog-search{
  top:-1px;
}
.blog-detl-call .blog-detl-sec {
  padding: 32px 15px;}
  .job-sec .job-inner-sec .job-desc-new .jon-loc span{
    font-size: 15px;
  }
  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left span{
    font-size: 15px;
  }
  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right span{
    font-size: 15px;
  }
  .contact-sec .contact-inner {
    padding: 40px 20px 50px 10px;
    max-width: 1100px;
}
.form-sec .MuiBox-root .MuiTextField-root label{
  font-size: 15px !important;
}
.navbar-toggler{
  border:none;
}
.navbar-toggler:focus{
  outline:none !important;
  box-shadow: none !important;
}
body .CookieConsent {
  width: 93% !important;
  padding: 0px 10px !important;
}
body .head-cookie{
font-size: 20px !important;
 }
 body .para-cookk {
  font-size: 15px !important;
  line-height: 28px !important;
}
body .declinbtn{
  margin:8px 0px !important;
  padding:5px 11px !important;
}
body .accptbtn{
  margin:8px 8px !important;
  padding:6px 11px !important;
}
.callactn-sec .callactn-content .red-button-sec {
  width: 39%;
  float: right;
}

// for menu bar code start here

.navTrigger {
  width: 29px;
  height: 25px;
  margin: auto;
  position: fixed;
  right: 30px;
  top: 28px;
  cursor: pointer;
}
.navTrigger i {
background-color: #484848;
border-radius: 2px;
content: '';
display: block;
width: 100%;
height: 2px;
}
.navTrigger i:nth-child(1) {
// -webkit-animation: outT 0.8s backwards;
// animation: outT 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}
.navTrigger i:nth-child(2) {
margin: 5px 0;
// -webkit-animation: outM 0.8s backwards;
// animation: outM 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}
.navTrigger i:nth-child(3) {
// -webkit-animation: outBtm 0.8s backwards;
// animation: outBtm 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}

.navbar-collapse{
  box-shadow:0 2px 6px rgba(0, 0, 0, 0.161);
  /* margin-bottom: -6px; */
  position: fixed !important;
  width: 100%;
  z-index: 999;
  display: block;
  position: fixed;
  top: 75px;
  background-color: $whitecolor;
  float: right;
  right: 0px;

}
.activeheader{
  background-color: $whitecolor;
}

.nav-link-menu::before{
  opacity: 0;
}
.navbar-expand-lg .navbar-nav{
  margin:0px !important;
}
.navTrigger {
  cursor: pointer;
  width: 30px;
  height: 25px;
  margin: auto;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
}
.scrolled .navbar-collapse{top: 76px;}
.navTrigger i {
  background-color: $textblackcolor;
  border-radius: 2px;
  content: '';
  display: block;
  width: 100%;
  height: 3px;
}



.navTrigger.active i:nth-child(1) {
  -webkit-animation: inT 0.8s forwards;
  animation: inT 0.8s forwards;
}

.navTrigger.active i:nth-child(2) {
  -webkit-animation: inM 0.8s forwards;
  animation: inM 0.8s forwards;
}

.navTrigger.active i:nth-child(3) {
  -webkit-animation: inBtm 0.8s forwards;
  animation: inBtm 0.8s forwards;
  visibility: hidden;
}

@-webkit-keyframes inM {
  50% {
      -webkit-transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(45deg);
  }
}

@-webkit-keyframes outM {
  50% {
      -webkit-transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(45deg);
  }
}

@-webkit-keyframes inT {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(9px) rotate(0deg);
  }
  100% {
      transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes outT {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(9px) rotate(0deg);
  }
  100% {
      transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes inBtm {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(-9px) rotate(0deg);
  }
  100% {
      transform: translateY(-9px) rotate(135deg);
  }
}

@-webkit-keyframes outBtm {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(-9px) rotate(0deg);
  }
  100% {
      transform: translateY(-9px) rotate(135deg);
  }
}


// new menu bar code start here

.menuToggle {
  display: block;
  position: relative;
  top: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  a {
    text-decoration: none;
    color: #232323;
    transition: all 0.3s ease;
    &:hover {
      color: tomato;
    }
  }
  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }
  span {
    position: relative;
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }
}

.menuToggle {
  input {
    &:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #232323;
      &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }
      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
      }
    }
    &:checked ~ .menuItem {
      transform: none;
    }
    
  }
}

.menuItem {
  position: absolute;
  width: 300px;
  padding: 50px;
  padding-top: 125px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  li {
    padding: 10px 0;
    font-size: 22px;
  }
}


.menu--right {
  .menuToggle {
    position: fixed;
    right: 0;
    input {
      right: 50px;
    }
    span {
      right: 50px;
    }
  }
  .menuItem {
    right: 0;
    margin: -100px 0 0 0;
    transform: translate(100%, 0);
    li {
      a {
        &:hover {
          padding-left: 5px;
        }
      }
    }
  }
}




//menu bar code end

.job-details .job-desc-inner .job-desc-right h3 {
  font-size: 17px;
  line-height: 27px;}
  
}



 @media (min-width: 830px) and (max-width: 900px){
  .team-img-widh {
    width: 33.33%;
}
.main-detsila-two {
  padding: 65px 0px 90px 0px;
}
.main-innerbanner .main-banner-sec h1 {
  font-size: 24px !important;
  line-height: 40px;
}
 }
 @media (min-width: 900px) and (max-width: 990px){
  .ps-timeline-sec .container ol.ps-timeline {
    margin: 170px 7px 140px 7px;
}
.main-innerbanner .main-banner-sec h1 {
  font-size: 24px !important;}
  .team-img-widh {
    width: 33.33%;
}
.prodt-detail-main .watch-sec {
  font-size: 18px;
  width: 66%;
}
.case-studies-list .case-study-box {
  padding: 32px 19px 32px 19px;
}
.case-studies-list .case-study-box p {
  font-size: 15px !important;}
  .container{
    max-width: 850px;
  }
  .consult-sec .consult-content p {
    width: 71%;
    max-width: 500px;
}
.callactn-sec .callactn-content .red-button-sec {
  width: 34%;
  float: right;
}
  .service-home-widg {
    width: 33.33%;
    margin-bottom: 20px;
}
.service-category {
  padding: 35px 0px 0px 0px;
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
}
.service-category .service-box .service-inner .service-icon .service-para-main h3 {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  top: 14px;
  left: 24px;
  line-height: 23px;
}
.service-category .service-box .service-inner .service-icon .service-para-main {
  width: 79%;
}
.service-category .service-box .service-inner .service-content {
  margin-top: 5px;
  height: 340px;
}
.service-category .service-box {
  padding: 24px 15px 34px 15px;
}
.project-box {
  padding: 30px 27px;
}
.service-inner-category .serv-categ-box .serv-categ-para p {
  height: 110px;
}
.service-inner-category {
  margin-top: 100px;
}
.vision-image-bg .serv-bg-iconrigt img {
  right: 40px;
}
.tem-img-overlay {
  height: 361px;
}
.proof-sec .prrof-img .proof-content-sec .team-content {
  position: absolute;
  bottom: 14%;}
  .proof-sec .prrof-img .proof-content-sec .team-content h4 {
    font-size: 23px;
    margin-bottom: 29px;
}
.product-main .prodct-left {
  margin-right: 15px;
  width: 100%;
}
.product-main .prodct-right {
  width: 100%;
  margin-top: 20px;
}
.produt-category .product-box .prodct-descp {
  padding: 27px 8px 0px 4px;
}
.case-video-sec .case-video-main .case-video-left .case-video-box {
  padding: 42px 21px;}
  .blog-list .blog-list-inner .blog-new-list {
    padding: 25px 16px 35px 16px;
}
.btn-job-aply {
  margin-top: 0px;
}
.vision-sec .vision-image-bg .bg-icon-belowservicess img {
  right: 66px;}
 }
 @media only screen and (min-width: 640px) and (max-width: 767px) {
  .toolcontent::after{
    width: 474px !important;
  }
  .new-cont-secss .tooltipss::after {
    padding: 6px 10px !important;
}
  .footer-sec .footer-right-sec ul li:last-child {
    margin-left: 0px !important;
  }
  .load-btn {
    margin: 10px 0px 0px 0px;}
  .modalblog-categ .blog-detail-rights .detail-categ{display: block !important;box-shadow: none;margin-top: 0px !important;
    padding: 0px 0px !important;}
  .modalblog-categ .blog-detail-rights .detail-categ{        display: block !important;
    box-shadow: none;
    margin-bottom: 18px;}
  .modalblog-categ .blog-detail-rights .detail-categ p{
    font-size: 21px !important;
    text-align: center;
    
  }
  .res-modl-blog .MuiDialog-container .MuiPaper-root {
    max-width: 560px !important;
}
  .modalblog-categ .blog-detail-rights .detail-categ p:after{
    background-color: $whitecolor !important;
  }
  .modalblog-categ{
    padding: 20px 0px 0px 0px !important;
  }
  .nav-link-menu.active::before{
    opacity: 1;
  }
  .error-image {
    height: 200px !important;
}
.error-page{
  padding-top: 55px !important;
}
.error-page .error-page-head{
  font-size: 24px !important;
}
.blog-details-sec .blog-detail-rights .detail-categ .categ-list ul{
  margin-bottom: 0px;
}
.error-page .error-page-para{
  font-size: 18px !important;
}
  .key-main-secss .differ-service-sec{
    padding: 0px 0px !important;
  }
  .error-page .error-page-head{
    font-size: 26px;
  }
  .error-page .error-page-para{
    font-size: 19px;
  }
  .key-main-secss .new-case-add {
    padding: 20px 0px 30px 0px !important;
  }
  .error-page-banner {
    height: 88vh;}
  .key-main-secss .new-case-add p {
    font-size: 17px !important;
    line-height: 31px !important;  
    margin-top: 15px !important;
  }
  .navbar-brand img {
    height: 48px;
        position: relative;
        top: 7px;
        width:auto;
  }
  .nav-link-menu{
    font-size: 15px;
    padding: 0px 22px;
    width: 100%;
    margin: 0px 0px !important;
    text-align: left;
  }
  .main-sec .banner-sec-content p{
    font-size: 16px;
    line-height: 33px;
    margin-bottom: 21px;
   
  }
  .main-sec .banner-sec-content h1 {
    font-size: 25px;
    line-height: 44px;
   
  }
  .mainbaner-img{
    margin:30px 0px 2px 0px;
  }
  .main-sec .banner-sec-content h2 {
    font-size: 25px;
    line-height: 37px;
    margin-top: 9px;
   
  }
  .mainbaner-img img {
    height: 280px;
}
  .homebanner {
    padding:20px 0px 50px 0px;;
  }
  .bg-icon-service img{
    height: 100px;
  }
  .home-service-sec .service-short-head .short-head-line{
    font-size: 15px !important;
  }

  .home-service-sec .service-short-head h2 {
    font-size: 22px;
    line-height: 34px;
  }
  .home-service-sec .service-short-head p {
    font-size: 17px !important;
    line-height: 27px;
  }
  .home-service-sec .service-short-head h2{
    margin: 20px 0px 17px 0px;
  }
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle{
    width: 60px;
    height: 60px;

  }
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle img {
    height: 36px;
    position: relative;
    top: 12px;
  }
  .bg-icon-belowservice img{
    height: 100px;
  }
  .service-category .service-box{
    padding: 24px 20px 34px 20px;
  }
  .service-category .service-box .service-inner .service-icon .icon-sec{
    width: 13%;
    margin-right: 19px;
  }
  .service-category .service-box .service-inner .service-icon .service-para-main {
    width: 82%;}
    .home-service-sec{
      padding: 55px 0px;
    }
  .service-category .service-box .service-inner .service-icon .service-para-main h3 {
    font-size: 18px;
    font-weight: 600;
    position: relative;
    top: 21px;
    left: -5px;
  }
  .service-category{
    padding: 35px 0px 0px 0px;
    width: 100%;
        max-width: 630px;
        margin: 0 auto;
  }
  .service-box  .service-inner .service-content {
    p{
      font-size: 15px;
      line-height: 32px;
    }
  }
  .service-category .service-box{
    z-index: 999;
  }
  .service-category .service-box .service-inner .service-content{
    margin-top: 5px;
    height: auto;
  }
  .btn-inner-sec .btn-name{
    font-size: 15px;
    top: 4px;
  }
  .btn-inner-sec{
    padding: 5px 12px;
  }
  .btn-inner-sec .btn-icon {
    height: 30px;
    width: 30px;left:7px}
    .btn-inner-sec .btn-icon svg{
      top:3px !important;
    }
    .project-box p {
      line-height: 38px;
      font-size: 18px;
    
    }
    .project-box-blue{
      z-index: 999;
    }
    .bg-icon-belowprojt img{
      height: 100px;
    }
    .projet-category-sec::before{
      top: -242px;
      left: -49%;
      width: 100%;
      height: 100%;
      background-size: 53px;
    }
    .vision-image-bg .bg-icon-belowservicess img {
      right: 10px;
      height: 100px;
    }
    .vision-sec .vision-content h2{
      font-size: 22px;
    }
    .vision-sec .vision-content p{
      font-size: 17px;
      line-height: 33px;
    }
    .vision-image::before{
      top: 14px;
      left: 123px;
      width: 240px;
      height: 225px;
    }
    .vision-image img{
      height: 234px;
    }
    .vision-sec .vision-image-bg .bg-icon-belowservicess img {
      right: -44px;
      height: 100px;
      top: -212px;
  }
  .vision-sec .vision-content{
    margin-top: 20px;
  }
  .footer-sec .footer-left-sec img{
    height: 54px;
    z-index: 999;
    position: relative;
  }
  .footer-sec .footer-left-sec .social-icon li img {
    height: 15px;}
    .footer-sec .footer-left-sec .social-icon li .twit-sec {
      height: 14px;}
      .footer-sec .footer-left-sec .social-icon li{
        width:30px;
        height: 30px;
      }
      .footer-sec .footer-left-sec .map-sec p{
        font-size: 15px;
      }
      .footer-sec .footer-left-sec .map-sec img{
        height: 22px;
        margin-right: 8px;
        float: left;
        top: 4px;
        position: relative;
      }
      .footer-sec .footer-left-sec .map-contect ul li p{
        font-size: 15px;
      }
      .footer-sec .footer-left-sec .map-contect ul li svg{
        font-size: 18px;
      }
      .map-contect ul li:first-child{
        margin-bottom: 10px;
      }
      .footer-sec .footer-mid-sec ul li{
        font-size: 15px;
        line-height: 38px;
      }
      .footer-sec .footer-mid-sec h3 {
        font-size: 16px;}
        .footer-sec .footer-right-sec h3 {
          font-size: 17px;}
          .footer-sec .footer-right-sec p {
            font-size: 15px;}
            .footer-sec .footer-right-sec{
              padding: 60px 0px 0px 11px;
            }
            .footer-sec .footer-right-sec .email-sec button{
              left:57%;
            }
            .footer-sec .footer-right-sec .email-sec .email-btn .btn-inner-sec {
              padding: 7px 10px;
          }
          .footer-sec .footer-right-sec ul li{
            font-size: 15px;
            width: 100%;
            margin-bottom: 7px;
          }
          .email-btn{
            width: 100%;
            position: absolute;
            right: 0px;
            text-align: right;
            top: -44px;
          }
         
          .footer-sec .footer-right-sec ul li:last-child {
            margin-left: 0px;
        }
        .footer-sec .footer-right-sec ul li a{
          left:-6px;
        }
        .background-video{
          width: 100%;
          height: auto;
        }
        .video-container{
          height: 35vh;
        }
        .about-main{
          height: 35vh;
        }
        .about-main .abut-banner-cont h1 {
          font-size: 25px;}
          .about-main .abut-banner-cont p{
            font-size: 15px;
            line-height: 27px;
            width: 100%;
            max-width: 610px;
            margin: 10px auto 0px auto;
          }
          .about-main .abut-banner-cont{
            bottom: 4vh;
          }
          .about-innov-main .about-innov-sec .about-innov-para {
            font-size: 17px;
            line-height: 32px;}
            .founder-sec-cont h2{
              font-size: 22px;
              line-height: 33px;
            }
            .founder-sec-cont p{
              font-size: 15px;
              line-height: 31px;
              margin: 17px 0px;
              text-align: justify;
            }
            .founder-sec-cont h3{
              font-size: 16px;
            }
            .founder-sec-cont h4{
              font-size: 16px;
            }
            .founder-sec-cont .book-sec{
              font-size: 16px !important;
            }
            .about-main .abut-banner-cont{
              width: 100%;
              max-width: 630px;
              transform: translate(-50%, -68%);
            }
            .book-sec span{
              display: block;
              margin: 4px 0px 0px 0px;
              img{
                margin-left: 0px !important;
                height: 19px !important;
                .book-label{
                  font-size: 18px !important;
                  display: inline-block !important;
                }
              }
            }
            .book-sec .book-label{
              display: inline-block !important;
              font-size: 17px !important;
            }
            .founder-sec{
              padding: 55px 0px;
              font-size: 18px !important;
            }

            .team-sec-content{
              .meet-pata{
                font-size: 17px !important;
              }
            }
            .team-sec .team-category-sec{
              padding: 30px 60px 10px 60px;
            }
            .team-sec .team-category-sec .teamming-sec-hover h5{
              font-size: 33px;
            }
            .team-sec .team-category-sec .teamming-sec-hover h6 {
              font-size: 16px;}
            .team-sec .team-category-sec .team-box h3 {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box h4 {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box p {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box{
              padding: 20px 0px;
            }
            .ps-timeline-sec .container ol.ps-timeline li{
              width:13.4%;
            }
            .ps-timeline{
                li{
                  .img-time {
                    img{
                      height: 49px !important;
                    }
                  }
                }
            }
            .ps-timeline-sec .container ol.ps-timeline {
              margin:170px -13px 140px -13px;}
            .ps-timeline-sec .container ol.ps-timeline li p{
              font-size: 16px;
              position: relative;
              top: 0px;
              left: 17px;
            }
            .team-sec .team-category-sec .team-box .teamimg-sec img {
              height: 147px;}
            .img-time-first {
              top: -28px;
              img{
                height: 63px !important;
              }
          }
          .img-last {
            top: -28px;
            img{
              height: 64px !important;
              top: -3px;
              position: relative;
            }
        }
        .img-time  .timedata{
          top: -35px !important;
        }
        .ps-timeline-sec .container ol.ps-timeline li .ps-sp-bot:before{
          bottom: -23px !important;
        }
        .main-sec .new-img-baner p {
          font-size: 18px !important;
          line-height: 34px !important;
          margin-top: 11px !important;
          margin-bottom: 22px !important;
      }
      .new-banr-img img {
        height: 270px;
    }
    .new-img-baner {
      margin-top: 20px;
  }
  .innerbannerimg {
    padding: 60px 0px 50px 0px;
}
.secure-main .secure-sec .serice-nee-fix{
  font-size: 17px !important;
  line-height: 33px;
  width: 100%;
  max-width: 810px;
  margin: 0 auto;
  text-align: center;
  margin-top: 28px;
}
.left-side-head-before h2 {
  font-size: 22px;
  line-height: 35px;
}
.differ-service-sec .differ-service-inner p {
  font-size: 17px;
  line-height: 33px;
  margin-top: 15px;
}
.serv-img-inner::before{
  top: 40px;
  left: 110px;
  width: 210px;
  height: 209px;
}
.serv-img-inner img {
  position: absolute;
  top: 40px;
  left: 85px;
  border-radius: 5px;
  height: 210px;
}
.service-img-right::after{
  top: 26px;
  left: 72px;
  width: 220px;
  height: 208px;
}
.servce-img-left::after {
  top: 31px !important;
  left: 139px !important;}
.vision-image-bg .serv-bg-iconrigt img {
  right: 160px;  }
  .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-icon img {
    height: 44px;
}
.service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3 {
  font-size: 18px;
  height: auto;
  line-height: 29px !important;}
  .service-inner-category .serv-categ-box .serv-categ-para p {
    font-size: 16px;
    line-height: 29px;
    height: auto;
}
.servce-img-left .vision-image-bg .serv-bg-iconrigt img {
  right: 0px;
  left: 120px;
  top: -27px; }
  .differ-service-sec .left-side-head-before {
    margin-top: 32px;
}
.servleft-img-inner::before {
  top: 44px;
  left: 195px;
}
  .servleft-img-inner img {
    position: relative;
    top: 44px !important;
    left: 16px !important;
  }
  .vision-sec .left-side-head-before {
    margin-top: 82px;
}
    
  .left-side-head-before h2:after{
    width: 9%;
    margin-right: -100%;
    margin-left: 3px;
  }  
  .service-inner-category {
    margin-top: 290px;
}  
.tem-img-overlay {
  height: 219px;
}
.service-inner-category .serv-categ-box{
  margin-bottom: 20px;
  z-index: 999;
  position: relative;
}
  .proof-sec .prrof-img .proof-content-sec .team-content h3 {
    font-size: 19px;}
    .proof-sec .prrof-img .proof-content-sec .team-content h4 {
      font-size: 19px;margin-bottom: 18px;}
      .proof-sec .prrof-img .proof-content-sec .team-content {
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translate(-50%, -30%);
        width: 100%;}
        .servicebtn-prof .btn-name{
          top:4px !important;
        }
        .proof-sec {
          padding: 55px 0px !important;
      }
        .product-sec .short-head-line{
          font-size: 17px !important;
        }
        .product-head{
          .secure-para{
            font-size: 21px !important;
          }
        }
        .product-main .prodct-left{
          margin-right: 0px;
          width: 100%;
        }
        .product-main .prodct-right{
          width: 100%;
          margin-top: 20px;
        }
        .produt-category .product-box{
          padding: 40px 8px;
        }
        .produt-category .product-box .prodct-descp p {
          font-size: 15px !important;}
          .produt-category .product-box .prodct-descp h2 {
            font-size: 16px !important;}
            .produt-category .product-box .prodct-descp {
              padding: 15px 12px 0px 19px;
          }
          .produt-category .product-box .prodct-image img {
            width: auto;
            height: 260px;
        }
            .btn-prodct{}
            .produt-category {
              margin-top: 25px;
              max-width: 430px;
              margin: 0 auto;
          }
            .produt-category .product-box .prodct-descp p{
              height: auto;
            }
            .btn-prodct .btn-inner-sec{
              padding: 6px 13px !important;
            }
            .btn-prodct .btn-inner-sec .btn-name{
              font-size: 14px;
              top: 1px;
              left: -3px;
            }
            .btn-prodct .btn-inner-sec .btn-icon {
              height: 26px;
              width: 26px;
              left: 7px;
          }
          .btn-prodct .btn-inner-sec .btn-icon svg {
            font-size: 16px;top: 1px !important;}
            .btn-prodct .btn-learn {
              float: left;
              margin-right: 6px;
          }
              .produt-category .product-box {
        padding: 30px 8px;
    }
    .footer-sec .footer-left-sec{
      padding: 40px 30px 0px 0px;
    }
    .footer-sec .footer-mid-sec {
      padding: 45px 0px 0px 40px; 
      display: none;}
    .footer-sec .footer-right-sec {
      padding: 40px 55px 0px 55px !important;
    }
    .main-sec .prodt-details h1 {
      font-size: 25px !important;
      line-height: 36px !important;
    }
      .main-innerbanner {
        padding: 40px 0px;
    }
    .footer-sec::before{
      width: 0%;
    }
    .footer-left-sec::before{
      content: "";
      background-color: $bluecolor;
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      transform: translate(-50%, 0%)
    
    }
    .prodt-detail-main .watch-sec{
      font-size: 18px;
      width: 51%;
  
    }
    .prodt-detail-main{
      margin-bottom: 101px;
    }
    .prodt-detail-main .play-sec-prdt {
      width: 27%;
      float: left;
  }
    .video-play-button:before{
      width: 65px;
      height: 65px;
    }
    .video-play-button:after{
      width: 60px;
      height: 60px;
    }
    .video-play-button img{
      left: 32px;
    }
    .video-play-button:before{
      left: 223%;
    }
    .prodtbannerimg img {
      width: 70%;
  }
  .main-innerbanner .prodt-details{
    margin-top: 10px;
  }
  .prodct-margin {
    margin-top: 20px;
}
.keyprocs-sec .keyprocs-content h2 {
  font-size: 21px;
  line-height: 34px;}

  .keyprocs-sec .keyprocs-content p {
    font-size: 16px;
    max-width: 670px;
    line-height: 29px;
}
    .keyprocs-sec .keyprocs-content{
      padding: 55px 0px;
    }
    .detail-carousl-sec .carousl-details-content h2 {
      font-size: 20px;
      z-index: 99999;
      position: relative;
      line-height: 32px;margin-bottom: 15px;}
    .carousel-item img{
      width: 55% !important;
    }
    .detail-carousl-sec .carousl-details-content p{
     font-size: 15px !important;
      font-weight: 500;
      line-height: 32px;
    }
    .detail-carousl-sec {
      padding: 55px 0px 55px 0px;
  }
  .keyprocs-feturesec .keyprocs-heading h2 {
    font-size: 22px;margin-bottom: 20px;}
    .keyprocs-feturesec{
      padding:55px 0px;
    }
    .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec{
      padding:20px 11px;
    }
    .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec img {
      height: 40px;
  }
  .detail-carousl-sec .carousl-details-content{
    margin-top: 52px !important;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec h3{
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 20px;
    height: 33px;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec p{
    font-size: 15px;
    line-height: 28px;
    height: 110px;
  }
  .keyprocs-feturesec .keyfeature-box{
    margin:0px 0px 40px 0px;
  }
  .impact-sec h2{
    font-size: 22px;
  }
  .impact-sec{
    padding: 55px 0px;
  }
  .impact-sec .imapct-inner-sec h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .impact-sec .imapct-inner-sec p {
    font-size: 17px;
    line-height: 26px;
  }
  .impact-sec .imapct-inner-sec .impact-box-center:before{
    left:-49px;
  }
  .impact-sec .imapct-inner-sec .impact-box-center:after{
    right:-50px;
  }
  .impact-sec .imapct-inner-sec{
    margin: 40px auto 20px auto;
  }
  .callactn-sec .callactn-content p {
    font-size: 17px;
        line-height: 29px;
        width: 100%;
        margin-right: 0px;
        max-width:100%;
  }
  .callactn-sec .callactn-content{
    max-width: 100% !important;
    padding: 25px 27px;
  }
  .pre-btnss .prev-main-sec .btn-name{
    font-size: 17px;
  }
  .pre-btnss .next-main-sec .btn-name{
    font-size: 17px;
    cursor: pointer;
  }
  .arrows-container{
    top:7px !important;
  }
  .callactn-sec{
    padding: 55px 0px;
  }
  .detail-carousl-sec .carousal-img .carousel .carousel-indicators button{
    width: 10px;
    height: 10px;
  }
  .carousel-indicators .active{
    height: 10px;
    width: 10px;
  }
  .case-studies-list .case-study-box p{        font-size: 16px !important;
    line-height: 27px;margin-bottom: 17px;}
  .footer-sec .footer-right-sec .copy-sec p{
    font-size: 15px;
    padding: 20px 0px 20px 13px;
    margin-top: 30px;
  }
  .footer-sec .footer-right-sec .email-sec button{
    width: 100%;
    /* left: 8px; */
    position: relative;
    left: 0px;
  }
  .case-studies-list .case-study-box{
    padding: 32px 27px 32px 27px;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-left{
    z-index: 999;
    position: relative;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-left{
    margin-right: 27px;
    width: 100%;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-right{
    width:100%;
    margin-top: 20px;
  }
  .main-innerbanner .main-banner-sec h1 {
    font-size: 23px !important;
    line-height: 40px;
    
}
  .main-detsila-two {
    padding: 60px 0px 60px 0px;
}
.margin-case {
  margin-top: 20px !important;
}
.case-video-sec .case-video-main .case-video-left .case-video-box p {
  font-size: 17px;
  line-height: 26px;
}
.case-video-sec .case-video-main .case-video-left .case-video-box{
  padding: 31px 15px;
  width: 100%;
  left: 1%;
  z-index: 999;
  transform: translate(10%, 47%);
}
.case-video-sec .case-video-main .case-video-left .case-video-box::before{
  height: 65%;
}
.case-video-sec .case-video-main .case-video-right:after{
  width: 100%;
  height: 93%;
  background-color: rgba(0, 0, 0, 0.27);
  left: 0px;
  z-index: 99;
  border-radius: 12px;
  top: 0;
}
.case-video-sec .case-video-main .case-video-right img {
  z-index: 99;
  border-radius: 12px;
  width: 100%;
}
.case-video-sec .case-video-main .case-video-right .play-sec-prdt .video-play-button{
  top: -101px;
  left: -8%;
}
.video-play-button img{
  height: 18px;
}
.case-video-sec .case-video-main .case-video-left{
  width:55%;
}
.case-vieo-inner{
  position: relative;
  left: 1px;
}
.case-video-sec{
  padding: 55px 0px;
}
.busn-sec .business-sec h3{
  font-size: 17px;
  line-height: 30px;
  max-width: 600px;
}
.home-service-sec .business-sec p{
  font-size: 16px !important;
  line-height: 26px;
  max-width: 650px;

}
.busn-main-sec .key-benefits-sec img {
  height: 53px;
}
.busn-main-sec .key-benefits-sec h3 {
  font-size: 17px;
  line-height: 29px;
  max-width: 280px;
}
.busn-main-sec .key-benefits-sec {
  margin-top: 35px;
}
.consult-sec .consult-content p{
  font-size: 19px !important;
  line-height: 34px;
  position: relative;
  top: -4px;
  width: 100%;
  margin-bottom: 18px;

}
.like{
  font-size: 17px !important;
  margin-right: 5px !important;
}
.dislike{
  font-size: 17px !important;
}
.callactn-sec .callactn-content .cal-btn-sec {
  width: 30%;
  float: right;}
.consult-btn .btn-white .btn-name {
  font-size: 15px;
}
.callactn-sec .callactn-content .consult-btn{
  width: 37%;
  float: right;

}
.resol-sec p {
  font-size: 17px;
  line-height: 32px;
  margin: 23px auto 38px auto;
}
.resol-inner-sec .resol-inner-content{
  max-width: 790px !important;
  padding: 31px 25px;
}
.resol-inner-sec .resol-inner-content .resol-head h3 {
  font-size: 17px;
  margin-bottom: 9px;
}
  .resol-inner-sec .resol-inner-content .resol-para p{
    font-size: 15px;
    font-weight: 500;
    line-height: 28px;
    
  }
  .outcome-sec{
    padding: 55px 0px;
  }
  .busn-sec .details-new h2{
    margin: 0px 0px 19px 0px !important;
  }
  .case-bg-icons img {
    position: absolute;
    left: 80px;
    transform: translate(-180%, -50%);
    height: 100px;
}
.consult-sec .consult-content{
  max-width: 860px !important;
 padding: 40px 30px 40px 30px;
}
.outcome-sec h2 {
  font-size: 22px;
}
.outcome-sec p {
  font-size: 18px;
  margin: 14px 0px 15px 0px;}
  .outcome-sec .outcome-order ul li {
    font-size: 15px;
    line-height: 30px;  
    margin: 9px 0px;
  }
  .outcome-sec .outcome-order{
    max-width: 420px;
  }
  .blog-list .blog-inner-sec .blog-category p{
    font-size: 16px;
  }
  .blog-list .blog-inner-sec .blog-category img {
    height: 29px;
    cursor: pointer;
    margin-left: 12px;
    font-weight: 500;
}
.blog-list .container-width-blog{
  max-width: 960px !important;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date{
  width: 40%;
  margin-bottom: 8px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date a img{
  height: 16px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date a span{
  font-size: 15px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p{
  font-size: 16px;
  position: relative;
  top: 3px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category{
  width: 60%;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p::before{
  left:-16px;
  width: 2px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content h2 {
 
  font-size: 16px;
  line-height: 27px;
  font-weight: 600;
  height: auto;
  margin-top: 6px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content p {
  font-size: 15px;
  font-weight: 400;
  line-height: 29px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content{
  padding: 15px 0px 19px 0px; 
}
.margin-blog-top {
  margin-top: 0px;
}
  .rating .like-count {
    margin-right: 6px !important;
  }
  .blog-list .blog-list-inner .blog-new-list {
    padding: 25px 17px 35px 17px;
}
.blog-list .blog-list-inner .blog-list-sec{
  z-index: 9999;
    position: relative;
    margin-bottom: 35px;
}
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-tag-para-new {
    width: 56%;}
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-tag-para p{
    font-size: 16px;
    padding: 12px 10px;
    line-height: 25px;
  }
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date img {
    height: 17px;
  }
  .blog-details-sec .blog-details-inner .blog-detal-content {
    padding: 20px 20px;}
    .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date a {
      font-size: 15px;
    }
      .blog-details-sec .blog-details-inner .blog-detal-content h2 {
        font-size: 17px;
        margin-top: 13px;
        line-height: 27px;
        margin-bottom: 18px;
      }
        .blog-details-sec .blog-details-inner .blog-detal-content p{
          margin-bottom: 15px;
          line-height: 31px;
          font-size: 15px;
        }
        .blog-details-sec .blog-details-inner .blog-detal-content h3 {
          font-size: 16px;
          margin-bottom: 17px;
          line-height: 29px;
      }
      .blog-details-sec .blog-details-inner .blog-detal-content h4 {
        font-size: 16px;}
        .blog-detal-content .rate-admin-details-sec .rating-blgdetals p{
          font-size: 16px !important;
        }
        .blog-details-sec .blog-details-inner .blog-detal-content .admin-sec p span {
          font-size: 16px;}
          .blog-details-sec .blog-details-inner .blog-detal-content .admin-sec p svg{
            font-size: 19px;
          }
          .blog-detl-call{
            .blog-detl-sec{
              p{
                font-size: 16px;
                line-height: 28px;
                margin-right: 2px;
                width: 100%;
              }
            }
          }
          .blog-detl-call .blog-detl-sec .blg-detl-btn .btn-red-sec {
            padding: 6px 23px;
        }
        .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .input-srch {
          width: 75%;}
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .search-icon{
            padding: 8px 9px;
          }
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .search-icon svg{
            font-size: 21px;
          }
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box{
            padding: 12px 0px;
          }
          .input-srch{
            font-size: 15px;
          }
          .blog-details-sec .blog-detail-rights .blog-search p {
            font-size: 16px;}
            .blog-details-sec .blog-detail-rights .blog-search{
              padding: 15px 15px;
              display: none;
            }
            .blog-details-sec .blog-detail-rights .detail-categ p {
              font-size: 17px;        margin-bottom: 10px;}
              .blog-details-sec .blog-detail-rights .detail-categ .categ-list ul li{
                font-size: 14px;
                padding: 6px 10px;
                line-height: 23px;
                width: auto;
              }
              .blog-details-sec .blog-detail-rights .detail-categ{
                padding: 15px 15px;
                display: none;
              }
              .blog-details-sec .blog-detail-rights .recent-post p {
                font-size: 16px;padding-bottom: 0px;}
                .blog-details-sec .blog-detail-rights .recent-post{
                  padding: 15px 15px;
                  margin-top: 15px;
                }
                .blog-details-sec .blog-detail-rights .recent-post ul li img{
                  width:25%;
                  margin-right: 12px;
                }
                .blog-details-sec .blog-detail-rights .recent-post ul li p{
                  width: 68%;
                  font-size: 15px;
                  line-height: 23px;
                  position: relative;
                  top: 1px;
                }
                .job-sec h2 {
                  font-size: 22px;
                  margin-bottom: 13px;}
                    .job-sec{
                      padding: 55px 0px;

                    }
                    .job-details .job-desc-inner .job-desc-left h2:after{
                      width:100%;
                    }
                    .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img {
                      width: 10%;}
                    .job-sec .job-inner-sec{
                      margin: 30px auto;
                      max-width: 890px;
                    }
                    .job-sec .job-inner-sec .job-desc-new h3 {
                      font-size: 17px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc{
                    margin: 7px 0px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left {
                    float: left;
                    margin: 8px 20px 4px 0px;
                }
                .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right {
                  margin-bottom: 3px;}
                  .job-sec .job-inner-sec .job-desc-new{
                    padding: 25px 30px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .jon-loc svg{
                    font-size: 18px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left svg{
                    font-size: 18px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right svg{
                    font-size: 18px;
                  }
                  .apply-btn-cont{
                    font-size: 16px;
                    margin-top: 7px !important;
                    display: block;
                  }
                  .job-sec .job-inner-sec .job-inner-left{
                    z-index: 999;
                    position: relative;
                    width: 100%;
                    margin-bottom: 25px;
                  }
                  .job-sec .job-inner-sec .job-inner-right {
                    width: 100%;}
                  .job-details{
                    padding: 55px 0px;
                  }
                  .job-details .job-desc-inner .job-desc-left h2 {
                    font-size: 17px;
                  }
                  .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text h3 {
                    font-size: 16px;
                    margin-bottom: 8px;
                }
                .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text p{
                  font-size: 15px;
                  line-height: 23px;
                }
                .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img img {
                  height: 31px;
              }
              .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img svg {
                font-size: 26px;
                position: relative;
                top: -2px;
            }
            .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-experience img{
              height: 23px;
            }
            .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-quali img{
              height: 23px;
            }
            .job-details .job-desc-inner .job-desc-left{
              padding: 20px 10px;
            }
            .job-details .job-desc-inner .job-desc-right h2 {
              font-size: 19px;}
              .job-details .job-desc-inner .job-desc-right p{
                line-height: 30px;
                font-size: 15px;
                margin-bottom: 11px;
              }
              .job-details .job-desc-inner .job-desc-right ul li{
                margin-bottom: 10px;
                font-size: 15px;
                line-height: 25px;
              }
              .listcarer li span {
                position: relative;
                top: -2px;
            }
            .job-details .job-desc-inner .job-desc-right{
              margin-left: 10px;
              margin-top: 22px;
            }
            .listcarer li span{
              top:-2px;
            }
            .job-details .job-desc-inner .job-desc-right ul li::marker{
              font-size: 20px;
            }
              .job-details .job-desc-inner .job-desc-right h4{
                font-size: 16px;
                margin: 14px 0px;
              }
              .btn-job-aply{
                margin-top: 5px;
              }
             .or-sec{
              margin: 0px 13px;
             }
             .contact-sec{
              padding: 45px 0px;
             }
             .contact-sec .contact-inner:before{
              width: 100%;
              background-color: transparent;
              box-shadow: none;
             }
             .contact-sec .contact-inner .contact-form h2 {
              font-size: 21px;}
              .new-cont-secss .tooltipss {
                left: 130px !important;}
                .new-cont-secss .tooltipss .fa-solid {
                  font-size: 14px !important;
              }
              .cont-newss p{
                font-size: 11px !important;
              }
              .invalid-feedback .error-msg{
                font-size: 13px !important;
              }
              .contact-sec .contact-inner .contact-info{
                padding: 22px 21px;
                max-width: 100%;
              }
              .form-msg-sec{
                margin-top: 8px !important;
              }
              .eror-contact-new{
                top:5px !important;
              }
              .location-sec h2 {
                font-size: 22px;
              margin-bottom: 25px;
              
            }
            .location-sec{
              padding: 0px !important;
            }
            .location-sec{
              iframe{
                height: 300px !important;
                width: 100%;
              }
            }
            .contact-sec .contact-inner .contact-info h2 {
              font-size: 19px;
            margin-top: 6px;
            }
            .new-cont-secss .tooltipss::after{
              font-size: 13px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p{
              font-size: 15px;
              line-height: 30px;
              margin-bottom: 20px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p svg{
              font-size: 22px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p span {
              margin-left: 14px;}
              .social-icons-contact .social-icon li img {
                height: 16px;top: 2px !important;}
                .social-icons-contact .social-icon li .twit-sec {
                  height: 14px;top: 2px !important;}
                  .social-icons-contact .social-icon li{
                    height: 31px;
                    width:31px;
                  }
                  .infinite-scroll-component p{
                    font-size: 15px;
                    line-height: 32px;
                  }
                  .privacy-policy-sec{
                    padding: 55px 0px;
                  }
                  .infinite-scroll-component h3 {
                    font-size: 19px;
                    margin-bottom: 16px;
                    margin-top: 18px;
                }
                .infinite-scroll-component h4 {
                  font-size: 18px;
              }
              .infinite-scroll-component span{
                font-size: 16px;
              }
              .infinite-scroll-component ul li{
                margin:7px 0px;
                font-size: 16px;
              }
              .infinite-scroll-component h5 {
                font-size: 17px;}
                .new-font-bold {
                  font-size: 18px !important;
              }
              .busn-main-sec .busn-key-head h2 {
                font-size: 22px;
                margin-bottom: 3px;
            }
            .about-innov-main .main-difeer-about{
              .about-innov-para{
                font-size: 15px !important;
                line-height: 29px;        
              }
            }
            .infinite-scroll-component p {
              margin-bottom: 12px;
          }
            .team-sec .team-newssss{
              .meta-patahhh{
                font-size: 16px !important;
                padding: 10px 0px 8px 0px;
              }
            }
            .team-sec .team-category-sec .teamming-sec-hover{
              width:100%;
            }
            .homebanner .banner-sec-content {
              margin-top: 40px;
          }
          .service-home-widg{
            width:100%;
            margin-bottom: 20px;
          }
          .main-sec .banner-sec-content p::before{
            left:-4px;
          }
          .project-box{
            padding: 30px 26px;
            margin-bottom: 20px;
          }
          .vision-sec{
            padding: 55px 0px;
          }
          .projet-category-sec{
            max-width: 420px;
          }
          .footer-sec .footer-right-sec .email-sec .inputemail{
            width:100%;
            font-size: 14px;
          }
          .footer-sec .footer-right-sec ul li::marker{
            font-size: 20px;
          }
          .header-sec {
            padding: 1px 0px;
        }
        .serv-img-inner {
          @include pos-block();
        }
        .imglogbet{
          top:-6px !important;
        }
        .founder-img{
          img{
            height: 290px;
          }
        }
        .team-img-widh{
          width: 50%;
        }
        .team-sec .team-category-sec .team-box .teamimg-sec{
          margin-bottom: 17px;
        }
        .team-sec .team-category-sec .team-box .teamimg-sec::before{
          box-shadow: 3px 4px 6px 0px rgb(0 0 0 / 24%);
        }
        .ps-timeline li .img-time-first img {
          height: 53px !important;
      }
      .ps-timeline li .img-last img {
        height: 53px !important;
    }
    .img-last::before{
      content: none !important;
      background-color: transparent !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .ps-bot p{
      top: -14px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-bot{
      width: 77% !important;
      position:  relative !important;
      margin-top: 0px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-top {
      position: relative;
      bottom: 0px;
      margin-bottom: 0px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    width: 77% !important;}
  .ps-timeline-sec .container ol.ps-timeline li .img-time-first .timedata{
    top: -35px !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-last .timedata{
    top: -35px !important;
  }
  .home-service-sec .service-short-head .short-head-line::before{
    width: 80px;
  }
  .home-service-sec .service-short-head .short-head-line::after{
    width: 80px;
  }
  .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3{
    padding-top: 5px;
  }
  .service-inner-category .serv-categ-box .serv-categ-para p {
    font-size: 15px !important;
    line-height: 28px !important;
    padding: 14px 10px 0px 10px !important;
}
.key-prodt-width{
  width:50%;
}
.keyfeature-inner{
  margin-top:10px;
}
.case-stu-para {
  font-size: 20px !important;
}
.case-studies-list{
  margin-top: 40px !important;
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
}
.blog-list .blog-inner-sec .blog-search{
  top:-1px;
}
.blog-detl-call .blog-detl-sec {
  padding: 32px 15px;}
  .job-sec .job-inner-sec .job-desc-new .jon-loc span{
    font-size: 15px;
  }
  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left span{
    font-size: 15px;
  }
  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right span{
    font-size: 15px;
  }
  .contact-sec .contact-inner {
    padding: 40px 20px 50px 20px;
    max-width: 1100px;
}
.form-sec .MuiBox-root .MuiTextField-root label{
  font-size: 15px !important;
}
.navbar-toggler{
  border:none;
}
.navbar-toggler:focus{
  outline:none !important;
  box-shadow: none !important;
}
body .CookieConsent {
  width: 98% !important;
  padding: 0px 2px !important;
}
body .head-cookie{
  font-size: 19px !important;
 }
 body .para-cookk {
  font-size: 15px !important;
  line-height: 28px !important;
}
body .declinbtn{
  margin:8px 0px !important;
  padding:5px 11px !important;
}
body .accptbtn{
  margin:8px 8px !important;
  padding:6px 11px !important;
}
.callactn-sec .callactn-content .red-button-sec {
  width: 100%;
  float: left;
  text-align: left; 
  margin-top: 17px;
}

// for menu bar code start here

.navTrigger {
  width: 29px;
  height: 25px;
  margin: auto;
  position: fixed;
  right: 30px;
  top: 28px;
  cursor: pointer;
}
.navTrigger i {
background-color: #484848;
border-radius: 2px;
content: '';
display: block;
width: 100%;
height: 2px;
}
.navTrigger i:nth-child(1) {
// -webkit-animation: outT 0.8s backwards;
// animation: outT 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}
.navTrigger i:nth-child(2) {
margin: 5px 0;
// -webkit-animation: outM 0.8s backwards;
// animation: outM 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}
.navTrigger i:nth-child(3) {
// -webkit-animation: outBtm 0.8s backwards;
// animation: outBtm 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}

.navbar-collapse{
  box-shadow:0 2px 6px rgba(0, 0, 0, 0.161);
  /* margin-bottom: -6px; */
  position: fixed !important;
  width: 100%;
  z-index: 999;
  display: block;
  position: fixed;
  top: 75px;
  background-color: $whitecolor;
  float: right;
  right: 0px;

}
.activeheader{
  background-color: $whitecolor;
}

.nav-link-menu::before{
  opacity: 0;
}
.navbar-expand-lg .navbar-nav{
  margin:0px !important;
}
.navTrigger {
  cursor: pointer;
  width: 30px;
  height: 25px;
  margin: auto;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
}
.scrolled .navbar-collapse{top:77px}
.navTrigger i {
  background-color: $textblackcolor;
  border-radius: 2px;
  content: '';
  display: block;
  width: 100%;
  height: 3px;
}



.navTrigger.active i:nth-child(1) {
  -webkit-animation: inT 0.8s forwards;
  animation: inT 0.8s forwards;
}

.navTrigger.active i:nth-child(2) {
  -webkit-animation: inM 0.8s forwards;
  animation: inM 0.8s forwards;
}

.navTrigger.active i:nth-child(3) {
  -webkit-animation: inBtm 0.8s forwards;
  animation: inBtm 0.8s forwards;
  visibility: hidden;
}

@-webkit-keyframes inM {
  50% {
      -webkit-transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(45deg);
  }
}

@-webkit-keyframes outM {
  50% {
      -webkit-transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(45deg);
  }
}

@-webkit-keyframes inT {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(9px) rotate(0deg);
  }
  100% {
      transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes outT {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(9px) rotate(0deg);
  }
  100% {
      transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes inBtm {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(-9px) rotate(0deg);
  }
  100% {
      transform: translateY(-9px) rotate(135deg);
  }
}

@-webkit-keyframes outBtm {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(-9px) rotate(0deg);
  }
  100% {
      transform: translateY(-9px) rotate(135deg);
  }
}


// new menu bar code start here

.menuToggle {
  display: block;
  position: relative;
  top: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  a {
    text-decoration: none;
    color: #232323;
    transition: all 0.3s ease;
    &:hover {
      color: tomato;
    }
  }
  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }
  span {
    position: relative;
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }
}

.menuToggle {
  input {
    &:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #232323;
      &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }
      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
      }
    }
    &:checked ~ .menuItem {
      transform: none;
    }
    
  }
}

.menuItem {
  position: absolute;
  width: 300px;
  padding: 50px;
  padding-top: 125px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  li {
    padding: 10px 0;
    font-size: 22px;
  }
}


.menu--right {
  .menuToggle {
    position: fixed;
    right: 0;
    input {
      right: 50px;
    }
    span {
      right: 50px;
    }
  }
  .menuItem {
    right: 0;
    margin: -100px 0 0 0;
    transform: translate(100%, 0);
    li {
      a {
        &:hover {
          padding-left: 5px;
        }
      }
    }
  }
}

.job-details .job-desc-inner .job-desc-right h3 {
  font-size: 16px;
  line-height: 27px;}
  .footer-sec .footer-left-sec .map-contect ul{
    margin-bottom: 30px;
  }
  .footer-right-sec{
    .email-sec{
      form{
        width:90%;
      }
    }
  }
  .footer-right-sec{
    h3{
      width:60%;
    }
    .email-sec{
      width:60%;
      float: left;
    }
    ul{
      width:40% !important;
      margin-top: 0px !important;
    }
  }
  .ps-timeline-sec .container ol.ps-timeline li{
    width:100% !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li {
    height: 100%;
    float: none !important;
    margin-bottom: 60px;
  }
  .ps-timeline{
    display: inline-block !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-sp-top:before{
    width: 2px;
    height: 43px;
    position: absolute;
    top: 47px;
    left: 71%;
  }
  .ps-timeline-sec .container ol.ps-timeline{
    margin: 0px 0px !important;
    padding-top: 70px !important;
  }
  .margin-catg-top {
    margin-top: 0px;
}
.vision-sec{
  .service-inner-category{
    margin-top: 30px !important;
  }
}
.prodtbannerimg{
  margin-top: 40px;
}
.main-innerbanner .prodt-details h1{
margin-bottom: 33px !important;
}
.width-impact-res{
width: 33.33% !important;
float: left;
}
.produt-category .product-box::before{
  height: 88%;
}
.absentcase{
  margin-bottom: 40px;
  .serv-img-inner img{
    position: relative;
    left: -35px;
  }
}
.absentcase .service-img-right::after{
  left:92px;
}
.prodt-modl-news .MuiDialog-container h2{
  font-size: 21px;
}
.prodt-modl-news .MuiDialog-container .MuiDialogContent-root{
  padding: 10px 40px !important;
}
.blog-detl-call {
  padding: 20px 0px 10px 0px;
}

.respon-filter-sec{
  display: block !important;
}
 .blog-details-sec{
padding: 35px 0px 0px 0px;
}
.social-icons-contact .social-icon{
  padding-top: 0px;
  padding-bottom: 20px;
}
.contact-sec .contact-inner .contact-form{
  box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px;
  background-color: $whitecolor;
  border-radius: 12px;
  padding: 34px 25px 34px 25px;
 
}
.footer-sec .container{
  max-width: 100%;
  padding: 0px 0px;
}
.footer-main{
  padding-right: 0px;
}
.footer-sec .footer-left-sec {
  padding: 40px 55px 0px 55px !important;
}


 }


 @media only screen and (min-width:700px) and (max-width: 767px) {
  .container{
    max-width: 610px;
  }
  .footer-left-sec::before{
    width:100%;
  }
  .footer-sec .footer-right-sec {
    padding: 40px 75px 0px 75px !important;
  }
  .footer-sec .footer-left-sec {
    padding: 40px 75px 0px 75px !important;
  }
  .vision-image::before {
    top: 14px;
    left: 154px !important;}
    .serv-img-inner img{
      left: 155px !important;
    }
    .serv-img-inner::before{
      left: 160px !important;
    }
    .service-img-right::after{
      left: 142px !important;
    }
    .servleft-img-inner img{
      left:-15px !important;
    }
 }

 @media only screen and (min-width: 576px) and (max-width: 639px){
  .toolcontent::after {
    width: 267px !important;
    bottom: -30px !important;
}
  .new-cont-secss .tooltipss::after {
    padding: 14px 10px !important;
}
  .footer-sec .footer-right-sec ul li:last-child {
    margin-left: 0px !important;
  }
 body .modl-blog-widh .MuiDialog-container .MuiDialogContent-root {
    padding: 10px 30px !important;
}
.modl-blog-widh .MuiDialog-scrollPaper .MuiPaper-elevation .closebew-icon {
  width: 39px;
  height: 40px;
}
  .modalblog-categ .blog-detail-rights .detail-categ{display: block !important;box-shadow: none;margin-top: 0px !important;
    padding: 0px 0px !important;}
  .modalblog-categ .blog-detail-rights .detail-categ{        display: block !important;
    box-shadow: none;
    margin-bottom: 18px;}
  .modalblog-categ .blog-detail-rights .detail-categ p{
    font-size: 21px !important;
    text-align: center;
    
  }
  .res-modl-blog .MuiDialog-container .MuiPaper-root {
    max-width: 560px !important;
}
  .modalblog-categ .blog-detail-rights .detail-categ p:after{
    background-color: $whitecolor !important;
  }
  .modalblog-categ{
    padding: 20px 0px 0px 0px !important;
  }
  .uplad-sec-new  h2 {
    font-size: 18px !important;
}
  .nav-link-menu.active::before{
    opacity: 1;
  }
  .ps-timeline-sec .container ol.ps-timeline li:last-child{
    height: 50px !important;
  }
  .blog-details-sec .blog-detail-rights .detail-categ .categ-list ul{
    margin-bottom: 0px;
  }
  .error-image {
    height: 200px !important;
}
.error-page{
  padding-top: 55px !important;
}
.error-page .error-page-head{
  font-size: 24px !important;
}
.error-page .error-page-para{
  font-size: 18px !important;
}
  .key-main-secss .differ-service-sec{
    padding: 0px 0px !important;
  }
  .error-page .error-page-head{
    font-size: 26px;
  }
  .error-page .error-page-para{
    font-size: 19px;
  }
  .key-main-secss .new-case-add {
    padding: 0px 0px 10px 0px !important;
  }
  .error-page-banner {
    height: 88vh;}
  .key-main-secss .new-case-add p {
    font-size: 17px !important;
    line-height: 31px !important;  
    margin-top: 15px !important;
  }
  .navbar-brand img {
    height: 48px;
        position: relative;
        top: 7px;
        width:auto;
  }
  .nav-link-menu{
    font-size: 15px;
    padding: 0px 22px;
    width: 100%;
    margin: 0px 0px !important;
    text-align: left;
  }
  .main-sec .banner-sec-content p{
    font-size: 16px;
    line-height: 33px;
    margin-bottom: 21px;
   
  }
  .main-sec .banner-sec-content h1 {
    font-size: 25px;
    line-height: 44px;
   
  }
  .mainbaner-img{
    margin:30px 0px 2px 0px;
  }
  .main-sec .banner-sec-content h2 {
    font-size: 25px;
    line-height: 37px;
    margin-top: 9px;
   
  }
  .mainbaner-img img {
    height: 280px;
}
  .homebanner {
    padding:20px 0px 50px 0px;;
  }
  .bg-icon-service img{
    height: 100px;
  }
  .home-service-sec .service-short-head .short-head-line{
    font-size: 15px !important;
  }

  .home-service-sec .service-short-head h2 {
    font-size: 21px;
    line-height: 34px;
  }
  .home-service-sec .service-short-head p {
    font-size: 17px !important;
    line-height: 27px;
  }
  .home-service-sec .service-short-head h2{
    margin: 20px 0px 12px 0px;
  }
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle{
    width: 60px;
    height: 60px;

  }
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle img {
    height: 36px;
    position: relative;
    top: 12px;
  }
  .bg-icon-belowservice img{
    height: 100px;
  }
  .service-category .service-box{
    padding: 24px 20px 34px 20px;
  }
  .service-category .service-box .service-inner .service-icon .icon-sec{
    width: 13%;
    margin-right: 19px;
  }
  .service-category .service-box .service-inner .service-icon .service-para-main {
    width: 82%;}
    .home-service-sec{
      padding: 55px 0px;
    }
  .service-category .service-box .service-inner .service-icon .service-para-main h3 {
    font-size: 18px;
    font-weight: 600;
    position: relative;
    top: 21px;
    left: -5px;
  }
  .service-category{
    padding: 35px 0px 0px 0px;
    width: 100%;
        max-width: 630px;
        margin: 0 auto;
  }
  .service-box  .service-inner .service-content {
    p{
      font-size: 15px;
      line-height: 32px;
    }
  }
  .service-category .service-box{
    z-index: 999;
  }
  .service-category .service-box .service-inner .service-content{
    margin-top: 5px;
    height: auto;
  }
  .btn-inner-sec .btn-name{
    font-size: 15px;
    top: 3px;
  }
  .btn-inner-sec{
    padding: 5px 12px;
  }
  .btn-inner-sec .btn-icon {
    height: 28px;
    width: 28px;
    left: 7px;}
    .btn-inner-sec .btn-icon svg{
      top: 2px !important;
      font-size: 17px;
    }
    .load-btn {
      margin: 10px 0px 0px 0px;}
    .consult-btn .btn-white{
      padding: 8px 14px;
    }
    .project-box p {
      line-height: 38px;
      font-size: 18px;
    
    }
    .project-box-blue{
      z-index: 999;
    }
    .bg-icon-belowprojt img{
      height: 100px;
    }
    .projet-category-sec::before{
      top: -242px;
      left: -49%;
      width: 100%;
      height: 100%;
      background-size: 53px;
    }
    .vision-image-bg .bg-icon-belowservicess img {
      right: 10px;
      height: 100px;
    }
    .vision-sec .vision-content h2{
      font-size: 21px;
    }
    .vision-sec .vision-content p{
      font-size: 17px;
      line-height: 31px;
      margin-bottom: 30px;
    }
    .case-privacy{
      font-size: 15px;
    }
    .vision-image::before{
      top: 14px;
      left: 123px;
      width: 240px;
      height: 225px;
    }
    .vision-image img{
      height: 234px;
    }
    .vision-sec .vision-image-bg .bg-icon-belowservicess img {
      right: -14px;
      height: 100px;
      top: -205px;
  }
  .vision-sec .vision-content{
    margin-top: 20px;
  }
  .footer-sec .footer-left-sec img{
    height: 54px;
    z-index: 999;
    position: relative;
  }
  .footer-sec .footer-left-sec .social-icon li img {
    height: 15px;}
    .footer-sec .footer-left-sec .social-icon li .twit-sec {
      height: 14px;}
      .footer-sec .footer-left-sec .social-icon li{
        width:30px;
        height: 30px;
      }
      .footer-sec .footer-left-sec .map-sec p{
        font-size: 15px;
      }
      .footer-sec .footer-left-sec .map-sec img{
        height: 22px;
        margin-right: 8px;
        float: left;
        top: 4px;
        position: relative;
      }
      .footer-sec .footer-left-sec .map-contect ul li p{
        font-size: 15px;
      }
      .footer-sec .footer-left-sec .map-contect ul li svg{
        font-size: 18px;
      }
      .map-contect ul li:first-child{
        margin-bottom: 10px;
      }
      .footer-sec .footer-mid-sec ul li{
        font-size: 15px;
        line-height: 38px;
      }
      .footer-sec .footer-mid-sec h3 {
        font-size: 16px;}
        .footer-sec .footer-right-sec h3 {
          font-size: 17px;}
          .footer-sec .footer-right-sec p {
            font-size: 15px;}
            .footer-sec .footer-right-sec{
              padding: 60px 0px 0px 11px;
            }
            .footer-sec .footer-right-sec .email-sec button{
              left:57%;
            }
            .footer-sec .footer-right-sec .email-sec .email-btn .btn-inner-sec {
              padding: 7px 10px;
          }
          .footer-sec .footer-right-sec ul li{
            font-size: 15px;
            width: 100%;
            margin-bottom: 7px;
          }
          .email-btn{
            width: 100%;
            position: absolute;
            right: 0px;
            text-align: right;
            top: -44px;
          }
         
          .footer-sec .footer-right-sec ul li:last-child {
            margin-left: 0px;
        }
        .footer-sec .footer-right-sec ul li a{
          left:-6px;
        }
        .background-video{
          width: 100%;
          height: auto;
        }
        .video-container{
          height: 35vh;
        }
        .about-main{
          height: 35vh;
        }
        .about-main .abut-banner-cont h1 {
          font-size: 25px;}
          .about-main .abut-banner-cont p{
            font-size: 15px;
            line-height: 27px;
            width: 100%;
            max-width: 610px;
            margin: 10px auto 0px auto;
          }
          .about-main .abut-banner-cont{
            bottom: 4vh;
          }
          .about-innov-main .about-innov-sec .about-innov-para {
            font-size: 17px;
            line-height: 32px;}
            .founder-sec-cont h2{
              font-size: 21px;
              line-height: 33px;
            }
            .founder-sec-cont p{
              font-size: 15px;
              line-height: 31px;
              margin: 17px 0px;
              text-align: justify;
            }
            .founder-sec-cont h3{
              font-size: 16px;
            }
            .founder-sec-cont h4{
              font-size: 16px;
            }
            .founder-sec-cont .book-sec{
              font-size: 16px !important;
            }
            .about-main .abut-banner-cont{
              width: 100%;
              max-width: 630px;
              transform: translate(-50%, -68%);
            }
            .book-sec span{
              display: block;
              margin: 4px 0px 0px 0px;
              img{
                margin-left: 0px !important;
                height: 19px !important;
                .book-label{
                  font-size: 18px !important;
                  display: inline-block !important;
                }
              }
            }
            .book-sec .book-label{
              display: inline-block !important;
              font-size: 17px !important;
            }
            .founder-sec{
              padding: 55px 0px;
              font-size: 18px !important;
            }

            .team-sec-content{
              .meet-pata{
                font-size: 17px !important;
              }
            }
            .team-sec .team-category-sec{
              padding: 30px 60px 10px 60px;
            }
            .team-sec .team-category-sec .teamming-sec-hover h5{
              font-size: 33px;
            }
            .team-sec .team-category-sec .teamming-sec-hover h6 {
              font-size: 16px;}
            .team-sec .team-category-sec .team-box h3 {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box h4 {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box p {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box{
              padding: 20px 0px;
            }
            .ps-timeline-sec .container ol.ps-timeline li{
              width:13.4%;
            }
            .ps-timeline{
                li{
                  .img-time {
                    img{
                      height: 49px !important;
                    }
                  }
                }
            }
            .ps-timeline-sec .container ol.ps-timeline {
              margin:170px -13px 140px -13px;}
            .ps-timeline-sec .container ol.ps-timeline li p{
              font-size: 16px;
              position: relative;
              top: -4px;
              left: 17px;
            }
            .team-sec .team-category-sec .team-box .teamimg-sec img {
              height: 147px;}
            .img-time-first {
              top: -28px;
              img{
                height: 63px !important;
              }
          }
          .img-last {
            top: -28px;
            img{
              height: 64px !important;
              top: -3px;
              position: relative;
            }
        }
        .img-time  .timedata{
          top: -35px !important;
        }
        .ps-timeline-sec .container ol.ps-timeline li .ps-sp-bot:before{
          bottom: -23px !important;
        }
        .main-sec .new-img-baner p {
          font-size: 18px !important;
          line-height: 34px !important;
          margin-top: 11px !important;
          margin-bottom: 22px !important;
      }
      .new-banr-img img {
        height: 270px;
    }
    .new-img-baner {
      margin-top: 20px;
  }
  .innerbannerimg {
    padding: 60px 0px 50px 0px;
}
.secure-main .secure-sec .serice-nee-fix{
  font-size: 17px !important;
  line-height: 33px;
  width: 100%;
  max-width: 810px;
  margin: 0 auto;
  text-align: center;
  margin-top: 28px;
}
.left-side-head-before h2 {
  font-size: 21px;
  line-height: 35px;
}
.differ-service-sec .differ-service-inner p {
  font-size: 17px;
  line-height: 33px;
  margin-top: 15px;
}
.keyprocs-sec .keyprocs-content h2{
  margin-bottom: 20px;
}
body .service-modl-new .MuiDialog-container h2 {
  font-size: 20px;
  padding: 20px 37px 4px 37px !important;
}
.serv-img-inner::before{
  top: 40px;
  left: 110px;
  width: 210px;
  height: 209px;
}
.serv-img-inner img {
  position: absolute;
  top: 40px;
  left: 85px;
  border-radius: 5px;
  height: 210px;
}
.service-img-right::after{
  top: 26px;
  left: 72px;
  width: 220px;
  height: 208px;
}
.servce-img-left::after {
  top: 31px !important;
  left: 139px !important;}
.vision-image-bg .serv-bg-iconrigt img {
  right: 160px;  }
  .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-icon img {
    height: 44px;
}
.service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3 {
  font-size: 18px;
  height: auto;
  line-height: 29px !important;}
  .service-inner-category .serv-categ-box .serv-categ-para p {
    font-size: 16px;
    line-height: 29px;
    height: auto;
}
.servce-img-left .vision-image-bg .serv-bg-iconrigt img {
  right: 0px;
  left: 120px;
  top: -27px; }
  .differ-service-sec .left-side-head-before {
    margin-top: 12px;
}
.videoplay-modl .MuiDialog-container .MuiPaper-root {
  max-width: 480px !important;
}
.servleft-img-inner::before {
  top: 44px;
  left: 195px;
}
  .servleft-img-inner img {
    position: relative;
    top: 44px !important;
    left: 16px !important;
  }
  .vision-sec .left-side-head-before {
    margin-top: 82px;
}
    
  .left-side-head-before h2:after{
    width: 9%;
    margin-right: -100%;
    margin-left: 3px;
  }  
  .service-inner-category {
    margin-top: 290px;
}  
.tem-img-overlay {
  height: 219px;
}
.service-inner-category .serv-categ-box{
  margin-bottom: 20px;
  z-index: 999;
  position: relative;
}
  .proof-sec .prrof-img .proof-content-sec .team-content h3 {
    font-size: 19px;}
    .proof-sec .prrof-img .proof-content-sec .team-content h4 {
      font-size: 19px;margin-bottom: 18px;}
      .proof-sec .prrof-img .proof-content-sec .team-content {
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translate(-50%, -30%);
        width: 100%;}
        .servicebtn-prof .btn-name{
          top: 3px !important;
        }
        .proof-sec {
          padding: 55px 0px !important;
      }
        .product-sec .short-head-line{
          font-size: 17px !important;
        }
        .product-head{
          .secure-para{
            font-size: 21px !important;
          }
        }
        .product-main .prodct-left{
          margin-right: 0px;
          width: 100%;
        }
        .product-main .prodct-right{
          width: 100%;
          margin-top: 20px;
        }
        .produt-category .product-box{
          padding: 40px 8px;
        }
        .produt-category .product-box .prodct-descp p {
          font-size: 15px !important;}
          .produt-category .product-box .prodct-descp h2 {
            font-size: 16px !important;}
            .produt-category .product-box .prodct-descp {
              padding: 15px 12px 0px 19px;
          }
          .produt-category .product-box .prodct-image img {
            width: auto;
            height: 260px;
        }
            .btn-prodct{}
            .produt-category {
              margin-top: 25px;
              max-width: 430px;
              margin: 0 auto;
          }
            .produt-category .product-box .prodct-descp p{
              height: auto;
            }
            .btn-prodct .btn-inner-sec{
              padding: 6px 13px !important;
            }
            .btn-prodct .btn-inner-sec .btn-name{
              font-size: 14px;
              top: 1px;
              left: -3px;
            }
            .btn-prodct .btn-inner-sec .btn-icon {
              height: 26px;
              width: 26px;
              left: 7px;
          }
          .btn-prodct .btn-inner-sec .btn-icon svg {
            font-size: 16px;top: 1px !important;}
            .btn-prodct .btn-learn {
              float: left;
              margin-right: 6px;
          }
              .produt-category .product-box {
        padding: 30px 8px;
    }
    .footer-sec .footer-left-sec{
      padding: 40px 30px 0px 0px;
    }
    .footer-sec .footer-mid-sec {
      padding: 45px 0px 0px 40px; 
      display: none;}
    .footer-sec .footer-right-sec {
      padding: 40px 55px 0px 55px !important;
    }
    .main-sec .prodt-details h1 {
      font-size: 25px !important;
      line-height: 36px !important;
    }
      .main-innerbanner {
        padding: 40px 0px;
    }
    .footer-sec::before{
      width: 0%;
    }
    .footer-left-sec::before{
      content: "";
      background-color: $bluecolor;
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      transform: translate(-50%, 0%)
    
    }
    .prodt-detail-main .watch-sec{
      font-size: 18px;
      width: 51%;
  
    }
    .prodt-detail-main{
      margin-bottom: 101px;
    }
    .prodt-detail-main .play-sec-prdt {
      width: 27%;
      float: left;
  }
    .video-play-button:before{
      width: 65px;
      height: 65px;
    }
    .video-play-button:after{
      width: 60px;
      height: 60px;
    }
    .video-play-button img{
      left: 32px;
    }
    .video-play-button:before{
      left: 223%;
    }
    .prodtbannerimg img {
      width: 70%;
  }
  .main-innerbanner .prodt-details{
    margin-top: 10px;
  }
  .prodct-margin {
    margin-top: 20px;
}
.keyprocs-sec .keyprocs-content h2 {
  font-size: 21px;
  line-height: 34px;}

  .keyprocs-sec .keyprocs-content p {
    font-size: 16px;
    max-width: 670px;
    line-height: 29px;
}
    .keyprocs-sec .keyprocs-content{
      padding: 55px 0px;
    }
    .detail-carousl-sec .carousl-details-content h2 {
      font-size: 20px;
      z-index: 99999;
      position: relative;
      line-height: 32px;margin-bottom: 15px;}
    .carousel-item img{
      width: 55% !important;
    }
    .detail-carousl-sec .carousl-details-content p{
     font-size: 15px !important;
      font-weight: 500;
      line-height: 32px;
    }
    .detail-carousl-sec {
      padding: 55px 0px 55px 0px;
  }
  .keyprocs-feturesec .keyprocs-heading h2 {
    font-size: 21px;margin-bottom: 20px;}
    .keyprocs-feturesec{
      padding:55px 0px;
    }
    .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec{
      padding:20px 11px;
    }
    .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec img {
      height: 40px;
  }
  .detail-carousl-sec .carousl-details-content{
    margin-top: 52px !important;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec h3{
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 20px;
    height: 33px;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec p{
    font-size: 15px;
    line-height: 28px;
    height: 110px;
  }
  .keyprocs-feturesec .keyfeature-box{
    margin:0px 0px 40px 0px;
  }
  .impact-sec h2{
    font-size: 21px;
  }
  .impact-sec{
    padding: 55px 0px;
  }
  .impact-sec .imapct-inner-sec h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .impact-sec .imapct-inner-sec p {
    font-size: 17px;
    line-height: 26px;
  }
  .impact-sec .imapct-inner-sec .impact-box-center:before{
    left:-49px;
  }
  .impact-sec .imapct-inner-sec .impact-box-center:after{
    right:-50px;
  }
  .impact-sec .imapct-inner-sec{
    margin: 40px auto 20px auto;
  }
  .callactn-sec .callactn-content p {
    font-size: 17px;
        line-height: 29px;
        width: 100%;
        margin-right: 0px;
        max-width:100%;
  }
  .callactn-sec .callactn-content{
    max-width: 100% !important;
    padding: 25px 27px;
  }
  .pre-btnss .prev-main-sec .btn-name{
    font-size: 17px;
  }
  .pre-btnss .next-main-sec .btn-name{
    font-size: 17px;
    cursor: pointer;
  }
  .arrows-container{
    top:7px !important;
  }
  .callactn-sec{
    padding: 55px 0px;
  }
  .detail-carousl-sec .carousal-img .carousel .carousel-indicators button{
    width: 10px;
    height: 10px;
  }
  .carousel-indicators .active{
    height: 10px;
    width: 10px;
  }
  .case-studies-list .case-study-box p{        font-size: 16px !important;
    line-height: 27px;margin-bottom: 17px;}
  .footer-sec .footer-right-sec .copy-sec p{
    font-size: 15px;
    padding: 20px 0px 20px 13px;
    margin-top: 30px;
  }
  .footer-sec .footer-right-sec .email-sec button{
    width: 100%;
    /* left: 8px; */
    position: relative;
    left: 0px;
  }
  .case-studies-list .case-study-box{
    padding: 32px 27px 32px 27px;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-left{
    z-index: 999;
    position: relative;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-left{
    margin-right: 27px;
    width: 100%;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-right{
    width:100%;
    margin-top: 20px;
  }
  .main-innerbanner .main-banner-sec h1 {
    font-size: 23px !important;
    line-height: 40px;
    
}
  .main-detsila-two {
    padding: 60px 0px 60px 0px;
}
.margin-case {
  margin-top: 20px !important;
}
.case-video-sec .case-video-main .case-video-left .case-video-box p {
  font-size: 17px;
  line-height: 26px;
}
.case-video-sec .case-video-main .case-video-left .case-video-box{
  padding: 31px 15px;
  width: 100%;
  left: 1%;
  z-index: 999;
  transform: translate(10%, 47%);
}
.case-video-sec .case-video-main .case-video-left .case-video-box::before{
  height: 65%;
}
.case-video-sec .case-video-main .case-video-right:after{
  width: 100%;
  height: 93%;
  background-color: rgba(0, 0, 0, 0.27);
  left: 0px;
  z-index: 99;
  border-radius: 12px;
  top: 0;
}
.case-video-sec .case-video-main .case-video-right img {
  z-index: 99;
  border-radius: 12px;
  width: 100%;
}
.case-video-sec .case-video-main .case-video-right .play-sec-prdt .video-play-button{
  top: -101px;
  left: -8%;
}
.video-play-button img{
  height: 18px;
}
.case-video-sec .case-video-main .case-video-left{
  width:55%;
}
.case-vieo-inner{
  position: relative;
  left: 1px;
}
.case-video-sec{
  padding: 55px 0px;
}
.busn-sec .business-sec h3{
  font-size: 17px;
  line-height: 30px;
  max-width: 600px;
}
.home-service-sec .business-sec p{
  font-size: 15px !important;
  line-height: 28px;
  max-width: 650px;

}
.busn-main-sec .key-benefits-sec img {
  height: 53px;
}
.busn-main-sec .key-benefits-sec h3 {
  font-size: 16px;
  line-height: 25px;
  max-width: 270px;
}
.busn-main-sec .key-benefits-sec {
  margin-top: 30px;
}
.consult-sec .consult-content p{
  font-size: 18px !important;
  line-height: 34px;
  position: relative;
  top: -4px;
  width: 100%;
  margin-bottom:9px;

}
.like{
  font-size: 17px !important;
  margin-right: 5px !important;
}
.dislike{
  font-size: 17px !important;
}
.callactn-sec .callactn-content .cal-btn-sec {
  width: 30%;
  float: right;}
.consult-btn .btn-white .btn-name {
  font-size: 15px;
}
.callactn-sec .callactn-content .consult-btn{
  width: 37%;
  float: right;

}
.resol-sec p {
  font-size: 18px;
        line-height: 30px;
  margin: 23px auto 38px auto;
}
.resol-inner-sec .resol-inner-content{
  max-width: 790px !important;
  padding: 31px 25px;
  margin-bottom: 15px;
}
.resol-inner-sec .resol-inner-content .resol-head h3 {
  font-size: 17px;
  margin-bottom: 9px;
}
  .resol-inner-sec .resol-inner-content .resol-para p{
    font-size: 15px;
    font-weight: 500;
    line-height: 28px;
    
  }
  .outcome-sec{
    padding: 55px 0px;
  }
  .busn-sec .details-new h2{
    margin: 0px 0px 19px 0px !important;
  }
  .case-bg-icons img {
    position: absolute;
    left: 80px;
    transform: translate(-180%, -50%);
    height: 100px;
}
.consult-sec .consult-content{
  max-width: 860px !important;
  padding: 30px 30px 30px 30px;
}
.outcome-sec h2 {
  font-size: 22px;
}
.outcome-sec p {
  font-size: 18px;
  margin: 14px 0px 15px 0px;}
  .outcome-sec .outcome-order ul li {
    font-size: 15px;
    line-height: 30px;  
    margin: 9px 0px;
  }
  .outcome-sec .outcome-order{
    max-width: 420px;
  }
  .blog-list .blog-inner-sec .blog-category p{
    font-size: 16px;
  }
  .blog-list .blog-inner-sec .blog-category img {
    height: 29px;
    cursor: pointer;
    margin-left: 12px;
    font-weight: 500;
}
.blog-list .container-width-blog{
  max-width: 960px !important;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date{
  width: 40%;
  margin-bottom: 8px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date a img{
  height: 16px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date a span{
  font-size: 15px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p{
  font-size: 16px;
  position: relative;
  top: 3px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category{
  width: 60%;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p::before{
  left:-16px;
  width: 2px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content h2 {
 
  font-size: 16px;
  line-height: 27px;
  font-weight: 600;
  height: auto;
  margin-top: 6px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content p {
  font-size: 15px;
  font-weight: 400;
  line-height: 29px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content{
  padding: 15px 0px 19px 0px; 
}
.margin-blog-top {
  margin-top: 0px;
}
  .rating .like-count {
    margin-right: 6px !important;
  }
  .blog-list .blog-list-inner .blog-new-list {
    padding: 25px 17px 35px 17px;
}
.blog-list .blog-list-inner .blog-list-sec{
  z-index: 9999;
    position: relative;
    margin-bottom: 30px;
}
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-tag-para-new {
    width: 56%;}
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-tag-para p{
    font-size: 15px;
    padding: 12px 10px;
    line-height: 25px;
  }
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date img {
    height: 17px;
  }
  .blog-details-sec .blog-details-inner .blog-detal-content {
    padding: 10px 20px;}
    .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date a {
      font-size: 15px;
    }
      .blog-details-sec .blog-details-inner .blog-detal-content h2 {
        font-size: 17px;
        margin-top: 13px;
        line-height: 27px;
        margin-bottom: 18px;
      }
        .blog-details-sec .blog-details-inner .blog-detal-content p{
          margin-bottom: 15px;
          line-height: 31px;
          font-size: 15px;
        }
        .blog-details-sec .blog-details-inner .blog-detal-content h3 {
          font-size: 16px;
          margin-bottom: 17px;
          line-height: 29px;
      }
      .blog-details-sec .blog-details-inner .blog-detal-content h4 {
        font-size: 16px;}
        .blog-detal-content .rate-admin-details-sec .rating-blgdetals p{
          font-size: 16px !important;
        }
        .blog-details-sec .blog-details-inner .blog-detal-content .admin-sec p span {
          font-size: 16px;}
          .blog-details-sec .blog-details-inner .blog-detal-content .admin-sec p svg{
            font-size: 19px;
          }
          .blog-detl-call{
            .blog-detl-sec{
              p{
                font-size: 16px;
                line-height: 28px;
                margin-right: 2px;
                width: 100%;
              }
            }
          }
          .blog-detl-call .blog-detl-sec .blg-detl-btn .btn-red-sec {
            padding: 6px 17px;
        }
        .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .input-srch {
          width: 75%;}
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .search-icon{
            padding: 8px 9px;
          }
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .search-icon svg{
            font-size: 21px;
          }
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box{
            padding: 12px 0px;
          }
          .input-srch{
            font-size: 15px;
          }
          .blog-details-sec .blog-detail-rights .blog-search p {
            font-size: 16px;}
            .blog-details-sec .blog-detail-rights .blog-search{
              padding: 15px 15px;
              display: none;
            }
            .blog-details-sec .blog-detail-rights .detail-categ p {
              font-size: 20px !important;        
              margin-bottom: 10px;}
              .blog-details-sec .blog-detail-rights .detail-categ .categ-list ul li{
                font-size: 14px;
                padding: 6px 10px;
                line-height: 23px;
                width: auto;
              }
              .blog-details-sec .blog-detail-rights .detail-categ{
                padding: 15px 15px;
                display: none;
              }
              .blog-details-sec .blog-detail-rights .recent-post p {
                font-size: 17px;padding-bottom: 0px;}
                .blog-details-sec .blog-detail-rights .recent-post{
                  padding: 15px 15px;
                  margin-top: 15px;
                }
                .blog-details-sec .blog-detail-rights .recent-post ul li img{
                  width:25%;
                  margin-right: 12px;
                }
                .blog-details-sec .blog-detail-rights .recent-post ul li p{
                  width: 68%;
                  font-size: 15px;
                  line-height: 23px;
                  position: relative;
                  top: 2px;
                }
                .job-sec h2 {
                  font-size: 21px;
                  margin-bottom: 0px;}
                    .job-sec{
                      padding: 55px 0px;

                    }
                    .job-details .job-desc-inner .job-desc-left h2:after{
                      width:100%;
                    }
                    .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img {
                      width: 10%;}
                    .job-sec .job-inner-sec{
                      margin: 30px auto;
                      max-width: 890px;
                    }
                    .job-sec .job-inner-sec .job-desc-new h3 {
                      font-size: 17px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc{
                    margin: 7px 0px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left {
                    float: left;
                    margin: 8px 20px 4px 0px;
                }
                .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right {
                  margin-bottom: 3px;}
                  .job-sec .job-inner-sec .job-desc-new{
                    padding: 25px 30px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .jon-loc svg{
                    font-size: 18px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left svg{
                    font-size: 18px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right svg{
                    font-size: 18px;
                  }
                  .apply-btn-cont{
                    font-size: 16px;
                    margin-top: 7px !important;
                    display: block;
                  }
                  .job-sec .job-inner-sec .job-inner-left{
                    z-index: 999;
                    position: relative;
                    width: 100%;
                    margin-bottom: 25px;
                  }
                  .job-sec .job-inner-sec .job-inner-right {
                    width: 100%;}
                  .job-details{
                    padding: 55px 0px;
                  }
                  .job-details .job-desc-inner .job-desc-left h2 {
                    font-size: 17px;
                  }
                  .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text h3 {
                    font-size: 16px;
                    margin-bottom: 8px;
                }
                .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text p{
                  font-size: 15px;
                  line-height: 23px;
                }
                .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img img {
                  height: 31px;
              }
              .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img svg {
                font-size: 26px;
                position: relative;
                top: -2px;
            }
            .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-experience img{
              height: 23px;
            }
            .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-quali img{
              height: 23px;
            }
            .job-details .job-desc-inner .job-desc-left{
              padding: 20px 10px;
            }
            .job-details .job-desc-inner .job-desc-right h2 {
              font-size: 18px;}
              .job-details .job-desc-inner .job-desc-right p{
                line-height: 30px;
                font-size: 15px;
                margin-bottom: 13px;
              }
              .job-details .job-desc-inner .job-desc-right ul li{
                margin-bottom: 13px;
                font-size: 15px;
                line-height: 25px;
              }
              .listcarer li span {
                position: relative;
                top: -2px;
            }
            .job-details .job-desc-inner .job-desc-right{
              margin-left: 10px;
              margin-top: 22px;
            }
            .listcarer li span{
              top:-2px;
            }
            .job-details .job-desc-inner .job-desc-right ul li::marker{
              font-size: 20px;
            }
              .job-details .job-desc-inner .job-desc-right h4{
                font-size: 16px;
                margin: 14px 0px;
              }
              .btn-job-aply{
                margin-top: 5px;
              }
             .or-sec{
              margin: 0px 13px;
             }
             .contact-sec{
              padding: 45px 0px;
             }
             .contact-sec .contact-inner:before{
              width: 100%;
              background-color: transparent;
              box-shadow: none;
             }
             .contact-sec .contact-inner .contact-form h2 {
              font-size: 21px;}
              .new-cont-secss .tooltipss {
                left: 130px !important;}
                .new-cont-secss .tooltipss .fa-solid {
                  font-size: 14px !important;
              }
              .cont-newss p{
                font-size: 11px !important;
              }
              .invalid-feedback .error-msg{
                font-size: 13px !important;
              }
              .contact-sec .contact-inner .contact-info{
                padding: 22px 21px;
                max-width: 100%;
              }
              .form-msg-sec{
                margin-top: 8px !important;
              }
              .eror-contact-new{
                top:5px !important;
              }
              .location-sec h2 {
                font-size: 22px;
              margin-bottom: 25px;
              
            }
            .location-sec{
              padding: 0px !important;
            }
            .location-sec{
              iframe{
                height: 300px !important;
                width: 100%;
              }
            }
            .contact-sec .contact-inner .contact-info h2 {
              font-size: 19px;
            margin-top: 6px;
            }
            .new-cont-secss .tooltipss::after{
              font-size: 13px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p{
              font-size: 15px;
              line-height: 30px;
              margin-bottom: 20px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p svg{
              font-size: 22px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p span {
              margin-left: 14px;}
              .social-icons-contact .social-icon li img {
                height: 16px;top: 2px !important;}
                .social-icons-contact .social-icon li .twit-sec {
                  height: 14px;top: 2px !important;}
                  .social-icons-contact .social-icon li{
                    height: 31px;
                    width:31px;
                  }
                  .infinite-scroll-component p{
                    font-size: 15px;
                    line-height: 32px;
                  }
                  .privacy-policy-sec{
                    padding: 55px 0px;
                  }
                  .infinite-scroll-component h3 {
                    font-size: 19px;
                    margin-bottom: 16px;
                    margin-top: 18px;
                }
                .infinite-scroll-component h4 {
                  font-size: 18px;
              }
              .infinite-scroll-component span{
                font-size: 16px;
              }
              .infinite-scroll-component ul li{
                margin:7px 0px;
                font-size: 16px;
              }
              .infinite-scroll-component h5 {
                font-size: 17px;}
                .new-font-bold {
                  font-size: 18px !important;
              }
              .busn-main-sec .busn-key-head h2 {
                font-size: 22px;
                margin-bottom: 3px;
            }
            .about-innov-main .main-difeer-about{
              .about-innov-para{
                font-size: 15px !important;
                line-height: 29px;        
              }
            }
            .infinite-scroll-component p {
              margin-bottom: 12px;
          }
            .team-sec .team-newssss{
              .meta-patahhh{
                font-size: 15px !important;
                padding: 10px 0px 8px 0px;
              }
            }
            .home-service-sec .team-newssss h2 {
              margin: 11px 0px 8px 0px;
          }
            .team-sec .team-category-sec .teamming-sec-hover{
              width:100%;
            }
            .homebanner .banner-sec-content {
              margin-top: 40px;
          }
          .service-home-widg{
            width:100%;
            margin-bottom: 20px;
          }
          .main-sec .banner-sec-content p::before{
            left:-4px;
          }
          .project-box{
            padding: 30px 26px;
            margin-bottom: 20px;
          }
          .vision-sec{
            padding: 55px 0px;
          }
          .projet-category-sec{
            max-width: 420px;
            padding: 35px 0px 10px 0px;
          }
          .footer-sec .footer-right-sec .email-sec .inputemail{
            width:100%;
            font-size: 14px;
          }
          .footer-sec .footer-right-sec ul li::marker{
            font-size: 20px;
          }
          .header-sec {
            padding: 1px 0px;
        }
        .serv-img-inner {
          @include pos-block();
        }
        .imglogbet{
          top:-6px !important;
        }
        .founder-img{
          img{
            height: 290px;
          }
        }
        .team-img-widh{
          width: 50%;
        }
        .team-sec .team-category-sec .team-box .teamimg-sec{
          margin-bottom: 17px;
        }
        .team-sec .team-category-sec .team-box .teamimg-sec::before{
          box-shadow: 3px 4px 6px 0px rgb(0 0 0 / 24%);
        }
        .ps-timeline li .img-time-first img {
          height: 53px !important;
      }
      .ps-timeline li .img-last img {
        height: 53px !important;
    }
    .img-last::before{
      content: none !important;
      background-color: transparent !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .ps-bot p{
      top: -14px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-bot{
      width: 77% !important;
      position:  relative !important;
      margin-top: 0px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-top {
      position: relative;
      bottom: 0px;
      margin-bottom: 0px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    width: 77% !important;}
  .ps-timeline-sec .container ol.ps-timeline li .img-time-first .timedata{
    top: -35px !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-last .timedata{
    top: -35px !important;
  }
  .home-service-sec .service-short-head .short-head-line::before{
    width: 80px;
  }
  .home-service-sec .service-short-head .short-head-line::after{
    width: 80px;
  }
  .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3{
    padding-top: 5px;
  }
  .service-inner-category .serv-categ-box .serv-categ-para p {
    font-size: 15px !important;
    line-height: 28px !important;
    padding: 14px 10px 0px 10px !important;
}
.key-prodt-width{
  width:50%;
}
.keyfeature-inner{
  margin-top:10px;
}
.case-stu-para {
  font-size: 20px !important;
}
.case-studies-list{
  margin-top: 40px !important;
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
}
.blog-list .blog-inner-sec .blog-search{
  top:-1px;
}
.blog-detl-call .blog-detl-sec {
  padding: 32px 15px;}
  .job-sec .job-inner-sec .job-desc-new .jon-loc span{
    font-size: 15px;
  }
  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left span{
    font-size: 15px;
  }
  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right span{
    font-size: 15px;
  }
  .contact-sec .contact-inner {
    padding: 10px 20px 20px 20px;
    max-width: 1100px;
}
.form-sec .MuiBox-root .MuiTextField-root label{
  font-size: 15px !important;
}
.navbar-toggler{
  border:none;
}
.navbar-toggler:focus{
  outline:none !important;
  box-shadow: none !important;
}
body .CookieConsent {
  width: 98% !important;
  padding: 0px 2px !important;
}
body .head-cookie{
  font-size: 19px !important;
 }
 body .para-cookk {
  font-size: 15px !important;
  line-height: 28px !important;
}
body .declinbtn{
  margin:8px 0px !important;
  padding:5px 11px !important;
}
body .accptbtn{
  margin:8px 8px !important;
  padding:6px 11px !important;
}
.callactn-sec .callactn-content .red-button-sec {
  width: 100%;
  float: left;
  text-align: left; 
  margin-top: 17px;
}

// for menu bar code start here

.navTrigger {
  width: 29px;
  height: 25px;
  margin: auto;
  position: fixed;
  right: 30px;
  top: 28px;
  cursor: pointer;
}
.navTrigger i {
background-color: #484848;
border-radius: 2px;
content: '';
display: block;
width: 100%;
height: 2px;
}
.navTrigger i:nth-child(1) {
// -webkit-animation: outT 0.8s backwards;
// animation: outT 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}
.navTrigger i:nth-child(2) {
margin: 5px 0;
// -webkit-animation: outM 0.8s backwards;
// animation: outM 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}
.navTrigger i:nth-child(3) {
// -webkit-animation: outBtm 0.8s backwards;
// animation: outBtm 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}

.navbar-collapse{
  box-shadow:0 2px 6px rgba(0, 0, 0, 0.161);
  /* margin-bottom: -6px; */
  position: fixed !important;
  width: 100%;
  z-index: 999;
  display: block;
  position: fixed;
  top: 75px;
  background-color: $whitecolor;
  float: right;
  right: 0px;

}
.activeheader{
  background-color: $whitecolor;
}

.nav-link-menu::before{
  opacity: 0;
}
.navbar-expand-lg .navbar-nav{
  margin:0px !important;
}
.navTrigger {
  cursor: pointer;
  width: 30px;
  height: 25px;
  margin: auto;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
}
.scrolled .navbar-collapse{top: 75px;}
.navTrigger i {
  background-color: $textblackcolor;
  border-radius: 2px;
  content: '';
  display: block;
  width: 100%;
  height: 3px;
}



.navTrigger.active i:nth-child(1) {
  -webkit-animation: inT 0.8s forwards;
  animation: inT 0.8s forwards;
}

.navTrigger.active i:nth-child(2) {
  -webkit-animation: inM 0.8s forwards;
  animation: inM 0.8s forwards;
}

.navTrigger.active i:nth-child(3) {
  -webkit-animation: inBtm 0.8s forwards;
  animation: inBtm 0.8s forwards;
  visibility: hidden;
}

@-webkit-keyframes inM {
  50% {
      -webkit-transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(45deg);
  }
}

@-webkit-keyframes outM {
  50% {
      -webkit-transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(45deg);
  }
}

@-webkit-keyframes inT {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(9px) rotate(0deg);
  }
  100% {
      transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes outT {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(9px) rotate(0deg);
  }
  100% {
      transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes inBtm {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(-9px) rotate(0deg);
  }
  100% {
      transform: translateY(-9px) rotate(135deg);
  }
}

@-webkit-keyframes outBtm {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(-9px) rotate(0deg);
  }
  100% {
      transform: translateY(-9px) rotate(135deg);
  }
}


// new menu bar code start here

.menuToggle {
  display: block;
  position: relative;
  top: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  a {
    text-decoration: none;
    color: #232323;
    transition: all 0.3s ease;
    &:hover {
      color: tomato;
    }
  }
  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }
  span {
    position: relative;
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }
}

.menuToggle {
  input {
    &:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #232323;
      &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }
      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
      }
    }
    &:checked ~ .menuItem {
      transform: none;
    }
    
  }
}

.menuItem {
  position: absolute;
  width: 300px;
  padding: 50px;
  padding-top: 125px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  li {
    padding: 10px 0;
    font-size: 22px;
  }
}


.menu--right {
  .menuToggle {
    position: fixed;
    right: 0;
    input {
      right: 50px;
    }
    span {
      right: 50px;
    }
  }
  .menuItem {
    right: 0;
    margin: -100px 0 0 0;
    transform: translate(100%, 0);
    li {
      a {
        &:hover {
          padding-left: 5px;
        }
      }
    }
  }
}

.job-details .job-desc-inner .job-desc-right h3 {
  font-size: 16px;
  line-height: 27px;}
  .footer-sec .footer-left-sec .map-contect ul{
    margin-bottom: 30px;
  }
  .footer-right-sec{
    .email-sec{
      form{
        width:90%;
      }
    }
  }
  .footer-right-sec{
    h3{
      width:60%;
    }
    .email-sec{
      width:60%;
      float: left;
    }
    ul{
      width:40% !important;
      margin-top: 0px !important;
    }
  }
  .ps-timeline-sec .container ol.ps-timeline li{
    width:100% !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li {
    height: 100%;
    float: none !important;
    margin-bottom: 60px;
  }
  .ps-timeline{
    display: inline-block !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-sp-top:before{
    width: 2px;
    height: 43px;
    position: absolute;
    top: 47px;
    left: 71%;
  }
  .ps-timeline-sec .container ol.ps-timeline{
    margin: 0px 0px !important;
    padding-top: 70px !important;
  }
  .margin-catg-top {
    margin-top: 0px;
}
.vision-sec{
  .service-inner-category{
    margin-top: 30px !important;
  }
}
.prodtbannerimg{
  margin-top: 40px;
}
.main-innerbanner .prodt-details h1{
margin-bottom: 33px !important;
}
.width-impact-res{
width: 33.33% !important;
float: left;
}
.produt-category .product-box::before{
  height: 88%;
}
.absentcase{
  margin-bottom: 40px;
  .serv-img-inner img{
    position: relative;
    left: -35px;
  }
}
.absentcase .service-img-right::after{
  left:92px;
}
.prodt-modl-news .MuiDialog-container h2{
  font-size: 20px;
  padding: 20px 37px 15px 37px !important;
}
.service-modl-new .form-serv-sec .serv-head-modl{
  font-size: 17px;
}
.prodt-modl-news .MuiDialog-container .MuiDialogContent-root{
  padding: 10px 40px !important;
}
.blog-detl-call {
  padding: 20px 0px 10px 0px;
}

.respon-filter-sec{
  display: block !important;
}
 .blog-details-sec{
padding: 35px 0px 0px 0px;
}
.social-icons-contact .social-icon{
  padding-top: 0px;
  padding-bottom: 20px;
}
.contact-sec .contact-inner .contact-form{
  box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px;
  background-color: $whitecolor;
  border-radius: 12px;
  padding: 34px 25px 34px 25px;
 
}
.footer-sec .container{
  max-width: 100%;
  padding: 0px 0px;
}
.footer-main{
  padding-right: 0px;
}
.footer-sec .footer-left-sec {
  padding: 30px 50px 0px 50px !important;
}
.MuiInputBase-input{font-size: 15px !important;}
.videoplay-modl .MuiDialog-container .MuiDialogContent-root {
  padding: 10px 30px !important;
}
.videoplay-modl .MuiDialog-scrollPaper .MuiPaper-root .MuiButtonBase-root {
  right: -4px;
  top: -3px;
}
 }

 @media only screen and (max-width: 1600px){
 .footer-sec .footer-right-sec ul li:last-child {
  margin-left: 36px;
}
 }

 @media only screen and (min-width: 481px) and (max-width: 575px){
  .toolcontent::after {
    width: 267px !important;
    bottom: -30px !important;
}
  .new-cont-secss .tooltipss::after {
    padding: 14px 10px !important;
}

  .footer-sec .footer-right-sec ul li:last-child {
    margin-left: 0px !important;
  }
 body .modl-blog-widh .MuiDialog-container .MuiDialogContent-root {
    padding: 10px 30px !important;
}
.modl-blog-widh .MuiDialog-scrollPaper .MuiPaper-elevation .closebew-icon {
  width: 39px;
  height: 40px;
}
  .modalblog-categ .blog-detail-rights .detail-categ{display: block !important;box-shadow: none;margin-top: 0px !important;
    padding: 0px 0px !important;}
  .modalblog-categ .blog-detail-rights .detail-categ{        display: block !important;
    box-shadow: none;
    margin-bottom: 18px;}
  .modalblog-categ .blog-detail-rights .detail-categ p{
    font-size: 21px !important;
    text-align: center;
    
  }
  .res-modl-blog .MuiDialog-container .MuiPaper-root {
    max-width: 560px !important;
}
  .modalblog-categ .blog-detail-rights .detail-categ p:after{
    background-color: $whitecolor !important;
  }
  .modalblog-categ{
    padding: 20px 0px 0px 0px !important;
  }
  .uplad-sec-new  h2 {
    font-size: 18px !important;
}
  .nav-link-menu.active::before{
    opacity: 1;
  }
  .ps-timeline-sec .container ol.ps-timeline li:last-child{
    height: 50px !important;
  }
  .blog-details-sec .blog-detail-rights .detail-categ .categ-list ul{
    margin-bottom: 0px;
  }
  .error-image {
    height: 200px !important;
}
.error-page{
  padding-top: 55px !important;
}
.error-page .error-page-head{
  font-size: 24px !important;
}
.error-page .error-page-para{
  font-size: 18px !important;
}
  .key-main-secss .differ-service-sec{
    padding: 0px 0px !important;
  }
  .error-page .error-page-head{
    font-size: 26px;
  }
  .error-page .error-page-para{
    font-size: 19px;
  }
  .key-main-secss .new-case-add {
    padding: 0px 0px 10px 0px !important;
  }
  .error-page-banner {
    height: 88vh;}
  .key-main-secss .new-case-add p {
    font-size: 17px !important;
    line-height: 31px !important;  
    margin-top: 15px !important;
  }
  .navbar-brand img {
    height: 48px;
        position: relative;
        top: 7px;
        width:auto;
  }
  .nav-link-menu{
    font-size: 15px;
    padding: 0px 22px;
    width: 100%;
    margin: 0px 0px !important;
    text-align: left;
  }
  .main-sec .banner-sec-content p{
    font-size: 16px;
    line-height: 31px;
    margin-bottom: 21px;
   
  }
  .main-sec .banner-sec-content h1 {
    font-size: 24px;
    line-height: 43px;
   
  }
  .mainbaner-img{
    margin:30px 0px 2px 0px;
  }
  .main-sec .banner-sec-content h2 {
    font-size: 24px;
    line-height: 37px;
    margin-top: 5px;
   
  }
  .mainbaner-img img {
    height: 250px;
}
  .homebanner {
    padding: 20px 0px 45px 0px;
  }
  .bg-icon-service img{
    height: 100px;
  }
  .home-service-sec .service-short-head .short-head-line{
    font-size: 15px !important;
  }

  .home-service-sec .service-short-head h2 {
    font-size: 20px;
    line-height: 33px;
  }
  .home-service-sec .service-short-head p {
    font-size: 17px !important;
    line-height: 27px;
  }
  .home-service-sec .service-short-head h2{
    margin: 20px 0px 16px 0px;
  }
  .home-service-sec .service-short-head .homeserv{
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px !important;
    line-height: 26px;
    margin-top: 13px !important;

  }
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle{
    width: 60px;
    height: 60px;

  }
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle img {
    height: 36px;
    position: relative;
    top: 12px;
  }
  .bg-icon-belowservice img{
    height: 100px;
  }
  .service-category .service-box{
    padding: 24px 20px 34px 20px;
  }
  .service-category .service-box .service-inner .service-icon .icon-sec{
    width: 13%;
    margin-right: 29px;
  }
  .service-category .service-box .service-inner .service-icon .service-para-main {
    width: 77%;}
    .home-service-sec{
      padding: 50px 0px;
    }
  .service-category .service-box .service-inner .service-icon .service-para-main h3 {
    font-size: 18px;
    font-weight: 600;
    position: relative;
    top: 21px;
    left: -5px;
  }
  .service-category{
    padding: 35px 0px 0px 0px;
    width: 100%;
    max-width: 410px;
        margin: 0 auto;
  }
  .service-box  .service-inner .service-content {
    p{
      font-size: 15px;
      line-height: 32px;
    }
  }
  .service-category .service-box{
    z-index: 999;
  }
  .service-category .service-box .service-inner .service-content{
    margin-top: 5px;
    height: auto;
  }
  .btn-inner-sec .btn-name{
    font-size: 15px;
    top: 3px;
  }
  .btn-inner-sec{
    padding: 5px 12px;
  }
  .btn-inner-sec .btn-icon {
    height: 28px;
    width: 28px;
    left: 7px;}
    .btn-inner-sec .btn-icon svg{
      top: 2px !important;
      font-size: 17px;
    }
    .load-btn {
      margin: 10px 0px 0px 0px;}
    .consult-btn .btn-white{
      padding: 8px 14px;
    }
    .project-box p {
      line-height: 38px;
      font-size: 18px;
    
    }
    .project-box-blue{
      z-index: 999;
    }
    .bg-icon-belowprojt img{
      height: 100px;
    }
    .projet-category-sec::before{
      top: -242px;
      left: -49%;
      width: 100%;
      height: 100%;
      background-size: 53px;
    }
    .vision-image-bg .bg-icon-belowservicess img {
      right: 10px;
      height: 100px;
    }
    .vision-sec .vision-content h2{
      font-size: 20px;
    }
    .vision-sec .vision-content p{
      font-size: 17px;
      line-height: 31px;
      margin-bottom: 30px;
      z-index: 999;
      position: relative;
    }
    .case-privacy{
      font-size: 15px;
    }
    .vision-image::before{
      top: 14px;
      left: 123px;
      width: 240px;
      height: 225px;
    }
    .vision-image img{
      height: 234px;
    }
    .vision-sec .vision-image-bg .bg-icon-belowservicess img {
      right: -14px;
      height: 100px;
      top: -205px;
  }
  .vision-sec .vision-content{
    margin-top: 20px;
  }
  .footer-sec .footer-left-sec img{
    height: 50px;
    z-index: 999;
    position: relative;
  }
  .footer-sec .footer-left-sec .social-icon li img {
    height: 14px;    top: 7px;}
    .footer-sec .footer-left-sec .social-icon li .twit-sec {
      height: 13px;}
      .footer-sec .footer-left-sec .social-icon li{
        width: 29px;
        height: 29px;
      }
      .footer-sec .footer-left-sec .map-sec p{
        font-size: 15px;
        margin-bottom: 10px;
      }
      .footer-sec .footer-left-sec .map-sec img{
        height: 22px;
        margin-right: 8px;
        float: left;
        top: 4px;
        position: relative;
      }
      .footer-sec .footer-left-sec .map-contect ul li p{
        font-size: 15px;
      }
      .footer-sec .footer-left-sec .map-contect ul li svg{
        font-size: 18px;
      }
      .map-contect ul li:first-child{
        margin-bottom: 10px;
      }
      .footer-sec .footer-mid-sec ul li{
        font-size: 15px;
        line-height: 38px;
      }
      .footer-sec .footer-mid-sec h3 {
        font-size: 16px;}
        .footer-sec .footer-right-sec h3 {
          font-size: 17px;}
          .footer-sec .footer-right-sec p {
            font-size: 15px;}
            .footer-sec .footer-right-sec{
              padding: 60px 0px 0px 11px;
            }
            .footer-sec .footer-right-sec .email-sec button{
              left:57%;
            }
            .footer-sec .footer-right-sec .email-sec .email-btn .btn-inner-sec {
              padding: 7px 10px;
          }
          .footer-sec .footer-right-sec ul li{
            font-size: 15px;
            width: 100%;
            margin-bottom: 7px;
          }
          .email-btn{
            width: 100%;
            position: absolute;
            right: 0px;
            text-align: right;
            top: -44px;
          }
         
          .footer-sec .footer-right-sec ul li:last-child {
            margin-left: 0px;
        }
        .footer-sec .footer-right-sec ul li a{
          left:-6px;
          top: -1px;
        }
        .background-video{
          width: 100%;
          height: auto;
        }
        .video-container{
          height: 35vh;
        }
        .about-main{
          height: 35vh;
        }
        .about-main .abut-banner-cont h1 {
          font-size: 23px;}
          .about-main .abut-banner-cont p{
            font-size: 15px;
            line-height: 27px;
            width: 100%;
            max-width: 400px;
            margin: 10px auto 0px auto;
          }
          .about-main .abut-banner-cont{
            bottom: 8vh;
          }
          .about-innov-main .about-innov-sec .about-innov-para {
            font-size: 17px;
            line-height: 32px;}
            .founder-sec-cont h2{
              font-size: 20px;
              line-height: 30px;
            }
            .founder-sec-cont p{
              font-size: 15px;
              line-height: 31px;
              margin: 17px 0px;
              text-align: justify;
            }
            .founder-sec-cont h3{
              font-size: 16px;
            }
            .founder-sec-cont h4{
              font-size: 16px;
            }
            .founder-sec-cont .book-sec{
              font-size: 16px !important;
            }
            .about-main .abut-banner-cont{
              width: 100%;
              max-width: 630px;
              transform: translate(-50%, 0%);
            }
            .book-sec span{
              display: block;
              margin: 4px 0px 0px 0px;
              img{
                margin-left: 0px !important;
                height: 19px !important;
                .book-label{
                  font-size: 18px !important;
                  display: inline-block !important;
                }
              }
            }
            .book-sec .book-label{
              display: inline-block !important;
              font-size: 16px !important;
            }
            .founder-sec{
              padding: 50px 0px;
              font-size: 18px !important;
            }

            .team-sec-content{
              .meet-pata{
                font-size: 17px !important;
              }
            }
            .team-sec .team-category-sec{
              padding: 30px 30px 10px 30px;
            }
            .team-sec .team-category-sec .teamming-sec-hover h5{
              font-size: 31px;
            }
            .team-sec .team-category-sec .teamming-sec-hover h6 {
              font-size: 16px;}
            .team-sec .team-category-sec .team-box h3 {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box h4 {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box p {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box{
              padding: 20px 0px;
            }
            .ps-timeline-sec .container ol.ps-timeline li{
              width:13.4%;
            }
            .ps-timeline{
                li{
                  .img-time {
                    img{
                      height: 49px !important;
                    }
                  }
                }
            }
            .ps-timeline-sec .container ol.ps-timeline {
              margin:170px -13px 140px -13px;}
            .ps-timeline-sec .container ol.ps-timeline li p{
              font-size: 16px;
              position: relative;
              top: -4px;
              left: 17px;
            }
            .team-sec .team-category-sec .team-box .teamimg-sec img {
              height: 147px;}
            .img-time-first {
              top: -28px;
              img{
                height: 63px !important;
              }
          }
          .img-last {
            top: -28px;
            img{
              height: 64px !important;
              top: -3px;
              position: relative;
            }
        }
        .img-time  .timedata{
          top: -35px !important;
        }
        .ps-timeline-sec .container ol.ps-timeline li .ps-sp-bot:before{
          bottom: -23px !important;
        }
        .main-sec .new-img-baner p {
          font-size: 18px !important;
          line-height: 33px !important;
          margin-top: 11px !important;
          margin-bottom: 22px !important;
      }
      .new-banr-img img {
        height: 250px;
    }
    .new-img-baner {
      margin-top: 10px;
  }
  .innerbannerimg {
    padding: 60px 0px 50px 0px;
}
.secure-main .secure-sec .serice-nee-fix{
  font-size: 17px !important;
  line-height: 33px;
  width: 100%;
  max-width: 810px;
  margin: 0 auto;
  text-align: center;
  margin-top: 28px;
}
.left-side-head-before h2 {
  font-size: 20px;
  line-height: 33px;
}
.differ-service-sec .differ-service-inner p {
  font-size: 17px;
  line-height: 33px;
  margin-top: 15px;
}
.keyprocs-sec .keyprocs-content h2{
  margin-bottom: 20px;
}
body .service-modl-new .MuiDialog-container h2 {
  font-size: 20px;
  padding: 20px 37px 4px 37px !important;
}
.serv-img-inner::before{
  top: 40px;
  left: 110px;
  width: 210px;
  height: 209px;
}
.serv-img-inner img {
  position: absolute;
  top: 40px;
  left: 85px;
  border-radius: 5px;
  height: 210px;
}
.service-img-right::after{
  top: 26px;
  left: 72px;
  width: 220px;
  height: 208px;
}
.servce-img-left::after {
  top: 31px !important;
  left: 139px !important;}
.vision-image-bg .serv-bg-iconrigt img {
  right: 333px;bottom: -90px;  }
  .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-icon img {
    height: 42px;
}
.service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3 {
  font-size: 18px;
  height: auto;
  line-height: 29px !important;}
  .service-inner-category .serv-categ-box .serv-categ-para p {
    font-size: 16px;
    line-height: 29px;
    height: auto;
}
.servce-img-left .vision-image-bg .serv-bg-iconrigt img {
  right: 0px;
  left: 330px;
  top: -17px; }
  .differ-service-sec .left-side-head-before {
    margin-top: 12px;
}
.videoplay-modl .MuiDialog-container .MuiPaper-root {
  max-width: 480px !important;
}
.servleft-img-inner::before {
  top: 44px;
  left: 136px;
}
.proof-sec .prrof-img .proof-content-sec img {
  width: 100%;
  height: 100%;
}
  .servleft-img-inner img {
    position: relative;
    top: 44px !important;
    left: 6px !important;
  }
  .vision-sec .left-side-head-before {
    margin-top: 82px;
}
    
  .left-side-head-before h2:after{
    width: 9%;
    margin-right: -100%;
    margin-left: 3px;
  }  
  .service-inner-category {
    margin-top: 290px;
}  
.tem-img-overlay {
  height: 100%;
}
.service-inner-category .serv-categ-box{
  margin-bottom: 20px;
  z-index: 999;
  position: relative;
}
  .proof-sec .prrof-img .proof-content-sec .team-content h3 {
    font-size: 18px;}
    .proof-sec .prrof-img .proof-content-sec .team-content h4 {
      font-size: 17px;
        margin-bottom: 15px;}
      .proof-sec .prrof-img .proof-content-sec .team-content {
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translate(-50%, -30%);
        width: 100%;}
        .servicebtn-prof .btn-name{
          top: 3px !important;
        }
        .red-button-sec .btn-red-sec{
          padding: 5px 13px;
        }
        .proof-sec {
          padding: 50px 0px !important;
      }
        .product-sec .short-head-line{
          font-size: 17px !important;
        }
        .product-head{
          .secure-para{
            font-size: 21px !important;
          }
        }
        .product-main .prodct-left{
          margin-right: 0px;
          width: 100%;
        }
        .product-main .prodct-right{
          width: 100%;
          margin-top: 20px;
        }
        .produt-category .product-box{
          padding: 40px 8px;
        }
        .produt-category .product-box .prodct-descp p {
          font-size: 15px !important;}
          .produt-category .product-box .prodct-descp h2 {
            font-size: 16px !important;
            margin-top: 10px;
            margin-bottom: 9px !important;}
            .produt-category .product-box .prodct-descp {
              padding: 10px 12px 0px 19px;
          }
          .produt-category .product-box .prodct-image img {
            width: auto;
            height: 240px;
        }
            .btn-prodct{}
            .produt-category {
              margin-top: 25px !important;
              max-width: 410px;
              margin: 0 auto;
          }
            .produt-category .product-box .prodct-descp p{
              height: auto;
              margin-bottom: 0px !important;
            }
            .btn-prodct .btn-inner-sec{
              padding: 6px 13px !important;
            }
            .btn-prodct .btn-inner-sec .btn-name{
              font-size: 14px;
              top: 1px;
              left: -3px;
            }
            .btn-prodct {
              margin-top: 15px;
          }
            .btn-prodct .btn-inner-sec .btn-icon {
              height: 26px;
              width: 26px;
              left: 7px;
          }
          .btn-prodct .btn-inner-sec .btn-icon svg {
            font-size: 16px;top: 1px !important;}
            .btn-prodct .btn-learn {
              float: left;
              margin-right: 6px;
          }
              .produt-category .product-box {
        padding: 30px 8px;
        z-index: 999;
    }
    .footer-sec .footer-left-sec{
      padding: 40px 30px 0px 0px;
    }
    .footer-sec .footer-mid-sec {
      padding: 45px 0px 0px 40px; 
      display: none;}
    .footer-sec .footer-right-sec {
      padding: 40px 25px 0px 25px !important;
    }
    .main-sec .prodt-details h1 {
      font-size: 25px !important;
      line-height: 36px !important;
    }
      .main-innerbanner {
        padding: 37px 0px;
    }
    .footer-sec::before{
      width: 0%;
    }
    .footer-left-sec::before{
      content: "";
      background-color: $bluecolor;
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      transform: translate(-50%, 0%)
    
    }
    .prodt-detail-main .watch-sec{
      font-size: 18px;
      width: 60%;
  
    }
    .prodt-detail-main{
      margin-bottom: 101px;
    }
    .prodt-detail-main .play-sec-prdt {
      width: 27%;
      float: left;
  }
    .video-play-button:before{
      width: 65px;
      height: 65px;
    }
    .video-play-button:after{
      width: 60px;
      height: 60px;
    }
    .video-play-button img{
      left: 32px;
    }
    .video-play-button:before{
      left: 227%;
    }
    .prodtbannerimg img {
      width: 70%;
  }
  .main-innerbanner .prodt-details{
    margin-top: 10px;
  }
  .prodct-margin {
    margin-top: 15px;
}
.keyprocs-sec .keyprocs-content h2 {
  font-size: 20px;
  line-height: 34px;}

  .keyprocs-sec .keyprocs-content p {
    font-size: 16px;
    max-width: 670px;
    line-height: 29px;
}
    .keyprocs-sec .keyprocs-content{
      padding: 50px 0px;
    }
    .detail-carousl-sec .carousl-details-content h2 {
      font-size: 20px;
      z-index: 99999;
      position: relative;
      line-height: 32px;margin-bottom: 15px;}
    .carousel-item img{
      width: 55% !important;
    }
    .detail-carousl-sec .carousl-details-content p{
     font-size: 15px !important;
      font-weight: 500;
      line-height: 32px;
    }
    .detail-carousl-sec {
      padding: 50px 0px 50px 0px;
  }
  .keyprocs-feturesec .keyprocs-heading h2 {
    font-size: 21px;        margin-bottom: 10px;}
    .keyprocs-feturesec{
      padding: 50px 0px;
    }
    .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec{
      padding:20px 11px;
    }
    .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec img {
      height: 40px;
  }
  .detail-carousl-sec .carousl-details-content{
    margin-top: 52px !important;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec h3{
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 9px;
    height: 47px;
    margin-top: 13px;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec p{
    font-size: 15px;
    line-height: 28px;
    height: 140px;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner:after{
    top:43px;
  }
  .keyprocs-feturesec .keyfeature-box{
    margin:0px 0px 40px 0px;
  }
  .impact-sec h2{
    font-size: 20px;
  }
  .impact-sec{
    padding: 50px 0px;
  }
  .impact-sec .imapct-inner-sec h3 {
    font-size: 21px;
    margin-bottom: 5px;
  }
  .impact-sec .imapct-inner-sec p {
    font-size: 16px;
    line-height: 24px;
  }
  .impact-sec .imapct-inner-sec .impact-box-center:before{
    left:-49px;
  }
  .impact-sec .imapct-inner-sec .impact-box-center:after{
    right:-50px;
  }
  .impact-sec .imapct-inner-sec{
    margin: 20px auto 0px auto;
  }
  .callactn-sec .callactn-content p {
    font-size: 17px;
    line-height: 28px;
        width: 100%;
        margin-right: 0px;
        max-width:100%;
  }
  .callactn-sec .callactn-content{
    max-width: 100% !important;
    padding: 25px 27px;
  }
  .pre-btnss .prev-main-sec .btn-name{
    font-size: 16px;
  }
  .pre-btnss .next-main-sec .btn-name{
    font-size: 16px;
    cursor: pointer;
  }
  .absentcase .service-img-right .serv-img-inner::before {
    top: 40px;
    left: 80px;}
  .arrows-container{
    top:7px !important;
  }
  .callactn-sec{
    padding: 50px 0px;
  }
  .detail-carousl-sec .carousal-img .carousel .carousel-indicators button{
    width: 10px;
    height: 10px;
  }
  .carousel-indicators .active{
    height: 10px;
    width: 10px;
  }
  .case-studies-list .case-study-box p{        font-size: 16px !important;
    line-height: 27px;margin-bottom: 17px;}
  .footer-sec .footer-right-sec .copy-sec p{
    font-size: 15px;
    padding: 20px 0px 20px 4px;
    margin-top: 30px;
  }
  .footer-sec .footer-right-sec .email-sec button{
    width: 100%;
    /* left: 8px; */
    position: relative;
    left: 0px;
  }
  .case-studies-list .case-study-box{
    padding: 32px 27px 32px 27px;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-left{
    z-index: 999;
    position: relative;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-left{
    margin-right: 27px;
    width: 100%;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-right{
    width:100%;
    margin-top: 20px;
  }
  .main-innerbanner .main-banner-sec h1 {
    font-size: 23px !important;
    line-height: 37px;
    
}
  .main-detsila-two {
    padding: 60px 0px 60px 0px;
}
.margin-case {
  margin-top: 15px !important;
}
.case-video-sec .case-video-main .case-video-left .case-video-box p {
  font-size: 16px;
  line-height: 26px;
}
.case-video-sec .case-video-main .case-video-left .case-video-box{
  padding: 25px 15px;
  width: 100%;
  left: 0%;
  z-index: 999;
  transform: translate(4%, 22%);
}
.case-video-sec .case-video-main .case-video-left .case-video-box::before{
  height: 65%;
}
.case-video-sec .case-video-main .case-video-right:after{
  width: 100%;
  height: 93%;
  background-color: rgba(0, 0, 0, 0.27);
  left: 0px;
  z-index: 99;
  border-radius: 12px;
  top: 0;
}
.case-video-sec .case-video-main .case-video-right img {
  z-index: 99;
  border-radius: 12px;
  width: 100%;
}
.case-video-sec .case-video-main .case-video-right .play-sec-prdt .video-play-button{
  top: -83px;
  left: -8%;
}
.video-play-button img{
  height: 18px;
}
.case-video-sec .case-video-main .case-video-left{
  width:55%;
}
.case-vieo-inner{
  position: relative;
  left: 1px;
}
.case-video-sec{
  padding: 50px 0px;
}
.busn-sec .business-sec h3{
  font-size: 17px;
  line-height: 29px;
  max-width: 600px;
}
.home-service-sec .business-sec p{
  font-size: 15px !important;
  line-height: 26px;
  max-width: 650px;

}
.busn-main-sec .key-benefits-sec img {
  height: 53px;
  margin-bottom: 5px;
}
.busn-main-sec .key-benefits-sec h3 {
  font-size: 16px;
  line-height: 25px;
  max-width: 270px;
}
.busn-main-sec .key-benefits-sec {
  margin-top: 24px;
}
.consult-sec .consult-content p{
  font-size: 17px !important;
  line-height: 31px;
  position: relative;
  top: -4px;
  width: 100%;
  margin-bottom:9px;

}
.like{
  font-size: 17px !important;
  margin-right: 5px !important;
}
.main-detsila-two .main-banner-sec h1 {
  font-size: 22px !important;}
.dislike{
  font-size: 17px !important;
}
.callactn-sec .callactn-content .cal-btn-sec {
  width: 30%;
  float: right;}
.consult-btn .btn-white .btn-name {
  font-size: 15px;
}
.callactn-sec .callactn-content .consult-btn{
  width: 37%;
  float: right;

}
.details-new p{
  margin-top: 10px;
  margin-bottom: 0px;
}
.resol-sec p {
  font-size: 17px;
  line-height: 30px;
  margin: 17px auto 35px auto;
}
.resol-inner-sec .resol-inner-content{
  max-width: 790px !important;
  padding: 25px 22px;
  margin-bottom: 15px;
  z-index: 999;
}
.resol-inner-sec .resol-inner-content .resol-head h3 {
  font-size: 17px;
  margin-bottom: 9px;
}
  .resol-inner-sec .resol-inner-content .resol-para p{
    font-size: 15px;
    font-weight: 500;
    line-height: 28px;
    max-width: 450px;
    
  }
  .blog-list .blog-inner-sec .blog-search .input-srch{
    max-width: 190px;
  }
  .blog-list .blog-inner-sec .blog-search{
    max-width: 215px;
  }
  .outcome-sec{
    padding: 50px 0px;
  }
  .busn-sec .details-new h2{
    margin: 0px 0px 12px 0px !important;
  }
  .case-bg-icons img {
    position: absolute;
    left: 80px;
    transform: translate(-180%, -50%);
    height: 100px;
}
.consult-sec .consult-content{
  max-width: 860px !important;
  padding: 25px 25px 25px 25px;
}
.absentcase .service-img-right .vision-image-bg .serv-bg-iconrigt img {
  right: 91px;
  bottom: -80px;
}
.pre-btnss{
  margin-top: 20px;
}
.categfilt-sec .category-box .categy-left {
  width: 100%;
}
.categfilt-sec .category-box .categy-right {
  width: 100%;}
.outcome-sec h2 {
  font-size: 20px;
}
.modl-blog-widh .MuiDialog-container h2 {
  margin-bottom: 9px;
}
.outcome-sec p {
  font-size: 17px;
        margin: 14px 0px 15px 0px;
        line-height: 28px;}
  .outcome-sec .outcome-order ul li {
    font-size: 15px;
    line-height: 30px;  
    margin: 9px 0px;
  }
  .outcome-sec .outcome-order{
    max-width: 360px;
  }
  .blog-list .blog-inner-sec .blog-category p{
    font-size: 16px;
  }
  .email-btn .btn-inner-sec .btn-name {
    font-size: 14px;
    top: 3px;
}
  .blog-list .blog-inner-sec .blog-category img {
    height: 29px;
    cursor: pointer;
    margin-left: 12px;
    font-weight: 500;
}
.footer-sec .footer-right-sec .email-sec .email-btn .btn-icon svg {
  font-size: 16px;}
.blog-list .container-width-blog{
  max-width: 960px !important;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date{
  width: 40%;
  margin-bottom: 8px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date a img{
  height: 16px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date a span{
  font-size: 15px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p{
  font-size: 15px;
  position: relative;
  top: 3px;
  left: 17px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category{
  width: 60%;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p::before{
  left: -11px;
  width: 2px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content h2 {
 
  font-size: 16px;
  line-height: 29px;
  font-weight: 600;
  height: auto;
  margin-top: 6px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content p {
  font-size: 15px;
  font-weight: 400;
  line-height: 29px;
  margin-top: 8px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content{
  padding: 15px 0px 19px 0px; 
}
.margin-blog-top {
  margin-top: 0px;
}
  .rating .like-count {
    margin-right: 6px !important;
  }
  .blog-list .blog-list-inner .blog-new-list {
    padding: 25px 17px 35px 17px;
}
.blog-list .blog-list-inner .blog-list-sec{
  z-index: 9999;
    position: relative;
    margin-bottom: 25px;
}
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-tag-para-new {
    width: 56%;}
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-tag-para p{
    font-size: 15px;
    padding: 12px 10px;
    line-height: 25px;
  }
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date img {
    height: 17px;
  }
  .blog-details-sec .blog-details-inner .blog-detal-content {
    padding: 10px 20px;}
    .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date a {
      font-size: 15px;
    }
      .blog-details-sec .blog-details-inner .blog-detal-content h2 {
        font-size: 17px;
        margin-top: 13px;
        line-height: 27px;
        margin-bottom: 14px;
      }
        .blog-details-sec .blog-details-inner .blog-detal-content p{
          margin-bottom: 15px;
          line-height: 31px;
          font-size: 15px;
        }
        .blog-details-sec .blog-details-inner .blog-detal-content h3 {
          font-size: 16px;
          margin-bottom: 17px;
          line-height: 29px;
      }
      .blog-details-sec .blog-details-inner .blog-detal-content h4 {
        font-size: 16px;        line-height: 25px;}
        .blog-detal-content .rate-admin-details-sec .rating-blgdetals p{
          font-size: 16px !important;
        }
        .blog-details-sec .blog-details-inner .blog-detal-content .admin-sec p span {
          font-size: 16px;}
          .blog-details-sec .blog-details-inner .blog-detal-content .admin-sec p svg{
            font-size: 19px;
          }
          .blog-detl-call{
            .blog-detl-sec{
              p{
                font-size: 16px;
                line-height: 28px;
                margin-right: 2px;
                width: 100%;
              }
            }
          }
          .blog-detl-call .blog-detl-sec .blg-detl-btn .btn-red-sec {
            padding: 6px 17px;
        }
        .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .input-srch {
          width: 75%;}
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .search-icon{
            padding: 8px 9px;
          }
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .search-icon svg{
            font-size: 21px;
          }
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box{
            padding: 12px 0px;
          }
          .input-srch{
            font-size: 15px;
          }
          .blog-details-sec .blog-detail-rights .blog-search p {
            font-size: 16px;}
            .blog-details-sec .blog-detail-rights .blog-search{
              padding: 15px 15px;
              display: none;
            }
            .blog-details-sec .blog-detail-rights .detail-categ p {
              font-size: 20px !important;        
              margin-bottom: 0px;}
              .blog-details-sec .blog-detail-rights .detail-categ .categ-list ul li{
                font-size: 14px;
                padding: 6px 10px;
                line-height: 23px;
                width: 100%;
              }
              .blog-details-sec .blog-detail-rights .detail-categ{
                padding: 15px 15px;
                display: none;
              }
              .blog-details-sec .blog-detail-rights .recent-post p {
                font-size: 17px;padding-bottom: 0px;}
                .blog-details-sec .blog-detail-rights .recent-post{
                  padding: 15px 15px;
                  margin-top: 15px;
                }
                .blog-details-sec .blog-detail-rights .recent-post ul li img{
                  width:25%;
                  margin-right: 12px;
                }
                .blog-details-sec .blog-detail-rights .recent-post ul li p{
                  width: 68%;
                  font-size: 15px;
                  line-height: 23px;
                  position: relative;
                  top: 0px;
                }
                .job-sec h2 {
                  font-size: 20px;
                  margin-bottom: 0px;}
                    .job-sec{
                      padding: 50px 0px;

                    }
                    .job-details .job-desc-inner .job-desc-left h2:after{
                      width:100%;
                    }
                    .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img {
                      width: 10%;}
                    .job-sec .job-inner-sec{
                      margin: 25px auto;
                      max-width: 890px;
                    }
                    .job-sec .job-inner-sec .job-desc-new h3 {
                      font-size: 17px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc{
                    margin: 7px 0px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left {
                    float: left;
                    margin: 8px 20px 4px 0px;
                }
                .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right {
                  margin-bottom: 3px;}
                  .job-sec .job-inner-sec .job-desc-new{
                    padding: 25px 30px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .jon-loc svg{
                    font-size: 18px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left svg{
                    font-size: 18px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right svg{
                    font-size: 18px;
                  }
                  .apply-btn-cont{
                    font-size: 16px;
                    margin-top: 7px !important;
                    display: block;
                  }
                  .job-sec .job-inner-sec .job-inner-left{
                    z-index: 999;
                    position: relative;
                    width: 100%;
                    margin-bottom: 25px;
                  }
                  .job-sec .job-inner-sec .job-inner-right {
                    width: 100%;}
                  .job-details{
                    padding: 55px 0px;
                  }
                  .job-details .job-desc-inner .job-desc-left h2 {
                    font-size: 17px;
                  }
                  .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text h3 {
                    font-size: 16px;
                    margin-bottom: 8px;
                }
                .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text p{
                  font-size: 15px;
                  line-height: 23px;
                }
                .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img img {
                  height: 31px;
              }
              .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img svg {
                font-size: 26px;
                position: relative;
                top: -2px;
            }
            .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-experience img{
              height: 23px;
            }
            .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-quali img{
              height: 23px;
            }
            .job-details .job-desc-inner .job-desc-left{
              padding: 20px 10px;
            }
            .job-details .job-desc-inner .job-desc-right h2 {
              font-size: 18px;}
              .job-details .job-desc-inner .job-desc-right p{
                line-height: 30px;
                font-size: 15px;
                margin-bottom: 13px;
              }
              .job-details .job-desc-inner .job-desc-right ul li{
                margin-bottom: 13px;
                font-size: 15px;
                line-height: 25px;
              }
              .listcarer li span {
                position: relative;
                top: -2px;
            }
            .job-details .job-desc-inner .job-desc-right{
              margin-left: 10px;
              margin-top: 22px;
            }
            .listcarer li span{
              top:-2px;
            }
            .job-details .job-desc-inner .job-desc-right ul li::marker{
              font-size: 20px;
            }
              .job-details .job-desc-inner .job-desc-right h4{
                font-size: 16px;
                margin: 14px 0px;
              }
              .btn-job-aply{
                margin-top: 5px;
              }
             .or-sec{
              margin: 0px 13px;
             }
             .contact-sec{
              padding: 45px 0px;
             }
             .contact-sec .contact-inner:before{
              width: auto;
              background-color: transparent;
              box-shadow: none;
             }
             .contact-sec .contact-inner .contact-form h2 {
              font-size: 20px;}
              .new-cont-secss .tooltipss {
                left: 123px !important;}
                .new-cont-secss .tooltipss .fa-solid {
                  font-size: 14px !important;
              }
              .cont-newss p{
                font-size: 12px !important;
              }
              .invalid-feedback .error-msg{
                font-size: 13px !important;
              }
              .contact-sec .contact-inner .contact-info{
                padding: 22px 21px;
                max-width: 100%;
              }
              .form-msg-sec{
                margin-top: 8px !important;
              }
              .eror-contact-new{
                top: -5px !important;
              }
              .location-sec h2 {
                font-size: 22px;
                margin-bottom: 20px;
              
            }
            .location-sec{
              padding: 0px !important;
            }
            .location-sec{
              iframe{
                height: 210px;
                width: 100%;        
              }
            }
            .contact-sec .contact-inner .contact-info h2 {
              font-size: 19px;
            margin-top: 6px;
            }
            .prodt-modl-news .new-modl-calen .MuiStack-root .MuiTextField-root label{
              font-size: 15px;
            }
            .new-cont-secss .tooltipss::after{
              font-size: 13px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p{
              font-size: 15px;
              line-height: 30px;
              margin-bottom: 20px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p svg{
              font-size: 22px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p span {
              margin-left: 14px;}
              .social-icons-contact .social-icon li img {
                height: 16px;top: 2px !important;}
                .social-icons-contact .social-icon li .twit-sec {
                  height: 14px;top: 2px !important;}
                  .social-icons-contact .social-icon li{
                    height: 31px;
                    width:31px;
                  }
                  .infinite-scroll-component p{
                    font-size: 15px;
                    line-height: 32px;
                  }
                  .privacy-policy-sec{
                    padding: 50px 0px;
                  }
                  .infinite-scroll-component h3 {
                    font-size: 18px;
                    margin-bottom: 10px;
                    margin-top: 19px;
                }
                .infinite-scroll-component h4 {
                  font-size: 17px;
              }
              .infinite-scroll-component span{
                font-size: 16px !important;
              }
              .infinite-scroll-component ul li{
                margin:7px 0px;
                font-size: 16px;
              }
              .infinite-scroll-component h5 {
                font-size: 17px;}
                .new-font-bold {
                  font-size: 18px !important;
              }
              .busn-main-sec .busn-key-head h2 {
                font-size: 20px;
                margin-bottom: 3px;
            }
            .about-innov-main .main-difeer-about{
              .about-innov-para{
                font-size: 15px !important;
                line-height: 29px;        
              }
            }
            .infinite-scroll-component p {
              margin-bottom: 12px;
          }
            .team-sec .team-newssss{
              .meta-patahhh{
                font-size: 15px !important;
                padding: 10px 0px 8px 0px;
              }
            }
            .home-service-sec .team-newssss h2 {
              margin: 11px 0px 8px 0px;
          }
            .team-sec .team-category-sec .teamming-sec-hover{
              width:100%;
            }
            .homebanner .banner-sec-content {
              margin-top: 25px;
          }
          .service-home-widg{
            width:100%;
            margin-bottom: 20px;
          }
          .main-sec .banner-sec-content p::before{
            left: -2px;
            width: 35px;
          }
          .project-box{
            padding: 30px 26px;
            margin-bottom: 20px;
          }
          .vision-sec{
            padding: 50px 0px;
          }
          .projet-category-sec{
            max-width: 420px;
            padding: 30px 0px 10px 0px;
          }
          .footer-sec .footer-right-sec .email-sec .inputemail{
            width:100%;
            font-size: 14px;
          }
          .footer-sec .footer-right-sec ul li::marker{
            font-size: 20px;
          }
          .header-sec {
            padding: 1px 10px !important;
        }
        .serv-img-inner {
          @include pos-block();
        }
        .imglogbet{
          top:-6px !important;
        }
        .founder-img{
          img{
            height: 270px;
          }
        }
        .team-img-widh{
          width: 50%;
        }
        .team-sec .team-category-sec .team-box .teamimg-sec{
          margin-bottom: 17px;
        }
        .team-sec .team-category-sec .team-box .teamimg-sec::before{
          box-shadow: 3px 4px 6px 0px rgb(0 0 0 / 24%);
        }
        .ps-timeline li .img-time-first img {
          height: 53px !important;
      }
      .ps-timeline li .img-last img {
        height: 53px !important;
    }
    .img-last::before{
      content: none !important;
      background-color: transparent !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .ps-bot p{
      top: -14px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-bot{
      width: 77% !important;
      position:  relative !important;
      margin-top: 0px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-top {
      position: relative;
      bottom: 0px;
      margin-bottom: 0px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    width: 77% !important;}
  .ps-timeline-sec .container ol.ps-timeline li .img-time-first .timedata{
    top: -35px !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-last .timedata{
    top: -35px !important;
  }
  .home-service-sec .service-short-head .short-head-line::before{
    width: 80px;
    margin-right: 13px;
  }
  .home-service-sec .service-short-head .short-head-line::after{
    width: 80px;
    margin-left: 13px;
  }
  .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3{
    padding-top: 5px;
  }
  .service-inner-category .serv-categ-box .serv-categ-para p {
    font-size: 15px !important;
    line-height: 27px !important;
    padding: 14px 10px 0px 10px !important;
}
.key-prodt-width{
  width:50%;
}
.keyfeature-inner{
  margin-top:10px;
}
.case-stu-para {
  font-size: 20px !important;
}
.case-studies-list{
  margin-top: 25px !important;
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
}
.blog-list .blog-inner-sec .blog-search{
  top:-1px;
}
.case-studies-list .case-studies-list-row{
  max-width: 410px;
}
.blog-detl-call .blog-detl-sec {
  padding: 32px 15px;}
  .job-sec .job-inner-sec .job-desc-new .jon-loc span{
    font-size: 15px;
  }
  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left span{
    font-size: 15px;
  }
  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right span{
    font-size: 15px;
  }
  .contact-sec .contact-inner {
    padding: 10px 20px 20px 20px;
    max-width: 1100px;
}
.form-sec .MuiBox-root .MuiTextField-root label{
  font-size: 15px !important;
}
.navbar-toggler{
  border:none;
}
.navbar-toggler:focus{
  outline:none !important;
  box-shadow: none !important;
}
body .CookieConsent {
  width: 98% !important;
  padding: 0px 2px !important;
}
body .head-cookie{
  font-size: 19px !important;
 }
 body .para-cookk {
  font-size: 15px !important;
  line-height: 28px !important;
}
body .declinbtn{
  margin:8px 0px !important;
  padding:5px 11px !important;
}
body .accptbtn{
  margin:8px 8px !important;
  padding:6px 11px !important;
}
.callactn-sec .callactn-content .red-button-sec {
  width: 100%;
  float: left;
  text-align: left; 
  margin-top: 17px;
}

// for menu bar code start here

.navTrigger {
  width: 29px;
  height: 25px;
  margin: auto;
  position: fixed;
  right: 30px;
  top: 28px;
  cursor: pointer;
}
.navTrigger i {
background-color: #484848;
border-radius: 2px;
content: '';
display: block;
width: 100%;
height: 2px;
}
.navTrigger i:nth-child(1) {
// -webkit-animation: outT 0.8s backwards;
// animation: outT 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}
.navTrigger i:nth-child(2) {
margin: 5px 0;
// -webkit-animation: outM 0.8s backwards;
// animation: outM 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}
.navTrigger i:nth-child(3) {
// -webkit-animation: outBtm 0.8s backwards;
// animation: outBtm 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}

.navbar-collapse{
  box-shadow:0 2px 6px rgba(0, 0, 0, 0.161);
  /* margin-bottom: -6px; */
  position: fixed !important;
  width: 100%;
  z-index: 999;
  display: block;
  position: fixed;
  top: 75px;
  background-color: $whitecolor;
  float: right;
  right: 0px;

}
.activeheader{
  background-color: $whitecolor;
}

.nav-link-menu::before{
  opacity: 0;
}
.navbar-expand-lg .navbar-nav{
  margin:0px !important;
}
.navTrigger {
  cursor: pointer;
  width: 30px;
  height: 25px;
  margin: auto;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
}
.scrolled .navbar-collapse{top: 75px;}
.navTrigger i {
  background-color: $textblackcolor;
  border-radius: 2px;
  content: '';
  display: block;
  width: 100%;
  height: 3px;
}



.navTrigger.active i:nth-child(1) {
  -webkit-animation: inT 0.8s forwards;
  animation: inT 0.8s forwards;
}

.navTrigger.active i:nth-child(2) {
  -webkit-animation: inM 0.8s forwards;
  animation: inM 0.8s forwards;
}

.navTrigger.active i:nth-child(3) {
  -webkit-animation: inBtm 0.8s forwards;
  animation: inBtm 0.8s forwards;
  visibility: hidden;
}

@-webkit-keyframes inM {
  50% {
      -webkit-transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(45deg);
  }
}

@-webkit-keyframes outM {
  50% {
      -webkit-transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(45deg);
  }
}

@-webkit-keyframes inT {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(9px) rotate(0deg);
  }
  100% {
      transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes outT {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(9px) rotate(0deg);
  }
  100% {
      transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes inBtm {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(-9px) rotate(0deg);
  }
  100% {
      transform: translateY(-9px) rotate(135deg);
  }
}

@-webkit-keyframes outBtm {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(-9px) rotate(0deg);
  }
  100% {
      transform: translateY(-9px) rotate(135deg);
  }
}


// new menu bar code start here

.menuToggle {
  display: block;
  position: relative;
  top: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  a {
    text-decoration: none;
    color: #232323;
    transition: all 0.3s ease;
    &:hover {
      color: tomato;
    }
  }
  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }
  span {
    position: relative;
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }
}

.menuToggle {
  input {
    &:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #232323;
      &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }
      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
      }
    }
    &:checked ~ .menuItem {
      transform: none;
    }
    
  }
}

.menuItem {
  position: absolute;
  width: 300px;
  padding: 50px;
  padding-top: 125px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  li {
    padding: 10px 0;
    font-size: 22px;
  }
}


.menu--right {
  .menuToggle {
    position: fixed;
    right: 0;
    input {
      right: 50px;
    }
    span {
      right: 50px;
    }
  }
  .menuItem {
    right: 0;
    margin: -100px 0 0 0;
    transform: translate(100%, 0);
    li {
      a {
        &:hover {
          padding-left: 5px;
        }
      }
    }
  }
}

.job-details .job-desc-inner .job-desc-right h3 {
  font-size: 16px;
  line-height: 27px;}
  .footer-sec .footer-left-sec .map-contect ul{
    margin-bottom: 30px;
  }
  .footer-right-sec{
    .email-sec{
      form{
        width:90%;
      }
    }
  }
  .footer-right-sec{
    h3{
      width:60%;
    }
    .email-sec{
      width: 58%;
      float: left;
    }
    ul{
      width:42% !important;
      margin-top: 0px !important;
    }
  }
  .ps-timeline-sec .container ol.ps-timeline li{
    width:100% !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li {
    height: 100%;
    float: none !important;
    margin-bottom: 60px;
  }
  .ps-timeline{
    display: inline-block !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-sp-top:before{
    width: 2px;
    height: 43px;
    position: absolute;
    top: 47px;
    left: 71%;
  }
  .ps-timeline-sec .container ol.ps-timeline{
    margin: 0px 0px !important;
    padding-top: 70px !important;
  }
  .margin-catg-top {
    margin-top: 0px;
}
.vision-sec{
  .service-inner-category{
    margin-top: 25px !important;
  }
}
.prodtbannerimg{
  margin-top: 40px;
}
.main-innerbanner .prodt-details h1{
margin-bottom: 33px !important;
}
.width-impact-res{
width: 33.33% !important;
float: left;
}
.produt-category .product-box::before{
  height: 88%;
}
.absentcase{
  margin-bottom: 40px;
  .serv-img-inner img{
    position: relative;
    left: -35px;
  }
}
.absentcase .service-img-right::after{
  left:92px;
}
.prodt-modl-news .MuiDialog-container h2{
  font-size: 20px;
  padding: 20px 17px 15px 17px !important;
}
.service-modl-new .form-serv-sec .serv-head-modl{
  font-size: 17px;
}
.prodt-modl-news .MuiDialog-container .MuiDialogContent-root{
  padding: 10px 35px !important;
}
.blog-detl-call {
  padding: 20px 0px 10px 0px;
}
.prodt-modl-news .form-radio-sec .MuiFormControl-root .MuiFormLabel-root{
  font-size: 16px;
}

.respon-filter-sec{
  display: block !important;
}
 .blog-details-sec{
padding: 35px 0px 0px 0px;
}
.social-icons-contact .social-icon{
  padding-top: 0px;
  padding-bottom: 10px;
}
.contact-sec .contact-inner .contact-form{
  box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px;
  background-color: $whitecolor;
  border-radius: 12px;
  padding: 34px 25px 34px 25px;
  z-index: 999;
 
}
.founder-sec-cont{
  padding: 0px 10px;
}
.footer-sec .container{
  max-width: 100%;
  padding: 0px 0px;
}
.footer-main{
  padding-right: 0px;
}
.footer-sec .footer-left-sec {
  padding: 30px 25px 0px 25px !important;
}
.MuiInputBase-input{font-size: 15px !important;}
.videoplay-modl .MuiDialog-container .MuiDialogContent-root {
  padding: 20px 30px !important;
}
.videoplay-modl .MuiDialog-scrollPaper .MuiPaper-root .MuiButtonBase-root {
  right: -4px;
  top: -3px;
}
.container{
  padding-left: 20px;
  padding-right: 20px;
}
.respon-filter-sec{
  padding:55px 0px 0px 0px !important;

}
.blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date{
  padding: 14px 0px 11px 0px;
  width: 37%;
}
.form-modal .casepropri {
  margin-top: 35px !important;
}

 }

 @media only screen and (min-width: 481px) and (max-width: 540px){
  .vision-image::before {
    left: 45% !important;
    transform: translate(-47%, 0px);
  }
 }

 @media only screen and (max-width: 480px) {
  .toolcontent::after {
    width: 267px !important;
    bottom: -30px !important;
}
  .new-cont-secss .tooltipss::after {
    padding: 14px 10px !important;
}
  .vision-image::before {
    left: 45% !important;
    transform: translate(-47%, 0px);
  }
  .footer-sec .footer-right-sec ul li:last-child {
    margin-left: 0px !important;
  }
 body .modl-blog-widh .MuiDialog-container .MuiDialogContent-root {
    padding: 10px 30px !important;
}
.modl-blog-widh .MuiDialog-scrollPaper .MuiPaper-elevation .closebew-icon {
  width: 39px;
  height: 40px;
}
  .modalblog-categ .blog-detail-rights .detail-categ{display: block !important;box-shadow: none;margin-top: 0px !important;
    padding: 0px 0px !important;}
  .modalblog-categ .blog-detail-rights .detail-categ{        display: block !important;
    box-shadow: none;
    margin-bottom: 18px;}
  .modalblog-categ .blog-detail-rights .detail-categ p{
    font-size: 21px !important;
    text-align: center;
    
  }
  .res-modl-blog .MuiDialog-container .MuiPaper-root {
    max-width: 560px !important;
}
  .modalblog-categ .blog-detail-rights .detail-categ p:after{
    background-color: $whitecolor !important;
  }
  .modalblog-categ{
    padding: 20px 0px 0px 0px !important;
  }
  .uplad-sec-new  h2 {
    font-size: 18px !important;
}
  .nav-link-menu.active::before{
    opacity: 1;
  }
  .ps-timeline-sec .container ol.ps-timeline li:last-child{
    height: 50px !important;
  }
  .blog-details-sec .blog-detail-rights .detail-categ .categ-list ul{
    margin-bottom: 0px;
  }
  .error-image {
    height: 200px !important;
}
.error-page{
  padding-top: 55px !important;
}
.error-page .error-page-head{
  font-size: 23px !important;
}
.error-page .error-page-para{
  font-size: 18px !important;
}
  .key-main-secss .differ-service-sec{
    padding: 0px 0px !important;
  }
  .error-page .error-page-head{
    font-size: 26px;
  }
  .error-page .error-page-para{
    font-size: 19px;
  }
  .key-main-secss .new-case-add {
    padding: 0px 0px 10px 0px !important;
  }
  .error-page-banner {
    height: 88vh;}
  .key-main-secss .new-case-add p {
    font-size: 17px !important;
    line-height: 31px !important;  
    margin-top: 15px !important;
  }
  .navbar-brand img {
    height: 48px;
        position: relative;
        top: 7px;
        width:auto;
  }
  .nav-link-menu{
    font-size: 15px;
    padding: 0px 22px;
    width: 100%;
    margin: 0px 0px !important;
    text-align: left;
  }
  .main-sec .banner-sec-content p{
    font-size: 16px;
    line-height: 31px;
    margin-bottom: 21px;
   
  }
  .main-sec .banner-sec-content h1 {
    font-size: 24px;
    line-height: 43px;
   
  }
  .mainbaner-img{
    margin:30px 0px 2px 0px;
  }
  .main-sec .banner-sec-content h2 {
    font-size: 24px;
    line-height: 37px;
    margin-top: 5px;
   
  }
  .mainbaner-img img {
    height: 250px;
}
  .homebanner {
    padding: 20px 0px 45px 0px;
  }
  .bg-icon-service img{
    height: 100px;
  }
  .home-service-sec .service-short-head .short-head-line{
    font-size: 15px !important;
  }

  .home-service-sec .service-short-head h2 {
    font-size: 20px;
    line-height: 33px;
  }
  .home-service-sec .service-short-head p {
    font-size: 17px !important;
    line-height: 27px;
  }
  .home-service-sec .service-short-head h2{
    margin: 20px 0px 16px 0px;
  }
  .home-service-sec .service-short-head .homeserv{
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px !important;
    line-height: 26px;
    margin-top: 13px !important;

  }
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle{
    width: 60px;
    height: 60px;

  }
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle img {
    height: 36px;
    position: relative;
    top: 12px;
  }
  .bg-icon-belowservice img{
    height: 100px;
  }
  .service-category .service-box{
    padding: 24px 20px 34px 20px;
  }
  .service-category .service-box .service-inner .service-icon .icon-sec{
    width: 13%;
    margin-right: 29px;
  }
  .service-category .service-box .service-inner .service-icon .service-para-main {
    width: 77%;}
    .home-service-sec{
      padding: 50px 0px;
    }
  .service-category .service-box .service-inner .service-icon .service-para-main h3 {
    font-size: 18px;
    font-weight: 600;
    position: relative;
    top: 21px;
    left: -5px;
  }
  .service-category{
    padding: 35px 0px 0px 0px;
    width: 100%;
    max-width: 410px;
        margin: 0 auto;
  }
  .service-box  .service-inner .service-content {
    p{
      font-size: 15px;
      line-height: 32px;
    }
  }
  .service-category .service-box{
    z-index: 999;
  }
  .service-category .service-box .service-inner .service-content{
    margin-top: 5px;
    height: auto;
  }
  .btn-inner-sec .btn-name{
    font-size: 15px;
    top: 3px;
  }
  .btn-inner-sec{
    padding: 5px 12px;
  }
  .btn-inner-sec .btn-icon {
    height: 28px;
    width: 28px;
    left: 7px;}
    .btn-inner-sec .btn-icon svg{
      top: 2px !important;
      font-size: 17px;
    }
    .load-btn {
      margin: 10px 0px 0px 0px;}
    .consult-btn .btn-white{
      padding: 7px 11px;
    }
    .project-box p {
      line-height: 38px;
      font-size: 18px;
    
    }
    .project-box-blue{
      z-index: 999;
    }
    .bg-icon-belowprojt img{
      height: 100px;
    }
    .projet-category-sec::before{
      top: -242px;
      left: -49%;
      width: 100%;
      height: 100%;
      background-size: 53px;
    }
    .vision-image-bg .bg-icon-belowservicess img {
      right: 10px;
      height: 100px;
    }
    .vision-sec .vision-content h2{
      font-size: 20px;
    }
    .vision-sec .vision-content p{
      font-size: 17px;
      line-height: 31px;
      margin-bottom: 30px;
      z-index: 999;
      position: relative;
    }
    .case-privacy{
      font-size: 15px;
    }
    .vision-image::before{
      top: 14px;
      width: 240px;
      height: 225px;
    }
    .vision-image img{
      height: 234px;
    }
    .vision-sec .vision-image-bg .bg-icon-belowservicess img {
      right: -14px;
      height: 100px;
      top: -205px;
  }
  .vision-sec .vision-content{
    margin-top: 20px;
  }
  .footer-sec .footer-left-sec img{
    height: 50px;
    z-index: 999;
    position: relative;
  }
  .footer-sec .footer-left-sec .social-icon li img {
    height: 14px;    top: 7px;}
    .footer-sec .footer-left-sec .social-icon li .twit-sec {
      height: 13px;}
      .footer-sec .footer-left-sec .social-icon li{
        width: 29px;
        height: 29px;
      }
      .footer-sec .footer-left-sec .map-sec p{
        font-size: 15px;
        margin-bottom: 10px;
      }
      .footer-sec .footer-left-sec .map-sec img{
        height: 22px;
        margin-right: 8px;
        float: left;
        top: 4px;
        position: relative;
      }
      .footer-sec .footer-left-sec .map-contect ul li p{
        font-size: 15px;
      }
      .footer-sec .footer-left-sec .map-contect ul li svg{
        font-size: 18px;
      }
      .map-contect ul li:first-child{
        margin-bottom: 10px;
      }
      .footer-sec .footer-mid-sec ul li{
        font-size: 15px;
        line-height: 38px;
      }
      .footer-sec .footer-mid-sec h3 {
        font-size: 16px;}
        .footer-sec .footer-right-sec h3 {
          font-size: 17px;}
          .footer-sec .footer-right-sec p {
            font-size: 15px;}
            .footer-sec .footer-right-sec{
              padding: 60px 0px 0px 11px;
            }
            .footer-sec .footer-right-sec .email-sec button{
              left:57%;
            }
            .footer-sec .footer-right-sec .email-sec .email-btn .btn-inner-sec {
              padding: 7px 10px;
          }
          .footer-sec .footer-right-sec ul li{
            font-size: 15px;
            width: 100%;
            margin-bottom: 7px;
          }
          .email-btn{
            width: 100%;
            position: absolute;
            right: 0px;
            text-align: right;
            top: -44px;
          }
         
          .footer-sec .footer-right-sec ul li:last-child {
            margin-left: 0px;
        }
        .footer-sec .footer-right-sec ul li a{
          left:-6px;
          top: -1px;
        }
        .background-video{
          width: 100%;
          height: auto;
        }
        .video-container{
          height: 35vh;
        }
        .about-main{
          height: 35vh;
        }
        .about-main .abut-banner-cont h1 {
          font-size: 23px;}
          .about-main .abut-banner-cont p{
            font-size: 15px;
            line-height: 27px;
            width: 100%;
            max-width: 400px;
            margin: 10px auto 0px auto;
          }
          .about-main .abut-banner-cont{
            bottom: 8vh;
          }
          .about-innov-main .about-innov-sec .about-innov-para {
            font-size: 17px;
            line-height: 32px;}
            .founder-sec-cont h2{
              font-size: 20px;
              line-height: 30px;
            }
            .founder-sec-cont p{
              font-size: 15px;
              line-height: 31px;
              margin: 17px 0px;
              text-align: justify;
            }
            .founder-sec-cont h3{
              font-size: 16px;
            }
            .founder-sec-cont h4{
              font-size: 16px;
            }
            .founder-sec-cont .book-sec{
              font-size: 16px !important;
            }
            .about-main .abut-banner-cont{
              width: 100%;
              max-width: 630px;
              transform: translate(-50%, 0%);
            }
            .book-sec span{
              display: block;
              margin: 4px 0px 0px 0px;
              img{
                margin-left: 0px !important;
                height: 19px !important;
                .book-label{
                  font-size: 18px !important;
                  display: inline-block !important;
                }
              }
            }
            .book-sec .book-label{
              display: inline-block !important;
              font-size: 16px !important;
            }
            .founder-sec{
              padding: 50px 0px;
              font-size: 18px !important;
            }

            .team-sec-content{
              .meet-pata{
                font-size: 17px !important;
              }
            }
            .team-sec .team-category-sec{
              padding: 30px 30px 10px 30px;
            }
            .team-sec .team-category-sec .teamming-sec-hover h5{
              font-size: 31px;
            }
            .team-sec .team-category-sec .teamming-sec-hover h6 {
              font-size: 16px;}
            .team-sec .team-category-sec .team-box h3 {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box h4 {
              font-size: 16px;
            }
            .team-sec .team-category-sec .team-box p {
              font-size: 15px;
            }
            .team-sec .team-category-sec .team-box{
              padding: 20px 0px;
            }
            .ps-timeline-sec .container ol.ps-timeline li{
              width:13.4%;
            }
            .ps-timeline{
                li{
                  .img-time {
                    img{
                      height: 49px !important;
                    }
                  }
                }
            }
            .ps-timeline-sec .container ol.ps-timeline {
              margin:170px -13px 140px -13px;}
            .ps-timeline-sec .container ol.ps-timeline li p{
              font-size: 16px;
              position: relative;
              top: -4px;
              left: 17px;
            }
            .team-sec .team-category-sec .team-box .teamimg-sec img {
              height: 147px;}
            .img-time-first {
              top: -28px;
              img{
                height: 63px !important;
              }
          }
          .img-last {
            top: -28px;
            img{
              height: 64px !important;
              top: -3px;
              position: relative;
            }
        }
        .img-time  .timedata{
          top: -35px !important;
        }
        .ps-timeline-sec .container ol.ps-timeline li .ps-sp-bot:before{
          bottom: -23px !important;
        }
        .main-sec .new-img-baner p {
          font-size: 18px !important;
          line-height: 33px !important;
          margin-top: 11px !important;
          margin-bottom: 26px !important;
      }
      .new-banr-img img {
        height: 250px;
    }
    .new-img-baner {
      margin-top: 10px;
  }
  .innerbannerimg {
    padding: 60px 0px 50px 0px;
}
.secure-main .secure-sec .serice-nee-fix{
  font-size: 17px !important;
  line-height: 33px;
  width: 100%;
  max-width: 810px;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
}
.left-side-head-before h2 {
  font-size: 20px;
  line-height: 33px;
}
.differ-service-sec .differ-service-inner p {
  font-size: 17px;
  line-height: 33px;
  margin-top: 15px;
}
.keyprocs-sec .keyprocs-content h2{
  margin-bottom: 20px;
}
body .service-modl-new .MuiDialog-container h2 {
  font-size: 20px;
  padding: 20px 37px 4px 37px !important;
}
.serv-img-inner::before{
  top: 40px;
  left: 110px;
  width: 210px;
  height: 209px;
}
.serv-img-inner img {
  position: absolute;
  top: 38px;
  left: 83px;
  border-radius: 5px;
  height: 210px;
}
.service-img-right::after{
  top: 26px;
  left: 72px;
  width: 220px;
  height: 208px;
}
.servce-img-left::after {
  top: 31px !important;
  left: 139px !important;}
.vision-image-bg .serv-bg-iconrigt img {
  right: 333px;bottom: -90px;  }
  .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-icon img {
    height: 42px;
}
.service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3 {
  font-size: 18px;
  height: auto;
  line-height: 29px !important;}
  .service-inner-category .serv-categ-box .serv-categ-para p {
    font-size: 16px;
    line-height: 29px;
    height: auto;
}
.servce-img-left .vision-image-bg .serv-bg-iconrigt img {
  right: 0px;
  left: 330px;
  top: -17px; }
  .differ-service-sec .left-side-head-before {
    margin-top: 0px;
}
.videoplay-modl .MuiDialog-container .MuiPaper-root {
  max-width: 480px !important;
}

.servleft-img-inner::before {
  top: 44px;
  left: 136px;
}
.proof-sec .prrof-img .proof-content-sec img {
  width: 100%;
  height: 100%;
}
  .servleft-img-inner img {
    position: relative;
    top: 44px !important;
    left: 10px !important;
  }
  .vision-sec .left-side-head-before {
    margin-top: 82px;
}
    
  .left-side-head-before h2:after{
    width: 9%;
    margin-right: -100%;
    margin-left: 3px;
  }  
  .service-inner-category {
    margin-top: 290px;
}  
.tem-img-overlay {
  height: 100%;
}
.service-inner-category .serv-categ-box{
  margin-bottom: 20px;
  z-index: 999;
  position: relative;
}
  .proof-sec .prrof-img .proof-content-sec .team-content h3 {
    font-size: 18px;}
    .proof-sec .prrof-img .proof-content-sec .team-content h4 {
      font-size: 17px;
        margin-bottom: 15px;}
      .proof-sec .prrof-img .proof-content-sec .team-content {
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translate(-50%, -30%);
        width: 100%;}
        .servicebtn-prof .btn-name{
          top: 3px !important;
        }
        .red-button-sec .btn-red-sec{
          padding: 5px 13px;
        }
        .proof-sec {
          padding: 50px 0px !important;
      }
        .product-sec .short-head-line{
          font-size: 17px !important;
        }
        .product-head{
          .secure-para{
            font-size: 21px !important;
          }
        }
        .product-main .prodct-left{
          margin-right: 0px;
          width: 100%;
        }
        .product-main .prodct-right{
          width: 100%;
          margin-top: 20px;
        }
        .produt-category .product-box{
          padding: 40px 8px;
        }
        .produt-category .product-box .prodct-descp p {
          font-size: 15px !important;}
          .produt-category .product-box .prodct-descp h2 {
            font-size: 16px !important;
            margin-top: 10px;
            margin-bottom: 9px !important;}
            .produt-category .product-box .prodct-descp {
              padding: 10px 12px 0px 19px;
          }
          .produt-category .product-box .prodct-image img {
            width: auto;
            height: 240px;
        }
            .btn-prodct{}
            .produt-category {
              margin-top: 25px !important;
              max-width: 410px;
              margin: 0 auto;
          }
            .produt-category .product-box .prodct-descp p{
              height: auto;
              margin-bottom: 0px !important;
            }
            .btn-prodct .btn-inner-sec{
              padding: 6px 13px !important;
            }
            .btn-prodct .btn-inner-sec .btn-name{
              font-size: 14px;
              top: 1px;
              left: -3px;
            }
            .btn-prodct {
              margin-top: 15px;
          }
            .btn-prodct .btn-inner-sec .btn-icon {
              height: 26px;
              width: 26px;
              left: 7px;
          }
          .btn-prodct .btn-inner-sec .btn-icon svg {
            font-size: 16px;top: 1px !important;}
            .btn-prodct .btn-learn {
              float: left;
              margin-right: 6px;
          }
              .produt-category .product-box {
        padding: 30px 8px;
        z-index: 999;
    }
    .footer-sec .footer-left-sec{
      padding: 40px 30px 0px 0px;
    }
    .footer-sec .footer-mid-sec {
      padding: 45px 0px 0px 40px; 
      display: none;}
    .footer-sec .footer-right-sec {
      padding: 40px 25px 0px 25px !important;
    }
    .main-sec .prodt-details h1 {
      font-size: 25px !important;
      line-height: 36px !important;
    }
      .main-innerbanner {
        padding: 37px 0px;
    }
    .footer-sec::before{
      width: 0%;
    }
    .footer-left-sec::before{
      content: "";
      background-color: $bluecolor;
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      transform: translate(-50%, 0%)
    
    }
    .prodt-detail-main .watch-sec{
      font-size: 18px;
      width: 60%;
  
    }
    .prodt-detail-main{
      margin-bottom: 101px;
    }
    .prodt-detail-main .play-sec-prdt {
      width: 27%;
      float: left;
  }
    .video-play-button:before{
      width: 65px;
      height: 65px;
    }
    .video-play-button:after{
      width: 60px;
      height: 60px;
    }
    .video-play-button img{
      left: 32px;
    }
    .video-play-button:before{
      left: 227%;
    }
    .prodtbannerimg img {
      width: 70%;
  }
  .main-innerbanner .prodt-details{
    margin-top: 10px;
  }
  .prodct-margin {
    margin-top: 15px;
}
.keyprocs-sec .keyprocs-content h2 {
  font-size: 20px;
  line-height: 33px;}

  .keyprocs-sec .keyprocs-content p {
    font-size: 16px;
    max-width: 670px;
    line-height: 29px;
}
    .keyprocs-sec .keyprocs-content{
      padding: 50px 0px;
    }
    .detail-carousl-sec .carousl-details-content h2 {
      font-size: 20px;
      z-index: 99999;
      position: relative;
      line-height: 32px;margin-bottom: 15px;}
    .carousel-item img{
      width: 85% !important;
    }
    .detail-carousl-sec .carousl-details-content p{
     font-size: 15px !important;
      font-weight: 500;
      line-height: 32px;
    }
    .detail-carousl-sec {
      padding: 50px 0px 50px 0px;
  }
  .keyprocs-feturesec .keyprocs-heading h2 {
    font-size: 20px;        margin-bottom: 10px;}
    .keyprocs-feturesec{
      padding: 50px 0px;
    }
    .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec{
      padding:20px 11px;
    }
    .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec img {
      height: 40px;
  }
  .detail-carousl-sec .carousl-details-content{
    margin-top: 52px !important;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec h3{
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 9px;
    height: 47px;
    margin-top: 13px;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec p{
    font-size: 15px;
    line-height: 27px;
    height: 140px;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner:after{
    top: 41px;
  }
  .keyprocs-feturesec .keyfeature-box{
    margin:0px 0px 40px 0px;
  }
  .impact-sec h2{
    font-size: 20px;
  }
  .impact-sec{
    padding: 50px 0px;
  }
  .impact-sec .imapct-inner-sec h3 {
    font-size: 21px;
    margin-bottom: 5px;
  }
  .impact-sec .imapct-inner-sec p {
    font-size: 16px;
    line-height: 23px;
  }
  .impact-sec .imapct-inner-sec .impact-box-center:before{
    left: -33px;
  }
  .impact-sec .imapct-inner-sec .impact-box-center:after{
    right: -38px;
  }
  .impact-sec .imapct-inner-sec{
    margin: 20px auto 0px auto;
  }
  .callactn-sec .callactn-content p {
    font-size: 17px;
    line-height: 28px;
        width: 100%;
        margin-right: 0px;
        max-width:100%;
  }
  .callactn-sec .callactn-content{
    max-width: 100% !important;
    padding: 25px 27px;
  }
  .pre-btnss .prev-main-sec .btn-name{
    font-size: 16px;
  }
  .pre-btnss .next-main-sec .btn-name{
    font-size: 16px;
    cursor: pointer;
  }
  .absentcase .service-img-right .serv-img-inner::before {
    top: 40px;
    left: 80px;}
  .arrows-container{
    top:7px !important;
  }
  .callactn-sec{
    padding: 50px 0px;
  }
  .detail-carousl-sec .carousal-img .carousel .carousel-indicators button{
    width: 10px;
    height: 10px;
  }
  .carousel-indicators .active{
    height: 10px;
    width: 10px;
  }
  .case-studies-list .case-study-box p{        font-size: 16px !important;
    line-height: 27px;margin-bottom: 17px;}
  .footer-sec .footer-right-sec .copy-sec p{
    font-size: 15px;
    padding: 20px 0px 20px 4px;
    margin-top: 30px;
  }
  .footer-sec .footer-right-sec .email-sec button{
    width: 100%;
    /* left: 8px; */
    position: relative;
    left: 0px;
  }
  .case-studies-list .case-study-box{
    padding: 32px 27px 32px 27px;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-left{
    z-index: 999;
    position: relative;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-left{
    margin-right: 27px;
    width: 100%;
  }
  .case-studies-list .case-studies-list-row .case-studies-list-right{
    width:100%;
    margin-top: 20px;
  }
  .main-innerbanner .main-banner-sec h1 {
    font-size: 23px !important;
    line-height: 37px;
    
}
  .main-detsila-two {
    padding: 50px 0px 50px 0px;
}
.margin-case {
  margin-top: 15px !important;
}
.case-video-sec .case-video-main .case-video-left .case-video-box p {
  font-size: 16px;
  line-height: 25px;
}
.case-video-sec .case-video-main .case-video-left .case-video-box{
  padding: 25px 15px;
  width: 100%;
  left: 0%;
  z-index: 999;
  transform: translate(4%, 22%);
}
.case-video-sec .case-video-main .case-video-left .case-video-box::before{
  height: 65%;
}
.case-video-sec .case-video-main .case-video-right:after{
  width: 100%;
  height: 93%;
  background-color: rgba(0, 0, 0, 0.27);
  left: 0px;
  z-index: 99;
  border-radius: 12px;
  top: 0;
}
.case-video-sec .case-video-main .case-video-right img {
  z-index: 99;
  border-radius: 12px;
  width: 100%;
}
.case-video-sec .case-video-main .case-video-right .play-sec-prdt .video-play-button{
  top: -83px;
  left: -8%;
}
.video-play-button img{
  height: 18px;
}
.case-video-sec .case-video-main .case-video-left{
  width:55%;
}
.case-vieo-inner{
  position: relative;
  left: 1px;
}
.case-video-sec{
  padding: 50px 0px;
}
.busn-sec .business-sec h3{
  font-size: 17px;
  line-height: 29px;
  max-width: 600px;
}
.home-service-sec .business-sec p{
  font-size: 15px !important;
  line-height: 26px;
  max-width: 650px;
  margin-bottom: 5px;

}
.busn-main-sec .key-benefits-sec img {
  height: 50px;
  margin-bottom: 5px;
}
.busn-main-sec .key-benefits-sec h3 {
  font-size: 16px;
  line-height: 25px;
  max-width: 270px;
}
.busn-main-sec .key-benefits-sec {
  margin-top: 24px;
}
.consult-sec .consult-content p{
  font-size: 17px !important;
  line-height: 31px;
  position: relative;
  top: -4px;
  width: 100%;
  margin-bottom: 14px;
}
.like{
  font-size: 17px !important;
  margin-right: 5px !important;
}
.main-detsila-two .main-banner-sec h1 {
  font-size: 22px !important;}
.dislike{
  font-size: 17px !important;
}
.callactn-sec .callactn-content .cal-btn-sec {
  width: 30%;
  float: right;}
.consult-btn .btn-white .btn-name {
  font-size: 15px;
}
.callactn-sec .callactn-content .consult-btn{
  width: 37%;
  float: right;

}
.details-new p{
  margin-top: 10px;
  margin-bottom: 0px;
}
.resol-sec p {
  font-size: 17px;
  line-height: 30px;
  margin: 17px auto 35px auto;
}
.resol-inner-sec .resol-inner-content{
  max-width: 790px !important;
  padding: 25px 22px;
  margin-bottom: 15px;
  z-index: 999;
}
.resol-inner-sec .resol-inner-content .resol-head h3 {
  font-size: 17px;
  margin-bottom: 9px;
}
  .resol-inner-sec .resol-inner-content .resol-para p{
    font-size: 15px;
    font-weight: 500;
    line-height: 28px;
    max-width: 450px;
    
  }
  .blog-list .blog-inner-sec .blog-search .input-srch{
    max-width: 190px;
  }
  .blog-list .blog-inner-sec .blog-search{
    max-width: 215px;
  }
  .outcome-sec{
    padding: 50px 0px;
  }
  .busn-sec .details-new h2{
    margin: 0px 0px 12px 0px !important;
  }
  .case-bg-icons img {
    position: absolute;
    left: 80px;
    transform: translate(-180%, -50%);
    height: 100px;
}
.consult-sec .consult-content{
  max-width: 860px !important;
  padding: 25px 25px 25px 25px;
}
.absentcase .service-img-right .vision-image-bg .serv-bg-iconrigt img {
  right: 91px;
  bottom: -80px;
}
.pre-btnss{
  margin-top: 20px;
}
.categfilt-sec .category-box .categy-left {
  width: 100%;
}
.categfilt-sec .category-box .categy-right {
  width: 100%;}
.outcome-sec h2 {
  font-size: 20px;
}
.modl-blog-widh .MuiDialog-container h2 {
  margin-bottom: 9px;
}
.outcome-sec p {
  font-size: 17px;
        margin: 14px 0px 15px 0px;
        line-height: 28px;}
  .outcome-sec .outcome-order ul li {
    font-size: 15px;
    line-height: 30px;  
    margin: 9px 0px;
  }
  .outcome-sec .outcome-order{
    max-width: 420px;
    margin: 0 30px 0px 30px !important;
  }
  .blog-list .blog-inner-sec .blog-category p{
    font-size: 16px;
  }
  .email-btn .btn-inner-sec .btn-name {
    font-size: 14px;
    top: 3px;
}
  .blog-list .blog-inner-sec .blog-category img {
    height: 29px;
    cursor: pointer;
    margin-left: 12px;
    font-weight: 500;
}
.footer-sec .footer-right-sec .email-sec .email-btn .btn-icon svg {
  font-size: 16px;}
.blog-list .container-width-blog{
  max-width: 960px !important;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date{
  width: 42%;
  margin-bottom: 8px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date a img{
  height: 16px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date a span{
  font-size: 15px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p{
  font-size: 15px;
  position: relative;
  top: 3px;
  left: 17px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category{
  width: 58%;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p::before{
  left: -11px;
  width: 2px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content h2 {
 
  font-size: 16px;
  line-height: 29px;
  font-weight: 600;
  height: auto;
  margin-top: 6px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content p {
  font-size: 15px;
  font-weight: 400;
  line-height: 29px;
  margin-top: 8px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content{
  padding: 15px 0px 19px 0px; 
}
.margin-blog-top {
  margin-top: 0px;
}
  .rating .like-count {
    margin-right: 6px !important;
  }
  .blog-list .blog-list-inner .blog-new-list {
    padding: 25px 17px 35px 17px;
}
.blog-list .blog-list-inner .blog-list-sec{
  z-index: 9999;
    position: relative;
    margin-bottom: 25px;
}
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-tag-para-new {
    width: 53%;}
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-tag-para p{
    font-size: 15px;
    padding: 12px 10px;
    line-height: 25px;
  }
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date img {
    height: 17px;
  }
  .blog-details-sec .blog-details-inner .blog-detal-content {
    padding: 10px 20px;}
    .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date a {
      font-size: 15px;
    }
      .blog-details-sec .blog-details-inner .blog-detal-content h2 {
        font-size: 17px;
        margin-top: 13px;
        line-height: 27px;
        margin-bottom: 14px;
      }
        .blog-details-sec .blog-details-inner .blog-detal-content p{
          margin-bottom: 15px;
          line-height: 31px;
          font-size: 15px;
        }
        .blog-details-sec .blog-details-inner .blog-detal-content h3 {
          font-size: 16px;
          margin-bottom: 17px;
          line-height: 29px;
      }
      .blog-details-sec .blog-details-inner .blog-detal-content h4 {
        font-size: 16px;        line-height: 25px;}
        .blog-detal-content .rate-admin-details-sec .rating-blgdetals p{
          font-size: 16px !important;
        }
        .blog-details-sec .blog-details-inner .blog-detal-content .admin-sec p span {
          font-size: 16px;}
          .blog-details-sec .blog-details-inner .blog-detal-content .admin-sec p svg{
            font-size: 19px;
          }
          .blog-detl-call{
            .blog-detl-sec{
              p{
                font-size: 16px;
                line-height: 28px;
                margin-right: 2px;
                width: 100%;
              }
            }
          }
          .blog-detl-call .blog-detl-sec .blg-detl-btn .btn-red-sec {
            padding: 6px 17px;
        }
        .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .input-srch {
          width: 75%;}
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .search-icon{
            padding: 8px 9px;
          }
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box .search-icon svg{
            font-size: 21px;
          }
          .blog-details-sec .blog-detail-rights .blog-search .blog-search-box{
            padding: 12px 0px;
          }
          .input-srch{
            font-size: 15px;
          }
          .blog-details-sec .blog-detail-rights .blog-search p {
            font-size: 16px;}
            .blog-details-sec .blog-detail-rights .blog-search{
              padding: 15px 15px;
              display: none;
            }
            .blog-details-sec .blog-detail-rights .detail-categ p {
              font-size: 20px !important;        
              margin-bottom: 0px;}
              .blog-details-sec .blog-detail-rights .detail-categ .categ-list ul li{
                font-size: 14px;
                padding: 6px 10px;
                line-height: 23px;
                width: 100%;
              }
              .blog-details-sec .blog-detail-rights .detail-categ{
                padding: 15px 15px;
                display: none;
              }
              .blog-details-sec .blog-detail-rights .recent-post p {
                font-size: 17px;padding-bottom: 0px;}
                .blog-details-sec .blog-detail-rights .recent-post{
                  padding: 15px 15px;
                  margin-top: 15px;
                }
                .blog-details-sec .blog-detail-rights .recent-post ul li img{
                  width:25%;
                  margin-right: 12px;
                }
                .blog-details-sec .blog-detail-rights .recent-post ul li p{
                  width: 68%;
                  font-size: 15px;
                  line-height: 23px;
                  position: relative;
                  top: 0px;
                }
                .job-sec h2 {
                  font-size: 20px;
                  margin-bottom: 0px;}
                    .job-sec{
                      padding: 50px 0px;

                    }
                    .job-details .job-desc-inner .job-desc-left h2:after{
                      width:100%;
                    }
                    .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img {
                      width: 10%;}
                    .job-sec .job-inner-sec{
                      margin: 25px auto;
                      max-width: 890px;
                    }
                    .job-sec .job-inner-sec .job-desc-new h3 {
                      font-size: 17px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc{
                    margin: 7px 0px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left {
                    float: left;
                    margin: 8px 20px 4px 0px;
                }
                .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right {
                  margin-bottom: 3px;}
                  .job-sec .job-inner-sec .job-desc-new{
                    padding: 25px 30px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .jon-loc svg{
                    font-size: 18px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left svg{
                    font-size: 18px;
                  }
                  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right svg{
                    font-size: 18px;
                  }
                  .apply-btn-cont{
                    font-size: 16px;
                    margin-top: 7px !important;
                    display: block;
                  }
                  .job-sec .job-inner-sec .job-inner-left{
                    z-index: 999;
                    position: relative;
                    width: 100%;
                    margin-bottom: 25px;
                  }
                  .job-sec .job-inner-sec .job-inner-right {
                    width: 100%;}
                  .job-details{
                    padding: 50px 0px;
                  }
                  .job-details .job-desc-inner .job-desc-left h2 {
                    font-size: 17px;
                  }
                  .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text h3 {
                    font-size: 16px;
                    margin-bottom: 8px;
                }
                .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text p{
                  font-size: 15px;
                  line-height: 23px;
                }
                .footer-sec .footer-left-sec .social-icon{margin: 23px 0px 18px 0px;}
                .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img img {
                  height: 29px;
              }
              .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img svg {
                font-size: 26px;
                position: relative;
                top: -2px;
            }
            .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-experience img{
              height: 23px;
            }
            .job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-quali img{
              height: 23px;
            }
            .job-details .job-desc-inner .job-desc-left{
              padding: 20px 10px;
            }
            .job-details .job-desc-inner .job-desc-left .job-inform-below ul li{
              margin-top:20px;
            }
            .job-details .job-desc-inner .job-desc-right h2 {
              font-size: 18px;}
              .job-details .job-desc-inner .job-desc-right p{
                line-height: 31px;
                font-size: 15px;
                margin-bottom: 13px;
                margin: 0px 0px;
              }
              .job-details .job-desc-inner .job-desc-right ul li{
                margin-bottom: 13px;
                font-size: 15px;
                line-height: 25px;
              }
              .listcarer li span {
                position: relative;
                top: -2px;
            }
            .or-sec{
              padding: 0px 20px !important;
            }
            .job-details .job-desc-inner .job-desc-right{
              margin-left: 10px;
              margin-top: 22px;
              margin-right: 10px;
              padding: 0px 5px !important;
            }
            .listcarer li span{
              top:-2px;
            }
            .job-details .job-desc-inner .job-desc-right ul li::marker{
              font-size: 20px;
            }
              .job-details .job-desc-inner .job-desc-right h4{
                font-size: 16px;
                margin: 14px 0px;
              }
              .btn-job-aply{
              margin-top: 25px;
              margin-right: 0px;
              margin-left: 0px;
              }
              .job-details .job-desc-inner .job-desc-right p {
                margin-bottom: 13px;
            }
             .or-sec{
              margin: 0px 13px !important;
             }
             body .job-detal-sec .uplad-sec-new h2{
              padding: 14px 10px 15px 0px !important;
              margin-top: 0px !important;
             }
             .contact-sec{
              padding: 45px 0px;
             }
             .contact-sec .contact-inner:before{
              width: auto;
              background-color: transparent;
              box-shadow: none;
             }
             .contact-sec .contact-inner .contact-form h2 {
              font-size: 20px;}
              .new-cont-secss .tooltipss {
                left: 123px !important;}
                .new-cont-secss .tooltipss .fa-solid {
                  font-size: 14px !important;
              }
              .cont-newss p{
                font-size: 12px !important;
              }
              .invalid-feedback .error-msg{
                font-size: 13px !important;
              }
              .contact-sec .contact-inner .contact-info{
                padding: 22px 21px;
                max-width: 100%;
              }
              .form-msg-sec{
                margin-top: 8px !important;
              }
              .eror-contact-new{
                top: -5px !important;
              }
              .location-sec h2 {
                font-size: 22px;
                margin-bottom: 20px;
              
            }
            .location-sec{
              padding: 0px !important;
            }
            .location-sec{
              iframe{
                height: 210px;
                width: 100%;        
              }
            }
            .contact-sec .contact-inner .contact-info h2 {
              font-size: 19px;
            margin-top: 6px;
            }
            .prodt-modl-news .new-modl-calen .MuiStack-root .MuiTextField-root label{
              font-size: 15px;
            }
            .new-cont-secss .tooltipss::after{
              font-size: 13px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p{
              font-size: 15px;
              line-height: 30px;
              margin-bottom: 20px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p svg{
              font-size: 22px;
            }
            .contact-sec .contact-inner .contact-info .contact-info-inner p span {
              margin-left: 14px;}
              .social-icons-contact .social-icon li img {
                height: 16px;top: 2px !important;}
                .social-icons-contact .social-icon li .twit-sec {
                  height: 14px;top: 2px !important;}
                  .social-icons-contact .social-icon li{
                    height: 31px;
                    width:31px;
                  }
                  .infinite-scroll-component p{
                    font-size: 15px;
                    line-height: 32px;
                  }
                  .privacy-policy-sec{
                    padding: 50px 0px;
                  }
                  .infinite-scroll-component h3 {
                    font-size: 18px;
                    margin-bottom: 10px;
                    margin-top: 19px;
                }
                .infinite-scroll-component h4 {
                  font-size: 17px;
              }
              .infinite-scroll-component span{
                font-size: 16px !important;
              }
              .infinite-scroll-component ul li{
                margin:7px 0px;
                font-size: 16px;
              }
              .infinite-scroll-component h5 {
                font-size: 17px;}
                .new-font-bold {
                  font-size: 18px !important;
              }
              .busn-main-sec .busn-key-head h2 {
                font-size: 20px;
                margin-bottom: 3px;
            }
            .about-innov-main .main-difeer-about{
              .about-innov-para{
                font-size: 15px !important;
                line-height: 29px;        
              }
            }
            .infinite-scroll-component p {
              margin-bottom: 12px;
          }
            .team-sec .team-newssss{
              .meta-patahhh{
                font-size: 15px !important;
                padding: 10px 0px 8px 0px;
              }
            }
            .home-service-sec .team-newssss h2 {
              margin: 11px 0px 8px 0px;
          }
            .team-sec .team-category-sec .teamming-sec-hover{
              width:100%;
            }
            .homebanner .banner-sec-content {
              margin-top: 25px;
          }
          .service-home-widg{
            width:100%;
            margin-bottom: 20px;
          }
          .main-sec .banner-sec-content p::before{
            left: -2px;
            width: 35px;
          }
          .project-box{
            padding: 30px 26px;
            margin-bottom: 20px;
          }
          .vision-sec{
            padding: 50px 0px;
          }
          .projet-category-sec{
            max-width: 420px;
            padding: 30px 0px 10px 0px;
          }
          .footer-sec .footer-right-sec .email-sec .inputemail{
            width:100%;
            font-size: 14px;
          }
          .footer-sec .footer-right-sec ul li::marker{
            font-size: 20px;
          }
          .header-sec {
            padding: 1px 10px !important;
        }
        .serv-img-inner {
          @include pos-block();
        }
        .imglogbet{
          top:-6px !important;
        }
        .founder-img{
          img{
            height: 270px;
          }
        }
        .team-img-widh{
          width: 50%;
        }
        .team-sec .team-category-sec .team-box .teamimg-sec{
          margin-bottom: 17px;
        }
        .team-sec .team-category-sec .team-box .teamimg-sec::before{
          box-shadow: 3px 4px 6px 0px rgb(0 0 0 / 24%);
        }
        .ps-timeline li .img-time-first img {
          height: 53px !important;
      }
      .ps-timeline li .img-last img {
        height: 53px !important;
    }
    .img-last::before{
      content: none !important;
      background-color: transparent !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .ps-bot p{
      top: -14px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-bot{
      width: 77% !important;
      position:  relative !important;
      margin-top: 0px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-top {
      position: relative;
      bottom: 0px;
      margin-bottom: 0px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    width: 77% !important;}
  .ps-timeline-sec .container ol.ps-timeline li .img-time-first .timedata{
    top: -35px !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-last .timedata{
    top: -35px !important;
  }
  .home-service-sec .service-short-head .short-head-line::before{
    width: 75px;
    margin-right: 13px;
  }
  .home-service-sec .service-short-head .short-head-line::after{
    width: 75px;
    margin-left: 13px;
  }
  .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3{
    padding-top: 5px;
  }
  .service-inner-category .serv-categ-box .serv-categ-para p {
    font-size: 15px !important;
    line-height: 27px !important;
    padding: 13px 10px 0px 10px !important;
}
.key-prodt-width{
  width:50%;
}
.keyfeature-inner{
  margin-top:10px;
}
.case-stu-para {
  font-size: 20px !important;
}
.case-studies-list{
  margin-top: 25px !important;
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
}
.blog-list .blog-inner-sec .blog-search{
  top:-1px;
}
.case-studies-list .case-studies-list-row{
  max-width: 410px;
}
.blog-detl-call .blog-detl-sec {
  padding: 32px 15px;}
  .job-sec .job-inner-sec .job-desc-new .jon-loc span{
    font-size: 15px;
  }
  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-left span{
    font-size: 15px;
  }
  .job-sec .job-inner-sec .job-desc-new .job-desc .job-desc-right span{
    font-size: 15px;
  }
  .contact-sec .contact-inner {
    padding: 10px 20px 20px 20px;
    max-width: 1100px;
}
.form-sec .MuiBox-root .MuiTextField-root label{
  font-size: 15px !important;
}
.navbar-toggler{
  border:none;
}
.navbar-toggler:focus{
  outline:none !important;
  box-shadow: none !important;
}
body .CookieConsent {
  width: 98% !important;
  padding: 0px 2px !important;
}
body .head-cookie{
  font-size: 19px !important;
 }
 body .para-cookk {
  font-size: 15px !important;
  line-height: 28px !important;
}
body .declinbtn{
  margin:8px 0px !important;
  padding:5px 11px !important;
}
body .accptbtn{
  margin:8px 8px !important;
  padding:6px 11px !important;
}
.callactn-sec .callactn-content .red-button-sec {
  width: 100%;
  float: left;
  text-align: left; 
  margin-top: 17px;
}

// for menu bar code start here

.navTrigger {
  width: 29px;
  height: 25px;
  margin: auto;
  position: fixed;
  right: 30px;
  top: 28px;
  cursor: pointer;
}
.navTrigger i {
background-color: #484848;
border-radius: 2px;
content: '';
display: block;
width: 100%;
height: 2px;
}
.navTrigger i:nth-child(1) {
// -webkit-animation: outT 0.8s backwards;
// animation: outT 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}
.navTrigger i:nth-child(2) {
margin: 5px 0;
// -webkit-animation: outM 0.8s backwards;
// animation: outM 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}
.navTrigger i:nth-child(3) {
// -webkit-animation: outBtm 0.8s backwards;
// animation: outBtm 0.8s backwards;
-webkit-animation-direction: reverse;
animation-direction: reverse;
}

.navbar-collapse{
  box-shadow:0 2px 6px rgba(0, 0, 0, 0.161);
  /* margin-bottom: -6px; */
  position: fixed !important;
  width: 100%;
  z-index: 999;
  display: block;
  position: fixed;
  top: 75px;
  background-color: $whitecolor;
  float: right;
  right: 0px;

}
.activeheader{
  background-color: $whitecolor;
}

.nav-link-menu::before{
  opacity: 0;
}
.navbar-expand-lg .navbar-nav{
  margin:0px !important;
}
.navTrigger {
  cursor: pointer;
  width: 30px;
  height: 25px;
  margin: auto;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
}
.scrolled .navbar-collapse{top: 75px;}
.navTrigger i {
  background-color: $textblackcolor;
  border-radius: 2px;
  content: '';
  display: block;
  width: 100%;
  height: 3px;
}



.navTrigger.active i:nth-child(1) {
  -webkit-animation: inT 0.8s forwards;
  animation: inT 0.8s forwards;
}

.navTrigger.active i:nth-child(2) {
  -webkit-animation: inM 0.8s forwards;
  animation: inM 0.8s forwards;
}

.navTrigger.active i:nth-child(3) {
  -webkit-animation: inBtm 0.8s forwards;
  animation: inBtm 0.8s forwards;
  visibility: hidden;
}

@-webkit-keyframes inM {
  50% {
      -webkit-transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(45deg);
  }
}

@-webkit-keyframes outM {
  50% {
      -webkit-transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(45deg);
  }
}

@-webkit-keyframes inT {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(9px) rotate(0deg);
  }
  100% {
      transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes outT {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(9px) rotate(0deg);
  }
  100% {
      transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes inBtm {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(-9px) rotate(0deg);
  }
  100% {
      transform: translateY(-9px) rotate(135deg);
  }
}

@-webkit-keyframes outBtm {
  0% {
      -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
      -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
      -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(-9px) rotate(0deg);
  }
  100% {
      transform: translateY(-9px) rotate(135deg);
  }
}


// new menu bar code start here

.menuToggle {
  display: block;
  position: relative;
  top: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  a {
    text-decoration: none;
    color: #232323;
    transition: all 0.3s ease;
    &:hover {
      color: tomato;
    }
  }
  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }
  span {
    position: relative;
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }
}

.menuToggle {
  input {
    &:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #232323;
      &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }
      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
      }
    }
    &:checked ~ .menuItem {
      transform: none;
    }
    
  }
}

.menuItem {
  position: absolute;
  width: 300px;
  padding: 50px;
  padding-top: 125px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  li {
    padding: 10px 0;
    font-size: 22px;
  }
}


.menu--right {
  .menuToggle {
    position: fixed;
    right: 0;
    input {
      right: 50px;
    }
    span {
      right: 50px;
    }
  }
  .menuItem {
    right: 0;
    margin: -100px 0 0 0;
    transform: translate(100%, 0);
    li {
      a {
        &:hover {
          padding-left: 5px;
        }
      }
    }
  }
}

.job-details .job-desc-inner .job-desc-right h3 {
  font-size: 16px;
  line-height: 27px;}
  .footer-sec .footer-left-sec .map-contect ul{
    margin-bottom: 30px;
  }
  .footer-right-sec{
    .email-sec{
      form{
        width:90%;
      }
    }
  }
  .footer-right-sec{
    h3{
      width:60%;
    }
    .email-sec{
      width: 58%;
      float: left;
    }
    ul{
      width:42% !important;
      margin-top: 0px !important;
    }
  }
  .ps-timeline-sec .container ol.ps-timeline li{
    width:100% !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li {
    height: 100%;
    float: none !important;
    margin-bottom: 60px;
  }
  .ps-timeline{
    display: inline-block !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-sp-top:before{
    width: 2px;
    height: 43px;
    position: absolute;
    top: 47px;
    left: 71%;
  }
  .ps-timeline-sec .container ol.ps-timeline{
    margin: 0px 0px !important;
    padding-top: 70px !important;
  }
  .margin-catg-top {
    margin-top: 0px;
}
.vision-sec{
  .service-inner-category{
    margin-top: 25px !important;
  }
}
.prodtbannerimg{
  margin-top: 40px;
}
.main-innerbanner .prodt-details h1{
margin-bottom: 33px !important;
}
.width-impact-res{
width: 33.33% !important;
float: left;
}
.produt-category .product-box::before{
  height: 88%;
}
.absentcase{
  margin-bottom: 40px;
  .serv-img-inner img{
    position: relative;
    left: -35px;
  }
}
.absentcase .service-img-right::after{
  left: 82px;
}
.prodt-modl-news .MuiDialog-container h2{
  font-size: 20px;
  padding: 20px 17px 15px 17px !important;
}
.service-modl-new .form-serv-sec .serv-head-modl{
  font-size: 17px;
}
.prodt-modl-news .MuiDialog-container .MuiDialogContent-root{
  padding: 10px 35px !important;
}
.blog-detl-call {
  padding: 20px 0px 10px 0px;
}
.prodt-modl-news .form-radio-sec .MuiFormControl-root .MuiFormLabel-root{
  font-size: 16px;
  margin-right: 10px !important;
}

.respon-filter-sec{
  display: block !important;
}
 .blog-details-sec{
padding: 35px 0px 0px 0px;
}
.social-icons-contact .social-icon{
  padding-top: 0px;
  padding-bottom: 10px;
}
.contact-sec .contact-inner .contact-form{
  box-shadow: rgba(137, 137, 137, 0.25) 0px 3px 8px;
  background-color: $whitecolor;
  border-radius: 12px;
  padding: 34px 25px 34px 25px;
  z-index: 999;
 
}
.founder-sec-cont{
  padding: 0px 10px;
}
.footer-sec .container{
  max-width: 100%;
  padding: 0px 0px;
}
.footer-main{
  padding-right: 0px;
}
.footer-sec .footer-left-sec {
  padding: 32px 25px 0px 25px !important;
}
.MuiInputBase-input{font-size: 15px !important;}
.videoplay-modl .MuiDialog-container .MuiDialogContent-root {
  padding: 20px 30px !important;
}
.videoplay-modl .MuiDialog-scrollPaper .MuiPaper-root .MuiButtonBase-root {
  right: -4px !important;
  top: -3px;
}
.container{
  padding-left: 23px;
  padding-right: 23px;
}
.respon-filter-sec{
  padding:55px 0px 0px 0px !important;

}
.blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date{
  padding: 14px 0px 11px 0px;
  width: 38%;
}
.form-modal .casepropri {
  margin-top: 21px !important;
}
.founder-sec-cont h4 .found-link img {
  top: 4px;
}
.blog-details-sec .blog-details-inner .blog-detal-content .admin-sec {
  margin: 0px 0px 20px 0px;
}

 }


 @media only screen and (min-width: 420px) and (max-width: 480px) {
  body .CookieConsent {
    width: 93% !important;
    padding: 0px 2px !important;
}
body .head-cookie {
  font-size: 17px !important;
  margin-bottom: 5px !important;
}
body .para-cookk {
  font-size: 14px !important;
  line-height: 25px !important;
}
body .declinbtn {
  margin: 0px 5px 6px 0px !important;
  padding: 5px 11px !important;
}
body .accptbtn {
  margin: 0px 5px 6px 0px !important;
  padding: 6px 11px !important;
}

  .main-sec .banner-sec-content p{
    line-height: 29px;
  }
  .mainbaner-img {
    margin: 20px 0px 2px 0px;
  }
  .mainbaner-img img {
    height: 240px;
  }
  .main-sec .banner-sec-content h2 {
    font-size: 21px;
    line-height: 37px;
    margin-top: 0px;
  }
  .main-sec .banner-sec-content h1 {
    font-size: 21px !important;
    line-height: 41px;
  }
  .main-sec .new-img-baner p {
    font-size: 17px !important;
    line-height: 30px !important;
  }
  .project-box p{
    margin-bottom: 17px;
    line-height: 36px;
    font-size: 17px;
  }
  .home-projt-sec .service-short-head h2{
    margin-bottom: 15px;
  }
  .homebanner .banner-sec-content {
    margin-top: 5px;
  }
  .homebanner {
    padding: 35px 0px 35px 0px;
  }
  .home-service-sec .service-short-head .short-head-line {
    font-size: 14px !important;
    padding: 0px 0px 0px 0px !important;
  }
  .home-service-sec .service-short-head h2 {
    font-size: 19px;
    line-height: 31px;
  }
  .home-service-sec .service-short-head h2 {
    margin: 20px 0px 12px 0px;
  }
  .home-service-sec .service-short-head .homeserv{
    margin-top: 10px !important;
  }
  .team-sec .team-category-sec .teamming-sec-hover h5 {
    font-size: 30px;
}
  .project-box {
    padding: 26px 26px;
  }
  .bg-icon-belowprojt img {
    height: 90px;
}
.bg-icon-service img {
  height: 90px;
}
.bg-icon-belowservice img {
  height: 90px;
}
.projet-category-sec::before{
  background-size: 49px;
}
  .service-category{
    max-width: 370px;
  }
  .service-category .service-box .service-inner .service-icon .service-para-main h3 {
    font-size: 17px;}
    .service-category .service-box {
      padding: 20px 20px 30px 20px;
  }
  .home-service-sec .service-short-head h2 {
    font-size: 19px;
    line-height: 31px;
  }
  .home-service-sec .service-short-head p {
    font-size: 16px !important;
  }
  .projet-category-sec {
    padding: 25px 0px 10px 0px;
    max-width: 350px;
}
.vision-sec .vision-content p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 22px;
}
.vision-sec .vision-content {
  margin-top: 0px;
}
.footer-right-sec .email-sec {
  width: 100%;
  float: left;
  margin-bottom: 13px;
}
.home-service-sec .details-new p {
  font-size: 15px !important;
}
.busn-sec .business-sec h3 {
  font-size: 16px;}
.footer-right-sec ul {
  width: 100% !important;
  margin-top: 20px !important;
  margin-bottom: 0px !important;
  padding-left: 20px;
}
.footer-sec .footer-right-sec ul li{
  width:42%;
}
.footer-sec .footer-right-sec .copy-sec p {
  margin-top: 0px;
}
.about-main .abut-banner-cont h1 {
  font-size: 21px;
}
.founder-img img {
  height: 250px;
}
.team-sec .service-short-head h2 {
  margin: 14px 0px 12px 0px;
}
.team-sec .team-category-sec {
  padding: 30px 10px 10px 10px;
}
.team-sec .team-category-sec .team-box .teamimg-sec img {
  height: 127px;
}
.team-sec .team-category-sec .team-box .teamimg-sec {
  width: 111px;
  height: 118px
}
.team-sec .team-category-sec .team-box {
  padding: 15px 0px;
}
.ps-timeline-sec .container ol.ps-timeline li p {
  left: 31px;
  top: -4px;
}
.ps-timeline-sec .container ol.ps-timeline li:last-child .ps-bot p {
  top: -18px !important;
}
.ps-timeline-sec .container ol.ps-timeline li .img-last .timedata {
  top: -39px !important;
}
.timedata{
  left:16px !important;
}
.ps-timeline-sec .container ol.ps-timeline {
  padding-top: 50px !important;
}
.footer-main .map-contect ul li:first-child {
  margin-bottom: 20px;
}
.home-service-sec .service-short-head .short-head-line::before {
  width: 55px;margin-right: 10px;
}
  .home-service-sec .service-short-head .short-head-line::after {
    width: 55px;margin-left: 10px;}
    .secure-main .secure-sec .serice-nee-fix {
      line-height: 30px;
      font-size: 16px !important;
    }
    .differ-service-sec .differ-service-inner p {
      font-size: 16px;
      line-height: 32px;
    }
      .vision-sec .vision-content h2 {
        font-size: 19px;
    }
    .service-inner-category .serv-categ-box{
      padding: 25px 10px 25px 10px;
    }
    .vision-sec .vision-content p {
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 18px;
      margin-top: 15px;
  }
  .servicebtn-prof .btn-name {
    top: 1px !important;
}
.red-button-sec .btn-red-sec {
  padding: 5px 11px;
}
.footer-sec .footer-right-sec p{
  padding: 15px 0px 3px 0px;
}
.footer-sec .footer-right-sec .copy-sec p{
  padding: 10px 0px 20px 4px;
}
.footer-sec .footer-right-sec {
  padding: 35px 25px 0px 25px !important;
}
.footer-right-sec .email-sec form {
  width: 80%;
}
  .vision-sec .vision-image-bg .bg-icon-belowservicess img {
    top: -41px;
    height: 90px;
    left: 260px;
}
.impact-sec h2{
margin-bottom: 12px;        
font-size: 19px;}
.produt-category .product-box .prodct-image img {
  width: 100%;
  height: auto;
}
.prodt-detail-main .watch-sec {
  width: 70%;
}
.videoplay-modl .MuiDialog-container .MuiPaper-root .MuiDialogContent-root {
  padding: 20px 30px !important;
}
.consult-btn .btn-inner-sec .btn-name {
  top: 1px;
}
.prodt-modl-news .MuiDialog-container .MuiDialogContent-root {
  padding: 10px 28px !important;
}
.resol-sec p {
  font-size: 16px;
  line-height: 29px;}
.serv-img-inner img{
  height: 200px;
}
.product-main .prodct-right {
  margin-top: 15px;
}
.key-prodt-width {
  width: 100%;
}
.absentcase .serv-img-inner img {
  position: relative;
  left: 1px;
}
.pre-btnss .prev-main-sec{
  margin-right: 20px;
}
.arrownewss {
  padding: 6px;
}
  .arrownew {
    padding: 6px;
  }
  .case-video-sec .case-video-main .case-video-right {
    width: 100%;}
    .case-video-sec .case-video-main .case-video-left {
      width: 100%;        margin-bottom: 110px;}
      .case-video-sec .case-video-main .case-video-left .case-video-box{
        transform: none !important;
      }
      .main-detsila-two .banner-sec-content h1 {
        font-size: 21px !important;
        line-height: 36px;
    }
.outcome-sec h2 {
  font-size: 19px;
}
.blog-details-sec .blog-details-inner .blog-tag-details .blog-tag-para-new {
  width: 100%;}
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date{
    width:100%;
    float: left;
    text-align: left;        
    padding: 10px 10px 11px 10px;
    margin-right: 0px !important;
  }
  .blog-details-sec .blog-details-inner .blog-detal-content {
    padding: 0px 20px;
}
.differ-service-sec .new-case-add p {
  font-size: 16px !important;
  line-height: 29px !important;
}
.blog-details-sec .blog-details-inner .blog-detal-content h2 {
  font-size: 16px;}
  .blog-details-sec .blog-details-inner .blog-detal-content p {
    margin-bottom: 12px;}
    .blog-details-sec .blog-details-inner .blog-detal-content .admin-sec {
      margin: 5px 0px 20px 0px;
  }
  .blog-detl-call .blog-detl-sec {
    padding: 23px 15px;
}
.blog-details-sec .blog-detail-rights .recent-post{
  margin-top: 10px;
}
.job-sec h2 {
  font-size: 19px;}
  .job-sec .job-inner-sec .job-desc-new {
    padding: 23px 27px;
}
.job-sec .job-inner-sec {
  max-width: 350px;
}
.outcome-sec p{
  line-height: 25px;
}
.outcome-sec .outcome-order ul li{
  line-height: 28px;
}
.outcome-sec .outcome-order {
  max-width: 340px;}
.keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec p{
  height: auto;
}
.keyprocs-sec .keyprocs-content h2 {
  font-size: 19px;}
  .detail-carousl-sec .carousl-details-content h2 {
    font-size: 19px;
    margin-bottom: 12px;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec h3{
    height: auto;
    margin-top: 9px;
  }
  .impact-sec .imapct-inner-sec {
    margin: 10px auto 0px auto;
}
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec {
    padding: 26px 21px;
}
.prodtbannerimg {
  margin-top: 20px;
}
.width-impact-res {
  width: 100% !important;
  float: left;
  margin-bottom: 31px;
}
.width-impact-res:last-child {
  margin-bottom: 0px;
}
.impact-sec .imapct-inner-sec .impact-box-center:before{
  display: none;
}
.impact-sec .imapct-inner-sec .impact-box-center:after{
  display: none;
}
.impact-sec .imapct-inner-sec p {
  font-size: 17px;
  font-weight: 500;
}
.keyfeature-inner {
  margin-top: 0px !important;
  width: 100%;
  max-width: 350px !important;
  margin: 0 auto;}
.vision-image img {
  height: 200px;
}
.keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec h3 {
  font-size: 16px;
  line-height: 27px;
}
.proof-sec .prrof-img .proof-content-sec .team-content h3 {
  font-size: 16px;
}
.proof-sec .prrof-img .proof-content-sec .team-content h4 {
  font-size: 15px;}
  .proof-sec .prrof-img .proof-content-sec .team-content{
    bottom: -4%;
  }
  .btn-inner-sec .btn-name {
    font-size: 14px !important;
    top: 2px;
  }
  .btn-inner-sec .btn-icon {
    height: 26px;
    width: 26px;
  }
  .btn-inner-sec .btn-icon svg {
    top: 1px !important;}
.serv-img-inner::before {
  top: 38px;
  left: 110px;
  width: 199px;
  height: 200px;
}
.servleft-img-inner::before {
  top: 44px !important;
  left: 112px !important;
}
.servce-img-left::after {
  top: 31px !important;
  left: 101px !important;
}
.left-side-head-before h2:after {
  margin-right: 0;}
.vision-image::before {
  top: 14px;
  width: 200px;
  height: 191px;
}
.founder-sec-cont h2 {
  font-size: 19px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date {
  width: 100%;}
  .blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category {
    width: 100%;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p::before{
  display: none;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p{
  left:0px;
}
.rating-clock svg{
  top:-1px !important;
}
.left-side-head-before h2 {
  font-size: 19px;
}
.vision-image-bg .serv-bg-iconrigt img {
  right: 263px;}
  .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3 {
    font-size: 17px;
  }
  .case-video-sec .case-video-main .case-video-right .play-sec-prdt .video-play-button {
    top: -73px;
    left: -12%;
}
.video-play-button:before {
  // width: 60px;
  // height: 60px;
  left: 233%;
}
.rating-time p{
  font-size: 14px;
}
.rating .like-count{
  font-size: 14px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content h2{
  margin-top: 0px;
}
.video-play-button:after {
  // width: 55px;
  // height: 55px;
}
// .video-play-button img {
//   left: 30px;
//   height: 16px;
// }
.busn-main-sec .busn-key-head h2 {
  font-size: 19px;
}
.case-bg-icons img {
    left: 70px;
    height: 90px;
}
.case-vieo-inner .play-sec-prdt{
  position: relative;
  display: block;
  .video-play-button{
      left: -5% !important;
      top: -135px !important;
}
 }
 .new-banr-img img {
  height: 230px;
}
.job-sec .job-inner-sec .job-desc-new h3 {
  font-size: 16px;
}
.job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img {
  width: 12%;
}
.job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text{
  width: 85%;
}
.job-details .job-desc-inner .job-desc-right {
  margin-left: 0px;}
.job-details .job-desc-inner .job-desc-left .job-inform-below ul li {
  margin-top: 15px;
}
.location-sec h2 {
  font-size: 20px;
  margin-bottom: 15px;
}
.recaptcha-sec{
  transform: scale(0.94);
    position: relative;
    transform-origin: 0 0;
}
.prodt-modl-news .MuiDialog-container .MuiDialogContent-root .form-sec-main .MuiFormControl-root .MuiFormGroup-root label{margin-right: 8px !important;}
.prodt-modl-news .MuiDialog-container h2 {
  font-size: 19px;
  padding: 18px 0px 9px 0px !important;
}
.MuiInputBase-input {
  font-size: 15px !important;
  padding: 15px 14px !important;
}
.prodt-modl-news .form-radio-sec .MuiFormControl-root .MuiFormGroup-root .MuiTypography-root {
  font-size: 15px;
}
.form-modal .casepropri {
  margin-top: 30px !important;
}
.prodt-modl-news .form-sec {
  margin-bottom: 8px;
}
.prodt-modl-news .form-radio-sec .MuiFormControl-root .MuiFormLabel-root {
  margin-right: 5px !important;
}
.new-respon-case .form-radio-sec .MuiFormControl-root {
    flex-direction: column !important;
}
    body .job-detal-sec .uplad-sec-new h2 {
        padding: 7px 0px 10px 0px !important;
        font-size: 16px !important;
    }
    .contact-form textarea{
      height: 72px !important;
    }
    .error-image {
      height: 190px !important;
  }
  .error-page .error-page-para {
    font-size: 17px !important;
}
.error-page .error-page-head {
  font-size: 22px !important;
}
.error-page-banner {
  height: 90vh !important;
}

}




@media only screen and (min-width: 440px) and (max-width: 480px){
  .prodt-detail-main .watch-sec {
    width: 57%;
  }
  .case-video-sec .case-video-main .case-video-left {
    width: 100%;
    margin-bottom: 90px;
}
.vision-image-bg .serv-bg-iconrigt img {
  right: 333px;
}
.vision-sec .vision-image-bg .bg-icon-belowservicess img {
  top: -21px;
  height: 90px;
  left: 290px;
}
.outcome-sec .outcome-order {
  max-width: 380px;}
  .servce-img-left::after {
    top: 31px !important;
    left: 121px !important;
}
.servleft-img-inner::before {
  top: 44px !important;
  left: 101px !important;
}
.servleft-img-inner img {
  left: 0px !important;
}
}



@media only screen and (max-width: 384px) {
  body .CookieConsent {
    width: 93% !important;
    padding: 0px 2px !important;
}
body .head-cookie {
  font-size: 17px !important;
  margin-bottom: 5px !important;
}
body .para-cookk {
  font-size: 14px !important;
  line-height: 25px !important;
}
body .declinbtn {
  margin: 0px 5px 6px 0px !important;
  padding: 5px 11px !important;
}
body .accptbtn {
  margin: 0px 5px 6px 0px !important;
  padding: 6px 11px !important;
}

  .main-sec .banner-sec-content p{
    line-height: 27px;
    margin-bottom: 10px;
    font-size: 15px !important;
  }
  .mainbaner-img {
    margin: 18px 0px 2px 0px;
  }
  .mainbaner-img img {
    height: 220px;
}
  .main-sec .banner-sec-content h2 {
    font-size: 20px;
    line-height: 30px;
    margin-top: 2px;
  }
  .container {
    padding-left: 18px;
    padding-right: 18px;
}
  .main-sec .banner-sec-content h1 {
    font-size: 20px !important;
    line-height: 41px;
  }
  .main-sec .new-img-baner p {
    font-size: 17px !important;
    line-height: 30px !important;
  }
  .project-box p{
    margin-bottom: 17px;
    line-height: 36px;
    font-size: 17px;
  }
  .home-projt-sec .service-short-head h2{
    margin-bottom: 15px;
  }
  .homebanner .banner-sec-content {
    margin-top: 5px;
  }
  .homebanner {
    padding: 40px 0px 40px 0px;
  }
  .home-service-sec .service-short-head .short-head-line {
    font-size: 14px !important;
    padding: 0px 0px 0px 0px !important;
  }
  .home-service-sec .service-short-head h2 {
    font-size: 19px;
    line-height: 31px;
  }
  .home-service-sec .service-short-head h2 {
    margin: 20px 0px 12px 0px;
  }
  .home-service-sec .service-short-head .homeserv{
    margin-top: 10px !important;
  }
  .team-sec .team-category-sec .teamming-sec-hover h5 {
    font-size: 30px;
}
  .project-box {
    padding: 26px 26px;
  }
  .bg-icon-belowprojt img {
    height: 90px;
}
.bg-icon-service img {
  height: 90px;
}
.bg-icon-belowservice img {
  height: 90px;
}
.projet-category-sec::before{
  background-size: 49px;
}
  .service-category{
    max-width: 370px;
    padding: 27px 0px 0px 0px;
  }
  .service-category .service-box .service-inner .service-icon .service-para-main h3 {
    font-size: 16px;
    top: 17px;
}
.service-category .service-box .service-inner .service-icon .service-para-main {
  width: 80%;
}
    .service-category .service-box {
      padding: 20px 20px 30px 20px;
  }
  .service-category .service-box .service-inner .service-icon .icon-sec .icon-circle {
    width: 50px;
    height: 50px;
}
.service-category .service-box .service-inner .service-icon .icon-sec .icon-circle img {
  height: 29px;
  top: 10px;
}
.service-category .service-box .service-inner .service-icon .icon-sec {
  width: 13%;
  margin-right: 21px;
}
  .home-service-sec .service-short-head h2 {
    font-size: 18px;
    line-height: 28px;
  }
  .home-service-sec {
    padding: 45px 0px;
}
  .home-service-sec .service-short-head p {
    font-size: 16px !important;
    line-height: 25px;
  }
  .project-box-blue{
    margin-bottom: 0px !important;
  }
  .projet-category-sec {
    padding: 25px 0px 10px 0px;
    max-width: 350px;
}
.vision-sec .vision-content p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 22px;
}
.service-box .service-inner .service-content p {
  margin-bottom: 10px;
}
.vision-sec .vision-content {
  margin-top: 0px;
  margin-bottom: 50px;
}
.footer-right-sec .email-sec {
  width: 100%;
  float: left;
  margin-bottom: 13px;
}
.home-service-sec .details-new p {
  font-size: 15px !important;
}
.busn-sec .business-sec h3 {
  font-size: 16px;}
.footer-right-sec ul {
  width: 100% !important;
  margin-top: 20px !important;
  margin-bottom: 0px !important;
  padding-left: 20px;
}
.footer-sec .footer-right-sec ul li{
  width:42%;
}
.footer-sec .footer-right-sec .copy-sec p {
  margin-top: 0px;
}
.about-main .abut-banner-cont h1 {
  font-size: 20px;
}
.founder-img img {
  height: 250px;
}
.team-sec .service-short-head h2 {
  margin: 14px 0px 12px 0px;
}
.team-sec .team-category-sec {
  padding: 30px 10px 10px 10px;
}
.about-main .abut-banner-cont p{
  max-width: 331px;
}
.about-main .abut-banner-cont {
  bottom: 6vh;
}
.founder-sec {
  padding: 45px 0px;
}
.founder-sec-cont h4{
  margin: 12px 0px 9px 0px;
}
.team-sec .team-category-sec .team-box h3 {
  font-size: 15px;
}
.team-sec .team-category-sec .team-box h4 {
  font-size: 15px;
}
.team-sec .team-category-sec .team-box p {
  font-size: 14px;
}
.team-sec .team-newssss .meta-patahhh{
  line-height: 27px;
  padding: 6px 0px 8px 0px;
}
.team-sec .team-category-sec .team-box .teamimg-sec img {
  height: 127px;
}
.team-sec .team-category-sec .team-box .teamimg-sec {
  width: 111px;
  height: 118px
}
.team-sec .team-category-sec .team-box {
  padding: 15px 0px;
}
.ps-timeline-sec .container ol.ps-timeline li p {
  left: 35px;
  top: -4px;
  font-size: 15px;
}
.ps-timeline-sec .container ol.ps-timeline li:last-child .ps-bot p {
  top: -18px !important;
}
.ps-timeline-sec .container ol.ps-timeline li .img-last .timedata {
  top: -39px !important;
}
.timedata{
  left:16px !important;
}
.ps-timeline-sec .container ol.ps-timeline {
  padding-top: 50px !important;
}
.footer-main .map-contect ul li:first-child {
  margin-bottom: 20px;
}
.home-service-sec .service-short-head .short-head-line::before {
  width: 45px;
  margin-right: 10px;
}
  .home-service-sec .service-short-head .short-head-line::after {
    width: 45px;
    margin-left: 10px;}
    .secure-main .secure-sec .serice-nee-fix {
      line-height: 30px;
      font-size: 16px !important;
    }
    .differ-service-sec .differ-service-inner p {
      font-size: 16px;
      line-height: 32px;
    }
      .vision-sec .vision-content h2 {
        font-size: 18px;
    }
    .service-inner-category .serv-categ-box{
      padding: 25px 10px 25px 10px;
    }
    .vision-sec .vision-content p {
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 18px;
      margin-top: 15px;
  }
  .servicebtn-prof .btn-name {
    top: 1px !important;
}
.red-button-sec .btn-red-sec {
  padding: 5px 11px;
}
.footer-sec .footer-right-sec p{
  padding: 15px 0px 3px 0px;
}
.footer-sec .footer-right-sec .copy-sec p{
  padding: 10px 0px 20px 4px;
}
.footer-sec .footer-right-sec {
  padding: 35px 25px 0px 25px !important;
}
.footer-right-sec .email-sec form {
  width: 80%;
}
  .vision-sec .vision-image-bg .bg-icon-belowservicess img {
    top: -41px;
    height: 90px;
    left: 260px;
}
.impact-sec h2{
margin-bottom: 12px;        
font-size: 19px;}
.produt-category .product-box .prodct-image img {
  width: 100%;
  height: auto;
}
.prodt-detail-main .watch-sec {
  width: 70%;
}
.videoplay-modl .MuiDialog-container .MuiPaper-root .MuiDialogContent-root {
  padding: 20px 30px !important;
}
.consult-btn .btn-inner-sec .btn-name {
  top: 1px;
}
.prodt-modl-news .MuiDialog-container .MuiDialogContent-root {
  padding: 10px 28px !important;
}
.resol-sec p {
  font-size: 16px;
  line-height: 29px;}
.serv-img-inner img{
  height: 200px;
}
.product-main .prodct-right {
  margin-top: 15px;
}
.key-prodt-width {
  width: 100%;
}
.absentcase .serv-img-inner img {
  position: relative;
  left: 1px;
}
.pre-btnss .prev-main-sec{
  margin-right: 20px;
}
.arrownewss {
  padding: 6px;
}
  .arrownew {
    padding: 6px;
  }
  .case-video-sec .case-video-main .case-video-right {
    width: 100%;}
    .case-video-sec .case-video-main .case-video-left {
      width: 100%;        margin-bottom: 110px;}
      .case-video-sec .case-video-main .case-video-left .case-video-box{
        transform: none !important;
      }
      .main-detsila-two .banner-sec-content h1 {
        font-size: 21px !important;
        line-height: 36px;
    }
.outcome-sec h2 {
  font-size: 19px;
}
.blog-details-sec .blog-details-inner .blog-tag-details .blog-tag-para-new {
  width: 100%;}
  .blog-details-sec .blog-details-inner .blog-tag-details .blog-detal-date{
    width:100%;
    float: left;
    text-align: left;        
    padding: 10px 10px 11px 10px;
    margin-right: 0px !important;
  }
  .blog-details-sec .blog-details-inner .blog-detal-content {
    padding: 0px 20px;
}
.differ-service-sec .new-case-add p {
  font-size: 16px !important;
  line-height: 29px !important;
}
.blog-details-sec .blog-details-inner .blog-detal-content h2 {
  font-size: 16px;}
  .blog-details-sec .blog-details-inner .blog-detal-content p {
    margin-bottom: 12px;}
    .blog-details-sec .blog-details-inner .blog-detal-content .admin-sec {
      margin: 5px 0px 20px 0px;
  }
  .blog-detl-call .blog-detl-sec {
    padding: 23px 15px;
}
.blog-details-sec .blog-detail-rights .recent-post{
  margin-top: 10px;
}
.job-sec h2 {
  font-size: 19px;}
  .job-sec .job-inner-sec .job-desc-new {
    padding: 23px 27px;
}
.job-sec .job-inner-sec {
  max-width: 350px;
}
.outcome-sec p{
  line-height: 25px;
}
.outcome-sec .outcome-order ul li{
  line-height: 28px;
}
.outcome-sec .outcome-order {
  max-width: 340px;}
.keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec p{
  height: auto;
}
.keyprocs-sec .keyprocs-content h2 {
  font-size: 19px;}
  .detail-carousl-sec .carousl-details-content h2 {
    font-size: 19px;
    margin-bottom: 12px;
  }
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec h3{
    height: auto;
    margin-top: 9px;
  }
  .impact-sec .imapct-inner-sec {
    margin: 10px auto 0px auto;
}
  .keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec {
    padding: 26px 21px;
}
.prodtbannerimg {
  margin-top: 20px;
}
.width-impact-res {
  width: 100% !important;
  float: left;
  margin-bottom: 31px;
}
.width-impact-res:last-child {
  margin-bottom: 0px;
}
.impact-sec .imapct-inner-sec .impact-box-center:before{
  display: none;
}
.impact-sec .imapct-inner-sec .impact-box-center:after{
  display: none;
}
.impact-sec .imapct-inner-sec p {
  font-size: 17px;
  font-weight: 500;
}
.keyfeature-inner {
  margin-top: 0px !important;
  width: 100%;
  max-width: 350px !important;
  margin: 0 auto;}
  .vision-image img {
    height: 190px;
}
.keyprocs-feturesec .keyfeature-box .keyfetur-inner .keyfeatre-inn-sec h3 {
  font-size: 16px;
  line-height: 27px;
}
.proof-sec .prrof-img .proof-content-sec .team-content h3 {
  font-size: 16px;
}
.proof-sec .prrof-img .proof-content-sec .team-content h4 {
  font-size: 15px;}
  .proof-sec .prrof-img .proof-content-sec .team-content{
    bottom: -4%;
  }
  .btn-inner-sec .btn-name {
    font-size: 14px !important;
    top: 2px;
  }
  .btn-inner-sec .btn-icon {
    height: 26px;
    width: 26px;
  }
  .btn-inner-sec .btn-icon svg {
    top: 1px !important;}
.serv-img-inner::before {
  top: 38px;
  left: 110px;
  width: 199px;
  height: 200px;
}
.servleft-img-inner::before {
  top: 44px !important;
  left: 112px !important;
}
.servce-img-left::after {
  top: 31px !important;
  left: 101px !important;
}
.left-side-head-before h2:after {
  margin-right: 0;}
.vision-image::before {
  top: 13px;
  width: 190px;
  height: 181px;
}
.founder-sec-cont h2 {
  font-size: 18px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-date {
  width: 100%;}
  .blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category {
    width: 100%;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p::before{
  display: none;
}
.blog-list .blog-list-inner .blog-list-sec .blog-impor-sec .blog-category p{
  left:0px;
}
.founder-sec-cont p{
  margin: 14px 0px;
}
.founder-sec-cont h3{
  margin-top: 20px;
}
.rating-clock svg{
  top:-1px !important;
}
.left-side-head-before h2 {
  font-size: 19px;
}
.vision-image-bg .serv-bg-iconrigt img {
  right: 263px;}
  .service-inner-category .serv-categ-box .serv-categ-main .serv-categ-head h3 {
    font-size: 17px;
  }
  .case-video-sec .case-video-main .case-video-right .play-sec-prdt .video-play-button {
    top: -73px;
    left: -12%;
}
.video-play-button:before {
  // width: 60px;
  // height: 60px;
  left: 233%;
}
.rating-time p{
  font-size: 14px;
}
.rating .like-count{
  font-size: 14px;
}
.blog-list .blog-list-inner .blog-list-sec .blog-list-content h2{
  margin-top: 0px;
}
.video-play-button:after {
  // width: 55px;
  // height: 55px;
}
// .video-play-button img {
//   left: 30px;
//   height: 16px;
// }
.busn-main-sec .busn-key-head h2 {
  font-size: 19px;
}
.case-bg-icons img {
    left: 70px;
    height: 90px;
}
.case-vieo-inner .play-sec-prdt{
  position: relative;
  display: block;
  .video-play-button{
      left: -5% !important;
      top: -135px !important;
}
 }
 .new-banr-img img {
  height: 230px;
}
.job-sec .job-inner-sec .job-desc-new h3 {
  font-size: 16px;
}
.job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-img {
  width: 12%;
}
.job-details .job-desc-inner .job-desc-left .job-inform-below ul li .info-icon .info-text{
  width: 85%;
}
.job-details .job-desc-inner .job-desc-right {
  margin-left: 0px;}
.job-details .job-desc-inner .job-desc-left .job-inform-below ul li {
  margin-top: 15px;
}
.location-sec h2 {
  font-size: 20px;
  margin-bottom: 15px;
}
.recaptcha-sec{
  transform: scale(0.94);
    position: relative;
    transform-origin: 0 0;
}
.prodt-modl-news .MuiDialog-container .MuiDialogContent-root .form-sec-main .MuiFormControl-root .MuiFormGroup-root label{margin-right: 8px !important;}
.prodt-modl-news .MuiDialog-container h2 {
  font-size: 19px;
  padding: 18px 0px 9px 0px !important;
}
.MuiInputBase-input {
  font-size: 15px !important;
  padding: 15px 14px !important;
}
.prodt-modl-news .form-radio-sec .MuiFormControl-root .MuiFormGroup-root .MuiTypography-root {
  font-size: 15px;
}
.form-modal .casepropri {
  margin-top: 30px !important;
}
.prodt-modl-news .form-sec {
  margin-bottom: 8px;
}
.prodt-modl-news .form-radio-sec .MuiFormControl-root .MuiFormLabel-root {
  margin-right: 5px !important;
}
.new-respon-case .form-radio-sec .MuiFormControl-root {
    flex-direction: column !important;
}
  body .job-detal-sec .uplad-sec-new h2 {
      padding: 7px 0px 10px 0px !important;
      font-size: 16px !important;
  }
  .contact-form textarea{
    height: 72px !important;
  }
  .error-image {
    height: 190px !important;
}
.error-page .error-page-para {
  font-size: 17px !important;
}
.error-page .error-page-head {
font-size: 22px !important;
}
.error-page-banner {
height: 90vh !important;
}
.email-verify-sec p {
  font-size: 12px;
}
.tooltipss i {
  font-size: 15px;
  top: 5px;
}

}










 @media only screen and (min-width: 576px) and (max-width: 767px){}
 @media only screen and (min-width: 768px) and (max-width: 991px){}
 @media only screen and (min-width: 912px) and (max-width: 980px) {}
 @media only screen and (max-width: 360px){}
 @media only screen and (max-width: 340px) {}
 @media only screen and (max-width: 320px){}
 @media only screen and (max-width: 384px) {

 }
 @media only screen and (max-width: 419px) and (min-width: 385px) {}
 @media only screen and (max-width: 420px) {}
 @media only screen and (min-width: 420px) and (max-width: 480px) {}
 /*Media Query code end from here */

.imglogbet{
  text-align: right;
  font-size: 12px;
  position: relative;
  top: -13px;
  color: $lightorangecolor;
  font-weight: 600;
  margin-bottom: 0px;

}

.CookieConsent{
background-color: $whitecolor !important;  
color:$textblackcolor !important;
align-items: baseline !important;
display: flex !important;
flex-wrap: wrap !important;
justify-content: center !important;
left: 50% !important;
width: 77% !important;
transform: translate(-50%,-13%) !important;
border: 2px solid $bluecolor !important;
box-shadow: #077dcc21 0px 7px 29px 0px !important;
font-family: $main-font !important;
padding: 15px 15px !important;
}
.head-cookie{
  text-align: left !important;
    color: $textblackcolor !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    margin-bottom: 10px !important;
}
.para-cookk{
  text-align: left !important;
  margin-bottom: 0px !important;
  font-size: 16px !important;
  line-height: 29px !important;
}
.accptbtn{
  background:$bluecolor !important;
    border: 0px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    color: $whitecolor !important;
    cursor: pointer !important;
    flex: 0 0 auto !important;
    padding: 9px 15px !important;
    margin: 2px !important;
    font-size: 15px !important;
}
.declinbtn{
  padding: 9px 15px !important;
  margin: 11px !important;
  font-size: 15px !important;
  border: 1px solid #077dcc !important;
    margin: 11px !important;
    background-color: transparent !important;
    color: #077dcc !important;
}

.videoplay-modl .MuiDialog-container .MuiPaper-root {
  max-width: 666px !important;}

  .videoplay-modl .MuiDialog-scrollPaper .MuiPaper-root .MuiButtonBase-root{right:-2px;}

  .cont-new-very{
    position: relative;
    top: -15px;
  }
 .new-cont-secss .tooltipss{
  left: 150px;
  bottom:12px;
  .fa-solid{
    font-size: 18px;
  }
 }

 .cont-newss{
  position: relative;
  display: block;
  p{
    position: absolute;
    right: 11px;
    font-size: 13px;
  }
 }


 .new-cont-secss .tooltipss::before{
    left: 2%;
    top: 0;
    transform: translateY(0%) translateX(-70%);
  }
  .new-cont-secss .tooltipss::after{
  top: 0;
    left: 50%;
    transform: translateY(-100%) translateX(-70%);
    padding: 1px 10px;

  }
  .error-page-banner{
    height: 90vh;
    width: 100%;
    padding: 0px !important;

  }
  .error-page{
    width: 100%;
    position: relative;
    display: block;
    padding-top: 15px;
    .error-page-head{
      color: $lightorangecolor;
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .error-page-para{
      color: #2a2a2a;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 2px;
    }
  }
  .btn-aror-algn{
    text-align: center;
    margin-top: 30px;
  }
  .error-image{
    height: 280px;;
  }

  .thankyouimg{
    height: 100px;
  }
 .thanks-para{
  margin-bottom: 12px;
 }
 .thanksuou{
  margin-top: 80px;
  h2{
    color: $bluecolor;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 25px;
  }
  p{
    color: #2a2a2a;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 7px;
  }
 }
 .rating{
  float: right;
 }

 .rating {
display: flex;
margin-top: 10px;
p{
  margin-bottom: 0px;
  margin-right: 17px;
  font-size: 17px;
  position: relative;
  top: 1px;
  font-weight: 500;
}
}

.like
{
  display: inline-block;
  cursor: pointer;
  margin: 0px;
  font-size: 18px;
  margin-right: 7px;
  color: $bluecolor;
}

.dislike{
  position: relative;
  top: 3px;
  display: inline-block;
  cursor: pointer;
  margin: 0px;
  font-size: 18px;
  margin-right: 0px;
  color: #3e3e3e;
}
.like:hover {
  color: $likegrey;
  transition: all .2s ease-in-out;
  transform: scale(1.1);
}

.dislike:hover{
  color: $red;
  transition: all .2s ease-in-out;
  transform: scale(1.1);
}

.blog-detal-content .rate-admin-details-sec .rating-blgdetals{
  float: left;
  margin-top: 7px;
  p{
  margin-bottom: 0px;
  margin-right: 17px;
  font-size: 17px;
  position: relative;
  top: -3px;
  font-weight: 500;
}

}

.ratils-detal{
 
  float: left;
  width: 100%;
  margin-left: 16px;
  margin-bottom: 35px;
  margin-top: 6px;
}

.rateing-width .rating p{
  font-size: 16px;
}
.listcarer li span{
  position: relative;
  top: -3px;
}

.scrolled{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  background: $whitecolor;
  box-shadow: 0px 0px 10px 0px #0000001a;
  transition: all 0.3s ease-in-out;
  padding: 0px 0px;
  nav{
    .navbar-brand{
      position: relative;
      top: 5px;
    }
  }
}

.modl-blog-widh .MuiDialog-container .MuiPaper-root {
  max-width: 670px !important;}
  .modl-submit-cate{
    margin-bottom: 20px;
    margin-top: 35px;
  }
  .modl-blog-widh .MuiDialog-container h2{
    margin-bottom: 15px;
  }

  .like-count{
    margin-right: 4px !important;
    font-size: 16px !important;
    position: relative !important;
    top: 3px !important;
    color: $green;
  }
  .like-count-details{
    margin-right: 7px !important;
    font-size: 16px !important;
    position: relative !important;
    top: -1px !important;
    color: #188351 !important;
  }

  .new-file-del{
    p{
      color: $textblackcolor;
    font-size: 15px;
      font-weight: 500;
    }
    span{
      color:$red;
      svg{
        font-size: 19px;
        position: relative;
        top: -2px;
        left: 3px;
        cursor: pointer;
      }
    }
  }

  .form-modal .casepropri{
    margin-top: 41px !important;
  }

  .key-main-secss .new-case-add p{
    font-weight: 500;
  }

  .respon-filter-sec{
    display: none;
    background-color: $whitecolor;
        padding:60px 0px 0px 0px !important;
  }

  .footer-fedback{
    .error-msg{
      top:4px;
    }
  }
  .footer-sec .form-control:focus{
    box-shadow: none;
  }
  .footer-sec .form-control:focus,
  .footer-sec .form-control:active {
     background: transparent;
  }
  .footer-sec input:-webkit-autofill {
    background-color: $geryfotcolor !important;
    box-shadow: 0 0 0px 1000px $geryfotcolor inset !important;
    -webkit-box-shadow: 0 0 0px 1000px $geryfotcolor inset !important;
}


.read-secss{
  text-align: center;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 98;
  left: 0;
  background: linear-gradient(3deg, $whitecolor 0%, rgb(255 255 255 / 72%) 100%);
  /* height: 250px; */
  padding: 20px 0px 70px 0px;
 
  .button-sec{
  text-align: center;
}
}

.read-main-secsss{
  position: relative;
  width: 100%;
}

.read-secss-inner{
  position: relative;
  top: 30px;
  p{
    margin-bottom: 0px !important;
    color: $bluecolor !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    margin-top: 3px !important;
  }
}
.rating-time{
  display: flex;
  p{
    margin-bottom: 0px;
    position: relative;
    top: 12px;
    color: #188351;
    font-weight: 500;
    font-size: 15px;
  }
}

.btn-flex{
  display: flex;
    justify-content: space-between;
}

.navbar-collapse{
  z-index: 9999;
}
.rating-clock{
  svg{
    font-size: 19px;
    margin-right: 4px;
    position: relative;
    top: 0px;
  }
}



 .toolcontent {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

 .toolcontent::after {
  content: attr(data-content);
    position: absolute;
    background: #313131;
    color: $whitecolor;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: normal;
    width: 440px;
    text-align: center;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: 100;
    line-height: 20px;
}

 .toolcontent:hover::after,
 .toolcontent:focus::after {
  opacity: 1;
  visibility: visible;
}
.new-cont-secss .tooltipss::after{
  padding: 4px 10px;
}

/* Adjust tooltip for small screens */
@media (max-width: 480px) {
 .toolcontent::after {
    width: 150px; /* Reduce width */
    font-size: 12px; /* Smaller font size */
  }
}


.carousal-img{
  img{
    width: 100%;
  }
}
